export default function ProBadge({ classes = '', size = 10 }) {
  return (
    <span
      className={`pro-badge ${classes} d-infline-flex align-items-center flex-direction-column`}
    >
      <svg
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05556 2.72727L5 0L6.94444 2.72727L10 1.5L9.06291 5.71693C8.96123 6.17447 8.55542 6.5 8.08672 6.5H1.91328C1.44459 6.5 1.03877 6.17447 0.937096 5.71693L0 1.5L3.05556 2.72727Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8C1 7.72386 1.22386 7.5 1.5 7.5H8.5C8.77614 7.5 9 7.72386 9 8V9.5C9 9.77614 8.77614 10 8.5 10H1.5C1.22386 10 1 9.77614 1 9.5V8Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}
