import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import ProBadge from '../../components/global/ProBadge';
import HorseIcon from '../../components/global/HorseIcon';
import Loader from '../../components/global/Loader';
// import CalendarToday from '../../components/seller/CalendarToday';
import { getSellerUsers } from '../../libs/apis';

function SellerIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);
  const initialState = { sellers: [], isLoading: true, isError: false };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { sellers, isLoading } = state;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  const isSuperUser = user.super_user || false;

  useEffect(() => {
    const getSellerData = () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        subscription: 1,
      };
      getSellerUsers(payload)
        .then(resp => {
          if (resp.data.response.length > 0) {
            setState({ sellers: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getSellerData();
  }, []);

  const isPro = user.subscription || false;

  return (
    <div className="admin-container">
      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content mt-1">
                <div className="heading text-center mb-3">
                  <h3 className="fs-6 fw-bold">
                    {t('welcome_hi')}, {user.name}
                  </h3>
                </div>

                <Link
                  to="/seller/schedule"
                  className="btn btn-primary btn-lg w-100 mb-3"
                >
                  <span className="fa fa-calendar me-2"></span>
                  {t('view_my_schedule')}
                </Link>

                <div className="admin-menu  mb-3">
                  <div className="row gx-3">
                    <div className="col-12">
                      <Link
                        to="/seller/order"
                        className="btn shadow-sm btn-sm btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-list"></span>
                        </span>
                        <span className="text">
                          <span className="fw-bold">{t('order_text')}</span>
                        </span>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/seller/product"
                        className="btn btn-sm shadow-sm btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-wrench"></span>
                        </span>
                        <span className="text">
                          <span className="fw-bold">{t('product_text')}</span>
                        </span>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/seller/inventory"
                        className="btn btn-sm shadow-sm btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-briefcase"></span>
                        </span>
                        <span className="text">
                          <span className="fw-bold">{t('inventory_text')}</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                {isSuperUser && (
                  <div className="pro-feature mb-3">
                    <hr />
                    <h6 className=" fw-bold mb-3">{t('pro_manage_seller')}</h6>
                    <div className="card border-2 rounded-3">
                      <div className="card-body p-3">
                        {isLoading ? (
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                        ) : (
                          <>
                            <h6 className="mb-3 d-flex align-items-end text-muted fw-bold">
                              <div className="seller-icon alert-success text-success w-32 h-32 rounded-circle me-2 lh-32 text-center">
                                <span className="fa fa-users"></span>
                              </div>
                              <span className="me-1 fs-3 fw-bold text-dark">
                                {sellers.length}
                              </span>{' '}
                              {t('pro_seller_subscribed')}
                            </h6>
                            <div className="row gx-3">
                              <div className="col-6">
                                <Link
                                  to="/pro/upgrade"
                                  className="btn btn-primary w-100 shadow-sm"
                                >
                                  <span className="text-warning fa fa-chevron-up me-2"></span>
                                  {t('pro_upgrade')}
                                </Link>
                              </div>
                              <div className="col-6">
                                <Link
                                  to="/pro/downgrade"
                                  className="btn btn-outline-secondary w-100 shadow-sm"
                                >
                                  <span className="me-2 fa fa-chevron-down text-danger"></span>
                                  {t('pro_downgrade')}
                                </Link>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isPro && (
                  <div className="pro-features">
                    <div className="heading text-center mb-3">
                      <h3 className="fs-6 fw-bold">
                        {t('pro_seller_feature_heading')}
                      </h3>
                    </div>

                    <Link
                      to="/pro/create-order"
                      className="btn btn-outline-gold btn-lg fs-6 fw-bold w-100 mb-4"
                    >
                      <span className="fa fa-calendar-o me-2"></span>
                      {t('pro_create_order')}
                    </Link>
                  </div>
                )}

                <div className="pro-feature mb-3 d-none">
                  <div className="card shadow border-2 border-primary">
                    <div className="card-header bg-primary rounded-0">
                      <h6 className="fs-bold mb-0 text-center text-light d-flex align-items-center">
                        <ProBadge
                          size={12}
                          classes="text-light me-1"
                          inverseClass="text-primary"
                        />
                        PRO Seller
                      </h6>
                    </div>
                    <div className="card-body p2">
                      {isSuperUser ? (
                        <div className="btn-container">
                          <div className="alert alert-warning mb-3 p-2 rounded-pill shadow">
                            <h6 className="mb-0 text-reset fw-bold small text-center">
                              As Pro Seller you can use these features:
                            </h6>
                          </div>
                          <div className="row gx-3">
                            <div className="col-6">
                              <Link
                                to="/seller/horses"
                                className="btn btn-primary w-100 shadow"
                              >
                                <span
                                  style={{ fill: 'white' }}
                                  className="me-2"
                                >
                                  <HorseIcon />
                                </span>
                                Manage Horses
                              </Link>
                            </div>
                            <div className="col-6">
                              <Link
                                to="/seller"
                                className="btn btn-primary w-100 shadow"
                              >
                                <span className="me-2 fa fa-wrench"></span>
                                Pro Setting
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="alert alert-warning mb-0">
                          <p>
                            Upgrade your account to PRO Seller and get these
                            benefit:
                          </p>
                          <ul>
                            <li>Manage your own horses</li>
                            <li>Use pro invoice features (coming soon)</li>
                            <li>Manage your pro company (coming soon)</li>
                            <li>And many more exciting features</li>
                          </ul>
                          <button className="btn btn-primary shadow w-100">
                            <span className="fa fa-check me-2"></span>
                            Unlock PRO Seller
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerIndex;
