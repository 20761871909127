import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTransaction } from '../../libs/apis';
import LoadMore from '../global/LoadMore';
import Loader from '../global/Loader';
import BoxOrder from '../global/BoxOrder';
import Modal from 'react-modal';
export default function OrderSummary() {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
    current: 1,
    last: 99,
    hasNext: false,
    isLoadMore: false,
    type: 0,
    status: 0,
    show: 0,
    isOpen: false,
    sortBy: 'desc',
    orderStatus: [],
    // searchText: '',
    shouldFilter: true,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };

  const {
    orders,
    isLoading,
    current,
    hasNext,
    isLoadMore,
    type,
    status,
    show,
    isOpen,
    sortBy,
    orderStatus,
    // searchText,
    shouldFilter,
  } = state;

  useEffect(() => {
    const getOrderData = async () => {
      setState({ isLoading: true, orders: [] });
      const payload = {
        type,
        status: orderStatus.map(e => e).join(','),
        show,
        sort: sortBy,
      };
      await getTransaction(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
            const page = resp.data.metadata.pagination?.page || null;

            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            } else {
              setState({ hasNext: false });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() =>
          setState({ isLoading: false, shouldFilter: false, isOpen: false }),
        );
    };
    if (shouldFilter) {
      getOrderData();
    }
  }, [user, type, status, sortBy, show, orderStatus, shouldFilter]);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
      type,
      status: orderStatus.map(e => e).join(','),
      show,
      sort: sortBy,
    };
    await getTransaction(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ orders: [...orders, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };

  if (!user) return null;
  const isPro = user.subscription || false;

  let filterCount = 0;
  if (sortBy !== 'desc') {
    filterCount += 1;
  }
  if (show !== 0) {
    filterCount += 1;
  }

  if (type !== 0) {
    filterCount += 1;
  }
  if (orderStatus.length > 0) {
    filterCount += orderStatus.length;
  }

  const orderTypeOptions = [
    { label: 'order_type_all', value: 0 },
    { label: 'order_type_normal', value: 1 },
    { label: 'order_type_opendate', value: 2 },
    { label: 'order_type_continuous', value: 3 },
    { label: 'order_type_emergency', value: 4 },
  ];

  const orderStatusOptions = [
    { label: 'order_type_all', value: 0 },
    { label: 'new_order_status', value: 1 },
    { label: 'changed_status', value: 2 },
    { label: 'confirmed_status', value: 3 },
    { label: 'invoice_sent_status', value: 4 },
    { label: 'payment_confirmation_status', value: 5 },
    { label: 'completed_status', value: 6 },
    { label: 'cancelled_status', value: 7 },
  ];

  const handleFilter = () => {
    setState({ shouldFilter: true });
  };
  const resetFilter = () => {
    setState({
      shouldFilter: true,
      sortBy: initialState.sortBy,
      orderStatus: initialState.orderStatus,
      show: initialState.show,
      type: initialState.type,
      current: 1,
    });
  };

  return (
    <div className="order-container">
      <div className="content py-3">
        <div className="px-1">
          <div className="d-flex justify-content-end">
            {/* <div className="col-4 col-md-3">
              <div className="field">
                <select
                  name="field"
                  id="field"
                  className="form-select mb-2"
                  defaultValue={searchType}
                  onChange={e => setState({ searchType: e.target.value })}
                >
                  <option value="-1" selected>
                    {t('field_option')}
                  </option>
                  <option value="1">{t('show_customer_only')}</option>
                  <option value="2">{t('show_pro_only')}</option>
                </select>
              </div>
            </div> */}
            {/* <div className="col-12 col-md-8">
              <div className="search-box mb-3">
                <input
                  type="search"
                  className="form-control"
                  placeholder={t('search_text')}
                  value={searchText}
                  onChange={e => setState({ searchText: e.target.value })}
                />
              </div>
            </div> */}
            <div className="">
              <div className="mb-3">
                <button
                  className={`btn rounded-pill align-items-center d-flex justify-content-center align-items-center ${
                    filterCount > 0
                      ? 'btn-primary'
                      : 'btn-outline-secondary text-primary'
                  }`}
                  onClick={() => setState({ isOpen: true })}
                >
                  <span className="fa fa-filter me-2"></span>
                  <span>{t('filter_label')}</span>
                  {filterCount > 0 && (
                    <span className="badge bg-white text-primary rounded-circle ms-2">
                      {filterCount}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={isOpen}
          onRequestClose={() => {
            setState({ isOpen: false });
          }}
          contentLabel="Modal"
          className="reveal p-3 full mt-5"
          ariaHideApp={false}
        >
          <div className="modal-container">
            <div className="d-flex mb-4 align-items-center justify-content-between">
              <div className="close-button d-flex align-items-center">
                <button
                  className="btn p-0 btn-close mb-0"
                  onClick={() => {
                    setState({ isOpen: false });
                  }}
                ></button>
                <h6 className="mb-0 ms-2 fw-bold">{t('filter_label')}</h6>
              </div>

              <button
                className="btn btn-link mb-0 fw-bold text-danger text-decoration-none p-0"
                onClick={resetFilter}
              >
                {t('reset_filter_button')}
              </button>
            </div>
            <div className="content">
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <label
                  htmlFor="sortBy"
                  className="fw-bold me-2"
                  style={{ textTransform: 'none' }}
                >
                  {t('sort_by_text')}
                </label>
                <select
                  name="sortBy"
                  id="sortBy"
                  className="form-select w-75"
                  defaultValue={sortBy}
                  onChange={e => setState({ sortBy: e.target.value })}
                >
                  <option value="desc" selected>
                    {t('desc_label')}
                  </option>
                  <option value="asc" selected>
                    {t('asc_label')}
                  </option>
                </select>
              </div>
              {/* <div className="sortby d-flex flex-row align-items-center mb-3">
                <label className="me-5 fw-bold text-dark" htmlFor="sortBy">
                  {t('sortby')}
                </label>
                <select
                  name="sortBy"
                  id="sortBy"
                  className={`${
                    sortBy
                      ? 'form-select ms-auto w-50 text-primary fw-bold  '
                      : 'form-select ms-auto w-50'
                  }`}
                  defaultValue={sortBy}
                  onChange={e => setState({ sortBy: e.target.value })}
                >
                  <option value={0} className="text-dark l">
                    {t('sortby_last_to_first')}
                  </option>
                  <option value={1} className="text-dark">
                    {t('sortby_first_to_last')}
                  </option>
                  <option value={2} className="text-dark">
                    {t('sortby_by_horse_name')}
                  </option>
                  <option value={3} className="text-dark">
                    {t('sortby_by_stable_name')}
                  </option>
                  <option value={4} className="text-dark">
                    {t('sortby_by_product_name')}
                  </option>
                </select>
              </div> */}
              <div className="show d-flex justify-content-between align-items-center mb-3">
                <label
                  className="me-2 fw-bold"
                  htmlFor="show"
                  style={{ textTransform: 'none' }}
                >
                  {t('show_label')}
                </label>
                <select
                  name="show"
                  id="show"
                  className="form-select w-75"
                  defaultValue={show}
                  onChange={e => setState({ show: e.target.value })}
                >
                  <option value="0">{t('show_all')}</option>
                  <option value="customer">{t('show_customer_only')}</option>
                  <option value="order">{t('show_pro_only')}</option>
                </select>
              </div>
              <div className="order-types d-flex align-items-center justify-content-between mb-4">
                <label
                  className="me-2 fw-bold"
                  htmlFor="type"
                  style={{ textTransform: 'none' }}
                >
                  {t('order_type_label')}
                </label>
                <select
                  name="ordertypes"
                  id="ordertypes"
                  className="form-select w-75"
                  defaultValue={type}
                  onChange={e => setState({ type: e.target.value })}
                >
                  {orderTypeOptions.map(opt => (
                    <option value={opt.value} key={opt.value}>
                      {t(opt.label)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="order-status mb-4">
                <h6 className="fw-bold mb-3">{t('order_status_label')}</h6>
                <div className="flex flex-wrap">
                  {orderStatusOptions.map(opt => (
                    <button
                      key={opt.value}
                      className={`btn me-2 mb-2 fs-12 rounded-pill ${
                        orderStatus.findIndex(
                          status => status === opt.value,
                        ) !== -1
                          ? 'btn-outline-primary fw-bold'
                          : 'btn-outline-secondary border-1'
                      }`}
                      onClick={() => {
                        if (
                          orderStatus.findIndex(ot => ot === opt.value) !== -1
                        ) {
                          const newOrderStatus = orderStatus.filter(
                            ot => ot !== opt.value,
                          );
                          setState({ orderStatus: newOrderStatus });
                        } else {
                          setState({
                            orderStatus: [...orderStatus, opt.value],
                          });
                        }
                      }}
                    >
                      {t(opt.label)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <button className="btn btn-primary w-100" onClick={handleFilter}>
              {t('apply_filter_button')}
            </button>
          </div>
        </Modal>

        {orders.length > 0 ? (
          <div className="order-list-container">
            {orders.map(order => {
              return <BoxOrder data={order} key={order.id} />;
            })}
          </div>
        ) : (
          <div className="text-center p-3">
            {isLoading ? (
              <Loader size="24" />
            ) : (
              <h6>{t('there_is_no_order')}</h6>
            )}
          </div>
        )}
        {hasNext && <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />}
      </div>
      {isPro && (
        <Link
          to={`/pro/create-order`}
          className="btn btn-gold btn-lg rounded-circle fs-4 d-flex justify-content-center align-items-center flex-direction-col position-fixed shadow p-0"
          style={{
            right: '1.5rem',
            bottom: '4.5rem',
            width: '56px',
            height: '56px',
            lineHeight: '56px',
            zIndex: '109',
          }}
        >
          <span className="fa fa-plus"></span>
        </Link>
      )}
    </div>
  );
}
