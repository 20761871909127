import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Page from '../../components/global/Page';
import InternalHeader from '../../components/global/InternalHeader';
import { requestForgotPassword } from '../../libs/apis';

function ForgotPassword() {
  const { t } = useTranslation();

  const initialState = {
    email: '',
    isSuccess: false,
    isLoading: false,
    isError: false,
    message: '',
  };

  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };

  const { email, isSuccess, isLoading, isError, message } = state;

  const handleForgot = async e => {
    e.preventDefault();
    setState({ isLoading: true, isError: false, message: '' });
    const payload = {
      email,
    };
    await requestForgotPassword(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({
            isSuccess: true,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, message: 'Error' });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  };

  return (
    <div className="app-container">
      <InternalHeader go="/login" title={t('forgot_password_heading')} />

      <div className="main-content py-3">
        <Page>
          {/* <h1 className="mb-3 text-center">Login</h1> */}
          {isError ? (
            <div className="alert alert-danger fade show" role="alert">
              {message}
            </div>
          ) : null}
          {!isSuccess ? (
            <form onSubmit={handleForgot}>
              <h6 className="m-3 small text-center">
                {t('forgot_password_text')}
              </h6>
              <div className="p-3">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={e => setState({ email: e.target.value })}
                    disabled={isLoading}
                    placeholder={t('email_label')}
                  />
                  <label htmlFor="email">{t('email_label')}</label>
                </div>

                <button
                  className="btn mt-1 bg-gradient btn-lg btn-primary shadow w-100"
                  type="submit"
                  disabled={isLoading}
                >
                  {t('submit_button')}
                </button>
              </div>
            </form>
          ) : (
            <div className="p-3 text-center">
              <p>{t('forgot_password_success_text')}</p>
              <Link to="/" className="btn btn-primary">
                {t('go_to_homepage_button')}
              </Link>
            </div>
          )}
        </Page>
      </div>
    </div>
  );
}

export default ForgotPassword;
