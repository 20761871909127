import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { getCompanySellers, addCompanySeller } from '../../libs/apis';
import userImg from '../../assets/images/user.png';

export default function WidgetInviteSeller({ companyId }) {
  const { t } = useTranslation();
  const initialState = {
    data: [],
    isOpen: false,
    isLoading: true,
    isError: false,
    isSubmit: false,
    isInviting: false,
    isInvitingError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen, data } = state;

  useEffect(() => {
    const getSellerData = async () => {
      const payload = {
        id: companyId,
      };
      await getCompanySellers(payload)
        .then(res => {
          if (res.status === 200) {
            setState({ data: res.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getSellerData();
  }, [companyId]);

  const invite = async (sellerIndex, sellerId) => {
    setState({ isInviting: true, isInvitingError: false });
    const payload = {
      id: companyId,
      seller_id: sellerId,
    };
    await addCompanySeller(payload)
      .then(res => {
        if (res.status === 201) {
          const userCompany = res.data.response;
          const newData = data;
          newData[sellerIndex].companies.push(userCompany);
          setState({ data: newData });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isInvitingError: true });
      })
      .finally({ isInviting: false });
  };

  return (
    <div className="invite-seller-widget">
      <div className="">
        <button
          className="btn btn-outline-primary w-100 btn-sm"
          onClick={() => setState({ isOpen: true })}
        >
          {t('com_invite_seller')}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="modal-container">
          <h6 className="text-primary fw-bold mb-3">
            {t('com_invite_seller')}
          </h6>
          <div
            className="seller-list"
            style={{ maxHeight: '50vh', overflowY: 'scroll' }}
          >
            {data.map((seller, index) => {
              const { id, photo, email, phone, address, companies } = seller;
              const name = seller.name ? seller.name : seller.username;
              const isOwner =
                companies.findIndex(
                  c => c.company_id === parseInt(companyId) && c.level === 2,
                ) !== -1;
              const canInvite =
                companies.findIndex(
                  c => c.company_id === parseInt(companyId) && !c.is_active,
                ) !== -1;
              const isActive =
                companies.findIndex(
                  c => c.company_id === parseInt(companyId) && c.is_active,
                ) !== -1;
              if (isActive) return null;
              return (
                <div className={`seller-block card mb-2`} key={index}>
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        {photo ? (
                          <img
                            src={photo}
                            alt=""
                            className="img-thumbnail me-1 rounded-circle "
                            style={{ width: '48px' }}
                          />
                        ) : (
                          <img
                            src={userImg}
                            alt=""
                            className="img-thumbnail me-1 rounded-circle "
                            style={{ width: '48px' }}
                          />
                        )}
                        <div className="address-detail">
                          <h6 className="small mb-1">{name}</h6>
                          <h6 className="small mb-1 fs-10">{email}</h6>
                          <h6 className="small mb-1 fs-10">{phone}</h6>
                          <p className="mb-0 me-2 fs-10 lh-1">
                            <span className="fa fa-map-marker text-danger me-1"></span>
                            {address}
                          </p>
                        </div>
                      </div>
                      {isOwner ? (
                        <div className="btn btn-sm btn-warning p-1 px-2">
                          {t('com_owner')}
                        </div>
                      ) : (
                        <>
                          {canInvite ? (
                            <button
                              className="btn btn-sm btn-secondary p-1 px-2"
                              disabled
                            >
                              {t('com_invited_button')}
                            </button>
                          ) : (
                            <>
                              {isActive ? (
                                <div className="btn btn-sm btn-light border p-1 px-2">
                                  {t('com_member')}
                                </div>
                              ) : (
                                <button
                                  className="btn btn-sm btn-primary p-1 px-2"
                                  onClick={() => invite(index, id)}
                                >
                                  {t('com_invite_button')}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => setState({ isOpen: false })}
            className="btn mt-3 btn-sm btn-secondary w-100"
          >
            {t('close_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
