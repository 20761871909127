import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select, { components } from 'react-select';
import {
  getSellerProducts,
  addProduct,
  getCategories,
  uploadImage,
} from '../../libs/apis';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/global/Loader';

export default function AutocompleteProduct({
  productId = null,
  callback = null,
  required = true,
}) {
  const { t } = useTranslation();
  const initialState = {
    isOpen: false,
    isLoading: true,
    isError: false,
    products: [],
    canSelect: true,
    selectedProductId: productId,
    selectedProduct: null,
    options: [],
    isLoadingSubmit: false,
    isErrorSubmit: false,
    isUploading: false,
    isUploadError: false,
    name: '',
    priceMin: '',
    priceMax: '',
    description: '',
    photo: '',
    type: 0,
    uploading: false,
    errorUpload: false,
    loading: false,
    error: false,
    categories: [],
    selectedCategory: 0,
    errorMessage: '',
    gallery: [],
    hasDefault: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isOpen,
    products,
    selectedProduct,
    options,
    canSelect,
    isLoading,
    name,
    priceMin,
    priceMax,
    description,
    loading,
    photo,
    type,
    uploading,
    errorUpload,
    error,
    categories,
    selectedCategory,
    errorMessage,
    gallery,
    hasDefault,
  } = state;

  const selectProduct = product => {
    if (product) {
      setState({ isOpen: false, selectedProductId: product.id });
      callback(product);
    }
  };

  useEffect(() => {
    const getProductData = async () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        all: true,
      };
      await getSellerProducts(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ products: resp.data.response });
            const options = [];
            resp.data.response.forEach(item => {
              let labelName = item.name;
              if (item.company && item.company.name) {
                labelName = `${item.name} - ${item.company.name}`;
              }
              const itemData = {
                value: item.id,
                label: labelName,
              };
              options.push(itemData);
            });
            setState({ options: options });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProductData();

    const getCats = async () => {
      await getCategories().then(resp => {
        if (resp.status === 200) {
          if (resp.data.response.length > 0) {
            setState({
              categories: resp.data.response,
              selectedCategory: resp.data.response[0].id,
            });
          }
        }
      });
    };
    getCats();

    // const getCategoryData = async () => {
    //   await getCategories(1).then(resp => {
    //     if (resp.status === 200) {
    //       setState({ categories: resp.data.response });
    //       if (resp.data.response.length > 0) {
    //         setState({ selectedCategory: resp.data.response[0].id });
    //       }
    //     }
    //   });
    // };
    // const getStableData = async () => {
    //   await getStables().then(resp => {
    //     if (resp.status === 200) {
    //       setState({ stableData: resp.data.response });
    //       // if (resp.data.response.length > 0) {
    //       //   setState({ selectedStable: resp.data.response[0].id });
    //       // }
    //     }
    //   });
    // };
    // getCategoryData();
    // getStableData();
  }, []);

  useEffect(() => {
    if (products.length && productId && !selectedProduct) {
      const findProduct = products.find(p => p.id === parseInt(productId));
      if (findProduct) {
        setState({
          selectedProduct: findProduct,
          selectedProductId: findProduct.id,
          canSelect: false,
        });
        callback(findProduct);
      }
    } else if (!productId && !hasDefault) {
      const findDefault = products.find(p => p.is_default);
      if (findDefault) {
        setState({
          hasDefault: true,
          selectedProduct: findDefault,
          canSelect: false,
        });
        callback(findDefault);
      } else {
        setState({
          selectedProduct: null,
          canSelect: true,
        });
      }
    } else if (!productId && hasDefault) {
      setState({
        selectedProduct: null,
        canSelect: true,
      });
    }
  }, [products, productId, selectedProduct, hasDefault, callback]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (!photo) {
      setState({
        error: true,
        errorMessage: 'Please upload correct image',
        loading: false,
      });
    } else {
      setState({ loading: true, error: false, errorMessage: '' });
      const payload = {
        name,
        description,
        type,
        price_min: priceMin,
        price_max: priceMax,
        category_id: selectedCategory,
        image: photo,
        gallery,
      };
      await addProduct(payload)
        .then(resp => {
          const product = resp.data.response || null;
          if (product && product.id) {
            setState({
              selectedProduct: product,
              canSelect: false,
              products: [product, ...products],
            });
            let labelName = product.name;
            if (product.company && product.company.name) {
              labelName = `${product.name} - ${product.company.name}`;
            }
            const productData = {
              value: product.id,
              label: labelName,
            };
            setState({ options: [productData, ...options] });
            selectProduct(product);
            // history.push(`/horse/${item.id}`);
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true, errorMessage: 'Please upload an image' });
        })
        .finally(() => {
          setState({ loading: false });
        });
    }
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ photo: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };
  const onDropGallery = async e => {
    const file = e.target.files;
    setState({ uploadGallery: true, errorGallery: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ gallery: [...gallery, res.data.secure_url] });
        }
      })
      .catch(err => {
        setState({ errorGallery: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadGallery: false });
      });
  };

  const deleteGallery = item => {
    const newGallery = gallery.filter(e => e !== item);
    setState({ gallery: newGallery });
  };

  const SelectOption = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <div className="d-flex align-items-center ps-3 flex-1">
          <span className="fa fa-search me-1 text-muted"></span>
          {children}
        </div>
      </components.Control>
    );
  };

  return (
    <div className="widget-product-list">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="fs-6 fw-bold mb-0">
          {t('product_label')}
          {` `}
          {required ? (
            <span className="fs-12 text-muted fw-normal">
              ({t('required_label')})<span className="text-danger">*</span>
            </span>
          ) : (
            <span className="fs-12 text-muted fw-normal">
              ({t('optional_label')})
            </span>
          )}
        </h3>
        {/* {selectedProduct && !canSelect && (
          <button
            className="btn fw-bold text-primary btn-sm"
            onClick={() => setState({ canSelect: true })}
          >
            <span>{t('change_button')}</span>
          </button>
        )} */}
      </div>
      {canSelect && (
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="select-container flex-1 pe-3">
            <Select
              placeholder={t('search_product')}
              noOptionsMessage={() => {
                t('no_product_found');
              }}
              components={{ Control: SelectOption }}
              options={options}
              onChange={e => {
                const findProduct = products.find(
                  product => product.id === e.value,
                );
                if (findProduct) {
                  setState({ selectedProduct: findProduct, canSelect: false });
                  selectProduct(findProduct);
                }
              }}
            />
          </div>
          <button
            className="btn btn-outline-primary w-40 h-40 lh-1 p-0 rounded"
            onClick={() => setState({ isOpen: true })}
          >
            <span className="fa fa-plus"></span>
          </button>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal p-3 small mt-5 rounded-top rounded-top-3"
        ariaHideApp={false}
      >
        <div className="modal-container">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="fs-6 fw-bold mb-0">
              {t('add_new_product_heading')}
            </h3>
          </div>

          <div className="content text-left">
            <div className="form-container">
              {error && (
                <div className="alert alert-danger fade show" role="alert">
                  {errorMessage}
                </div>
              )}
              <form>
                <div className="form-floating mb-3 d-none">
                  <select
                    className="form-select "
                    name="type"
                    defaultValue={type}
                    onChange={e => setState({ type: e.target.value })}
                  >
                    <option value="0">{t('service_type')}</option>
                    <option value="1">{t('item_type')}</option>
                  </select>
                  <label htmlFor="type">
                    {t('select_type')}
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <select
                    className="form-select "
                    name="category"
                    onChange={e =>
                      setState({ selectedCategory: e.target.value })
                    }
                  >
                    <option value="0" disabled>
                      {t('category_select')}
                    </option>
                    {categories.length > 0 &&
                      categories.map((cat, index) => (
                        <option key={cat.id + index} value={cat.id}>
                          {t(cat.name.toLowerCase().replace(' ', '_'))}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="category">
                    {t('select_category')}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-group">
                  {/* <ImageUploader
                        singleImage={true}
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                      />
                      <input type="hidden" name="photo" value={photo} /> */}
                  {errorUpload && (
                    <div className="alert alert-danger text-center">
                      <p className="m-0">{t('failed_to_upload')}</p>
                    </div>
                  )}
                  {uploading && <Loader size={24} />}
                  <div className="image-uploader text-center">
                    {photo && (
                      <div className="mb-3 d-flex justify-content-center">
                        <img
                          src={photo}
                          alt="upload"
                          className="img-thumbnail"
                        />
                      </div>
                    )}

                    <label
                      htmlFor="img"
                      className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                    >
                      <span className="fa fa-image me-3"></span>
                      {photo ? (
                        <small>
                          {t('change_product_image')}
                          <span className="text-danger">*</span>
                        </small>
                      ) : (
                        <small>
                          {t('upload_product_image')}
                          <span className="text-danger">*</span>
                        </small>
                      )}
                      <input
                        type="file"
                        id="img"
                        accept="image/*"
                        required
                        onChange={onDropImage}
                        className="fs-6 small"
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          right: '0',
                          bottom: '0',
                          opacity: 0,
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={name}
                    onChange={e => setState({ name: e.target.value })}
                    disabled={loading}
                    required
                    placeholder={t('product_name_label')}
                  />
                  <label htmlFor="name">
                    {t('product_name_label')}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        id="priceMin"
                        value={priceMin}
                        required
                        onChange={e => setState({ priceMin: e.target.value })}
                        disabled={loading}
                        placeholder={t('price_min_label')}
                      />
                      <label htmlFor="priceMin">
                        {t('price_min_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        id="priceMax"
                        required
                        value={priceMax}
                        onChange={e => setState({ priceMax: e.target.value })}
                        disabled={loading}
                        placeholder={t('price_max_label')}
                      />
                      <label htmlFor="priceMax">
                        {t('price_max_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    name="description"
                    id="description"
                    rows="3"
                    required
                    placeholder={t('product_description_label')}
                    value={description}
                    onChange={e => setState({ description: e.target.value })}
                    style={{ height: '120px' }}
                  ></textarea>
                  <label htmlFor="description">
                    {t('product_description_label')}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="gallery mb-3 p-3 bg-white bg-gradient border rounded">
                  <h6 className="text-uppercase text-success small">
                    {t('gallery_heading')}
                  </h6>
                  <div className="gallery-uploader mb-3">
                    <div className="add-gallery">
                      <label
                        htmlFor="add-gallery"
                        className="btn w-100 bg-light border rounded-3 shadow-sm bg-gradient position-relative"
                      >
                        <span className="icon me-2">
                          <span className="fa fa-plus"></span>
                        </span>
                        <span className="text">{t('add_gallery')}</span>
                        <input
                          type="file"
                          accept="image/*"
                          id="add-gallery"
                          name="add-gallery"
                          onChange={onDropGallery}
                        />
                      </label>
                    </div>
                  </div>
                  {gallery.length > 0 && (
                    <div className="gallery-container mb-3 row g-2">
                      {gallery.map((g, i) => (
                        <div className="col-6" key={g + i}>
                          <div className="gallery-item border rounded shadow-sm">
                            <img src={g} alt={g + i} className="rounded-top" />
                            <div className="p-2 bg-light">
                              <button
                                className="btn btn-danger btn-sm w-100 m-0"
                                onClick={() => deleteGallery(g)}
                              >
                                <span className="fa fa-trash me-1"></span>
                                <small>{t('delete_label')}</small>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              onClick={() => setState({ isOpen: false })}
              className="btn btn-outline-secondary w-50"
            >
              {t('cancel_button')}
            </button>
            {name !== '' && photo !== '' && !isLoading ? (
              <button
                onClick={handleSubmit}
                className="btn btn-primary w-50 ms-3"
              >
                {t('save_and_select_button')}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-disabled btn-primary w-50 ms-3"
              >
                {t('save_and_select_button')}
              </button>
            )}
          </div>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
