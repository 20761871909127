import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/global/Loader';

import { getCustomers } from '../../libs/apis';

export default function CustomerList() {
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError } = state;

  useEffect(() => {
    const getCustomerData = async () => {
      await getCustomers()
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getCustomerData();
  }, []);

  if (data.length === 0 && isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  return (
    <div className="widget-container pb-4">
      <Link
        to="/customer/new"
        className="btn btn-primary btn-lg rounded-circle fs-4 d-flex justify-content-center align-items-center flex-direction-col position-fixed shadow p-0"
        style={{
          right: '1.5rem',
          bottom: '4.5rem',
          width: '56px',
          height: '56px',
          lineHeight: '56px',
        }}
      >
        <span className="fa fa-plus"></span>
      </Link>
      {data.length > 0 && (
        <div className="section-content">
          {data.map((customer, index) => {
            const { id, name } = customer;
            const borderClass = index > 0 ? 'border-top' : '';
            return (
              <div className={`py-2 ${borderClass}`} key={index}>
                <Link
                  to={`/customer/${id}`}
                  className="d-flex align-items-center justify-content-between py-1 text-decoration-none text-muted"
                >
                  <div className="stable-content flex-1">
                    <h6 className="mb-0 small">{name}</h6>
                  </div>
                  <div className="align-self-end ml-2 fs-6">
                    <span className="fa fa-chevron-right"></span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
