import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSellerInventory, syncProducts } from '../../libs/apis';

import WidgetInventory from '../../components/global/WidgetInventory';

function SellerProduct() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  const initialState = {
    dataItems: [],
    dataWorks: [],
    isLoading: true,
    isError: false,
    hasChange: true,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { dataItems, dataWorks, hasChange } = state;

  useEffect(() => {
    const getItem = async () => {
      const payload = {
        type: 0,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ dataItems: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getWork = async () => {
      const payload = {
        type: 1,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ dataWorks: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getItem();
    getWork();
  }, []);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  const myCompany = user.company || null;

  const handleChange = () => {
    setState({ hasChange: true });
  };

  const handleSync = async () => {
    const payload = {
      id: myCompany.id,
    };
    await syncProducts(payload)
      .then(resp => {
        console.log('res', resp);
      })
      .finally(() => setState({ hasChange: false }));
  };

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <Link to="/seller" className=" text-light text-decoration-none">
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content">
                <div className="product-summary py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-dark text-uppercase fw-bold">
                      {t('inventory_text')}
                    </h6>
                    <div className="text-end mb-3">
                      <Link
                        to="/seller/inventory/new"
                        className="btn btn-sm mb-0 rounded-pill btn-primary"
                      >
                        <span className="fa fa-plus me-1"></span>
                        {t('new_inventory')}
                      </Link>
                      {myCompany && (
                        <>
                          {!hasChange ? (
                            <button className="btn mt-1 ms-2 btn-sm btn-secondary opacity-50 rounded-pill">
                              <span className="fa fa-refresh me-1"></span>
                              <strong>{t('com_sync_button')}</strong>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm mt-1 ms-2 btn-warning rounded-pill"
                              onClick={handleSync}
                            >
                              <span className="fa fa-refresh me-1"></span>
                              <strong>{t('com_sync_button')}</strong>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {dataItems.length > 0 && (
                    <div className="card mb-3">
                      <div className="card-header p-2 bg-primary">
                        <h6 className="mb-0 text-light small">
                          <span className="fa fa-briefcase me-2"></span>
                          {t('item_label')}
                        </h6>
                      </div>
                      <div className="card-body p-0 bg-white">
                        {dataItems.map((p, i) => {
                          return (
                            <WidgetInventory
                              index={i}
                              key={p.id}
                              data={p}
                              onChange={handleChange}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {dataWorks.length > 0 && (
                    <div className="card mb-3">
                      <div className="card-header p-2 bg-primary">
                        <h6 className="mb-0 text-light small">
                          <span className="fa fa-wrench me-2"></span>
                          {t('work_label')}
                        </h6>
                      </div>
                      <div className="card-body p-0 bg-white">
                        {dataWorks.map((p, i) => {
                          return (
                            <WidgetInventory index={i} key={p.id} data={p} />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerProduct;
