import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WidgetHorse from '../../components/global/WidgetHorse';

import { useSelector } from 'react-redux';
// import { CSVLink } from 'react-csv';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/id';
import HorseIcon from '../../components/global/HorseIcon';
import { getSellerProducts, getTransactionItemList } from '../../libs/apis';

function HorseList() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  const companies = user.companies;
  const initialState = {
    isCompany: false,
    selectedProduct: 0,
    selectedCompany: 0,
    data: [],
    products: [],
    isError: false,
    isLoading: true,
    isLoadingProduct: true,
    isErrorProduct: false,
    sortCol: -1,
    asc: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isCompany,
    selectedProduct,
    selectedCompany,
    data,
    products,
    isLoading,
    sortCol,
    asc,
  } = state;
  const hasCompany = user.company;
  const isCompanyOwner = hasCompany && hasCompany.id === selectedCompany;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  useEffect(() => {
    const getProductData = async () => {
      await getSellerProducts()
        .then(resp => {
          if (resp.status === 200) {
            setState({
              products: resp.data.response,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorProduct: true });
        })
        .finally(() => setState({ isLoadingProduct: false }));
    };
    getProductData();
  }, []);

  useEffect(() => {
    const getHorseData = async () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        companyId: isCompany ? selectedCompany : null,
        productId: selectedProduct,
        isCompanyOwner: isCompany && isCompanyOwner,
      };
      await getTransactionItemList(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => setState({ isLoading: false }));
    };

    if (selectedProduct !== 0) {
      getHorseData();
    }
  }, [selectedProduct, isCompany, isCompanyOwner, selectedCompany]);

  const productList =
    selectedCompany === 0
      ? products.filter(p => p.company === null)
      : products.filter(p => p.company?.id === parseInt(selectedCompany));

  const sortBy = index => {
    setState({ sortCol: index, asc: !asc });
  };

  const sortData = data.sort((a, b) => {
    const dateA = new Date(a.transaction.confirmation_date);
    const dateB = new Date(b.transaction.confirmation_date);

    const intervalA = a.transaction.interval || 0;
    const intervalB = b.transaction.interval || 0;

    const buyerA = a.transaction.user;
    const buyerB = b.transaction.user;

    const hasBillingA = a.item.billing_active;
    const hasBillingB = b.item.billing_active;

    if (sortCol === 0) {
      let nameA = a.item.name.toLowerCase();
      let nameB = b.item.name.toLowerCase();
      if (asc) {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
        return 0;
      }
    } else if (sortCol === 1 || sortCol === 2) {
      if (asc) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    } else if (sortCol === 3) {
      if (a.transaction.type === 3 && b.transaction.type === 3) {
        if (asc) {
          return intervalA - intervalB;
        } else {
          return intervalB - intervalA;
        }
      }
      return 0;
    } else if (sortCol === 4) {
      if (a.transaction.type === 3 && b.transaction.type === 3) {
        const nextA = moment(dateA).add(intervalA, 'days').toDate();
        const nextB = moment(dateB).add(intervalB, 'days').toDate();
        if (asc) {
          return nextA - nextB;
        } else {
          return nextB - nextA;
        }
      }
      return 0;
    } else if (sortCol === 5) {
      const noteA =
        a.transaction.transaction_items.find(item => a.item.id === item.item.id)
          ?.summary_text || `-`;
      const noteB =
        a.transaction.transaction_items.find(item => b.item.id === item.item.id)
          ?.summary_text || `-`;
      if (asc) {
        if (noteA < noteB) return -1;
        if (noteA > noteB) return 1;
        return 0;
      } else {
        if (noteA > noteB) return -1;
        if (noteA < noteB) return 1;
        return 0;
      }
    } else if (sortCol === 6) {
      const stableA = a.item.stable.name;
      const stableB = b.item.stable.name;
      if (asc) {
        if (stableA < stableB) return -1;
        if (stableA > stableB) return 1;
        return 0;
      } else {
        if (stableA > stableB) return -1;
        if (stableA < stableB) return 1;
        return 0;
      }
    } else if (sortCol === 7) {
      let ownerA = buyerA.name;
      let ownerB = buyerB.name;

      if (hasBillingA && hasBillingB) {
        if (a.item.billing_name) {
          ownerA = a.item.billing_name;
        }
        if (b.item.billing_name) {
          ownerB = b.item.billing_name;
        }
      }

      if (asc) {
        if (ownerA < ownerB) return -1;
        if (ownerA > ownerB) return 1;
        return 0;
      } else {
        if (ownerA > ownerB) return -1;
        if (ownerA < ownerB) return 1;
        return 0;
      }
    } else if (sortCol === 8) {
      let emailA = buyerA.email;
      let emailB = buyerB.email;

      if (hasBillingA && hasBillingB) {
        if (a.item.billing_email) {
          emailA = a.item.billing_email;
        }
        if (b.item.billing_email) {
          emailB = b.item.billing_email;
        }
      }

      if (asc) {
        if (emailA < emailB) return -1;
        if (emailA > emailB) return 1;
        return 0;
      } else {
        if (emailA > emailB) return -1;
        if (emailA < emailB) return 1;
        return 0;
      }
    } else if (sortCol === 9) {
      let businessA = '-';
      let businessB = '-';

      if (hasBillingA && hasBillingB) {
        if (a.item.billing_company_id) {
          businessA = a.item.billing_company_id;
        }
        if (b.item.billing_company_id) {
          businessB = b.item.billing_company_id;
        }
      }

      if (asc) {
        if (businessA < businessB) return -1;
        if (businessA > businessB) return 1;
        return 0;
      } else {
        if (businessA > businessB) return -1;
        if (businessA < businessB) return 1;
        return 0;
      }
    } else if (sortCol === 10) {
      let phoneA = buyerA.phone;
      let phoneB = buyerB.phone;

      if (hasBillingA && hasBillingB) {
        if (a.item.billing_phone) {
          phoneA = a.item.billing_phone;
        }
        if (b.item.billing_phone) {
          phoneB = b.item.billing_phone;
        }
      }

      if (asc) {
        if (phoneA < phoneB) return -1;
        if (phoneA > phoneB) return 1;
        return 0;
      } else {
        if (phoneA > phoneB) return -1;
        if (phoneA < phoneB) return 1;
        return 0;
      }
    } else if (sortCol === 11) {
      let addressA = buyerA.address;
      let addressB = buyerB.address;

      if (hasBillingA && hasBillingB) {
        if (a.item.billing_address) {
          addressA = a.item.billing_address;
        }
        if (b.item.billing_address) {
          addressB = b.item.billing_address;
        }
      }

      if (asc) {
        if (addressA < addressB) return -1;
        if (addressA > addressB) return 1;
        return 0;
      } else {
        if (addressA > addressB) return -1;
        if (addressA < addressB) return 1;
        return 0;
      }
    } else if (sortCol === 12) {
      let sellerA = a.transaction.product.seller.name;
      let sellerB = b.transaction.product.seller.name;

      if (asc) {
        if (sellerA < sellerB) return -1;
        if (sellerA > sellerB) return 1;
        return 0;
      } else {
        if (sellerA > sellerB) return -1;
        if (sellerA < sellerB) return 1;
        return 0;
      }
    }
    return 0;
  });

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left d-flex justify-content-between align-items-center">
                <h1 className="fs-6 text-light mb-0">
                  <span className="me-2" style={{ fill: 'white' }}>
                    <HorseIcon />
                  </span>
                  {t('hl_title')}
                </h1>
                {isCompanyOwner && (
                  <>
                    {isCompany ? (
                      <button
                        onClick={() => setState({ isCompany: false })}
                        className="btn btn-sm fs-12 text-primary btn-light p-1 px-2 rounded-pill"
                      >
                        <span className="fa fa-circle text-primary me-1"></span>
                        {t('company_horse')}
                      </button>
                    ) : (
                      <button
                        onClick={() => setState({ isCompany: true })}
                        className="btn btn-sm fs-12 btn-light p-1 px-2 rounded-pill opacity-75"
                      >
                        <span className="fa fa-circle text-secondary me-1"></span>
                        {t('company_horse')}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="row g-2">
                  <div className="col-6">
                    <div className="input-group mb-3">
                      <label
                        className="small text-primary fw-bold input-group-text bg-light"
                        htmlFor="company"
                        title="Company"
                      >
                        <span className="fa fa-building"></span>
                      </label>

                      <select
                        name="company"
                        id="company"
                        defaultValue={selectedCompany}
                        className="form-select form-select-sm"
                        onChange={e => {
                          const companyId = e.target.value;
                          setState({
                            selectedCompany: parseInt(companyId),
                            selectedProduct: 0,
                            data: [],
                          });
                        }}
                      >
                        {!isCompany && (
                          <option value={0}>{t('personal')}</option>
                        )}
                        {companies.map(com => (
                          <option value={com.company.id} key={com.company.id}>
                            {com.company.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-3">
                      <label
                        className="small text-primary fw-bold input-group-text bg-light"
                        htmlFor="product"
                        title="Product"
                      >
                        <span className="fa fa-briefcase"></span>
                      </label>
                      <select
                        name="product"
                        id="product"
                        className="form-select form-select-sm"
                        defaultValue={selectedProduct}
                        onChange={e => {
                          setState({
                            selectedProduct: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={0}>-</option>
                        {productList.map(product => {
                          return (
                            <option value={product.id} key={product.id}>
                              {product.name}
                            </option>
                          );
                        })}
                        {/* <option value="1">Brushing 101</option>
                        <option value="2">Shoeing 101</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <InvoiceSummary /> */}

                {data.length > 0 ? (
                  <div className="invoice-list mt-3">
                    <div className="table-container table-responsive">
                      <table className="table table-bordered table-striped text-dark table-sm text-nowrap fs-12 table-responsive table-sortable">
                        <thead className="table-secondary text-center">
                          <tr>
                            <th
                              onClick={() => sortBy(0)}
                              className={`${
                                sortCol === 0 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('horse_name_label')}{' '}
                              {sortCol === 0 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(1)}
                              className={`${
                                sortCol === 1 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_last_shod')}{' '}
                              {sortCol === 1 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(2)}
                              className={`${
                                sortCol === 2 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_weeks')}{' '}
                              {sortCol === 2 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(3)}
                              className={`${
                                sortCol === 3 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_interval')}{' '}
                              {sortCol === 3 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(4)}
                              className={`${
                                sortCol === 4 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_next')}{' '}
                              {sortCol === 4 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(5)}
                              className={`${
                                sortCol === 5 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_notes')}{' '}
                              {sortCol === 5 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(6)}
                              className={`${
                                sortCol === 6 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('stable')}{' '}
                              {sortCol === 6 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(7)}
                              className={`${
                                sortCol === 7 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_owner')}{' '}
                              {sortCol === 7 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(8)}
                              className={`${
                                sortCol === 8 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_email')}{' '}
                              {sortCol === 8 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(9)}
                              className={`${
                                sortCol === 9 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_business_id')}{' '}
                              {sortCol === 9 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(10)}
                              className={`${
                                sortCol === 10 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_phone')}{' '}
                              {sortCol === 10 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(11)}
                              className={`${
                                sortCol === 11 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('hl_address')}{' '}
                              {sortCol === 11 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                            <th
                              onClick={() => sortBy(12)}
                              className={`${
                                sortCol === 12 ? 'bg-secondary text-light' : ''
                              }`}
                            >
                              {t('farrier')}{' '}
                              {sortCol === 12 && (
                                <span
                                  className={`fa fa-sort-${
                                    asc ? 'asc' : 'desc'
                                  } ms-2`}
                                ></span>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortData.map(tr => {
                            const date = tr.transaction.confirmation_date
                              ? tr.transaction.confirmation_date
                              : tr.transaction.transaction_request[0].datetime;
                            const interval =
                              tr.transaction.type === 3
                                ? tr.transaction.interval
                                : null;
                            const next =
                              tr.transaction.type === 3
                                ? moment(date).add(interval, 'days').format()
                                : null;
                            const notes =
                              tr.transaction.transaction_items.find(
                                item => tr.item.id === item.item.id,
                              ).summary_text || `-`;
                            const has_billing =
                              tr.item?.billing_active || false;
                            const buyer = tr.transaction.user;
                            let owner = buyer.name;
                            let email = buyer.email;
                            // let location = tr.transaction.address;
                            let businessId = `-`;
                            let phone = buyer.phone;
                            let address = buyer.address;
                            const seller = tr.transaction.product.seller;
                            if (has_billing) {
                              const {
                                billing_address,
                                billing_company_id,
                                billing_email,
                                billing_name,
                                billing_phone,
                              } = tr.item;
                              if (billing_name) owner = billing_name;
                              if (billing_email) email = billing_email;
                              if (billing_phone) phone = billing_phone;
                              if (billing_address) address = billing_address;
                              if (billing_company_id)
                                businessId = billing_company_id;
                            }
                            const stable = tr.item.stable
                              ? tr.item.stable
                              : null;

                            return (
                              <tr key={tr.id}>
                                {/* <td>{tr.item.name}</td> */}
                                <td>
                                  <WidgetHorse withImage={false} data={tr} />
                                </td>
                                <td className="text-center">
                                  {seller.id !== user.id ? (
                                    <span>
                                      {moment(date).format('DD/MM/YYYY')}
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/seller/order/${
                                        tr.transaction.id
                                      }?back=${`/seller/horse-list`}`}
                                    >
                                      {moment(date).format('DD/MM/YYYY')}
                                    </Link>
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  title={moment(date).format('LL')}
                                >
                                  {moment(date).isoWeeks()}
                                </td>
                                <td
                                  className="text-center"
                                  title={`${interval} ${t('hl_days')}`}
                                >
                                  {interval ? interval / 7 : `-`}
                                </td>
                                <td
                                  className="text-center"
                                  title={moment(next).format('LL')}
                                >
                                  {next ? moment(next).isoWeeks() : `-`}
                                </td>
                                <td
                                  title={notes}
                                  style={{
                                    maxWidth: '180px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {notes}
                                </td>
                                <td>{stable ? stable.name : '-'}</td>
                                <td>{owner}</td>
                                <td>{email}</td>
                                {/* <td title={location} style={{maxWidth: '180px', textOverflow: 'ellipsis', overflow: 'hidden'}}>{location}</td> */}
                                <td>{businessId}</td>
                                <td>{phone}</td>
                                <td>{address}</td>
                                <td>{seller.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-warning text-center">
                    {!isLoading ? (
                      <p className="mb-0 fs-12">{t('hl_no_record')}</p>
                    ) : (
                      <p className="mb-0 fs-12">{t('hl_select_product')}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default HorseList;
