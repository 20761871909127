export function setUserToken(token) {
  if (window) {
    window.localStorage.setItem('token', token);
  }
}

export function getUserToken() {
  let token = null;
  if (window) {
    const t = window.localStorage.getItem('token');
    if (t) {
      token = t;
    }
  }
  return token;
}

export function deleteUserToken() {
  if (window) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('expires_in');
  }
}

export function deleteUser() {
  if (window) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('user');
  }
}

export function setStorage(key, val) {
  if (window) {
    window.localStorage.setItem(key, val);
  }
}

export function getStorage(key) {
  let val = null;
  if (window) {
    val = window.localStorage.getItem(key);
  }
  return val;
}

export function numberFormat(num) {
  const floatNum = parseFloat(num).toFixed(2);
  return floatNum;
}
