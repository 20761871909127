import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import { getGeoLocation } from '../../libs/apis';

export default function WidgetSetMap({
  longitude = 0,
  latitude = 0,
  callback = () => null,
  cancel = () => null,
  isOpen = false,
  zoom = 15,
}) {
  const { t } = useTranslation();
  const initialState = {
    center: [latitude, longitude],
    geoAddress: '',
    lat: latitude,
    lng: longitude,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { center, geoAddress, lat, lng } = state;
  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
        }
      }
    });
  };

  const callbackMap = () => {
    const payload = {
      lat,
      lng,
      geoAddress,
    };
    callback(payload);
  };

  const onMapDragEnd = e => {
    setState({
      lat: e.center.lat(),
      lng: e.center.lng(),
    });
    getGeoLoc(e.center.lat(), e.center.lng());
  };

  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };

  return (
    <div className="widget-map">
      <Modal
        isOpen={isOpen}
        onRequestClose={cancel}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="seller-complete-info">
          <div className="">
            <div
              className="mb-2 position-relative"
              style={{ height: '50vh', width: '100%' }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                }}
                center={center}
                zoom={zoom}
                draggable={true}
                onChange={onChangeMap}
                onDragEnd={onMapDragEnd}
                options={{ gestureHandling: 'greedy' }}
              ></GoogleMapReact>
              <div className="marker-overlay">
                <div className="pin">
                  <span className="fa fa-map-marker text-success"></span>
                </div>
              </div>
            </div>
            <div className="geolocation-text bg-white p-2 mb-2">
              <h6 className="mb-1 fs-12 fw-bold">{t('location_label')}:</h6>
              <p className="lh-1 mb-0 fs-14 text-dark">{geoAddress}</p>
            </div>
          </div>
          <button
            onClick={callbackMap}
            className="btn btn-sm btn-outline-primary w-100"
          >
            {t('save_button')}
          </button>
        </div>
        <button className="btn btn-close close-reveal" onClick={cancel}>
          &times;
        </button>
      </Modal>
    </div>
  );
}
