import React from 'react';
import GoogleMapReact from 'google-map-react';

export default function WidgetShowMap({
  latitude = 0,
  longitude = 0,
  zoom = 15,
}) {
  return (
    <div className="widget-show-map">
      <div
        className="position-relative"
        style={{ height: '50vh', width: '100%' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
          }}
          center={[latitude, longitude]}
          zoom={zoom}
          draggable={false}
          options={{ gestureHandling: 'greedy' }}
        ></GoogleMapReact>
        <div className="marker-overlay">
          <div className="pin">
            <span className="fa fa-map-marker text-success"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
