import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select, { components } from 'react-select';
import { addCustomer, getCustomers } from '../../libs/apis';
import { useTranslation } from 'react-i18next';

export default function AutocompleteCustomer({
  customerId = null,
  callback = null,
  required = true,
}) {
  const { t } = useTranslation();
  const initialState = {
    isOpen: false,
    customers: [],
    selectedCustomerId: customerId,
    selectedCustomer: null,
    options: [],
    canSelect: true,
    isLoadingSubmit: false,
    isErrorSubmit: false,
    isLoading: false,
    isError: false,
    isSubmiting: false,
    isSubmitError: false,
    submitMessage: '',
    message: '',
    name: '',
    email: '',
    company_name: '',
    business_id: '',
    phone: '',
    address: '',
    city: '',
    postal_code: '',
    notes: '',
    is_monthly_billing_active: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isOpen,
    customers,
    selectedCustomer,
    options,
    canSelect,
    // message,
    // isError,
    isSubmitError,
    submitMessage,
    isLoading,
    name,
    email,
    company_name,
    business_id,
    phone,
    address,
    city,
    postal_code,
    notes,
    is_monthly_billing_active,
  } = state;

  const selectCustomer = customer => {
    if (customer) {
      setState({ isOpen: false, canSelect: false });
      callback(customer);
    }
  };

  useEffect(() => {
    const getCustomerData = async () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        all: true,
      };
      await getCustomers(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ customers: resp.data.response });
            const options = [];
            resp.data.response.forEach(item => {
              const itemData = {
                value: item.id,
                label: item.name,
              };
              options.push(itemData);
            });
            setState({ options: options });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getCustomerData();
  }, []);

  useEffect(() => {
    if (customers.length && customerId && !selectedCustomer) {
      const findCustomer = customers.find(p => p.id === parseInt(customerId));
      if (findCustomer) {
        setState({
          selectedCustomer: findCustomer,
          selectedcustomerId: findCustomer.id,
        });
        callback(findCustomer);
      }
    } else if (!customerId) {
      // if horseId === null, reset
      setState({ selectedHorse: null, canSelect: true });
    }
  }, [customers, customerId, selectedCustomer, callback]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isSubmiting: true, isSubmitError: false, submitMessage: '' });
    const payload = {
      name,
      email,
      company_name,
      business_id,
      phone,
      address,
      city,
      postal_code,
      notes,
      is_monthly_billing_active,
    };
    await addCustomer(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const customer = resp.data.response || null;
          if (customer && customer.id) {
            setState({
              selectedCustomer: customer,
              canSelect: false,
              customers: [customer, ...customers],
            });
            const customerData = {
              value: customer.id,
              label: customer.name,
            };
            setState({ options: [customerData, ...options] });
            selectCustomer(customer);
          }
        } else {
          setState({
            isSubmitError: true,
            submitMessage: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, submitMessage: err.message });
      })
      .finally(() => {
        setState({ isSubmiting: false });
      });
  };

  const SelectOption = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <div className="d-flex align-items-center ps-3 flex-1">
          <span className="fa fa-search me-1 text-muted"></span>
          {children}
        </div>
      </components.Control>
    );
  };

  return (
    <div className="widget-product-list">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="fs-6 fw-bold mb-0">
          {t('customer_label')}
          {` `}
          {required && (
            <span className="fs-12 text-muted fw-normal">
              ({t('required_label')})<span className="text-danger">*</span>
            </span>
          )}
        </h3>
      </div>
      {canSelect && (
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="select-container flex-1 pe-3">
            <Select
              placeholder={t('search_customer')}
              noOptionsMessage={() => {
                t('no_customer_found');
              }}
              options={options}
              components={{ Control: SelectOption }}
              onChange={e => {
                const findCustomer = customers.find(
                  customer => customer.id === e.value,
                );
                if (findCustomer) {
                  // setState({
                  //   selectedCustomer: findCustomer,
                  //   canSelect: false,
                  // });
                  selectCustomer(findCustomer);
                }
              }}
            />
          </div>
          <button
            className="btn btn-outline-primary w-40 h-40 lh-1 p-0 rounded"
            onClick={() => setState({ isOpen: true })}
          >
            <span className="fa fa-plus"></span>
          </button>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal p-3 small mt-5 rounded-top rounded-top-3"
        ariaHideApp={false}
      >
        <div className="modal-container px-2">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="fs-6 fw-bold mb-0">{t('add_new_customer')}</h3>
          </div>

          <div className="content text-left">
            <div className="form-container">
              {isSubmitError && (
                <div className="alert alert-danger fade show" role="alert">
                  {submitMessage}
                </div>
              )}
              <div>
                <div className="form-group mb-3">
                  <label htmlFor="name">
                    {t('customer_name_label')}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="name"
                    value={name}
                    onChange={e =>
                      setState({ name: e.target.value, hasInput: true })
                    }
                    disabled={isLoading}
                    required
                    placeholder={t('customer_name')}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="email">
                    {t('customer_email_label')}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="email"
                    id="email"
                    value={email}
                    onChange={e =>
                      setState({ email: e.target.value, hasInput: true })
                    }
                    disabled={isLoading}
                    required
                    placeholder={t('customer_email')}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="company_name">
                    {t('customer_company_name_label')}
                  </label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="company_name"
                    value={company_name}
                    onChange={e => setState({ company_name: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_company_name')}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="business_id">
                    {t('customer_business_id_label')}
                  </label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="business_id"
                    value={business_id}
                    onChange={e => setState({ business_id: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_business_id')}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="phone">{t('customer_phone_label')}</label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="number"
                    id="phone"
                    value={phone}
                    onChange={e => setState({ phone: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_phone')}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="address">{t('customer_address_label')}</label>
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="address"
                    value={address}
                    onChange={e => setState({ address: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_street')}
                  />
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="city"
                    value={city}
                    onChange={e => setState({ city: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_town')}
                  />
                  <input
                    className="form-control mt-2 fw-bold"
                    type="text"
                    id="postal_code"
                    value={postal_code}
                    onChange={e => setState({ postal_code: e.target.value })}
                    disabled={isLoading}
                    required
                    placeholder={t('customer_zipcode')}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="notes">{t('customer_notes_label')}</label>
                  <textarea
                    className="form-control mt-2"
                    id="notes"
                    value={notes}
                    onChange={e =>
                      setState({ notes: e.target.value, hasInput: true })
                    }
                    disabled={isLoading}
                    required
                    placeholder={t('customer_notes')}
                    style={{ height: '80px' }}
                  />
                </div>
              </div>
              <div className="ms-2 d-flex ">
                <p className="me-auto fw-bold">
                  {t('customer_monthly_billing_label')}
                </p>
                <div className="form-check form-switch mb-3">
                  <input
                    style={{ height: '20px', width: '40px' }}
                    id=" is_monthly_billing_active"
                    name=" is_monthly_billing_active"
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setState({
                        is_monthly_billing_active: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
              {is_monthly_billing_active && (
                <div
                  className="text px-4 py-2 text-center rounded"
                  style={{ backgroundColor: '#F2F2F2' }}
                >
                  {t('customer_monthly_billing_text')}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              onClick={() => setState({ isOpen: false })}
              className="btn btn-outline-secondary w-50"
            >
              {t('cancel_button')}
            </button>
            {name !== '' && email !== '' ? (
              <button
                onClick={handleSubmit}
                className="btn btn-primary w-50 ms-3"
              >
                {t('save_and_select_button')}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-disabled btn-primary w-50 ms-3"
              >
                {t('save_and_select_button')}
              </button>
            )}
          </div>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
