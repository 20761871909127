import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { updateProduct, deleteProduct } from '../../libs/apis';

export default function Product({ data, onChange = () => {} }) {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const {
    id,
    name,
    category,
    price_min,
    price_max,
    description,
    image,
    type,
    active = false,
    company = data.company || null,
    is_default = false,
  } = data;

  const initialState = {
    isOpen: false,
    isOpenDefault: false,
    isLoading: false,
    isError: false,
    isSale: active,
    isDefault: is_default,
    isDeleteOpen: false,
    isDeleting: false,
    isDeleted: false,
    productOwner: data.company
      ? data.is_company_active
        ? data.company.id
        : 0
      : 0,
    hasChanged: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isOpen,
    isOpenDefault,
    isLoading,
    isError,
    isSale,
    isDefault,
    isDeleteOpen,
    isDeleting,
    isDeleted,
    productOwner,
    hasChanged,
  } = state;

  const handleDelete = async () => {
    setState({ isDeleting: true, isDeleted: false });
    const payload = {
      id,
    };
    await deleteProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ isDeleted: true });
        }
      })
      .finally(() => setState({ isDeleting: false }));
  };

  const handleChange = () => {
    setState({ loading: true });
    const payload = {
      id,
      category_id: category.id,
      name,
      type,
      price_min,
      description,
      price_max,
      active,
      is_company_active: productOwner !== 0,
    };
    updateProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ hasChanged: false });
            onChange();
          }
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ loading: false, isOpen: false });
      });
  };

  if (!user) return null;
  if (isDeleted) return null;

  const setForSale = async active => {
    setState({ loading: true });
    const payload = {
      id,
      category_id: category.id,
      name,
      type,
      price_min,
      description,
      price_max,
      active,
    };
    await updateProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ isSale: resp.data.response.active });
          }
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ loading: false, isOpen: false });
      });
  };

  const setAsDefault = async () => {
    setState({ isLoading: true });
    const payload = {
      id,
      category_id: category.id,
      name,
      type,
      price_min,
      description,
      price_max,
      active,
      is_default: !isDefault,
    };
    await updateProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ isDefault: resp.data.response.active });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ isLoading: false, isOpen: false });
      });
  };

  const isCompany = !!company;
  const myCompany = user.company;
  const isOwner = isCompany && myCompany && myCompany.id === company.id;
  const isCompanyActive = data.is_company_active;

  const isNotCompanyActive = !isOwner && !isCompanyActive && isCompany;

  const isPro = user.subscription || false;

  if (isNotCompanyActive)
    return (
      <div className={`mb-4 rounded-3 border-2`}>
        <div className="d-flex align-items-center me-2">
          <button
            className={`p-0 btn me-2 fs-5 ${
              isDefault ? 'text-warning' : 'text-muted'
            }`}
            onClick={() => setState({ isOpenDefault: true })}
          >
            <span className="fa fa-star"></span>
          </button>
          <h6 className="mb-0 fw-bold">
            <strong>{name}</strong>
          </h6>
        </div>
        <div className="card-body p-3">
          {isError && (
            <div className="alert alert-warning my-3">
              <p className="m-0 small">
                Failed to update product status, try again..
              </p>
            </div>
          )}
          <div className="d-flex align-items-center">
            {image && (
              <div
                className="mb-0 me-3"
                style={{ width: '64px', flex: '0 0 64px' }}
              >
                <img src={image} alt={name} className="rounded-3" />
              </div>
            )}
            <div className="product-info" style={{ flex: '1' }}>
              <div className="">
                <div className="d-flex justify-content-between mb-2">
                  <h6 className="mb-0 fw-bold">
                    <span className="text-success">{t('$')}</span> {price_min}-
                    {price_max}
                  </h6>
                  <h6 className="mb-1 small fs-14 text-muted">
                    <span className="fa fa-tags opacity-50 me-1"></span>
                    {t(category.name.toLowerCase().replace(' ', '_'))}
                  </h6>
                </div>
                <p className="mb-2 lh-sm small">{description}</p>

                {isCompany && !isOwner && (
                  <div className="d-inline-block align-items-center p-1 px-2 rounded-3 alert-primary mb-1 small">
                    <span className="fa fa-building me-2"></span>
                    <span className="text-start" style={{ flex: 1 }}>
                      {company.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="alert alert-warning p-1 mt-2 mb-0">
            <small className="d-block">{t('com_disabled_product')}</small>
          </div>

          <Modal
            isOpen={isOpenDefault}
            onRequestClose={() => setState({ isOpenDefault: false })}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <button className="btn btn-close close-reveal">&times;</button>
            <h6 className="fw-bold">{t('set_as_default_heading')}</h6>
            <p>{t('set_as_default_text')}</p>
            {isLoading ? (
              <div className="button-group d-flex align-items-center">
                <button disabled className="btn btn-secondary w-50">
                  {t('cancel_button')}
                </button>
                <button disabled className="btn ms-3 btn-primary w-50">
                  <span className="fa fa-spinner fa-spin"></span>{' '}
                  {t('loading_text')}
                </button>
              </div>
            ) : (
              <div className="button-group d-flex align-items-center">
                <button
                  onClick={() => setState({ isOpenDefault: false })}
                  className="btn btn-secondary w-50"
                >
                  {t('cancel_button')}
                </button>
                <button
                  onClick={setAsDefault}
                  className="w-50 btn ms-3 btn-primary"
                >
                  {t('set_as_default_button')}
                </button>
              </div>
            )}
          </Modal>

          <Modal
            isOpen={isOpen}
            onRequestClose={() => setState({ isOpen: false })}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <button className="btn btn-close close-reveal">&times;</button>
            <h5>{t('are_you_sure')}</h5>
            <p>{t('product_set_for_sale')}</p>
            {isLoading ? (
              <div className="button-group">
                <button disabled className="btn btn-secondary">
                  {t('cancel_button')}
                </button>
                <button disabled className="btn ms-3 btn-primary">
                  <span className="fa fa-spinner fa-spin"></span>{' '}
                  {t('loading_text')}
                </button>
              </div>
            ) : (
              <div className="button-group">
                <button
                  onClick={() => setState({ isOpen: false })}
                  className="btn btn-secondary"
                >
                  {t('cancel_button')}
                </button>
                <button onClick={setForSale} className="btn ms-3 btn-primary">
                  {t('set_for_sale')}
                </button>
              </div>
            )}
          </Modal>
        </div>
      </div>
    );

  return (
    <div
      className={`mb-4 rounded-3 border border-2 ${
        isSale ? 'opacity-100' : 'opacity-75'
      }`}
    >
      <div className="">
        <div className="d-flex align-items-center justify-content-between py-2 px-2 border-bottom">
          <div className="d-flex align-items-center me-2">
            <button
              className={`p-0 btn me-2 fs-5 ${
                isDefault ? 'text-warning' : 'text-muted'
              }`}
              onClick={() => setState({ isOpenDefault: true })}
            >
              <span className="fa fa-star"></span>
            </button>
            <h6 className="mb-0 fw-bold">
              <strong>{name}</strong>
            </h6>
          </div>

          <div className="text-end d-flex align-items-center justify-content-end">
            <h6 className="mb-0 me-1 small fs-12">{t('on_sale')}</h6>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`customSwitch-${id}`}
                checked={isSale}
                onChange={e => setForSale(e.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor={`customSwitch-${id}`}
              ></label>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2 px-3">
        {isError && (
          <div className="alert alert-warning my-3">
            <p className="m-0 small">
              Failed to update product status, try again..
            </p>
          </div>
        )}
        <div className="d-flex align-items-center">
          {image && (
            <div
              className="mb-0 me-3"
              style={{ width: '64px', flex: '0 0 64px' }}
            >
              <img src={image} alt={name} className="rounded-3" />
            </div>
          )}
          <div className="product-info" style={{ flex: '1' }}>
            <div className="">
              <div className="d-flex justify-content-between mb-2">
                <h6 className="mb-0 fw-bold">
                  {t('$')} {price_min}-{price_max}
                </h6>
                <h6 className="mb-1 text-muted fs-12">
                  <span className="fa fa-tags me-1"></span>
                  {t(category.name.toLowerCase().replace(' ', '_'))}
                </h6>
              </div>
              <p className="mb-2 lh-sm small">{description}</p>

              {isOwner && (
                <>
                  <div className="mb-2 d-flex align-items-center">
                    <span className="fa fa-building me-2"></span>
                    <select
                      name="select"
                      id="select"
                      className="form-control form-select alert-primary small form-select-sm mb-0"
                      value={productOwner}
                      onChange={e =>
                        setState({
                          productOwner: parseInt(e.target.value),
                          hasChanged: true,
                        })
                      }
                    >
                      <option value={0}>{t('personal')}</option>
                      <option value={user.company.id}>
                        {user.company.name}
                      </option>
                    </select>
                  </div>
                  {hasChanged && (
                    <div className="change-confirm d-flex justify-content-end align-items-center mb-1">
                      <button
                        className="btn btn-sm btn-link text-muted text-decoration-none"
                        onClick={() => {
                          setState({
                            hasChanged: false,
                            productOwner: company ? company.id : 0,
                          });
                        }}
                      >
                        <span className="fa fa-times me-1"></span>
                        {t('cancel_button')}
                      </button>
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={handleChange}
                      >
                        <span className="fa fa-check me-1"></span>
                        {t('save_button')}
                      </button>
                    </div>
                  )}
                </>
              )}

              {isCompany && !isOwner && (
                <div className="d-inline-block align-items-center p-1 px-2 rounded-3 alert-primary mb-1 small">
                  <span className="fa fa-building me-2 text-primary"></span>
                  <span className="text-start" style={{ flex: 1 }}>
                    {company.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={isOpenDefault}
          onRequestClose={() => setState({ isOpenDefault: false })}
          contentLabel="Modal"
          className="reveal small p-3"
          ariaHideApp={false}
        >
          <button className="btn btn-close close-reveal">&times;</button>
          <h6 className="fw-bold">{t('set_as_default_heading')}</h6>
          <p>{t('set_as_default_text')}</p>
          {isLoading ? (
            <div className="button-group d-flex align-items-center">
              <button disabled className="btn btn-secondary w-50">
                {t('cancel_button')}
              </button>
              <button disabled className="btn ms-3 btn-primary w-50">
                <span className="fa fa-spinner fa-spin"></span>{' '}
                {t('loading_text')}
              </button>
            </div>
          ) : (
            <div className="button-group d-flex align-items-center">
              <button
                onClick={() => setState({ isOpenDefault: false })}
                className="btn btn-secondary w-50"
              >
                {t('cancel_button')}
              </button>
              <button
                onClick={setAsDefault}
                className="w-50 btn ms-3 btn-primary"
              >
                {t('set_as_default_button')}
              </button>
            </div>
          )}
        </Modal>

        <Modal
          isOpen={isOpen}
          onRequestClose={() => setState({ isOpen: false })}
          contentLabel="Modal"
          className="reveal small p-3"
          ariaHideApp={false}
        >
          <button className="btn btn-close close-reveal">&times;</button>
          <h5>{t('are_you_sure')}</h5>
          <p>{t('product_set_for_sale')}</p>
          {isLoading ? (
            <div className="button-group">
              <button disabled className="btn btn-secondary">
                {t('cancel_button')}
              </button>
              <button disabled className="btn ms-3 btn-primary">
                <span className="fa fa-spinner fa-spin"></span>{' '}
                {t('loading_text')}
              </button>
            </div>
          ) : (
            <div className="button-group">
              <button
                onClick={() => setState({ isOpen: false })}
                className="btn btn-secondary"
              >
                {t('cancel_button')}
              </button>
              <button onClick={setForSale} className="btn ms-3 btn-primary">
                {t('set_for_sale')}
              </button>
            </div>
          )}
        </Modal>
      </div>
      <div className="py-2 px-3 d-flex align-items-center border-top justify-content-between">
        <div className="button-group flex-1">
          {((isCompany && isOwner) || !isCompany) && (
            <button
              className="btn mb-0  me-2 btn-sm btn-outline-secondary"
              onClick={() => setState({ isDeleteOpen: true })}
            >
              {t('delete_label')}
            </button>
          )}
          <Link
            to={`/seller/product/edit/${id}`}
            className="btn mb-0  btn-sm btn-outline-secondary"
          >
            {t('edit_label')}
          </Link>
        </div>
        {isPro && (
          <Link
            to={`/pro/create-order/?product_id=${id}`}
            className="btn mb-0 btn-sm btn-outline-gold"
          >
            {t('pro_create_order')}
          </Link>
        )}
        <Modal
          isOpen={isDeleteOpen}
          onRequestClose={() => setState({ isDeleteOpen: false })}
          contentLabel="Modal"
          className="reveal small p-3"
          ariaHideApp={false}
        >
          <button
            className="btn btn-close close-reveal"
            onClick={() => setState({ isDeleteOpen: false })}
          >
            &times;
          </button>
          <h5>{t('are_you_sure')}</h5>
          <p>{t('delete_product_text')}</p>
          {isDeleting ? (
            <div className="button-group">
              <button disabled className="btn btn-secondary">
                {t('cancel_button')}
              </button>
              <button disabled className="btn ms-3 btn-danger">
                <span className="fa fa-spinner fa-spin"></span>{' '}
                {t('loading_text')}
              </button>
            </div>
          ) : (
            <div className="button-group">
              <button
                onClick={() => setState({ isDeleteOpen: false })}
                className="btn btn-secondary"
              >
                {t('cancel_button')}
              </button>
              <button onClick={handleDelete} className="btn ms-3 btn-danger">
                {t('delete_label')}
              </button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}
