import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function BackButton({
  go = null,
  text = 'back_button',
  classes = 'bg-primary text-light',
}) {
  const { t } = useTranslation();
  const history = useHistory();
  if (go === null) {
    return (
      <div
        className={`back-button d-flex align-items-center position-sticy sticky-top shadow-sm border-bottom ${classes}`}
        style={{ top: '0' }}
      >
        <button
          className="text-start fs-5 btn p-3 btn-transparent  me-2"
          onClick={() => history.goBack()}
        >
          <span className="fa fa-chevron-left"></span>
        </button>
        <h1 className="fs-6 mb-0">{text}</h1>
      </div>
    );
  }
  return (
    <div
      className="back-button position-sticy sticky-top border-top border-bottom bg-dark"
      style={{ top: '60px' }}
    >
      <Link to={go} className="text-start text-light w-100 btn btn-transparent">
        <span className="fa fa-chevron-left me-2"></span>
        <span>{t(text)}</span>
      </Link>
    </div>
  );
}
