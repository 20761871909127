import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTransactionExport } from '../../libs/apis';
import Loader from '../../components/global/Loader';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { numberFormat } from '../../libs/utils';

function SellerOrder() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');
  const isSuperUser = user.super_user || false;

  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { orders, isLoading } = state;

  useEffect(() => {
    const getOrderData = async () => {
      const payload = {
        statusFrom: 4,
      };
      await getTransactionExport(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, []);

  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-white',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  let exportData = [];
  const headers = [
    t('export_order_date'),
    t('export_reference_number'),
    t('export_buyer_name'),
    t('export_invoice_recepient'),
    t('export_product'),
    t('export_additional_info'),
    t('export_vat_paid'),
    t('export_total_category1'),
    t('export_total_category2'),
    t('export_total_excl_vat'),
    t('export_total_incl_vat'),
    t('export_date_of_invoice'),
    t('export_due_date_of_invoice'),
    t('export_seller_name'),
    t('export_status'),
    t('export_download_pdf'),
  ];
  if (orders.length > 0) {
    orders.forEach(order => {
      let data = [];
      const orderDate = moment(order.confirmation_date).format('DD.MM.YYYY');

      const referenceNumber = order.reference_number;
      const buyerName = order.user.name ? order.user.name : order.user.username;
      const invoiceRecepient = order.user.email;
      const product = order.product.name;
      const additionalInfo =
        order.summary_text && order.summary_text !== ''
          ? order.summary_text
          : '-';

      let vatPaid = 0;
      let category1ExclVat = 0;
      let category2ExclVat = 0;
      let totalExclVat = 0;
      let totalInclVat = 0;

      const allItems = order.transaction_additional_items || [];
      allItems.forEach(item => {
        const { price, discount, vat, quantity, type } = item;

        const subtotal = price * quantity;
        const subtotalDiscount =
          discount > 0 ? subtotal - (subtotal * discount) / 100 : subtotal;
        const subtotalVat =
          vat > 0
            ? subtotalDiscount + (subtotalDiscount * vat) / 100
            : subtotalDiscount;
        totalExclVat += subtotalDiscount;
        totalInclVat += subtotalVat;
        vatPaid += subtotalVat - subtotalDiscount;

        if (type === 0) {
          category1ExclVat += subtotalDiscount;
        } else {
          category2ExclVat += subtotalDiscount;
        }
      });
      const validRequest = order.transaction_request.find(
        e => e.status >= 4 && e.status !== 7,
      );
      const created_at = validRequest
        ? validRequest.created_at
        : order.created_at;

      const dateOfInvoice = moment(created_at).format('DD.MM.YYYY');
      const dueDateOfInvoice = moment(created_at)
        .add(7, 'days')
        .format('DD.MM.YYYY');
      const sellerName = order.product.seller.name
        ? order.product.seller.name
        : order.product.seller.username;
      const orderStatus = t(stats[order.status - 1].status);
      const pdf = order.pdf;

      data.push(orderDate);
      data.push(referenceNumber);
      data.push(buyerName);
      data.push(invoiceRecepient);
      data.push(product);
      data.push(additionalInfo);
      data.push(numberFormat(vatPaid));
      data.push(numberFormat(category1ExclVat));
      data.push(numberFormat(category2ExclVat));
      data.push(numberFormat(totalExclVat));
      data.push(numberFormat(totalInclVat));
      data.push(dateOfInvoice);
      data.push(dueDateOfInvoice);
      data.push(sellerName);
      data.push(orderStatus);
      data.push(pdf);
      exportData.push(data);
    });
  }
  let downloadableData = [...exportData];
  downloadableData.unshift(headers);

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h1 className="fs-6 text-light mb-0">
                  <span className="fa fa-download me-2"></span>
                  {t('export_invoice_text')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                {orders.length > 0 ? (
                  <div className="invoice-list">
                    <div className="table-container table-responsive">
                      <table className="table table-bordered table-striped text-dark table-sm text-nowrap fs-12 table-responsive">
                        <thead className="table-secondary text-center">
                          <tr>
                            <th>{t('order_text')}</th>
                            <th>{t('export_download_pdf')}</th>
                            <th>{t('export_order_date')}</th>
                            <th>{t('export_reference_number')}</th>
                            <th>{t('export_buyer_name')}</th>
                            <th>{t('export_invoice_recepient')}</th>
                            <th>{t('export_product')}</th>
                            <th>{t('export_additional_info')}</th>
                            <th>{t('export_vat_paid')}</th>
                            <th>{t('export_total_category1')}</th>
                            <th>{t('export_total_category2')}</th>
                            <th>{t('export_total_excl_vat')}</th>
                            <th>{t('export_total_incl_vat')}</th>
                            <th>{t('export_date_of_invoice')}</th>
                            <th>{t('export_due_date_of_invoice')}</th>
                            <th>{t('export_seller_name')}</th>
                            <th>{t('export_status')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {exportData.map((data, index) => {
                            return (
                              <tr key={index} className="text-center">
                                <td>
                                  <Link
                                    to={`/seller/order/${orders[index].id}`}
                                  >
                                    #{orders[index].id}
                                  </Link>
                                </td>
                                <td className="text-center">
                                  <a
                                    href={orders[index].pdf}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <span className="text-danger fa fa-file-pdf-o"></span>
                                  </a>
                                </td>
                                <td>{data[0]}</td>
                                <td>{data[1]}</td>
                                <td>{data[2]}</td>
                                <td>{data[3]}</td>
                                <td>{data[4]}</td>
                                <td>{data[5]}</td>

                                <td>{data[6]}</td>
                                <td>{data[7]}</td>
                                <td>{data[8]}</td>
                                <td>{data[9]}</td>
                                <td>{data[10]}</td>

                                <td>{data[11]}</td>
                                <td>{data[12]}</td>
                                <td>{data[13]}</td>
                                <td>{data[14]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <CSVLink
                      data={downloadableData}
                      className="btn btn-primary btn-lg w-100 shadow my-3"
                      filename={`export-${moment().format(
                        'YYYY-MM-DD-HH-mm-ss',
                      )}.csv`}
                    >
                      <span className="fa fa-download me-2"></span>
                      {t('download_csv_button')}
                    </CSVLink>
                    {isSuperUser && (
                      <div className="btn-container my-3">
                        <Link
                          to="/seller/invoice/export/super"
                          className="btn btn-danger  btn-lg w-100 fs-6"
                        >
                          {t('export_all_transaction_button')}
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center p-3">
                    {isLoading ? (
                      <Loader center size="24" />
                    ) : (
                      <h6>{t('there_is_no_order')}</h6>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerOrder;
