import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Page from '../../components/global/Page';
import toolsImg from '../../assets/images/tools.png';

function Error404() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="app-container">
      <Helmet>
        <title>{t('buyer_only_page')} | Luokki</title>
      </Helmet>
      <div className="error-page">
        <Page>
          <div className="error-container p-3">
            <div className="error-image">
              <img src={toolsImg} alt={t('buyer_only_page')} width="80" />
            </div>
            <h2>{t('for_buyer_heading')}</h2>
            <p>
              {t('for_buyer_text')}. <br />
              {t('register_your_buyer_text')}{' '}
              <Link to={`/login${location.search}`}>{t('login_button')}</Link>
            </p>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => history.goBack()}
            >
              {t('back_button')}
            </button>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default Error404;
