import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

export default function WidgetHorse({ data, withImage = true }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const {
    image,
    name,
    description,
    birthdate,
    withers,
    job,
    breed,
    gallery,
    billing_active = false,
    billing_email = '',
    billing_data = [],
    stable = null,
  } = data.item;

  return (
    <div className="horse-widget-info me-1">
      <div className="position-relative horse-item">
        {/* {image && (
          <div className="mb-1">
            <img src={image} alt={name} className="img-thumbnail rounded-circle shadow-sm" />
          </div>
        )} */}
        <div
          onClick={() => setOpen(true)}
          className={`text-nowrap mb-0 stretched-link lh-1`}
        >
          <h6
            className={`${
              withImage
                ? 'shadow-sm small d-flex align-items-center p-1 rounded-pill bg-primary px-1 pe-2 text-light text-center'
                : ' mb-0 fs-14 text-primary lh-1'
            }`}
          >
            {image && withImage && (
              <img
                src={image}
                alt={name}
                className="me-1 rounded-circle shadow-sm"
                width="20"
                height="20"
                style={{ height: '20px', objectFit: 'cover' }}
              />
            )}
            {name}
            {billing_active && withImage && (
              <span className="text-light ms-1 fa fa-check-circle"></span>
            )}
          </h6>
          {stable && (
            <span className="w-100  text-muted d-block fs-10 fw-lighter">
              <em>{stable.name}</em>
            </span>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="seller-complete-info">
          <h5 className="text-success small text-uppercase mb-3">
            {t('horse_information_heading')}
          </h5>
          {image && (
            <img src={image} alt={name} className="rounded shadow-sm mb-3" />
          )}
          <h6 className="fw-bold">
            {name}
            {billing_active ? (
              <span className="text-primary ms-1 fa fa-check-circle"></span>
            ) : (
              <span className="text-muted fst-italic fs-10 ms-1">
                ({t('billing_info_inactive')})
              </span>
            )}
          </h6>
          {stable && (
            <div className="alert alert-info p-1 shadow-sm mb-1 d-inline-block px-2 rounded-pill">
              <h6 className="mb-0 fs-12 text-reset">
                <span className="fa fa-map me-2"></span>
                {stable.name}
              </h6>
            </div>
          )}
          {billing_active && billing_email !== '' && (
            <div className="alert alert-warning p-2">
              <h6 className="fs-12 mb-1">{t('billing_info_label')}</h6>
              <p className="mb-1 fs-12">
                {t('billing_info_email')}: {billing_email}
              </p>
              {billing_data.length > 0 && (
                <>
                  {billing_data.map(bil_data => {
                    const { name, value } = bil_data;
                    return (
                      <p className="mt-1 mb-0 fs-12" key={name}>
                        {t(name)}: {value}
                      </p>
                    );
                  })}
                </>
              )}
            </div>
          )}
          {/* <div className="info-box d-flex py-2">
            <small className="label">{t('horse_name_label')}</small>
            <small>:</small>
            <h6>{name}</h6>
          </div> */}
          <p>{description}</p>
          <div className="card bg-white shadow mb-3">
            <div className="card-header bg-secondary p-2">
              <h6 className="mb-0 text-light small">
                <span className="fa fa-table me-2"></span>
                {t('horse_detail')}
              </h6>
            </div>
            <div className="card-body p-0">
              <div className="table-container">
                <table className="table table-sm table-bordered table-striped mb-0">
                  <tbody>
                    <tr>
                      <th>{t('horse_date_of_birth')}</th>
                      <td>:</td>
                      <td className="w-50">{birthdate || '-'}</td>
                    </tr>
                    <tr>
                      <th>{t('horse_withers')}</th>
                      <td>:</td>
                      <td>{withers || '-'}</td>
                    </tr>
                    <tr>
                      <th>{t('horse_job')}</th>
                      <td>:</td>
                      <td>{job || '-'}</td>
                    </tr>
                    <tr>
                      <th>{t('horse_breed')}</th>
                      <td>:</td>
                      <td>{breed || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {gallery && gallery.length > 0 && (
            <div className="horse-gallery sgallery-container mb-3">
              <div className="card shadow">
                <div className="card-header bg-secondary p-2">
                  {gallery.length > 1 ? (
                    <div className="swiper-navigations d-flex align-items-center justify-content-between">
                      <button className="swiper-nav swiper-prev btn btn-sm py-1 btn-light ">
                        <span className="fa fa-chevron-left"></span>
                      </button>
                      <h6 className="m-0 small text-light">
                        <span className="fa fa-image me-2"></span>
                        {t('horse_gallery')}
                      </h6>
                      <button className="swiper-nav swiper-next btn btn-sm btn-light py-1">
                        <span className="fa fa-chevron-right"></span>
                      </button>
                    </div>
                  ) : (
                    <div className="swiper-navigations d-flex align-items-center justify-content-center">
                      <h6 className="m-0 small text-light">
                        <span className="fa fa-image me-2"></span>
                        {t('horse_gallery')}
                      </h6>
                    </div>
                  )}
                </div>
                <div className="card-body p-0">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoHeight={true}
                    navigation={{
                      nextEl: '.swiper-next',
                      prevEl: '.swiper-prev',
                    }}
                  >
                    {gallery.map((g, index) => (
                      <SwiperSlide key={g + index}>
                        <img src={g} alt={g + index} className="w-100" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          )}

          <button
            onClick={() => setOpen(false)}
            className="btn btn-sm btn-secondary w-100"
          >
            {t('close_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setOpen(false)}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
