import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import GoogleMapReact from 'google-map-react';

import Page from '../../components/global/Page';
import LoadMore from '../../components/global/LoadMore';
import Product from '../../components/global/Product';
import Skeleton from 'react-loading-skeleton';
// import { useHistory } from 'react-router-dom';
import { getCategories, getProducts } from '../../libs/apis';

function Browse() {
  const { t } = useTranslation();
  // const history = useHistory();
  // const formSubmit = e => {
  //   e.preventDefault();
  //   if (selectedCategory !== 0) {
  //     history.push(`/explore?category=${selectedCategory}`);
  //   }
  // };
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const initialState = {
    current: 1,
    last: 99,
    hasNext: true,
    isLoadMore: false,
    viewAs: 'list',
    center: [],
    showProducts: false,
    sellerId: 0,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { current, hasNext, viewAs, center, showProducts, sellerId } = state;

  useEffect(() => {
    const getCats = async () => {
      const cats = await getCategories();
      if (cats && cats.status === 200) {
        if (cats.data.response && cats.data.response.length > 0)
          setCategories(cats.data.response);
      }
    };
    getCats();
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProductData = async () => {
      setLoading(true);
      const payload = {
        category: selectedCategory,
      };
      await getProducts(payload).then(resp => {
        if (resp.status === 200) {
          if (resp.data.response && resp.data.response.length > 0) {
            setData(resp.data.response);
          }
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      });
      setLoading(false);
    };
    navigator.geolocation.getCurrentPosition(
      position => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        console.log('getCurrentPosition Success ' + lat + ', ' + lng); // logs position correctly
        const loc = {
          center: {
            lat: lat,
            lng: lng,
          },
          zoom: 8,
        };
        setState({ center: [60.3127401, 25.11155] });
        // setLocation(loc)
        getProductData(loc);
      },
      error => {
        const loc = {
          center: {
            lat: 60.3127401,
            lng: 25.11155,
          },
          zoom: 8,
        };
        setState({ center: [60.3127401, 25.11155] });
        // setLocation(loc)
        getProductData(loc);
      },
      { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
    );
  }, [selectedCategory]);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      category: selectedCategory,
      page: current + 1,
    };
    await getProducts(payload).then(resp => {
      if (resp.status === 200) {
        console.log('res', resp.data.response);
        setData([...data, ...resp.data.response]);
        const page = resp.data.metadata.pagination.page || null;
        if (page) {
          setState({ current: page.current, last: page.last });
          const hasNextPage = page.current < page.last;
          setState({ hasNext: hasNextPage });
        }
      }
    });
  };

  return (
    <div className="app-container">
      <Helmet>
        <title>{t('explore_page')} | Luokki</title>
      </Helmet>
      {/* <div className="home-header">
        <div className="container">
          <div className="row align-items-center  ">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between">
                <input type="text" id="search" className="form-control form-control-sm mb-0" placeholder={t('search_by_name_text')} style={{ flex: '1' }} />
                <button className="btn bg-gradient btn btn-outline-primary" type="submit"><span className="fa fa-search"></span></button>
                <button className="btn btn-sm btn-primary btn-round mb-0 ms-3"><span className="fa fa-search"></span></button>
                <h2>{t('explore_heading')}</h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="search-widget bg-white pt-0 shadow-sm">
        {/* <div className="container">
          <div className="row">
            <div className="col">
              <form action="search" id="search-form" onSubmit={formSubmit}>
                <div className="input-groups mb-0 d-flex align-items-center">
                  <input
                    type="text"
                    id="search"
                    className="form-control form-control-sm mb-0"
                    placeholder={t('search_by_name_text')}
                    style={{ flex: '1' }}
                  />
                  <button className="btn btn-sm btn-primary btn-round mb-0 ms-3">
                    <span className="fa fa-search"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        <div className="app-filter-view d-flex align-items-center bg-white">
          <button
            className={`btn mb-0 rounded-0 w-50 ${
              viewAs === 'list' ? 'active' : 'inactive text-muted opacity-50'
            }`}
            onClick={() =>
              setState({ showProducts: false, viewAs: 'list', sellerId: 0 })
            }
          >
            <span className="fa fa-list me-1"></span> {t('list_view_label')}
          </button>
          <button
            className={`btn mb-0 rounded-0 w-50 ${
              viewAs === 'map' ? 'active' : 'inactive text-muted opacity-50'
            }`}
            onClick={() =>
              setState({ showProducts: false, viewAs: 'map', sellerId: 0 })
            }
          >
            <span className="fa fa-map me-2"></span> {t('map_view_label')}
          </button>
        </div>
      </div>
      <Page>
        <div className="list-order-container pt-4">
          <div className="app-filter d-flex align-items-center justify-content-between mb-3">
            <h6 className="small mb-0 me-3">{t('filter_label')}:</h6>
            <div className="app-filter-sort justify-content-end d-flex align-items-center">
              {/* <select
                name="sortBy"
                id="sortBy"
                className="form-select form-select-sm"
              >
                <option value="0" disabled>
                  {t('sort_by_text')}
                </option>
                <option value="ascPrice">{t('lowest_price_label')}</option>
                <option value="descPrice">{t('highest_price_label')}</option>
                <option value="nearby">{t('near_me_label')}</option>
                <option value="mostPopular">{t('most_popular_label')}</option>
                <option value="mostOrdered">{t('most_ordered_label')}</option>
              </select> */}

              <select
                className="form-select form-select-sm w-50 ms-2"
                aria-label={t('category_select')}
                name="category"
                onChange={e => setSelectedCategory(e.target.value)}
              >
                <option value="0">{t('category_select')}</option>
                {categories.length > 0 &&
                  categories.map((cat, index) => (
                    <option key={cat.id + index} value={cat.id}>
                      {t(cat.name.toLowerCase().replace(' ', '_'))}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {viewAs === 'list' ? (
            <div className="list-order-item">
              {loading && data.length === 0 && (
                <div className="order-item">
                  <div className="order-item-image">
                    <Skeleton height={48} />
                  </div>
                  <div className="order-item-description">
                    <h4>
                      <Skeleton />
                    </h4>
                    <p className="mb-0 text-muted">
                      <Skeleton />
                    </p>
                  </div>
                </div>
              )}
              {/* {data.length > 0 && (
              <h3>{t('near_you_heading')}</h3>
            )} */}
              {data.length > 0 &&
                data.map(p => {
                  return <Product data={p} key={p.id} />;
                })}
              {hasNext && !loading && data.length > 0 && (
                <LoadMore isLoadMore={false} loadMore={nextPage} />
              )}
            </div>
          ) : (
            <div className="map-view">
              <div
                style={{ height: '280px', width: '100%' }}
                className="shadow-sm mb-3"
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                  }}
                  defaultCenter={center}
                  defaultZoom={12}
                >
                  <Marker
                    lat={60.3127401}
                    lng={25.11155}
                    photo={null}
                    me
                    click={() => alert('my location')}
                  />
                  {data.length > 0 &&
                    data.map((d, index) => {
                      const { seller, id, name } = d;
                      return (
                        <Marker
                          key={id + name}
                          lat={seller.latitude}
                          lng={seller.longitude}
                          data={d}
                          photo={seller.photo}
                          click={() =>
                            setState({
                              showProducts: true,
                              sellerId: seller.id,
                            })
                          }
                        />
                      );
                    })}
                </GoogleMapReact>
              </div>
              {data.length > 0 &&
                showProducts &&
                data.map(p => {
                  if (p.seller.id !== sellerId) return null;
                  return <Product data={p} key={p.id} />;
                })}
            </div>
          )}
        </div>
      </Page>
      {/* <Page>
        <div className="list-order-container pt-3">
          <h3><span className="fa fa-history"></span>{t('my_recent_orders_title')}</h3>
          <div className="list-order-item">
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/2"><img src={placeholder} alt="Item 1" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/2">Lorem ipsum dolor sit amet.</Link></h4>
              </div>
            </div>
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/3"><img src={placeholder} alt="Item 2" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/3">Consectetur adipisicing elit. Quasi veniam ea.</Link></h4>
              </div>
            </div>
          </div>
        </div>

        <div className="list-order-container">
          <h3><span className="fa fa-hourglass"></span>{t('most_ordered_products_title')}</h3>
          <div className="list-order-item">
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/8"><img src={placeholder} alt="Item 3" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/8">Consectetur adipisicing elit.</Link></h4>
              </div>
            </div>
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/2"><img src={placeholder} alt="Item 4" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/2">Dolor sit amet consectetur adipisicing at.</Link></h4>
              </div>
            </div>
          </div>
        </div>

        <div className="list-order-container">
          <h3><span className="fa fa-star"></span>{t('promoted_products_title')}</h3>
          <div className="list-order-item">
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/3"><img src={placeholder} alt="Item 5" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/3">Coluptatibus ullam est hic consequuntur illo!</Link></h4>
              </div>
            </div>
            <div className="order-item">
              <div className="order-item-image">
                <Link to="/product/8"><img src={placeholder} alt="Item 6" /></Link>
              </div>
              <div className="order-item-description">
                <h4><Link to="/product/8">Lorem ipsum dolor sit amet cons.</Link></h4>
              </div>
            </div>
          </div>
        </div>
      </Page> */}
      {/* <MainMenu /> */}
    </div>
  );
}

export default Browse;

const Marker = ({ click = false, photo, me = false }) => (
  <div
    className="map-marker text-center"
    onClick={click}
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    {me ? (
      <span className="icon">
        <span className="fa fa-map-marker text-danger"></span>
      </span>
    ) : (
      <img
        src={photo}
        className="rounded-circle shadow-sm border border-success"
        width="24"
        height="24"
        alt="marker"
      />
    )}
  </div>
);
