import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import Page from '../../components/global/Page';
import { updateProfile, getGeoLocation, uploadImage } from '../../libs/apis';
import userImg from '../../assets/images/user.png';
import { useSelector, useDispatch } from 'react-redux';
import { update } from '../../redux/slices/authSlice';

function EditProfile() {
  const { t } = useTranslation();
  const { user, role } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const initialState = {
    username: user ? user.username : '',
    name: user ? (user.name ? user.name : '') : '',
    legalId: user ? (user.legal_id ? user.legal_id : '') : '',
    phone: user ? (user.phone ? user.phone : '') : '',
    address: user ? (user.address ? user.address : '') : '',
    city: user ? (user.city ? user.city : '') : '',
    email: user ? user.email : '',
    pinCode: user ? (user.pincode ? user.pincode : '') : '',
    homepageUrl: user
      ? user.website
        ? user.website.replace('https://', '')
        : ''
      : '',
    lat: user ? (user.latitude ? parseFloat(user.latitude) : '') : '',
    lng: user ? (user.longitude ? parseFloat(user.longitude) : '') : '',
    description: user ? (user.biodata ? user.biodata : '') : '',
    loading: false,
    error: false,
    uploading: false,
    errorUpload: false,
    uploadBanner: false,
    errorBannerUpload: false,
    photoUrl: user ? (user.photo ? user.photo : '') : '',
    bannerUrl: user ? (user.banner ? user.banner : '') : '',
    newBannerUrl: null,
    zoom: 15,
    message: '',
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
    bankAccount: user ? (user.bank_account ? user.bank_account : '') : '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    username,
    name,
    phone,
    email,
    address,
    description,
    lat,
    lng,
    loading,
    error,
    uploading,
    errorUpload,
    message,
    photoUrl,
    zoom,
    geolocationStatus,
    center,
    draggable,
    geocode,
    geoAddress,
    bannerUrl,
    homepageUrl,
    newBannerUrl,
    errorBannerUpload,
    bankAccount,
  } = state;

  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
          // console.log('geo', geo)
        }
      }
    });
  };

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    if (lat === '' && lng === '') {
      navigator.geolocation.getCurrentPosition(
        position => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
          getInitialGeoLoc(latitude, longitude);
          setState({
            lat: latitude,
            lng: longitude,
            geolocationStatus: true,
            center: [latitude, longitude],
          });
        },
        error => {
          console.error(JSON.stringify(error));
        },
        { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
      );
    } else {
      getInitialGeoLoc(lat, lng);
      setState({ geolocationStatus: true, center: [lat, lng] });
    }
  }, [user, lat, lng]);

  const onMapDragEnd = e => {
    setState({
      lat: e.center.lat(),
      lng: e.center.lng(),
    });
    getGeoLoc(lat, lng);
  };

  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };

  const submitForm = async ev => {
    ev.preventDefault();
    setState({ loading: true, error: false });
    let areaCode2 = '',
      city2 = '';
    if (geocode.length > 0) {
      areaCode2 = geocode[0].long_name;
      city2 = geocode[3].long_name;
    }

    let payload = {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
      pinCode: areaCode2,
      city: city2,
    };
    if (name !== '') {
      payload.name = name;
    }
    if (photoUrl !== '') {
      payload.photo = photoUrl;
    }
    if (phone !== '') {
      payload.phone = phone;
    }
    if (description !== '') {
      payload.biodata = description;
    }
    if (homepageUrl !== '') {
      payload.website = homepageUrl;
    }
    if (address !== '') {
      payload.address = address;
    }
    if (newBannerUrl !== '') {
      payload.banner = newBannerUrl;
    }
    if (bankAccount !== '') {
      payload.bank_account = bankAccount;
    }
    await updateProfile(payload)
      .then(resp => {
        if (resp.status === 200) {
          const payload = {
            user: resp.data.response,
          };
          dispatch(update(payload));
          history.replace('/profile');
        }
      })
      .catch(err => console.log('err', err));
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });
    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data.secure_url) {
            const resized = res.data.secure_url.replace(
              'https://res.cloudinary.com/luokki/image/upload/',
              'https://res.cloudinary.com/luokki/image/upload/w_300,h_300,c_fill/',
            );
            setState({ photoUrl: resized });
          }
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };

  const onDropBanner = async e => {
    const file = e.target.files;
    setState({ uploadBanner: true, errorBannerUpload: false });
    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ newBannerUrl: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorBannerUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadBanner: false });
      });
  };

  return (
    <div className="app-container">
      <InternalHeader
        title={`${t('edit_profile_page')}: ${name ? name : username}`}
      />
      <div className="main-content pb-5">
        <Page>
          <div className="content py-3 text-break">
            {error && (
              <div className="alert alert-danger fade show" role="alert">
                {message}
              </div>
            )}

            {role === 'seller' && (
              <div className="form-group mb-4">
                {errorBannerUpload && (
                  <div className="alert alert-danger text-center">
                    <p className="m-0">
                      Failed to upload banner image, try again.
                    </p>
                  </div>
                )}
                <div className="image-uploader banner text-center">
                  {newBannerUrl ? (
                    <div className="mb-0 text-center">
                      <img src={newBannerUrl} alt="upload" className="" />
                    </div>
                  ) : (
                    <div className="mb-0 text-center">
                      {bannerUrl ? (
                        <img src={bannerUrl} alt="upload" className="" />
                      ) : (
                        <img
                          src="https://via.placeholder.com/800x240"
                          alt="upload"
                          className=""
                        />
                      )}
                    </div>
                  )}

                  <label
                    htmlFor="img"
                    className={`position-relative rounded-0 w-100 d-flex align-items-center mb-3 btn btn-primary`}
                  >
                    <span className="fa fa-image me-3"></span>
                    <small>Change Banner</small>
                    <input
                      type="file"
                      accept="image/*"
                      id="img"
                      onChange={onDropBanner}
                      className="fs-6 small"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        opacity: 0,
                      }}
                    />
                  </label>
                </div>
              </div>
            )}

            <div className="form-group">
              {errorUpload && (
                <div className="alert alert-danger text-center">
                  <p className="m-0">Failed to upload logo image, try again.</p>
                </div>
              )}
              <div className="image-uploader cover-image">
                {photoUrl ? (
                  <div className="mb-3 text-center">
                    <img
                      src={photoUrl}
                      alt="upload"
                      className="shadow img-thumbnail"
                    />
                  </div>
                ) : (
                  <div className="mb-3 text-center">
                    {!uploading ? (
                      <img
                        src={userImg}
                        alt="upload"
                        className=" shadow img-thumbnail"
                      />
                    ) : (
                      <Loader size={24} />
                    )}
                  </div>
                )}

                <label
                  htmlFor="img"
                  className={`position-relative rounded-pill w-100 d-flex align-items-center mb-3 btn btn-primary`}
                >
                  <span className="fa fa-image me-3"></span>
                  {role === 'company' ? (
                    <small>{t('upload_photo_label')}</small>
                  ) : (
                    <small>{t('upload_photo_label')}</small>
                  )}

                  <input
                    type="file"
                    id="img"
                    accept="image/*"
                    onChange={onDropImage}
                    className="fs-6 small"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      opacity: 0,
                    }}
                  />
                </label>
              </div>
            </div>
            <form onSubmit={submitForm}>
              {/* <div className="profile-container mb-2">
                <div className="edit-cover-image ">
                  <img src="https://www.lifelearnplatform.com/wp-content/uploads/sites/8/2020/02/jussi.jpg" alt="avatar" width="120" className="shadow border" />
                </div>
              </div> */}
              {photoUrl !== '' && <input type="hidden" value={photoUrl} />}
              <div className="form-floating mb-3">
                <input
                  className="form-control form-control-disabled"
                  type="text"
                  id="username"
                  value={username}
                  readOnly
                  disabled
                />
                <label htmlFor="username">{t('username_label')}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  className="form-control form-control-disabled"
                  type="email"
                  id="email"
                  value={email}
                  readOnly
                  disabled
                />
                <label htmlFor="email">{t('email_label')}</label>
              </div>
              {role === 'company' ? (
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="legalName"
                    value={name}
                    onChange={e => setState({ name: e.target.value })}
                    disabled={loading}
                    placeholder={t('company_name_label')}
                  />
                  <label htmlFor="legalName">{t('company_name_label')}</label>
                </div>
              ) : (
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="legalName"
                    value={name}
                    onChange={e => setState({ name: e.target.value })}
                    disabled={loading}
                    placeholder={t('name_label')}
                  />
                  <label htmlFor="legalName">{t('name_label')}</label>
                </div>
              )}
              {role === 'seller' && (
                <>
                  {/* <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="companyId"
                      value={legalId}
                      onChange={(e) => setState({ legalId: e.target.value })}
                      disabled={loading}
                      placeholder={t('company_id_label')} />
                    <label htmlFor="companyId">{t('company_id_label')}</label>
                  </div> */}
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="homepageUrl"
                      value={homepageUrl}
                      onChange={e => setState({ homepageUrl: e.target.value })}
                      disabled={loading}
                      placeholder={t('homepage_url_label')}
                    />
                    <label htmlFor="homepageUrl">
                      {t('homepage_url_label')}
                    </label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="bankAccount"
                      value={bankAccount}
                      onChange={e => setState({ bankAccount: e.target.value })}
                      disabled={loading}
                      placeholder={t('bank_account_label')}
                    />
                    <label htmlFor="bankAccount">
                      {t('bank_account_label')}
                    </label>
                  </div>
                </>
              )}

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  id="phone"
                  value={phone}
                  minLength="6"
                  onChange={e => setState({ phone: e.target.value })}
                  disabled={loading}
                  placeholder={t('phone_label')}
                />
                <label htmlFor="phone">{t('phone_label')}</label>
              </div>

              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  rows="3"
                  placeholder={t('bio_label')}
                  value={description}
                  onChange={e => setState({ description: e.target.value })}
                  style={{ height: '120px' }}
                ></textarea>
                <label htmlFor="description">{t('bio_label')}</label>
              </div>

              {/* <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setState({ city: e.target.value })}
                  disabled={loading}
                  placeholder="City" />
                <label htmlFor="city">City</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="pinCode"
                  value={pinCode}
                  onChange={(e) => setState({ pinCode: e.target.value })}
                  disabled={loading}
                  placeholder="Pin Code" />
                <label htmlFor="pinCode">Pin Code</label>
              </div> */}

              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  name="address"
                  id="address"
                  rows="3"
                  placeholder={t('address_label')}
                  value={address}
                  onChange={e => setState({ address: e.target.value })}
                  style={{ height: '120px' }}
                ></textarea>
                <label htmlFor="address">{t('address_label')}</label>
              </div>

              {geolocationStatus && (
                <div
                  className="rounded mb-4 p-3 bg-gradient"
                  style={{ background: '#ddd' }}
                >
                  <label htmlFor="gmap" className="mb-2">
                    {t('geolocation_label')}
                  </label>

                  <div
                    className="mb-3 position-relative"
                    style={{ height: '50vh', width: '100%' }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                      }}
                      center={center}
                      zoom={zoom}
                      draggable={draggable}
                      onChange={onChangeMap}
                      onDragEnd={onMapDragEnd}
                      options={{ gestureHandling: 'greedy' }}
                    ></GoogleMapReact>
                    <div className="marker-overlay">
                      <div className="pin">
                        <span className="fa fa-map-marker text-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="geolocation-text bg-white p-2">
                    <h6 className="mb-1 fs-12">
                      <span className="fa fa-map-marker me-1 text-danger"></span>
                      Your Location:
                    </h6>
                    <p className="lh-1 mb-0 fs-14 text-dark">{geoAddress}</p>
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="btn bg-gradient btn-lg btn-success w-100"
              >
                <span className="me-3 fa fa-save"></span>
                {t('save_profile_button')}
              </button>
            </form>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default EditProfile;
