import React, { useState, useEffect } from 'react';
import { Redirect, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import Modal from 'react-modal';
import WidgetShowMap from '../../components/global/WidgetShowMap';

import {
  getStableDetail,
  getItems,
  // deleteItem,
} from '../../libs/apis';

function StableDetail() {
  const { t } = useTranslation();
  const { stableId } = useParams();
  // const history = useHistory();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
    horseData: [],
    isHorseLoading: false,
    isHorseError: false,
    horseMessage: '',
    lat: '',
    lng: '',
    center: [],
    geolocationStatus: false,
    zoom: 16,
    geoAddress: '',
    isOpen: false,
    hasLocation: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, horseData, isLoading, isError, isOpen, hasLocation } = state;

  useEffect(() => {
    const getStableData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: stableId,
      };
      await getStableDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            const stableData = resp.data.response;
            setState({
              data: stableData,
              hasLocation: stableData.latitude && stableData.longitude,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, message: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getHorseData = async () => {
      setState({ isHorseLoading: true, isHorseError: false, horseMessage: '' });
      const payload = {
        stableId: stableId,
      };
      await getItems(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ horseData: resp.data.response });
          } else {
            setState({
              isHorseError: true,
              horseMessage: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isHorseError: true, horseMessage: err.error.message });
        })
        .finally(() => setState({ isHorseLoading: false }));
    };
    getStableData();
    getHorseData();
  }, [stableId]);

  if (!stableId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const {
    name,
    logo,
    description,
    phone,
    // business_id,
    // vat_id,
    address,
    latitude,
    longitude,
  } = data;

  // const removeHorse = async () => {
  //   setState({ isDeleting: true, isDeleteError: false, deleteMessage: '' });
  //   const payload = {
  //     id: stableId,
  //   };
  //   await deleteItem(payload)
  //     .then(resp => {
  //       if (resp.status === 200) {
  //         history.push('/profile');
  //       }
  //     })
  //     .catch(err => {
  //       console.log('err', err);
  //       setState({ isDeleteError: true, deleteMessage: err.error.message });
  //     })
  //     .finally(() => setState({ isDeleting: false }));
  // };

  const removeStable = () => {
    console.log('remove');
  };

  const checkRemoveStable = () => {
    if (horseData.length > 0) {
      console.log('open move horse');
    } else {
      setState({ isOpen: true });
    }
  };

  return (
    <div className="app-container">
      <SubHeader title={t('stable_detail_title')} go="/manage">
        <Link to="/manage" className="btn text-primary fw-bold">
          {t('done_button')}
        </Link>
      </SubHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            {logo && (
              <div className="mb-2 d-flex justify-content-center">
                <img
                  src={logo}
                  alt={name}
                  height="160"
                  width="160"
                  className="rounded-circle"
                />
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="fw-bold mb-0">
                {t('stable_information_heading')}
              </h6>
              <Link
                to={`/stable/${stableId}/edit`}
                className="text-decoration-none fs-14 fw-bold p-0"
              >
                {t('change_button')}
              </Link>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="name">{t('stable_name_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{name}</h6>
            </div>
            <hr />
            <div className="info-field mb-3">
              <label htmlFor="phone">{t('phone_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{phone ? phone : '-'}</h6>
            </div>
            <hr />
            <div className="info-field mb-3">
              <label htmlFor="description">{t('description_heading')}</label>
              <h6 className="fs-14 fw-bold mb-0">
                {description ? description : '-'}
              </h6>
            </div>
            <hr />
            <div className="info-field mb-3">
              <label htmlFor="address">{t('address_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{address ? address : '-'}</h6>
            </div>
            <hr />
            <div className="info-field mb-3">
              <label htmlFor="geo">{t('geo_location_label')}</label>
              {/* <h6 className="fs-14 fw-bold mb-0">{address}</h6> */}
              {hasLocation ? (
                <WidgetShowMap latitude={latitude} longitude={longitude} />
              ) : (
                <h6 className="fs-14 fw-bold text-muted opacity-50 mb-0">
                  {t('no_geolocation')}
                </h6>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
              <h6 className="fw-bold mb-0">{t('horse_title')}</h6>
              {/* <Link
                to={`/stable/${stableId}/move`}
                className="text-decoration-none fs-14 fw-bold p-0"
              >
                {t('move_horse_button')}
              </Link> */}
            </div>

            {horseData.map((horse, index) => {
              const { id, name, image } = horse;
              const borderClass = index > 0 ? 'border-top' : '';
              return (
                <div className={`horse-card ${borderClass} py-2`} key={id}>
                  <div className="">
                    <Link
                      to={`/horse/${id}`}
                      className="d-flex align-items-center text-decoration-none"
                    >
                      {image && (
                        <img
                          src={image}
                          alt=""
                          className="rounded-circle me-2"
                          style={{ width: '40px', height: '40px' }}
                        />
                      )}
                      <span className="h6 fw-bold flex-1">{name}</span>
                      <span className="fa fa-chevron-right ml-2 text-muted"></span>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-4">
            <button
              className="btn btn-outline-danger w-100"
              onClick={checkRemoveStable}
            >
              <span className="fa fa-trash me-2"></span>
              {t('delete_stable_button')}
            </button>
          </div>

          <Modal
            isOpen={isOpen}
            onRequestClose={() => {
              setState({ isOpen: false });
            }}
            contentLabel="Modal"
            className="reveal p-4 small"
            ariaHideApp={false}
          >
            <div className="modal-container">
              <h6 className="mb-3 fw-bold">{t('delete_stable_label')}</h6>
              <p>{t('delete_stable_text')}</p>
              <div className="btn-container d-flex justify-content-between align-items-center">
                <button
                  className="btn fs-14 btn-outline-secondary w-50"
                  onClick={() => setState({ isOpen: false })}
                >
                  {t('cancel_button')}
                </button>
                <button
                  className="btn fs-14 btn-danger ms-3 w-50"
                  onClick={removeStable}
                >
                  {t('delete_stable_button')}
                </button>
              </div>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => {
                setState({ isOpen: false });
              }}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default StableDetail;
