import { useTranslation } from 'react-i18next';
import { numberFormat } from '../../libs/utils';

export default function ProductBox({ product, classes = '', price = 0 }) {
  const { t } = useTranslation();
  return (
    <div
      className={`selected-product border border-2 rounded-1 p-3 ${classes}`}
    >
      <div className="d-flex align-items-start">
        {product.image && (
          <img
            src={product.image}
            alt={product.name}
            width="48"
            className="rounded me-3 align-self-start"
          />
        )}
        <div className="product-detail">
          <h4 className="fw-bold fs-6 mb-1">{product.name}</h4>
          <h5 className="fs-10 mb-0 text-muted">
            <span className="fa fa-tag me-1"></span>
            {product.category.name}
          </h5>
          {product.company && (
            <h5 className="fs-10 mb-0 text-muted">
              <span className="fa fa-building me-1"></span>
              {product.company.name}
            </h5>
          )}
          <h5 className="fs-6 mb-0 mt-1 fw-bold">
            {t('$')}
            {price === 0 ? (
              <>
                {numberFormat(product.price_min)} -{' '}
                {numberFormat(product.price_max)}
              </>
            ) : (
              <>{numberFormat(price)}</>
            )}
          </h5>
        </div>
      </div>
    </div>
  );
}
