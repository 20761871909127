import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addNotif } from '../../redux/slices/appSlice';
import { getStorage, setStorage } from '../../libs/utils';
import { checkNotification } from '../../libs/apis';
import moment from 'moment';

import Logo from '../../assets/images/logo.png';
import userImg from '../../assets/images/user.png';
import ProBadge from './ProBadge';

export default function L() {
  const location = useLocation();
  const route = location.pathname;
  const { user, role } = useSelector(state => state.auth);
  const isPro = user && user.subscription ? user.subscription : false;

  const { hasNotification } = useSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkNotif = async () => {
      const lastNotifTime = getStorage('last_notif_time');
      checkNotification().then(resp => {
        if (resp.status === 200) {
          let hasNotif = false;
          const lastNotif = resp.data.response.created_at || moment();
          if (lastNotifTime) {
            if (moment(lastNotif).diff(moment(lastNotifTime))) {
              hasNotif = true;
            }
          } else {
            hasNotif = true;
          }
          if (hasNotif) {
            const payload = {
              hasNotification: true,
            };
            setStorage('last_notif_time', lastNotif);
            dispatch(addNotif(payload));
          }
        }
      });
    };
    if (user) {
      checkNotif();
    }
  }, [user, location, dispatch]);

  if (user) {
    return (
      <div className="main-header py-1 sticky-top">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header-container d-flex align-items-center justify-content-between position-relative">
                <div className="logo text-center" style={{ flex: 1 }}>
                  <Link to={`${role === 'seller' ? '/seller' : '/'}`}>
                    <img src={Logo} alt="Luokki" />
                  </Link>
                </div>
                <div
                  className="menu-profile position-absolute"
                  style={{ right: 0 }}
                >
                  <div className="d-flex align-items-center">
                    <Link
                      to="/notification"
                      className={`menu-notification position-relative fs-5 me-3 ${
                        route === '/notification' ? 'active' : ''
                      }`}
                    >
                      {hasNotification && (
                        <span
                          className="badge bg-danger rounded-circle p-1 position-absolute top-0 start-50"
                          style={{
                            marginLeft: '3px',
                            marginTop: '3px',
                            display: 'block',
                            animation: 'pulse 1s infinite ease-in-out',
                          }}
                        ></span>
                      )}
                      <span className="fa fa-bell"></span>
                    </Link>

                    <Link
                      to="/profile"
                      className="text-decoration-none text-muted text-center"
                    >
                      <span className="menu-icon">
                        {user.photo ? (
                          <img
                            src={user.photo}
                            alt=""
                            width="40"
                            height="40"
                            style={{ width: '40px', height: '40px' }}
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src={userImg}
                            alt=""
                            width="40"
                            height="40"
                            style={{ width: '40px', height: '40px' }}
                            className="rounded-circle"
                          />
                        )}
                      </span>
                      {isPro && (
                        <span className="pro-icon">
                          <ProBadge />
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="main-header py-1 bg-light shadow-sm sticky-top">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div className="header-container d-flex align-items-center justify-content-between position-relative">
              <div className="logo text-center" style={{ flex: 1 }}>
                <Link to={`${role === 'seller' ? '/seller' : '/'}`}>
                  <img src={Logo} alt="Luokki" />
                </Link>
              </div>
              <div
                className="menu-profile position-absolute"
                style={{ right: 0 }}
              >
                <Link
                  to="/login"
                  className="text-decoration-none text-muted text-center"
                >
                  <span className="menu-icon">
                    <img
                      src={userImg}
                      alt=""
                      width="40"
                      height="40"
                      style={{ width: '40px', height: '40px' }}
                      className="rounded-circle shadow-sm img-thumbnail"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
