import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/global/Loader';

import { getStables } from '../../libs/apis';

function StableList() {
  const { t } = useTranslation();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError } = state;

  useEffect(() => {
    const getStableData = async () => {
      await getStables()
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getStableData();
  }, []);

  if (data.length === 0 && isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  // const {
  //   name,
  //   image,
  //   description,
  //   birthdate,
  //   withers,
  //   job,
  //   breed,
  //   gallery,
  //   billing_active = false,
  //   billing_email,
  //   billing_data,
  // } = data;

  return (
    <div className="app-container">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="my-3">
              <div className="section-header d-flex justify-content-between align-items-center mb-3">
                <h1 className="fs-6 mb-0 fw-bold d-flex align-items-center">
                  <span className="fa fa-map-marker me-2"></span>
                  {t('stable')}
                </h1>
                <Link to="/stable/new" className="btn btn-primary btn-sm">
                  <span className="fa fa-plus me-2"></span>
                  {t('add_new_stable')}
                </Link>
              </div>

              {data.length > 0 ? (
                <div className="section-content">
                  <hr />
                  <div className="row g-2">
                    {data.map((stable, index) => {
                      const { id, name, image } = stable;
                      return (
                        <div className="col-6" key={index}>
                          <div className="card shadow mb-2">
                            <div className="card-body p-3">
                              {image && (
                                <div className="stable-img mb-3">
                                  <img
                                    src={image}
                                    alt={name}
                                    className="rounded w-100"
                                  />
                                </div>
                              )}
                              <div className="stable-content text-center">
                                <h3 className="fs-6 mb-3 fw-bold">{name}</h3>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to={`/stable/${id}`}
                                    className="btn btn-sm btn-outline-secondary me-2 w-50 py-0 fs-12"
                                  >
                                    <span className="fa fa-eye me-1"></span>
                                    {t('view_button')}
                                  </Link>
                                  <Link
                                    to={`/stable/${id}/edit`}
                                    className="btn btn-sm py-0 fs-12 btn-outline-warning w-50"
                                  >
                                    <span className="fa fa-pencil me-1"></span>
                                    {t('edit_label')}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="px-3 mt-5">
                  <h6 className="small mb-3 text-center">
                    {t('no_stable_info')}
                  </h6>
                  <Link to="/stable/new" className="btn btn-warning w-100">
                    <span className="fa fa-plus me-2"></span>{' '}
                    {t('add_new_stable')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StableList;
