import React from 'react';
import Modal from 'react-modal';

export default function ModalBlock({
  children,
  isOpen = false,
  center = null,
  onClose = () => {},
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      className={`reveal p-3 ${center ? 'center' : ''}`}
      ariaHideApp={false}
    >
      <div className="modal-container">{children}</div>
      <button className="btn btn-close close-reveal" onClick={onClose}>
        &times;
      </button>
    </Modal>
  );
}
