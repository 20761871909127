import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Page from '../../components/global/Page';

import { useSelector } from 'react-redux';
import { getTransaction } from '../../libs/apis';
import LoadMore from '../../components/global/LoadMore';
import Loader from '../../components/global/Loader';
import BoxOrder from '../../components/global/BoxOrder';

function OrderPage() {
  const { t } = useTranslation();
  const { role } = useSelector(state => state.auth);

  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
    current: 1,
    last: 99,
    hasNext: false,
    isLoadMore: false,
  };

  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };

  const { orders, current, hasNext, isLoading, isLoadMore } = state;

  useEffect(() => {
    const getOrderData = async () => {
      await getTransaction()
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
            const page = resp.data.metadata.pagination.page || null;
            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, []);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
    };
    await getTransaction(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ orders: [...orders, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };
  return (
    <div className="app-container">
      {/* <InternalHeader go="/" title={t('order_page')} /> */}
      <div className="main-content py-3">
        {role === 'seller' ? (
          <Page>
            <div className="content py-3">
              <div className="d-flex align-items-center mb-3 justify-content-between">
                <h6 className="mb-0">{t('new_order_heading')}</h6>
                {/* <Link to="/seller/orders" className="btn btn-sm  rounded-pill btn-primary">
									<small>{t('view_all_text')}</small> <strong>{t('order_text')}</strong>
								</Link> */}
              </div>

              {orders.length > 0 ? (
                <div className="order-list-container">
                  {orders.map(order => {
                    return <BoxOrder data={order} key={order.id} />;
                  })}
                </div>
              ) : (
                <div className="text-center p-3">
                  <h6>{t('there_is_no_order')}</h6>
                </div>
              )}
            </div>
          </Page>
        ) : (
          <Page>
            {role === 'buyer' ? (
              <div className="content py-3">
                {orders.length > 0 ? (
                  <div className="order-list-container">
                    {orders.map(order => {
                      return <BoxOrder data={order} key={order.id} />;
                    })}
                  </div>
                ) : (
                  <div className="text-center p-3">
                    {isLoading ? (
                      <Loader center size="24" />
                    ) : (
                      <Link to="/explore" className="btn btn-success">
                        <span className="fa fa-search me-2"></span>
                        {t('explore_button')}
                      </Link>
                    )}
                  </div>
                )}
                {hasNext && (
                  <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />
                )}
              </div>
            ) : (
              <div className="content text-center py-5">
                <h3 className="mb-5">Order list will be shown here</h3>
                <Link to="/">
                  <button className="btn mb-5 bg-gradient w-100 btn-primary">
                    {t('go_to_homepage_button')}
                  </button>
                </Link>
              </div>
            )}
          </Page>
        )}
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default OrderPage;
