import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import LoadMore from '../global/LoadMore';

import { getItems } from '../../libs/apis';

export default function WidgetStableList() {
  const { t } = useTranslation();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    current: 1,
    last: 99,
    hasNext: false,
    isLoadMore: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError, current, isLoadMore, hasNext } = state;

  useEffect(() => {
    const getItemsData = async () => {
      const payload = {
        page: 1,
      };
      await getItems(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
            const page = resp.data.metadata.pagination?.page || null;
            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            } else {
              setState({ hasNext: false });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getItemsData();
  }, []);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
    };

    await getItems(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ data: [...data, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };

  if (data.length === 0 && isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  return (
    <div className="widget-container pb-4">
      <Link
        to="/horse/new"
        className="btn btn-primary btn-lg rounded-circle fs-4 d-flex justify-content-center align-items-center flex-direction-col position-fixed shadow p-0"
        style={{
          right: '1.5rem',
          bottom: '4.5rem',
          width: '56px',
          height: '56px',
          lineHeight: '56px',
        }}
      >
        <span className="fa fa-plus"></span>
      </Link>
      {data.length > 0 && (
        <div className="section-content">
          {data.map((horse, index) => {
            const { id, name, image, stable = null } = horse;
            const borderClass = index > 0 ? 'border-top' : '';
            return (
              <div className={`py-2 ${borderClass}`} key={horse.id}>
                <Link
                  to={`/horse/${id}`}
                  className="d-flex align-items-center justify-content-between py-1 text-decoration-none text-muted"
                >
                  {image ? (
                    <div className="horse-img me-2 w-24 h-24">
                      <img
                        src={
                          image.indexOf('c_crop') > 0
                            ? image
                            : image.replace(
                                '/image/upload/',
                                '/image/upload/c_crop,h_1280,w_1280/',
                              )
                        }
                        alt={name}
                        className="rounded-circle w-100"
                      />
                    </div>
                  ) : (
                    <div className="horse-img me-2">
                      <div className="w-24 h-24 rounded-circle bg-light ">
                        <span className="fa fa-home text-muted"></span>
                      </div>
                    </div>
                  )}
                  <div className="horse-content flex-1">
                    <h6 className="mb-0 fs-10 lh-1 small">
                      {name}
                      {horse.billing_active ? (
                        <span className=" d-inline-block p-1 rounded-pill border border-2 text-primary ms-3 fs-8 fw-bold">
                          {t('billing_info_active')}
                        </span>
                      ) : (
                        <span className="border border-2 d-inline-block p-1 rounded-pill ms-3 fs-8 fw-bold">
                          {t('billing_info_inactive')}
                        </span>
                      )}
                    </h6>
                    {stable && (
                      <h6 className="mb-0 lh-1 fs-8 text-muted">
                        {stable.name}
                      </h6>
                    )}
                  </div>
                  <div className="align-self-end ml-2 fs-6">
                    <span className="fa fa-chevron-right"></span>
                  </div>
                </Link>
              </div>
            );
          })}
          {hasNext && <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />}
        </div>
      )}
    </div>
  );
}
