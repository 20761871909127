import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

export default function WidgetSeller({ data, size = 'medium' }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const {
    logo,
    name,
    email,
    description,
    business_id,
    bank_account,
    vat_id,
    website,
  } = data;
  const imgSize = size === 'small' ? 40 : 60;

  return (
    <div className="seller-widget-info">
      <div className="d-flex align-items-center position-relative">
        {logo && (
          <div
            className={`me-${size === 'small' ? 2 : 3}`}
            style={{ flex: `0 0 ${imgSize}px` }}
          >
            <img
              src={logo}
              alt={name}
              className="img-thumbnail rounded-circle shadow-sm"
              style={{ width: `${imgSize}px` }}
            />
          </div>
        )}
        <div className="seller-indo">
          <div className="d-flex justify-content-between align-items-start">
            <h6 onClick={() => setOpen(true)} className="stretched-link">
              {name} <span className="fa fa-info-circle text-muted ms-1"></span>
            </h6>
          </div>
          {business_id && (
            <h6 className="text-muted fw-light font-ssp small m-0">
              {t('com_business_id')}: {business_id}
            </h6>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="seller-complete-info">
          <h5 className="text-success small text-uppercase mb-3">
            {t('company_information_heading')}
          </h5>
          {logo && (
            <img src={logo} alt={name} className="rounded shadow-sm mb-3" />
          )}
          {description && <p className="small">{description}</p>}
          <div className="info-box d-flex py-2">
            <small className="label">{t('com_name')}</small>
            <small>:</small>
            <h6>{name}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('com_email')}</small>
            <small>:</small>
            <h6>{email}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('com_business_id')}</small>
            <small>:</small>
            <h6>{business_id}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('com_vat_id')}</small>
            <small>:</small>
            <h6>{vat_id}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('com_bank_account')}</small>
            <small>:</small>
            <h6>{bank_account}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('com_website')}</small>
            <small>:</small>
            <h6>{website}</h6>
          </div>
          <button
            onClick={() => setOpen(false)}
            className="btn btn-sm btn-secondary w-100"
          >
            {t('close_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setOpen(false)}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
