import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { addNotif } from '../../redux/slices/appSlice';
import { getStorage, setStorage } from '../../libs/utils';
import { checkNotification } from '../../libs/apis';
import moment from 'moment';
import HorseIcon from './HorseIcon';

export default function MainMenu() {
  const { t } = useTranslation();
  const location = useLocation();
  const route = location.pathname;
  const isHome = route === '/';
  const { role, user } = useSelector(state => state.auth);
  const { hasNotification } = useSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkNotif = async () => {
      const lastNotifTime = getStorage('last_notif_time');
      checkNotification().then(resp => {
        if (resp.status === 200) {
          let hasNotif = false;
          const lastNotif = resp.data.response.created_at || moment();
          if (lastNotifTime) {
            if (moment(lastNotif).diff(moment(lastNotifTime))) {
              hasNotif = true;
            }
          } else {
            hasNotif = true;
          }
          if (hasNotif) {
            const payload = {
              hasNotification: true,
            };
            setStorage('last_notif_time', lastNotif);
            dispatch(addNotif(payload));
          }
        }
      });
    };
    if (user) {
      checkNotif();
    }
  }, [user, location, dispatch]);

  if (role === 'seller') {
    return (
      <div className="main-menu shadow border-top">
        <div className={`menu-item ${route === '/seller' ? 'active' : ''}`}>
          <Link to="/seller">
            <span className="menu-icon">
              <span className="fa fa-dashboard"></span>
            </span>
            <span className="menu-text">{t('menu_dashboard')}</span>
          </Link>
        </div>
        <div
          className={`menu-item ${route === '/seller/order' ? 'active' : ''}`}
        >
          <Link to="/seller/order">
            <span className="menu-icon">
              <span className="fa fa-list"></span>
            </span>
            <span className="menu-text">{t('menu_order')}</span>
          </Link>
        </div>
        <div
          className={`menu-item ${
            route === '/seller/schedule' ? 'active' : ''
          }`}
        >
          <Link to="/seller/schedule">
            <span className="menu-icon">
              <span className="fa fa-calendar"></span>
            </span>
            <span className="menu-text">{t('menu_schedule')}</span>
          </Link>
        </div>
        {/* <div className={`menu-item ${route === '/seller/product' ? 'active' : ''}`}>
          <Link to="/seller/product">
            <span className="menu-icon"><span className="fa fa-briefcase"></span></span>
            <span className="menu-text">{t('menu_products')}</span>
          </Link>
        </div> */}
        <div
          className={`menu-item ${
            route === '/seller/bookkeeping' ? 'active' : ''
          }`}
        >
          <Link to="/seller/bookkeeping">
            <span className="menu-icon">
              <span className="fa fa-book"></span>
            </span>
            <span className="menu-text">{t('menu_bookkeeping')}</span>
          </Link>
        </div>
        {/* <div
          className={`menu-item ${route === '/notification' ? 'active' : ''}`}
        >
          <Link to="/notification">
            <span className="menu-icon position-relative">
              <span className="fa fa-bell"></span>
              {hasNotification && (
                <span
                  className="badge bg-danger rounded-circle p-1 position-absolute top-0 start-50"
                  style={{ marginLeft: '2px', marginTop: '2px' }}
                ></span>
              )}
            </span>
            <span className="menu-text">{t('menu_notification')}</span>
          </Link>
        </div> */}
        <div className={`menu-item ${route === '/manage' ? 'active' : ''}`}>
          <Link to="/manage">
            <span className="menu-icon position-relative">
              <span className="menu-icon">
                <HorseIcon />
              </span>
            </span>
            <span className="menu-text">{t('manage_label')}</span>
          </Link>
        </div>
      </div>
    );
  }
  if (user) {
    return (
      <div className="main-menu shadow border-top">
        {/* <div className={`menu-item ${isHome ? 'active' : ''}`}>
          <Link to="/">
            <span className="menu-icon"><span className="fa fa-home"></span></span>
            <span className="menu-text">{t('menu_home')}</span>
          </Link>
        </div>
         */}
        <div className={`menu-item ${route === '/explore' ? 'active' : ''}`}>
          <Link to="/explore">
            <span className="menu-icon">
              <span className="fa fa-search"></span>
            </span>
            <span className="menu-text">{t('menu_explore')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/orders' ? 'active' : ''}`}>
          <Link to="/orders">
            <span className="menu-icon">
              <span className="fa fa-list"></span>
            </span>
            <span className="menu-text">{t('menu_order')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/schedule' ? 'active' : ''}`}>
          <Link to="/schedule">
            <span className="menu-icon">
              <span className="fa fa-calendar"></span>
            </span>
            <span className="menu-text">{t('menu_schedule')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/manage' ? 'active' : ''}`}>
          <Link to="/manage">
            <span className="menu-icon">
              <HorseIcon />
            </span>
            <span className="menu-text">{t('manage_label')}</span>
          </Link>
        </div>
        {/* <div className={`menu-item ${route === '/wishlist' ? 'active' : ''}`}>
          <Link to="/wishlist">
            <span className="menu-icon">
              <span className="fa fa-star"></span>
            </span>
            <span className="menu-text">{t('menu_favorite')}</span>
          </Link>
        </div> */}
        {/* <div className={`menu-item ${route === '/notification' ? 'active' : ''}`}>
        <Link to="/notification">
          <span className="menu-icon"><span className="fa fa-bell"></span></span>
          <span className="menu-text">{t('menu_notification')}</span>
        </Link>
      </div> */}
        <div
          className={`menu-item ${route === '/notification' ? 'active' : ''}`}
        >
          <Link to="/notification">
            <span className="menu-icon position-relative">
              <span className="fa fa-bell"></span>
              {hasNotification && (
                <span
                  className="badge bg-danger rounded-circle p-1 position-absolute top-0 start-50"
                  style={{ marginLeft: '2px', marginTop: '2px' }}
                ></span>
              )}
            </span>
            <span className="menu-text">{t('menu_notification')}</span>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="main-menu shadow border-top">
      <div className={`menu-item ${isHome ? 'active' : ''}`}>
        <Link to="/">
          <span className="menu-icon">
            <span className="fa fa-home"></span>
          </span>
          <span className="menu-text">{t('menu_home')}</span>
        </Link>
      </div>
      <div className={`menu-item ${route === '/explore' ? 'active' : ''}`}>
        <Link to="/explore">
          <span className="menu-icon">
            <span className="fa fa-search"></span>
          </span>
          <span className="menu-text">{t('menu_explore')}</span>
        </Link>
      </div>
      <div className={`menu-item ${route === '/login' ? 'active' : ''}`}>
        <Link to="/login">
          <span className="menu-icon">
            <span className="fa fa-sign-in"></span>
          </span>
          <span className="menu-text">{t('login_button')}</span>
        </Link>
      </div>
    </div>
  );
}
