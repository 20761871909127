import React, { useState, useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getProductDetail } from '../../libs/apis';

import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';

function ConfirmPage() {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const initialState = {
    data: [],
    isLoading: true,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError, message } = state;
  useEffect(() => {
    const getProduct = async () => {
      const payload = {
        productId: orderId,
      };
      await getProductDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProduct();
  }, [orderId]);

  if (!orderId) return <Redirect to="/404" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );

  const { name } = data;

  return (
    <div className="app-container">
      <InternalHeader title={t('confirm_page')} />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              <div className="multisteps-form pt-5">
                <div className="row">
                  <div className="col-12  ms-auto me-auto mb-5">
                    <div className="multisteps-form__progress">
                      <button
                        className="multisteps-form__progress-btn active js-active"
                        type="button"
                        title={t('request_label')}
                      >
                        {t('request_label')}
                      </button>
                      <button
                        className="multisteps-form__progress-btn active js-active"
                        type="button"
                        title={t('quotation_label')}
                      >
                        {t('quotation_label')}
                      </button>
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title={t('confirmation_label')}
                      >
                        {t('confirmation_label')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <small className="text-success">Product/Service Information</small>
              <p className="mb-0">Horse Maintenance</p>
              <h6 className="text-primary">{t('$')} 123-156</h6>

              <p className="mb-0">Horses First</p>
              <h6 className="text-primary mb-3">ID: 123-xxxxx-xxxx</h6>

              <small className="text-success">User Information</small>
              <h6 className="mb-0 ">Ervandra Halim</h6>
              <h6 className="small">+6281731233</h6> */}
              <div className="quotation-status mb-3">
                <div className="product-detail text-center mb-3">
                  <div className="text-center">
                    <img
                      src={data.image}
                      className="img-thumbnail mb-1"
                      width="150"
                      alt="waiting vendor"
                    />
                    <h6>{name}</h6>
                  </div>
                </div>
                {/*
                <p className="text-center"><img src={toolImg} width="80" alt="waiting vendor" /></p>
                <h6 className="text-secondary text-center">{t('complete_order_text')}</h6> */}
                <h6 className="text-secondary text-center">
                  {t('complete_order_text')}
                </h6>
              </div>

              <Link to="/explore">
                <button className="btn mb-5 w-100 btn-success bg-gradient btn-lg shadow">
                  {t('explore_more_button')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="main-menu shadow-md fixed-bottom">
        <div className="menu-item">
          <Link to="/">
            <span className="menu-icon"><span className="fa fa-home"></span></span>
            <span className="menu-text">{t('home_button_label')}</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to="/profile">
            <span className="menu-icon"><span className="fa fa-user"></span></span>
            <span className="menu-text">{t('profile_button_label')}</span>
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default ConfirmPage;
