import React from 'react';

export default function Loader({ size = 24, center = false, color = 'dark' }) {
  if (center)
    return (
      <div
        className={`loader  mb-3 opacity-50 mx-auto d-flex justify-content-center align-items-center text-${color}`}
        style={{
          fontSize: `${size}px`,
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <span className="fa fa-spin fa-circle-o-notch"></span>
      </div>
    );
  return (
    <div
      className={`loader opacity-50 mb-3 mx-auto text-${color}`}
      style={{ fontSize: `${size}px` }}
    >
      <span className="fa fa-spin fa-circle-o-notch"></span>
    </div>
  );
}
