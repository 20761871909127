import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WidgetSeller from '../../components/global/WidgetSeller';
import BackButton from '../../components/global/BackButton';
import WidgetInviteSeller from '../../components/company/WidgetInviteSeller';
import WidgetDeleteSeller from '../../components/company/WidgetDeleteSeller';
import Loader from '../../components/global/Loader';
import Modal from 'react-modal';

import { useSelector } from 'react-redux';
import {
  getCompanyDetail,
  getCompanyMembers,
  deactivateCompanyMember,
} from '../../libs/apis';

function CompanyIndex() {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  if (role !== 'seller') {
    history.push('/profile');
  }
  const isOwner =
    (user.company && user.company.id === parseInt(companyId)) || false;
  const initialState = {
    data: null,
    members: [],
    isLoading: true,
    isError: false,
    isUploading: false,
    isUploadError: false,
    isSubmiting: false,
    isSubmitError: false,
    isOpen: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isLoading, data, members, isOpen } = state;

  useEffect(() => {
    const getCompanyData = async () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        id: companyId,
      };
      await getCompanyDetail(payload)
        .then(res => {
          if (res.status === 200) {
            // console.log('ada data', res);
            setState({ data: res.data.response });
          }
        })
        .catch(() => setState({ isError: true }))
        .finally(() => setState({ isLoading: false }));
    };
    const getMembers = async () => {
      setState({ isLoadingMember: true, isErrorMember: false });
      const payload = {
        id: companyId,
      };
      await getCompanyMembers(payload)
        .then(res => {
          if (res.status === 200) {
            setState({ members: res.data.response });
          }
        })
        .catch(() => setState({ isErrorMember: true }))
        .finally(() => setState({ isLoadingMember: false }));
    };
    getCompanyData();
    getMembers();
  }, [companyId]);

  if (isLoading || !data) return <Loader size="48" center />;
  const {
    name,
    logo,
    business_id,
    vat_id,
    email,
    bank_account,
    description,
    website,
  } = data;

  const leaveCompany = () => {
    const payload = {
      id: companyId,
      user_id: user.id,
    };
    deactivateCompanyMember(payload)
      .then(res => {
        console.log('res', res);
        if (res.status === 200) {
          window.location.replace('/profile');
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {});
  };

  return (
    <div className="app-container">
      <BackButton go="/profile" text="back_to_profile" />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="py-3">
              {logo && (
                <div className="com-logo text-center mb-3">
                  <img src={logo} alt={name} className="w-50" />
                </div>
              )}
              <div className="text-center">
                <h1 className="text-primary mb-3 fs-3 fw-bold">{name}</h1>
                <p className="mb-3">{description}</p>
              </div>
              <div className="com-content">
                <div className="card mb-3 shadow">
                  <div className="card-header">
                    <h6 className="m-0">{t('com_legal_information')}</h6>
                  </div>
                  <div className="card-body">
                    <h6 className="d-flex flex-wrap justify-content-between">
                      {t('com_business_id')}:{' '}
                      <strong className="text-primary">{business_id}</strong>
                    </h6>
                    <h6 className="mb-0 mt-3 d-flex flex-wrap justify-content-between">
                      {t('com_vat_id')}:{' '}
                      <strong className="text-primary">{vat_id}</strong>
                    </h6>
                    <h6 className="mb-0 mt-3 d-flex flex-wrap justify-content-between">
                      {t('com_bank_account')}:{' '}
                      <strong className="text-primary">{bank_account}</strong>
                    </h6>
                    <h6 className="mb-0 mt-3 d-flex flex-wrap justify-content-between">
                      {t('com_email')}:{' '}
                      <strong className="text-primary">{email}</strong>
                    </h6>
                    <h6 className="mb-0 mt-3 d-flex flex-wrap justify-content-between">
                      {t('com_website')}:{' '}
                      <strong className="text-primary">{website}</strong>
                    </h6>
                  </div>
                  {isOwner && (
                    <div className="card-footer">
                      <Link
                        to={`/company/${companyId}/edit`}
                        className="btn btn-sm btn-secondary w-100"
                      >
                        {t('com_edit_button')}
                      </Link>
                    </div>
                  )}
                </div>
                {isOwner && (
                  <div className="card mb-3 shadow d-none">
                    <div className="card-header">
                      <h6 className="m-0">{t('com_management')}</h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <Link
                            to={`/company/${companyId}/products`}
                            className="btn btn-primary w-100 lh-sm"
                          >
                            <span className="fa fa-wrench me-2"></span>
                            {t('com_manage_products')}
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to={`/company/${companyId}/inventory`}
                            className="btn btn-primary w-100 lh-sm"
                          >
                            <span className="fa fa-briefcase me-2"></span>
                            {t('com_manage_inventory')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card mb-3 shadow">
                  <div className="card-header">
                    <h6 className="m-0">{t('com_list_of_sellers')}</h6>
                  </div>
                  <div className="card-body p-0">
                    <div style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                      {members.map((e, index) => {
                        const isOwner = e.level === 2;
                        return (
                          <div className={`p-2 border-top`} key={index}>
                            <WidgetSeller
                              data={e.user}
                              size="small"
                              owner={isOwner}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {isOwner ? (
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-6">
                          <WidgetInviteSeller companyId={companyId} />
                        </div>
                        <div className="col-6">
                          <WidgetDeleteSeller
                            companyId={companyId}
                            members={members}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {members.findIndex(m => m.user.id === user.id) !== -1 && (
                        <div className="card-footer">
                          <button
                            className="btn btn-outline-danger w-100 btn-sm"
                            onClick={() => setState({ isOpen: true })}
                          >
                            {t('com_leave_button')}
                          </button>
                          <Modal
                            isOpen={isOpen}
                            onRequestClose={() => setState({ isOpen: false })}
                            contentLabel="Modal"
                            className="reveal p-3"
                            ariaHideApp={false}
                          >
                            <div className="modal-container">
                              <h6 className="text-primary fw-bold mb-3">
                                {t('com_leave_company_heading')}
                              </h6>
                              <p>{t('com_leave_company_description')}</p>
                              <div className="btn-container d-flex justify-content-between align-items-center">
                                <button
                                  onClick={() => setState({ isOpen: false })}
                                  className="btn btn-sm btn-secondary w-50"
                                >
                                  {t('cancel_button')}
                                </button>
                                <button
                                  onClick={leaveCompany}
                                  className="btn btn-sm btn-danger w-50 ms-2"
                                >
                                  {t('com_leave_button')}
                                </button>
                              </div>
                            </div>
                            <button
                              className="btn btn-close close-reveal"
                              onClick={() => setState({ isOpen: false })}
                            >
                              &times;
                            </button>
                          </Modal>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* <div className="card border-danger mb-3 shadow">
                  <div className="card-header">
                    <h6 className="m-0">{t('com_danger_zone')}</h6>
                  </div>
                  <div className="card-body">
                    <button className="btn btn-sm btn-outline-danger w-100">
                      {t('com_close_company')}
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyIndex;
