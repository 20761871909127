import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/global/Loader';
import WidgetCompany from '../../components/global/WidgetCompany';
import { useSelector } from 'react-redux';

import {
  getCategories,
  getProductDetail,
  updateProduct,
  uploadImage,
} from '../../libs/apis';

function SellerIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const { productId } = useParams();
  const { user } = useSelector(state => state.auth);
  const initialState = {
    data: null,
    isLoading: true,
    isError: false,
    id: productId,
    name: '',
    priceMin: '',
    priceMax: '',
    description: '',
    photo: '',
    type: 0,
    active: false,
    uploading: false,
    errorUpload: false,
    loading: false,
    error: false,
    categories: [],
    selectedCategory: null,
    errorMessage: '',
    gallery: [],
    company: null,
    is_default: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    id,
    name,
    priceMin,
    priceMax,
    active,
    description,
    loading,
    photo,
    type,
    uploading,
    errorUpload,
    error,
    categories,
    selectedCategory,
    errorMessage,
    gallery,
    company,
    is_default,
  } = state;

  useEffect(() => {
    const getCats = async () => {
      await getCategories().then(resp => {
        if (resp.status === 200) {
          if (resp.data.response.length > 0) {
            setState({
              categories: resp.data.response,
              selectedCategory: resp.data.response[0].id,
            });
          }
        }
      });
    };
    getCats();
    const getProductData = async () => {
      const payload = {
        id: productId,
      };
      await getProductDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            const p = resp.data.response;
            setState({ data: p });
            setState({
              active: p.active,
              name: p.name,
              description: p.description ? p.description : '',
              type: p.type || 0,
              priceMin: p.price_min,
              priceMax: p.price_max,
              photo: p.image,
              selectedCategory: p.category.id,
              gallery: p.gallery,
              company: p.company || null,
              is_default: p.is_default || false,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProductData();
  }, [productId]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ loading: true, error: false, errorMessage: '' });
    const payload = {
      id,
      name,
      description,
      type,
      price_min: priceMin,
      price_max: priceMax,
      category_id: selectedCategory,
      image: photo,
      active,
      gallery,
      is_default,
    };
    await updateProduct(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          history.push('/seller/product');
        } else {
          setState({ error: true, errorMessage: resp.message });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ error: true, errorMessage: err.message });
      })
      .finally(() => {
        setState({ loading: false });
      });
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ photo: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };
  const onDropGallery = async e => {
    const file = e.target.files;
    setState({ uploadGallery: true, errorGallery: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ gallery: [...gallery, res.data.secure_url] });
        }
      })
      .catch(err => {
        setState({ errorGallery: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadGallery: false });
      });
  };

  const deleteGallery = item => {
    const newGallery = gallery.filter(e => e !== item);
    setState({ gallery: newGallery });
  };

  const isCompany = !!company;
  const isOwner = isCompany && company.user_id === user.id;
  const canEdit = !isCompany || isOwner;

  return (
    <div className="admin-container">
      <div className="admin-header bg-primary bg-gradient py-3 sticky-top shadow-sm">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <Link
                  to="/seller/product"
                  className="text-light text-decoration-none"
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
                <h6 className="text-light m-0">{t('seller_admin')}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="small text-primary text-uppercase fw-bold mb-0">
                    {t('edit_product_heading')}
                  </h4>
                  <div className="text-end d-flex align-items-center">
                    <h6 className="mb-0 me-2">{t('on_sale')}</h6>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input me-2"
                        checked={active}
                        id="customSwitch1"
                        onChange={e => setState({ active: e.target.checked })}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      >
                        {/* {active ? (
                          <span className="text">Yes</span>
                        ) : (
                            <span className="text">No</span>
                          )} */}
                      </label>
                    </div>
                  </div>
                </div>
                {isCompany && (
                  <div className="alert alert-primary p-2 mb-3 mt-3">
                    <h6 className="small mb-2 text-primary">
                      <span className="fa fa-building me-2"></span>
                      {t('com_inventory_owner')}
                    </h6>
                    <WidgetCompany data={company} />
                  </div>
                )}
                <hr />

                <div className="form-container">
                  {error && (
                    <div className="alert alert-danger fade show" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mb-0 fw-bold me-3">
                          <span className="fa fa-star text-warning me-2"></span>
                          {t('set_as_default_heading')}
                        </h6>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input me-2"
                            checked={is_default}
                            id="customSwitch2"
                            onChange={e =>
                              setState({ is_default: e.target.checked })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <select
                        className="form-select "
                        name="type"
                        defaultValue={type}
                        onChange={e => setState({ type: e.target.value })}
                        disabled={loading || !canEdit}
                      >
                        <option value="0">{t('service_type')}</option>
                        <option value="1">{t('item_type')}</option>
                      </select>
                      <label htmlFor="type">
                        {t('select_type')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="form-floating mb-3">
                      <select
                        className="form-select "
                        name="category"
                        value={selectedCategory}
                        onChange={e =>
                          setState({ selectedCategory: e.target.value })
                        }
                        disabled={loading || !canEdit}
                      >
                        <option value="0" disabled>
                          {t('category_select')}
                        </option>
                        {categories.length > 0 &&
                          categories.map((cat, index) => {
                            return (
                              <option key={cat.id + index} value={cat.id}>
                                {t(cat.name.toLowerCase().replace(' ', '_'))}
                              </option>
                            );
                          })}
                      </select>
                      <label htmlFor="category">{t('select_category')}</label>
                    </div>

                    <div className="form-group">
                      {/* <ImageUploader
                        singleImage={true}
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                      />
                      <input type="hidden" name="photo" value={photo} /> */}
                      {errorUpload && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {uploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        {photo && (
                          <div className="mb-3 d-flex justify-content-center">
                            <img
                              src={photo}
                              alt="upload"
                              className="img-thumbnail"
                            />
                          </div>
                        )}

                        <label
                          htmlFor="img"
                          className={`position-relative w-100 d-flex align-items-center mb-3 btn ${
                            !canEdit
                              ? 'btn-secondary opacity-50'
                              : 'btn-primary'
                          }`}
                        >
                          <span className="fa fa-image me-3"></span>
                          {photo ? (
                            <small>{t('change_product_image')}</small>
                          ) : (
                            <small>{t('upload_product_image')}</small>
                          )}
                          <input
                            type="file"
                            id="img"
                            accept="image/*"
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                            disabled={loading || !canEdit}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={loading || !canEdit}
                        required
                        placeholder={t('product_name_label')}
                      />
                      <label htmlFor="name">{t('product_name_label')}</label>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="number"
                            id="priceMin"
                            value={priceMin}
                            required
                            onChange={e =>
                              setState({ priceMin: e.target.value })
                            }
                            disabled={loading}
                            placeholder={t('price_min_label')}
                          />
                          <label htmlFor="priceMin">
                            {t('price_min_label')}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="number"
                            id="priceMax"
                            required
                            value={priceMax}
                            onChange={e =>
                              setState({ priceMax: e.target.value })
                            }
                            disabled={loading}
                            placeholder={t('price_max_label')}
                          />
                          <label htmlFor="priceMax">
                            {t('price_max_label')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        rows="3"
                        placeholder={t('product_description_label')}
                        value={description}
                        onChange={e =>
                          setState({ description: e.target.value })
                        }
                        style={{ height: '120px' }}
                      ></textarea>
                      <label htmlFor="description">
                        {t('product_description_label')}
                      </label>
                    </div>

                    <div className="gallery mb-3 p-3 bg-white bg-gradient border rounded">
                      <h6 className="text-uppercase text-success small">
                        {t('gallery_heading')}
                      </h6>
                      <div className="gallery-uploader mb-3">
                        <div className="add-gallery">
                          <label
                            htmlFor="add-gallery"
                            className="btn w-100 bg-light border rounded-3 shadow-sm bg-gradient position-relative"
                          >
                            <span className="icon me-2">
                              <span className="fa fa-plus"></span>
                            </span>
                            <span className="text">{t('add_gallery')}</span>
                            <input
                              type="file"
                              id="add-gallery"
                              accept="image/*"
                              name="add-gallery"
                              onChange={onDropGallery}
                            />
                          </label>
                        </div>
                      </div>
                      {gallery.length > 0 && (
                        <div className="gallery-container mb-3 row g-2">
                          {gallery.map((g, i) => (
                            <div className="col-6" key={g + i}>
                              <div className="gallery-item border rounded shadow-sm">
                                <img
                                  src={g}
                                  alt={g + i}
                                  className="rounded-top"
                                />
                                <div className="p-2 bg-light">
                                  <button
                                    className="btn btn-danger btn-sm w-100 m-0"
                                    onClick={() => deleteGallery(g)}
                                  >
                                    <span className="fa fa-trash me-1"></span>
                                    <small>{t('delete_label')}</small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-success w-100 mb-3"
                    >
                      {t('save_product_button')}{' '}
                      <span className="ms-2 fa fa-chevron-right"></span>
                    </button>
                  </form>

                  <div className="product-stats card shadow mb-3 d-none">
                    <div className="stats card-body">
                      <h5 className="mb-3 text-success opacity-75 size-14 text-uppercase">
                        <span className="fa fa-line-chart me-2"></span>
                        {t('statistic_heading')}
                      </h5>
                      <div className="cards">
                        <div className="card-bodys">
                          <table className="table bg-white mb-0 table-bordered table-md">
                            <tbody>
                              <tr>
                                <td className="text-nowrap w-25">
                                  <small>{t('stat_completed')}</small>
                                </td>
                                <td className="text-center">:</td>
                                <td className="w-75 font-cabin">
                                  300 {t('order_text')}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-nowrap w-25">
                                  <small>{t('stat_total_order')}</small>
                                </td>
                                <td className="text-center">:</td>
                                <td className="w-75 font-cabin">
                                  350 {t('order_text')}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-nowrap w-25">
                                  <small>{t('stat_avg_price')}</small>
                                </td>
                                <td className="text-center">:</td>
                                <td className="w-75 font-cabin">
                                  <span className="text-success">{t('$')}</span>{' '}
                                  175
                                </td>
                              </tr>
                              <tr>
                                <td className="text-nowrap w-25">
                                  <small>{t('stat_visited')}</small>
                                </td>
                                <td className="text-center">:</td>
                                <td className="w-75 font-cabin">
                                  1.985.123 {t('stat_times')}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-nowrap w-25">
                                  <small>{t('stat_last_ordered')}</small>
                                </td>
                                <td className="text-center">:</td>
                                <td className="w-75 font-cabin">
                                  February 12, 2021
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerIndex;
