import React, { useState, forwardRef } from 'react';
import Modal from 'react-modal';
import Slot from './Slot';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addTransactionBid } from '../../libs/apis';
import { addNotif } from '../../redux/slices/appSlice';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';

registerLocale('fi', fi);
registerLocale('id', id);

export default function NewItem({
  data = null,
  timeslot = null,
  callback = () => {},
  mode = 'slot',
  newData = null,
  selectedCompany = null,
  orderIndex = 0,
}) {
  const dispatch = useDispatch();
  const { role, user } = useSelector(state => state.auth);
  const isSeller = role === 'seller';
  const link = isSeller ? 'seller/order' : 'order';
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));
  const [open, setOpen] = useState(false);
  const [selectedHorseId, setSelectedHorseId] = useState(null);

  const initialState = {
    dataState: newData.transaction,
    isOpen: false,
    isBidding: false,
    isError: false,
    isLoading: false,
    note: '',
    date: data.transaction_request[0]
      ? new Date(data.transaction_request[0].datetime)
      : new Date(),
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen, isBidding, date, notes, dataState } = state;

  if (!dataState) return null;
  const {
    id,
    transaction_request,
    transaction_items,
    status,
    product,
    prev_order,
    next_order,
  } = dataState;

  const lastBid = transaction_request[0];
  // const color = status >= 4 ? 'success' : 'warning';
  // let first = parseInt(moment(data.transaction_request.datetime).format('H'))
  // let hour = first;
  // transaction_items.forEach(e => {
  //   let h = parseInt(e.time.split(':')[0])
  //   if (h > hour) {
  //     hour = h;
  //   }
  // });
  // const height = ((hour - first) + 2) * 64;

  const processOrder = async stat => {
    setState({ isBidding: true });
    const payload = {
      id: id,
      price: lastBid.price,
      datetime: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      note: notes,
      status: stat,
    };
    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({
            dataState: resp.data.response,
            isOpen: false,
            notes: '',
            date: new Date(
              moment(
                resp.data.response.transaction_request[0].datetime,
              ).format(),
            ),
          });
          callback(resp.data.response);
          dispatch(addNotif({ hasNotification: true }));
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setState({ isBidding: false }));
  };

  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-light',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-light',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  const stat = status - 1;
  const classNames = `${stats[stat].color} ${stats[stat].background} ${stats[stat].border}`;

  // let minTime = 0;
  // let maxTime = 0;
  // transaction_items.forEach(e => {
  //   const theTime = e.time ? e.time : moment(timeslot).format('HH:mm');
  //   // console.log('thetime', moment(newData.start_datetime).set({hour: theTime.split(':')[0], minute: theTime.split(':')[1]}));
  //   if (theTime <= minTime) {
  //     minTime = theTime;
  //   }
  //   if (theTime >= maxTime) {
  //     maxTime = theTime;
  //   }
  // });
  // let hourSlots =
  //   maxTime - minTime === 0 ? transaction_items.length : maxTime - minTime;
  const isNew = mode === 'slot';

  const isMine = newData.user.username === user.username;
  // const lowTime = moment(newData.start_datetime);
  // const highTime = moment(newData.end_datetime);
  // console.log('dif', moment.duration(highTime.diff(lowTime)).asHours())
  // const hourSlots = moment.duration(highTime.diff(lowTime)).asMinutes() / 30;

  // let completeHorseTime = true;
  // transaction_items.forEach(e => {
  //   if(!e.time) completeHorseTime = false;
  // });

  if (transaction_items.length === 0) return null;
  const companyOrder = product.company;
  // const isOtherCompany = selectedCompany && companyOrder ? selectedCompany !== product.company.id : false;
  const callbackSlot = slotData => {
    setSelectedHorseId(null);
    setOpen(false);
    callback(slotData);
  };

  if (!isMine && !companyOrder) return null;
  if (
    companyOrder &&
    selectedCompany &&
    selectedCompany !== '0' &&
    parseInt(selectedCompany) !== companyOrder.id
  )
    return null;

  return (
    <>
      {newData.transaction.transaction_items.map((item, index) => {
        const hasTime = item.time;
        const sHour = hasTime
          ? moment(newData.start_datetime).set({
              hour: item.time.split(':')[0],
              minute: item.time.split(':')[1],
            })
          : moment(newData.start_datetime).set({ hour: 23, minute: 59 });
        const eHour = hasTime
          ? moment(newData.start_datetime).set({
              hour: parseInt(item.time.split(':')[0]) + 1,
              minute: item.time.split(':')[1],
            })
          : moment(newData.start_datetime).set({ hour: 23, minute: 59 });

        const top = hasTime ? sHour.hour() * 2 + sHour.minute() / 30 : -1;
        const bottom = hasTime ? eHour.hour() * 2 + eHour.minute() / 30 : 0;

        return (
          <div
            className={`${
              isNew ? 'horse-order-item' : 'order-block'
            } order-item`}
            style={{
              height: hasTime ? `${65}px` : '66px',
              gridRow: hasTime ? `${top + 1} / ${bottom + 1}` : `1`,
              // gridColumn: `${orderIndex} / ${orderIndex + 1}`,
            }}
          >
            <div
              className={`item-summary rounded-1 border ${
                hasTime ? classNames : `bg-secondary opacity-75`
              } bg-gradient`}
              onClick={() => {
                setOpen(!open);
                setSelectedHorseId(item.item.id);
              }}
              style={{ padding: '4px', height: '100%' }}
            >
              {!isMine && (
                <h6
                  className="mb-1 fs-12 d-flex align-items-center"
                  style={{ marginLeft: '-4px', marginTop: '-4px' }}
                >
                  {product.company?.logo && (
                    <img
                      src={product.company?.logo}
                      alt={product.company?.name}
                      style={{ width: '16px', marginRight: '2px' }}
                    />
                  )}
                  {mode === 'slot' && (
                    <span
                      className="fs-10 mb-0 opacity-75"
                      style={{ fontSize: '8px' }}
                    >
                      {product.company?.name}
                    </span>
                  )}
                </h6>
              )}
              <div
                className="text-end small fs-12 lh-1 ms-1 float-end"
                style={{ marginTop: '-.125rem', marginRight: '-.125rem' }}
              >
                {/* {open ? (
            <span className="fa fa-minus"></span>
          ) : (
            <span className="fa fa-plus"></span>
          )} */}
              </div>

              <h6
                className={`mb-0 lh-0 fs-10 small ${stats[stat].color} font-ssp`}
              >
                {(prev_order || next_order) && (
                  <span className="me-1 fa fa-refresh"></span>
                )}
                <span className="horse-name" style={{ fontSize: '8px' }}>
                  {item.item.name}
                </span>{' '}
                <br />
                <span className="horse-name" style={{ fontSize: '8px' }}>
                  #{newData.transaction.id}
                </span>
              </h6>
            </div>
          </div>
        );
      })}
      <Modal
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
          setSelectedHorseId(null);
        }}
        contentLabel="Modal"
        className="reveal p-3 small"
        ariaHideApp={false}
      >
        <div className="horse-list-container">
          <h6 className="mb-3 text-primary fw-bold">
            {t('order_detail_heading')}
          </h6>
          {(prev_order || next_order) && (
            <div className="alert alert-warning mb-3 p-2">
              <h6 className="mb-0 mt-1 fs-12">
                <span className="fa fa-refresh me-1"></span>
                {t('order_continuous')}
              </h6>
            </div>
          )}
          {!isMine && (
            <div className="card shadow-sm mb-3">
              <div className="card-header alert-success p-2">
                <h6 className="mb-0 fs-12">
                  <span className="fa fa-building me-1"></span>
                  {t('seller')} & {t('company')} {id}
                </h6>
              </div>
              <div className="card-body p-2">
                <div className="d-flex align-items-center">
                  {product.seller.photo && (
                    <img
                      src={product.seller.photo}
                      alt={product.seller.name}
                      className="me-2 img-thumbnail rounded"
                      style={{ width: '32px' }}
                    />
                  )}
                  <div>
                    <h6 className="fs-12 mb-1">{product.seller.name}</h6>
                    <h6 className="mb-0 d-flex align-items-center">
                      {product.company?.logo && (
                        <img
                          src={product.company?.logo}
                          alt={product.company?.name}
                          className="me-2"
                          style={{ width: '24px' }}
                        />
                      )}
                      <span className="fs-12 mb-0 text-primary">
                        {product.company?.name}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="border shadow-sm p-2 mb-3">
            <div className="d-flex align-items-center">
              {product.image && (
                <img
                  src={product.image}
                  alt={product.name}
                  className="me-2 img-thumbnail rounded"
                  style={{ width: '48px' }}
                />
              )}
              <div style={{ flex: 1 }}>
                <h6 className="small mb-1">{product.name}</h6>
                <h6 className="fs-12 mb-0">
                  <span className="fa fa-clock-o me-2"></span>
                  {moment(transaction_request[0].datetime).format(
                    'MMMM DD, HH:mm',
                  )}
                </h6>
                {prev_order && next_order === null && (
                  <>
                    <div className="mt-1">
                      <button
                        className="btn p-0 px-1 fs-12 btn-sm btn-outline-info"
                        onClick={() => setState({ isOpen: !isOpen })}
                      >
                        {t('change_order_heading')}
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`mb-1 fs-10 small border align-self-start p-1 lh-1 rounded-3 fw-normal bg-gradient ${stats[stat].background} ${stats[stat].color} ${stats[stat].border}`}
              >
                {t(stats[stat].status)}
              </div>
            </div>
            {isOpen && (
              <div className="my-2 p-2 border rounded">
                <div className="mb-0">
                  {/* <label
                    className="small text-success font-cabin"
                    htmlFor="requestedPrice"
                  >
                    {t('requested_price_label')}
                    <span className="text-danger">*</span>{' '}
                    <small className="text-body text-capitalize">
                      ( {numberFormat(product.price_min * horseCount)} -{' '}
                      {numberFormat(product.price_max * horseCount)} )
                    </small>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">{t('$')}</span>
                    <input
                      type="number"
                      className="form-control text-end"
                      id="requestedPrice"
                      name="requestedPrice"
                      pattern="\d*"
                      min="0"
                      placeholder={lastBid.price}
                      value={price}
                      onChange={e => setState({ price: e.target.value })}
                      required
                    />
                    <span className="input-group-text">.00</span>
                  </div> */}
                  <label
                    className="small text-success font-cabin"
                    htmlFor="datetime"
                  >
                    {t('preferred_date_time_label')}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mb-3">
                    <DatePicker
                      showTimeSelect
                      selected={date}
                      locale={lang}
                      onChange={date => setState({ date: date })}
                      customInput={<CustomInputCalendar />}
                      dateFormat="MMM dd, yyyy - HH:mm"
                      className="w-100"
                      filterDate={date => {
                        let valid = isSeller
                          ? moment(date).isSameOrAfter(
                              moment().subtract(1, 'days'),
                            )
                          : moment(date).isAfter(moment());
                        // if (moment(date).isoWeekday() >= 6) { valid = false }
                        return valid;
                      }}
                      excludeTimes={[
                        new Date().setHours(1, 0),
                        new Date().setHours(1, 30),
                        new Date().setHours(2, 0),
                        new Date().setHours(2, 30),
                        new Date().setHours(3, 0),
                        new Date().setHours(3, 30),
                        new Date().setHours(4, 0),
                        new Date().setHours(4, 30),
                        new Date().setHours(5, 0),
                        new Date().setHours(5, 30),
                        new Date().setHours(6, 0),
                        new Date().setHours(6, 30),
                        new Date().setHours(21, 30),
                        new Date().setHours(22, 0),
                        new Date().setHours(22, 30),
                        new Date().setHours(23, 0),
                        new Date().setHours(23, 30),
                        new Date().setHours(24, 0),
                        new Date().setHours(24, 30),
                      ]}
                    />
                  </div>
                  {/* <Datetime id="datepicker" isValidDate={valid} locale={lang} className="rdt-input-group" inputProps={{ readOnly: true, className: 'form-control rounded-end datetime-date' }} initialValue={date} dateFormat="YYYY-MM-DD" closeOnSelect={true} timeFormat={false} onChange={(e) => {
														const d = e.format('YYYY-MM-DD');
														const t = time;
														const dt = `${d} ${t}`;
														setState({ date: d, newDatetime: dt })
													}} /> */}

                  {/* <DateTimePicker name="datetime" onChange={e => setState({ date: e })} value={date} format="y-MM-dd HH:mm" calendarIcon={<span className="fa fa-calendar" />} clearIcon={null} className="d-flex w-100 mb-3 bg-white rounded" showLeadingZeros={true} required /> */}
                  <div className="my-notes mb-0">
                    <div className="form-floating">
                      <textarea
                        className="form-control "
                        name="notes"
                        id="additionalInfo"
                        rows="3"
                        placeholder={t('write_a_note_label')}
                        value={notes}
                        onChange={e => setState({ notes: e.target.value })}
                        style={{ height: '90px' }}
                      ></textarea>
                      <label
                        className="text-success font-cabin small"
                        htmlFor="additionalInfo"
                      >
                        {t('write_a_note_label')}
                      </label>
                    </div>
                  </div>
                  <div className="btn-container mt-3">
                    <div className="d-flex">
                      {isBidding ? (
                        <button
                          className="btn fs-12 btn-secondary btn-sm w-100 btn-disabled"
                          disabled
                        >
                          {t('change_and_confirm')}
                        </button>
                      ) : (
                        <button
                          className="btn fs-12 btn-outline-primary btn-sm w-100"
                          onClick={() => processOrder(3)}
                        >
                          {t('change_and_confirm')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <h6 className="mb-3 text-primary fw-bold">{t('horse_list')}</h6>
          <div className="horse-items mb-3">
            {newData.transaction.transaction_items.map((h, i) => {
              if (h.item.id !== selectedHorseId) return null;
              return (
                <Slot
                  callbackSlot={callbackSlot}
                  horse={h}
                  status={status}
                  orderId={id}
                  key={i}
                  timeslot={timeslot}
                  single={transaction_items.length === 1}
                  isMine={isMine}
                  calData={newData}
                  isHorse={true}
                />
              );
            })}
          </div>
          {isMine ? (
            <div className="btn-container d-flex ">
              <Link
                className="btn btn-sm me-2 w-50 btn-secondary"
                to={`/${link}/${id}`}
              >
                {t('view_order_button')} #{id}
              </Link>
              <button
                className="btn btn-sm w-50 btn-outline-secondary"
                onClick={() => setOpen(false)}
              >
                {t('close_button')}
              </button>
            </div>
          ) : (
            <div className="btn-container d-flex ">
              <button
                className="btn btn-sm w-100 btn-outline-secondary"
                onClick={() => {
                  setOpen(false);
                  setSelectedHorseId(null);
                }}
              >
                {t('close_button')}
              </button>
            </div>
          )}
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => {
            setOpen(false);
            setSelectedHorseId(null);
          }}
        >
          &times;
        </button>
      </Modal>
    </>
  );
}
