import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/global/Loader';

import { getItems } from '../../libs/apis';

function ItemList() {
  const { t } = useTranslation();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError } = state;

  useEffect(() => {
    const getItemData = async () => {
      await getItems()
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getItemData();
  }, []);

  if (data.length === 0 && isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  // const {
  //   name,
  //   image,
  //   description,
  //   birthdate,
  //   withers,
  //   job,
  //   breed,
  //   gallery,
  //   billing_active = false,
  //   billing_email,
  //   billing_data,
  // } = data;

  return (
    <div className="app-container">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="my-3">
              <div className="section-header d-flex justify-content-between align-items-center mb-3">
                <h1 className="fs-6 mb-0 fw-bold d-flex align-items-center">
                  {t('my_horse_label')}
                </h1>
                <Link to="/horse/new" className="btn btn-primary btn-sm">
                  <span className="fa fa-plus me-2"></span>
                  {t('add_horse_button')}
                </Link>
              </div>

              {data.length > 0 ? (
                <div className="section-content">
                  {data.map((horse, index) => {
                    const {
                      id,
                      name,
                      image,
                      billing_active,
                      stable = null,
                    } = horse;
                    return (
                      <div className="card shadow mb-2" key={index}>
                        <div className="card-body p-2">
                          <div className="row g-2 align-items-center">
                            <div className="col-3">
                              <img src={image} alt={name} className="rounded" />
                            </div>
                            <div className="col-9">
                              <h3 className="fs-6 mb-0">
                                {name}
                                {billing_active ? (
                                  <span className="fa fa-check-circle text-primary ms-1"></span>
                                ) : (
                                  <span className="text-muted fst-italic fs-10 ms-1">
                                    ({t('billing_info_inactive')})
                                  </span>
                                )}
                              </h3>
                              {stable && (
                                <div className="alert alert-info p-1 shadow-sm mb-3 d-inline-block px-2 rounded-pill">
                                  <h6 className="fs-10 mb-0 text-reset">
                                    <span className="fa fa-map-marker me-2"></span>
                                    {stable.name}
                                  </h6>
                                </div>
                              )}
                              <div className="d-flex align-items-center">
                                <Link
                                  to={`/horse/${id}`}
                                  className="btn btn-sm btn-outline-secondary me-2 py-0 fs-12"
                                >
                                  <span className="fa fa-eye me-1"></span>
                                  {t('view_button')}
                                </Link>
                                <Link
                                  to={`/horse/${id}/edit`}
                                  className="btn btn-sm py-0 fs-12 btn-outline-warning"
                                >
                                  <span className="fa fa-pencil me-1"></span>
                                  {t('edit_horse_button')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="px-3 mt-5">
                  <h6 className="small mb-3 text-center">
                    {t('billing_no_horse_info')}
                  </h6>
                  <Link to="/horse/new" className="btn btn-warning w-100">
                    <span className="fa fa-plus me-2"></span>{' '}
                    {t('add_horse_button')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemList;
