import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
// import defaultProduct from '../../assets/images/default-product.jpg';
import '../../assets/css/app.scss';
import ProBadge from './ProBadge';
import HorseIcon from './HorseIcon';

export default function BoxOrder({ data }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const { role } = useSelector(state => state.auth);
  const { id, product, transaction_request, transaction_items, type } = data;
  const { name, company = null } = product;
  const lastTx = transaction_request[0];
  const { status, datetime } = lastTx;
  // const txCount = transaction_request.length;
  // const firstTx = txCount > 1 ? transaction_request[txCount - 1] : lastTx;
  // const { username } = user;
  //  const { status, note, datetime, price } = lastTx;
  // const statuses = ['new_order_status', 'change_from_seller_status', 'change_from_buyer_status', 'confirm_from_seller_status', 'confirm_from_buyer_status', 'complete_from_seller_status', 'complete_from_buyer_status', 'cancel_from_seller_status', 'cancel_from_buyer_status'];
  // const today = new Date().getTime();
  // const deliver = new Date(datetime).getTime();
  // const due = today >= deliver;
  // const statusText = status === 5 && !due ? 'waiting_for_delivery' : statuses[status - 1];
  // const cancelled = status > 7;

  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  //   setIsReadMore(!isReadMore);
  // };
  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-light',
      background: 'bg-primary',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-primary',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-primary',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
      opacity: 'opacity-50',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  // const showComment = lastTx.user.username !== username;
  // let firstPrice = firstTx.price;
  // let firstDate = firstTx.datetime;

  const stat = status - 1;

  const types = [
    {
      typeName: 'type_normal',
      typeColor: 'secondary',
    },
    {
      typeName: 'type_opendate',
      typeColor: 'secondary',
    },
    {
      typeName: 'type_continuous',
      typeColor: 'warning',
    },
    {
      typeName: 'type_emergency',
      typeColor: 'danger',
    },
  ];

  const isCompany = !!company;
  let isEmptyStable = true;
  transaction_items.forEach(horse => {
    if (horse.item.stable && horse.item.stable.name !== '')
      isEmptyStable = false;
  });

  const isPro = data.product.seller.id === data.user.id;

  return (
    <div
      className={`border border-2 overflow-hidden rounded-3 position-relative mb-3 ${stats[stat].opacity}`}
    >
      <div className="py-2 px-3 border-bottom position-relative">
        <div
          className={`position-absolute start-0 top-0 bottom-0 ${stats[stat].background}`}
          style={{ width: '8px' }}
        >
          &nbsp;
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="header-text">
            <h5 className="fs-14 mb-1 fw-bold">{t(stats[stat].status)}</h5>
            <h6 className="fs-12 mb-0 text-muted">
              <Link
                to={`/${role === 'seller' ? 'seller/' : ''}order/${id}`}
                className="text-decoration-none text-dark mb-0"
              >
                {t('order_text')} #{id}{' '}
              </Link>
            </h6>
          </div>
          <div className="ms-2 d-flex align-self-start flex-1">
            <h6 className="fs-10 p-1 lh-1 border border-2 mb-0 rounded-pill fw-bold text-muted">
              {t(types[type - 1].typeName)}
            </h6>
            {isPro && (
              <h6 className="fs-10 p-1 lh-1 border border-2 mb-0 ms-1 px-2 rounded-pill fw-bold">
                <ProBadge size="10" classes="pro-badge--invert" />
              </h6>
            )}
          </div>
          <h6 className="date-order mb-0 d-flex ms-2 align-items-center text-muted fs-10 fw-bold text-muted">
            <span className="fa fa-calendar me-2"></span>
            <Moment locale={lang} format="MMM DD - HH:mm">
              {datetime}
            </Moment>
          </h6>
        </div>
      </div>

      <div className="p-3">
        <div className="product">
          <div className="product-content d-flex justify-content-between">
            {/* <div className="product-image me-2">
              {image ? (
                <img
                  className="h-40 w-40 rounded"
                  src={image}
                  alt={name}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <img
                  className="h-40 w-40 rounded"
                  src={defaultProduct}
                  alt={name}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              )}
            </div> */}
            <div className="product-explain d-flex justify-content-around ">
              <h5 className="fs-6 fw-bold mb-1 me-3">
                <Link
                  to={`/${role === 'seller' ? 'seller/' : ''}order/${id}`}
                  className="text-decoration-none text-dark stretched-link"
                >
                  {name}
                </Link>
              </h5>
              <div className="explain-bottom d-flex align-items-center justify-content-between">
                {isCompany && (
                  <h6 className="fs-12 fw-bold mb-0 me-3 text-muted">
                    <span className="fa fa-building me-2"></span>
                    {company.name}
                  </h6>
                )}

                <h6 className="fs-12 mb-0 text-muted fw-bold">
                  <span className="fa fa-user me-2 "></span>
                  {data.user.name}
                </h6>
              </div>
              {/* horse */}
              {transaction_items.length > 0 && (
                <div className="ms-3 d-flex align-items-center justify-content-start d-none d-sm-flex ">
                  <h6 className="fs-12 fw-bold d-flex align-items-center mb-0 text-muted">
                    {/* <span className="fa fa-paw me-2"></span> */}

                    {transaction_items.map((h, index) => {
                      if (index > 0) return null;
                      return (
                        <>
                          <span className="me-2 text-muted">
                            <HorseIcon size={12} />
                          </span>
                          <span key={h.id}>
                            {h.item.name}
                            {` `}
                            {transaction_items.length > 1 && (
                              <span>
                                {' '}
                                + {transaction_items.length - 1}{' '}
                                {t('more_text')}
                              </span>
                            )}
                            {/* <span className="text-light ms-1 fa fa-check-circle"></span> */}
                          </span>
                        </>
                      );
                    })}
                  </h6>

                  {!isEmptyStable && (
                    <h6 className="ms-3 mb-0 fs-12 fw-bold text-muted ">
                      <span className="fa fa-home me-2"></span>
                      {transaction_items.map((h, index) => {
                        if (index > 0 || !h.item.stable) return null;
                        return (
                          <span key={h.id}>
                            {h.item.stable.name}
                            {transaction_items.length > 1 && (
                              <span>
                                {' '}
                                + {transaction_items.length - 1} more
                              </span>
                            )}
                          </span>
                        );
                      })}
                    </h6>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {transaction_items.length > 0 && (
        <div className="border-top p-2 px-3 d-sm-none">
          <div className="d-flex align-items-center justify-content-start  ">
            <h6 className="fs-12 fw-bold d-flex align-items-center mb-0 text-muted">
              {/* <span className="fa fa-paw me-2"></span> */}
              {transaction_items.map((h, index) => {
                if (index > 0) return null;
                return (
                  <span key={h.id}>
                    <span className="me-2 text-muted">
                      <HorseIcon size={12} />
                    </span>
                    {h.item.name}
                    {` `}
                    {transaction_items.length > 1 && (
                      <span>
                        {' '}
                        + {transaction_items.length - 1} {t('more_text')}
                      </span>
                    )}
                    {/* <span className="text-light ms-1 fa fa-check-circle"></span> */}
                  </span>
                );
              })}
            </h6>

            {!isEmptyStable && (
              <h6 className="ms-3 mb-0 fs-12 fw-bold text-muted">
                <span className="fa fa-home me-2"></span>
                {transaction_items.map((h, index) => {
                  if (index > 0 || !h.item.stable) return null;
                  return (
                    <span key={h.id}>
                      {h.item.stable.name}
                      {transaction_items.length > 1 && (
                        <span> + {transaction_items.length - 1} more</span>
                      )}
                    </span>
                  );
                })}
              </h6>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
