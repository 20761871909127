import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { readNotification } from '../../libs/apis';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/id';
import { useSelector } from 'react-redux';

export default function NotifItem({ notif, isSeller = false }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const { data, read_at, type, created_at } = notif;
  const link =
    type === 1 ? `${isSeller ? '/seller/' : '/'}order/${data.id}` : '';

  const handleNotif = e => {
    e.preventDefault();
    const payload = {
      id: notif.id,
    };
    readNotification(payload)
      .then(resp => {
        if (resp.status === 200) {
          setTimeout(() => {
            history.push(link);
          }, 500);
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const stat = data.status - 1;
  const id = data.id;

  const notifTitle = [
    'notif_title_new_order',
    'notif_title_order_changed',
    'notif_title_order_confirmed',
    'notif_title_invoice_sent',
    'notif_title_payment_confirmation',
    'notif_title_completed_order',
    'notif_title_cancelled_order',
  ];

  const fromMe = data.transaction_request[0].user.username === user.username;

  const notifMessageSeller = [
    'notif_message_new_order_seller',
    fromMe
      ? 'notif_message_changed_order_seller_me'
      : 'notif_message_changed_order_seller',
    fromMe
      ? 'notif_message_confirm_order_seller_me'
      : 'notif_message_confirm_order_seller',
    'notif_message_invoice_sent_seller',
    'notif_message_payment_confirmation_seller',
    'notif_message_completed_order_seller',
    fromMe
      ? 'notif_message_cancelled_order_seller_me'
      : 'notif_message_cancelled_order_seller',
  ];

  const notifMessageBuyer = [
    'notif_message_new_order_buyer',
    fromMe
      ? 'notif_message_changed_order_buyer_me'
      : 'notif_message_changed_order_buyer',
    fromMe
      ? 'notif_message_confirm_order_buyer_me'
      : 'notif_message_confirm_order_buyer',
    'notif_message_invoice_sent_buyer',
    'notif_message_payment_confirmation_buyer',
    'notif_message_completed_order_buyer',
    fromMe
      ? 'notif_message_cancelled_order_buyer_me'
      : 'notif_message_cancelled_order_buyer',
  ];

  const product = data.product.name;
  const name = isSeller ? data.user.name : data.product.seller.name;

  const diff = moment().add(new Date().getTimezoneOffset(), 'minutes').utc();
  const diffTime = moment(diff).diff(moment(created_at));
  const time = moment(moment() - diffTime).format();

  const stable = data.transaction_items[0].item.stable?.name || 'Stable';
  const isContinuous = data.type === 3;
  // console.log('t', stables);

  return (
    <div
      className={`notif-item p-2 px-1 mb-2 position-relative rounded-3 shadow-sm btn w-100 text-start ${
        isContinuous ? 'btn-outline-warning' : 'btn-outline-primary'
      } ${read_at ? 'opacity-75' : ''}`}
    >
      <div className="align-items-center d-flex">
        <div className="notif-icon me-1 px-2">
          <img
            src={data.product.image}
            alt=""
            className="rounded-circle shadow-sm border"
            width="40px"
            height="40px"
            style={
              read_at
                ? {
                    width: '40px',
                    height: '40px',
                    objectFit: 'cover',
                    filter: 'grayscale(100%)',
                  }
                : { width: '40px', height: '40px', objectFit: 'cover' }
            }
          />
        </div>
        <div className="notif-content">
          <h6 className=" mb-0 fs-12 text-reset fw-bold">
            <a
              href={link}
              className={`stretched-link text-decoration-none ${
                isContinuous ? 'text-reset' : 'text-reset'
              }`}
              onClick={handleNotif}
            >
              {isContinuous && <span className="fa fa-refresh me-1"></span>}
              <Trans i18nKey={notifTitle[stat]} id={id}>
                {{ id }}
              </Trans>
            </a>
          </h6>
          <p className="fs-10 mb-0 text-muted">
            <span className="fa fa-clock-o me-1"></span>
            <Moment fromNow={true}>{time}</Moment>
          </p>
          <p className="fs-12 mb-0 text-muted">
            {isSeller ? (
              <Trans
                i18nKey={notifMessageSeller[stat]}
                id={id}
                product={product}
                name={name}
                stable={stable}
              >
                <strong>{{ product }}</strong> {{ name }}{' '}
                <strong>{{ stable }}</strong> {{ id }}
              </Trans>
            ) : (
              <Trans
                i18nKey={notifMessageBuyer[stat]}
                id={id}
                product={product}
                name={name}
              >
                {{ product }} {{ name }} {{ id }}
              </Trans>
            )}
          </p>
        </div>
        {!read_at && (
          <span
            className="position-absolute px-1 bg-danger bg-gradient rounded opacity-75"
            style={{ top: '1px', right: '1px' }}
          >
            <span className="fs-10 text-light d-block">
              {t('notif_new_label')}
            </span>
          </span>
        )}
      </div>
    </div>
  );
}
