import React, { useState, useEffect } from 'react';
import { Redirect, useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import Modal from 'react-modal';

import { deleteCustomer, getCustomerDetail } from '../../libs/apis';

function StableDetail() {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const history = useHistory();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
    isOpen: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError, isOpen } = state;

  useEffect(() => {
    const getCustomerData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: customerId,
      };
      await getCustomerDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            const customerData = resp.data.response;
            setState({
              data: customerData,
              hasLocation: customerData.latitude && customerData.longitude,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, message: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getCustomerData();
  }, [customerId]);

  if (!customerId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const {
    name,
    email,
    address,
    company_name,
    business_id,
    phone,
    city,
    postal_code,
    notes,
    is_monthly_billing_active,
  } = data;

  const removeCustomer = async () => {
    setState({ isDeleting: true, isDeleteError: false, deleteMessage: '' });
    const payload = {
      id: customerId,
    };
    await deleteCustomer(payload)
      .then(resp => {
        if (resp.status === 200) {
          history.push('/manage?tab=customer');
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isDeleteError: true, deleteMessage: err.error.message });
      })
      .finally(() => setState({ isDeleting: false }));
  };

  return (
    <div className="app-container">
      <SubHeader title={t('customer_detail_title')} go="/manage">
        <Link to="/manage" className="btn text-primary fw-bold">
          {t('done_button')}
        </Link>
      </SubHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="fw-bold mb-0">{t('customer_detail_title')}</h6>
              <Link
                to={`/customer/${customerId}/edit`}
                className="text-decoration-none fs-14 fw-bold p-0"
              >
                {t('change_button')}
              </Link>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="name">{t('customer_name_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{name}</h6>
            </div>
            <hr />
            <div className="info-field mb-3">
              <label htmlFor="email">{t('customer_email_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{email ? email : '-'}</h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="business_id">
                {t('customer_business_id_label')}
              </label>
              <h6 className="fs-14 fw-bold mb-0">
                {business_id ? business_id : '-'}
              </h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="company_name">{t('company_name_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">
                {company_name ? company_name : '-'}
              </h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="phone">{t('customer_phone_label')}</label>
              <h6 className="fs-14 fw-bold mb-0">{phone ? phone : '-'}</h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="address">{t('customer_address')}</label>
              <h6 className="fs-14 fw-bold mb-0">{address ? address : '-'}</h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="city">{t('customer_city')}</label>
              <h6 className="fs-14 fw-bold mb-0">{city ? city : '-'}</h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="postal_code">{t('customer_zipcode')}</label>
              <h6 className="fs-14 fw-bold mb-0">
                {postal_code ? postal_code : '-'}
              </h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="notes">{t('customer_notes_label')}</label>
              <h6 className="fs-14 fw-bold mnotesb-0">{notes ? notes : '-'}</h6>
            </div>

            <div className="info-field mb-3">
              <label htmlFor="is_monthly_billing_active">
                {t('is_monthly_billing_active_label')}
              </label>
              <h6 className="fs-14 fw-bold mb-0">
                {is_monthly_billing_active ? t('active_label') : '-'}
              </h6>
            </div>
          </div>

          <div className="mt-4">
            <button
              className="btn btn-outline-danger w-100"
              onClick={() => setState({ isOpen: true })}
            >
              <span className="fa fa-trash me-2"></span>
              {t('delete_customer_label')}
            </button>
          </div>

          <Modal
            isOpen={isOpen}
            onRequestClose={() => {
              setState({ isOpen: false });
            }}
            contentLabel="Modal"
            className="reveal p-4 small"
            ariaHideApp={false}
          >
            <div className="modal-container">
              <h6 className="mb-3 fw-bold">{t('delete_customer_label')}</h6>
              <p>{t('delete_stable_text')}</p>
              <div className="btn-container d-flex justify-content-between align-items-center">
                <button
                  className="btn fs-14 btn-outline-secondary w-50"
                  onClick={() => setState({ isOpen: false })}
                >
                  {t('cancel_button')}
                </button>
                <button
                  className="btn fs-14 btn-danger ms-3 w-50"
                  onClick={removeCustomer}
                >
                  {t('delete_customer_button')}
                </button>
              </div>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => {
                setState({ isOpen: false });
              }}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default StableDetail;
