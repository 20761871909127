import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getSellerProducts, syncProducts } from '../../libs/apis';
import Product from './Product';

export default function ProductSummary() {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const myCompany = user.company || null;
  const initialState = {
    data: [],
    isLoading: true,
    isError: false,
    hasChange: true,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, hasChange } = state;

  useEffect(() => {
    const getProductData = async () => {
      await getSellerProducts()
        .then(resp => {
          if (resp.status === 200) {
            setState({
              data: resp.data.response,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProductData();
  }, []);

  const handleChange = () => {
    setState({ hasChange: true });
  };

  const handleSync = async () => {
    const payload = {
      id: myCompany.id,
    };
    await syncProducts(payload)
      .then(resp => {
        console.log('res', resp);
      })
      .finally(() => setState({ hasChange: false }));
  };

  if (!user) return null;

  return (
    <div className="product-summary ">
      <div className="d-flex justify-content-end align-items-center">
        {data.length > 0 && (
          <div className="mb-3 d-flex flex-direction-col align-items-end">
            {myCompany && (
              <>
                {!hasChange ? (
                  <button className="btn btn-sm btn-secondary opacity-50 rounded-pill">
                    <span className="fa fa-refresh me-1"></span>
                    <strong>{t('com_sync_button')}</strong>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-warning rounded-pill"
                    onClick={handleSync}
                  >
                    <span className="fa fa-refresh me-1"></span>
                    <strong>{t('com_sync_button')}</strong>
                  </button>
                )}
              </>
            )}
            <Link
              to="/seller/product/new"
              className="btn btn-sm rounded-pill btn-primary ms-2"
            >
              <span className="fa fa-plus me-1"></span>
              <small>{t('new')}</small> <strong>{t('product')}</strong>
            </Link>
          </div>
        )}
      </div>

      {data.length > 0 && (
        <div>
          {data.map(p => {
            return <Product data={p} key={p.id} onChange={handleChange} />;
          })}
        </div>
      )}
      {data.length === 0 && !isLoading && (
        <div className="text-center my-3">
          <Link
            to="/seller/product/new"
            className="btn mb-0 rounded-pill btn-warning"
          >
            <span className="fa fa-plus me-2"></span>
            <small>{t('add_your_first')}</small> <strong>{t('product')}</strong>
          </Link>
        </div>
      )}
    </div>
  );
}
