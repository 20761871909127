import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import TransactionHistory from '../../components/global/TransactionHistory';
import Resizer from 'react-image-file-resizer';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

// import SwiperCore, { Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';

import {
  getItemDetail,
  updateItem,
  getStables,
  deleteItem,
  uploadImage,
} from '../../libs/apis';

// SwiperCore.use([Navigation]);

function ItemPage() {
  const { t } = useTranslation();
  const { horseId } = useParams();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    image: null,
    isLoadingStable: false,
    isErrorStable: false,
    messageStable: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
    isEditStable: false,
    stableData: [],
    selectedStable: -1,
    isSubmit: false,
    isSubmitError: false,
    isUploadError: false,
    isUploading: false,
    isOpen: false,
    hasInput: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isError,
    // isLoadingStable,
    // isErrorStable,
    isUploadError,
    isUploading,
    image,
    isOpen,
    hasInput,
    // isSubmitError
  } = state;

  useEffect(() => {
    const getItemData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: horseId,
      };
      await getItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({
              data: resp.data.response,
              image: resp.data.response.image,
            });
            if (resp.data.response.stable) {
              setState({ selectedStable: resp.data.response.stable.id });
            }
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, message: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getStableData = async () => {
      setState({
        isLoadingStable: true,
        isErrorStable: false,
        messageStable: '',
      });
      await getStables()
        .then(resp => {
          if (resp.status === 200) {
            setState({ stableData: resp.data.response });
          } else {
            setState({
              isErrorStable: true,
              messageStable: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          // setState({ isErrorStable: true, messageStable: err.error.message });
        })
        .finally(() => setState({ isLoadingStable: false }));
    };
    getItemData();
    getStableData();
  }, [horseId]);

  if (role === 'seller' && !user.subscription) {
    return (
      <div className="app-container" style={{ height: 'calc(100vh - 108px)' }}>
        <div
          className="container mb-5 d-flex flex-direction-col justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 108px)' }}
        >
          <div className="text-center p-5">
            <h6 className="fw-bold fs-5 mb-3">{t('pro_restriction_title')}</h6>
            <p className="text-muted">{t('pro_restriction_text')}</p>
            <a
              className="btn btn-sm btn-outline-primary"
              target="_blank"
              href="mailto:laskutus@horsesfirst.com"
              rel="noreferrer noopener"
            >
              {t('contact_horsesfirst')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (!horseId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const {
    name,
    description,
    birthdate,
    withers,
    job,
    breed,
    billing_active = false,
    billing_email,
    billing_name,
    billing_company_id,
    billing_address,
    billing_phone,
    stable = null,
  } = data;

  const removeHorse = async () => {
    setState({ isDeleting: true, isDeleteError: false, deleteMessage: '' });
    const payload = {
      id: horseId,
    };
    await deleteItem(payload)
      .then(resp => {
        if (resp.status === 200) {
          history.push('/profile');
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isDeleteError: true, deleteMessage: err.error.message });
      })
      .finally(() => setState({ isDeleting: false }));
  };

  const onDropImage = async e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ isUploading: true, isUploadError: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  setState({ image: res.data.secure_url });
                }
              })
              .catch(err => {
                setState({ isUploadError: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ isUploading: false });
              });
          },
          'base64',
          1280,
          null,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const updateHorse = async () => {
    setState({ isSubmit: true, isSubmitError: false, submitMessage: '' });
    const payload = data;
    payload.category_id = data.category.id;
    payload.image = image;
    payload.stable_id = data.stable.id;
    await updateItem(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ data: resp.data.response });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isSubmitError: true });
      })
      .finally(() => setState({ isSubmit: false }));
  };

  const isPro = user.subscription || false;

  return (
    <div className="app-container">
      <SubHeader
        title={t('horse_detail_title')}
        go="/manage?tab=horse"
        prevent={hasInput}
      >
        {!image || name === '' ? (
          <button type="button" className="btn text-secondary" disabled>
            {t('save_horse_button')}
          </button>
        ) : (
          <button onClick={updateHorse} className="btn text-primary">
            {t('save_horse_button')}
          </button>
        )}
      </SubHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="main-content">
              <div className="form-group">
                {isUploadError && (
                  <div className="alert alert-danger text-center">
                    <p className="m-0">{t('failed_to_upload')}</p>
                  </div>
                )}
                {/* <Uploader /> */}
                {isUploading && <Loader size={24} />}
                <div className="image-uploader text-center">
                  <label
                    htmlFor="img"
                    className="position-relative d-inline-block w-auto mx-auto align-items-center btn text-center justify-content-center fw-bold overflow-hidden"
                  >
                    {image ? (
                      <div className="mb-2 d-flex justify-content-center">
                        <img
                          src={image}
                          alt="upload"
                          height="160"
                          width="160"
                          className="rounded-circle"
                        />
                      </div>
                    ) : (
                      <div className="mb-1 d-flex justify-content-center">
                        <div
                          style={{ width: '160px', height: '160px' }}
                          className="rounded-circle d-flex justify-content-center align-items-center flex-direction-column bg-light display-1"
                        >
                          <span className="fa fa-image text-white"></span>
                        </div>
                      </div>
                    )}

                    {image ? (
                      <small>
                        {t('change_photo')}{' '}
                        <span className="text-danger">*</span>
                      </small>
                    ) : (
                      <small>
                        {t('add_photo')} <span className="text-danger">*</span>
                      </small>
                    )}

                    <input
                      type="file"
                      id="img"
                      accept="image/*"
                      onChange={onDropImage}
                      className="fs-6 small"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        opacity: 0,
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                <h6 className="fw-bold mb-0">{t('horse_stable_heading')}</h6>
                <Link
                  to={`/horse/${horseId}/stable`}
                  className="text-decoration-none fs-14 fw-bold p-0"
                >
                  {t('change_button')}
                </Link>
              </div>
              <h6 className="text-muted fs-14 mb-3">
                {stable ? stable.name : '-'}
              </h6>

              <div className="d-flex align-items-center justify-content-between mt-5 mb-3">
                <h6 className="fw-bold mb-0">{t('billing_info_label')}</h6>
                <Link
                  to={`/horse/${horseId}/billing`}
                  className="text-decoration-none fs-14 fw-bold p-0"
                >
                  {t('change_button')}
                </Link>
              </div>
              {!billing_active ? (
                <h6 className="text-muted fs-14 mb-3">
                  {t('billing_info_inactive')}
                </h6>
              ) : (
                <div className="billing-info">
                  <label htmlFor="billing_info_email">
                    {t('billing_info_email')}
                  </label>
                  <h6 className="fs-14 fw-bold mb-0">
                    {billing_email ? billing_email : '-'}
                  </h6>

                  <hr />
                  <label htmlFor="billing_info_name">
                    {t('billing_info_name')}
                  </label>
                  <h6 className="fs-14 fw-bold mb-0">
                    {billing_name ? billing_name : '-'}
                  </h6>

                  <hr />
                  <label htmlFor="billing_info_company_id">
                    {t('billing_info_company_id')}
                  </label>
                  <h6 className="fs-14 fw-bold mb-0">
                    {billing_company_id ? billing_company_id : '-'}
                  </h6>

                  <hr />
                  <label htmlFor="billing_address">
                    {t('billing_address')}
                  </label>
                  <h6 className="fs-14 fw-bold mb-0">
                    {billing_address ? billing_address : '-'}
                  </h6>

                  <hr />
                  <label htmlFor="billing_phone">{t('billing_phone')}</label>
                  <h6 className="fs-14 fw-bold mb-0">
                    {billing_phone ? billing_phone : '-'}
                  </h6>
                </div>
              )}

              <div className="horse-information mt-5">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="fw-bold mb-0">
                    {t('horse_information_heading')}
                  </h6>
                  <Link
                    to={`/horse/${horseId}/edit`}
                    className="text-decoration-none fs-14 fw-bold p-0"
                  >
                    {t('change_button')}
                  </Link>
                </div>

                <div className="info-field mb-3">
                  <label htmlFor="name">{t('horse_name_label')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{name}</h6>
                </div>

                <hr />

                <div className="info-field mb-3">
                  <label htmlFor="dob">{t('horse_date_of_birth')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{birthdate || '-'}</h6>
                </div>

                <hr />

                <div className="info-field mb-3">
                  <label htmlFor="name">{t('horse_withers')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{withers || '-'}</h6>
                </div>

                <hr />

                <div className="info-field mb-3">
                  <label htmlFor="job">{t('horse_job')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{job || '-'}</h6>
                </div>

                <hr />

                <div className="info-field mb-3">
                  <label htmlFor="breed">{t('horse_breed')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{breed || '-'}</h6>
                </div>

                <hr />

                <div className="info-field mb-3">
                  <label htmlFor="description">{t('description')}</label>
                  <h6 className="fs-14 fw-bold mb-0">{description || '-'}</h6>
                </div>
              </div>
            </div>

            <div className="main-content">
              <div className="horse-service-container">
                <div className=" mt-5 ">
                  <h6 className="mb-3 fw-bold">{t('service_history')}</h6>

                  <div className="">
                    <TransactionHistory horseId={horseId} />
                  </div>
                </div>
              </div>

              {/* {gallery && gallery.length > 0 && (
                <div className="horse-gallery sgallery-container">
                  <div className="card shadow">
                    <div className="card-header bg-secondary p-2">
                      {gallery.length > 1 ? (
                        <div className="swiper-navigations d-flex align-items-center justify-content-between">
                          <button className="swiper-nav swiper-prev btn btn-sm py-1 btn-light ">
                            <span className="fa fa-chevron-left"></span>
                          </button>
                          <h6 className="m-0 small text-light">
                            <span className="fa fa-image me-2"></span>
                            {t('horse_gallery')}
                          </h6>
                          <button className="swiper-nav swiper-next btn btn-sm btn-light py-1">
                            <span className="fa fa-chevron-right"></span>
                          </button>
                        </div>
                      ) : (
                        <div className="swiper-navigations d-flex align-items-center justify-content-center">
                          <h6 className="m-0 small text-light">
                            <span className="fa fa-image me-2"></span>
                            {t('horse_gallery')}
                          </h6>
                        </div>
                      )}
                    </div>
                    <div className="card-body p-0">
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        autoHeight={true}
                        navigation={{
                          nextEl: '.swiper-next',
                          prevEl: '.swiper-prev',
                        }}
                      >
                        {gallery.map((g, index) => (
                          <SwiperSlide key={g + index}>
                            <img src={g} alt={g + index} className="w-100" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              )} */}

              <button
                className="btn btn-outline-danger w-100 mt-4"
                onClick={() => setState({ isOpen: true })}
              >
                <span className="fa fa-trash me-2"></span>
                {t('remove_horse_button')}
              </button>
            </div>

            <Modal
              isOpen={isOpen}
              onRequestClose={() => {
                setState({ isOpen: false });
              }}
              contentLabel="Modal"
              className="reveal p-4 small"
              ariaHideApp={false}
            >
              <div className="modal-container">
                <h6 className="mb-3 fw-bold">{t('delete_horse_label')}</h6>
                <p>{t('delete_horse_text')}</p>
                <div className="btn-container d-flex justify-content-between align-items-center">
                  <button
                    className="btn fs-14 btn-outline-secondary w-50"
                    onClick={() => setState({ isOpen: false })}
                  >
                    {t('cancel_button')}
                  </button>
                  <button
                    className="btn fs-14 btn-danger ms-3 w-50"
                    onClick={removeHorse}
                  >
                    {t('delete_horse_button')}
                  </button>
                </div>
              </div>
              <button
                className="btn btn-close close-reveal"
                onClick={() => {
                  setState({ isOpen: false });
                }}
              >
                &times;
              </button>
            </Modal>
          </div>
        </div>
      </div>
      {isPro && (
        <Link
          to={`/pro/create-order?horse_id=${horseId}`}
          className="btn btn-gold btn-lg rounded-circle fs-4 d-flex justify-content-center align-items-center flex-direction-col position-fixed shadow p-0"
          style={{
            right: '1.5rem',
            bottom: '4.5rem',
            width: '56px',
            height: '56px',
            lineHeight: '56px',
          }}
        >
          <span className="fa fa-plus"></span>
        </Link>
      )}
    </div>
  );
}

export default ItemPage;
