import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import ProductSummary from '../../components/seller/ProductSummary';
import SubHeader from '../../components/global/SubHeader';

function SellerProduct() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <SubHeader title={t('product_list')} go="/seller" />
      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content">
                <ProductSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerProduct;
