import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';

import { getCalendars } from '../../libs/apis';
import { setStorage, getStorage } from '../../libs/utils';

import Item from './NewItem';
import HorseItem from './HorseItem';
import Loader from '../global/Loader';
import { useSelector } from 'react-redux';
registerLocale('fi', fi);
registerLocale('id', id);

export default function Calendar({ isWidget = false }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const history = useHistory();
  const search = qs.parse(history.location.search);
  const qsDate = search.date || null;
  // const qsEndDate = search.end || null;
  let today = qsDate ? new Date(qsDate) : new Date();
  const theMode =
    search && search.mode
      ? search.mode
      : getStorage('calendarMode')
      ? getStorage('calendarMode')
      : 'today';
  if (!qsDate && theMode !== 'today') {
    if (theMode === 'weekly') {
      today = moment().startOf('isoWeek').toDate();
    } else if (theMode === 'monthly') {
      today = moment().startOf('month').toDate();
    } else {
      today = moment().toDate();
    }
  }
  const initialState = {
    data: [],
    isLoading: true,
    isError: false,
    mode: theMode,
    openMenu: false,
    date: today,
    startWeek: new Date(moment(today).startOf('isoWeek')),
    endWeek: new Date(moment(today).endOf('isoWeek')),
    calendarOpen: false,
    isRefetch: false,
    selectedCompany: search.company ? search.company : '0',
    individual: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isRefetch,
    isLoading,
    mode,
    openMenu,
    date,
    startWeek,
    endWeek,
    calendarOpen,
    selectedCompany,
    individual,
  } = state;

  const { user, role } = useSelector(state => state.auth);

  useEffect(() => {
    const getData = async () => {
      setState({ isRefetch: true });
      const theMode = mode;
      let startDate = moment(startWeek).format('YYYY-MM-DD');
      let endDate = moment(endWeek).format('YYYY-MM-DD');
      if (theMode === 'today') {
        startDate = moment(date).format('YYYY-MM-DD');
        endDate = moment(date).format('YYYY-MM-DD');
      } else if (theMode === 'monthly') {
        startDate = moment(date).startOf('months').format('YYYY-MM-DD');
        endDate = moment(date).endOf('months').format('YYYY-MM-DD');
      }
      const payload = {
        startDate,
        endDate,
      };
      if (role === 'seller' && user.companies && user.companies.length > 0) {
        payload.isCompany = true;
      }
      if (selectedCompany) {
        payload.company_id = selectedCompany;
      }
      await getCalendars(payload)
        .then(res => {
          if (res.status === 200) {
            // console.log('[CALENDARS]', res.data.response);
            setState({ data: res.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isRefetch: false, isLoading: false }));
    };
    getData();
    const lastMode = getStorage('calendarMode');
    if (lastMode && lastMode !== mode) {
      setStorage('calendarMode', mode);
    } else {
      setStorage('calendarMode', mode);
    }
  }, [date, mode, endWeek, startWeek, role, user.companies, selectedCompany]);

  if (isLoading) return <Loader center size="48" />;

  const getStartWeek = newDate => {
    const sw = new Date(moment(newDate).startOf('isoWeek'));
    return sw;
  };
  const getEndWeek = newDate => {
    const ew = new Date(moment(newDate).endOf('isoWeek'));
    return ew;
  };
  const isWeekday = date => {
    // const day = date.getDay();
    // return day !== 0 && day !== 6;
    return true;
  };
  const addDays = day => {
    const d = new Date(startWeek);
    d.setDate(d.getDate() + day);
    return d;
  };
  const nextWeek = () => {
    let n = new Date(moment(date).add(1, 'days'));
    if (mode === 'today') {
      n = new Date(moment(date).add(1, 'days'));
      search.date = moment(n).format('YYYY-MM-DD');
      search.mode = 'today';
      history.push(`?${qs.stringify(search)}`);
    } else if (mode === 'weekly') {
      n = new Date(moment(getStartWeek(date)).add(1, 'weeks'));
      search.date = moment(n).format('YYYY-MM-DD');
      search.mode = 'weekly';
      history.push(`?${qs.stringify(search)}`);
    } else if (mode === 'monthly') {
      n = new Date(moment(date).add(1, 'months'));
      search.date = moment(n).startOf('month').format('YYYY-MM-DD');
      search.mode = 'monthly';
      history.push(`?${qs.stringify(search)}`);
    }
    setState({ date: n, startWeek: getStartWeek(n), endWeek: getEndWeek(n) });
    currentDate = 1;
  };
  const prevWeek = () => {
    let n = new Date(moment(date).subtract(1, 'days'));
    if (mode === 'today') {
      n = new Date(moment(date).subtract(1, 'days'));
      search.date = moment(n).format('YYYY-MM-DD');
      search.mode = 'today';
      history.push(`?${qs.stringify(search)}`);
    } else if (mode === 'weekly') {
      n = new Date(moment(getStartWeek(date)).subtract(1, 'weeks'));
      search.date = moment(n).format('YYYY-MM-DD');
      search.mode = 'weekly';
      history.push(`?${qs.stringify(search)}`);
    } else if (mode === 'monthly') {
      n = new Date(moment(date).subtract(1, 'months'));
      search.date = moment(n).startOf('month').format('YYYY-MM-DD');
      search.mode = 'monthly';
      history.push(`?${qs.stringify(search)}`);
    }
    setState({ date: n, startWeek: getStartWeek(n), endWeek: getEndWeek(n) });
    currentDate = 1;
  };
  const daysInMonth = newDate => {
    const num =
      32 - new Date(newDate.getFullYear(), newDate.getMonth(), 32).getDate();
    return num;
  };
  const firstDay = newDate => {
    const fd = new Date(newDate.getFullYear(), newDate.getMonth()).getUTCDay();
    return fd;
  };
  const rows = [...Array(6)];
  const cols = [...Array(7)];
  let currentDate = 1;
  let minHour = 7;
  let maxHour = 19;
  data.forEach(d => {
    const momStart = moment(d.start_datetime).format('H');
    const momEnd = moment(d.end_datetime).format('H');
    if (momStart < minHour) {
      minHour = momStart;
    }
    if (momEnd > maxHour) {
      maxHour = momEnd;
    }
  });
  if (individual) {
    minHour = 1;
    maxHour = 24;
  }
  const startTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDay(),
    7,
  );
  const difStartHour = 7 - minHour;
  const difEndHour = maxHour - 19 > 1 ? 5.5 : 0;
  let startTimeSlot = [];
  let endTimeSlot = [];
  for (let i = 0; i < difStartHour * 2; i++) {
    startTimeSlot.push(moment(startTime).set({ hour: i / 2 + 1 }));
  }
  for (let y = 0; y < difEndHour; y++) {
    endTimeSlot.push(moment(startTime).set({ hour: y + 20 }));
  }
  const slots = [
    ...startTimeSlot,
    moment(startTime),

    moment(startTime).add(0.5, 'hours'),
    moment(startTime).add(1, 'hours'),
    moment(startTime).add(1.5, 'hours'),

    moment(startTime).add(2, 'hours'),
    moment(startTime).add(2.5, 'hours'),

    moment(startTime).add(3, 'hours'),
    moment(startTime).add(3.5, 'hours'),

    moment(startTime).add(4, 'hours'),
    moment(startTime).add(4.5, 'hours'),

    moment(startTime).add(5, 'hours'),
    moment(startTime).add(5.5, 'hours'),

    moment(startTime).add(6, 'hours'),
    moment(startTime).add(6.5, 'hours'),

    moment(startTime).add(7, 'hours'),
    moment(startTime).add(7.5, 'hours'),

    moment(startTime).add(8, 'hours'),
    moment(startTime).add(8.5, 'hours'),

    moment(startTime).add(9, 'hours'),
    moment(startTime).add(9.5, 'hours'),

    moment(startTime).add(10, 'hours'),
    moment(startTime).add(10.5, 'hours'),

    moment(startTime).add(11, 'hours'),
    moment(startTime).add(11.5, 'hours'),

    moment(startTime).add(12, 'hours'),
    moment(startTime).add(12.5, 'hours'),
    ...endTimeSlot,
  ];
  const weekSlots = [
    moment(startWeek),
    moment(startWeek).add(1, 'days'),
    moment(startWeek).add(2, 'days'),
    moment(startWeek).add(3, 'days'),
    moment(startWeek).add(4, 'days'),
    moment(startWeek).add(5, 'days'),
    moment(startWeek).add(6, 'days'),
  ];
  const updateOrderSlot = newData => {
    const copyData = data;
    const didx = copyData.findIndex(e => e.id === newData.id);
    if (didx !== -1) {
      copyData[didx] = newData;
      setState({ data: copyData });
    }
  };
  const updateItem = newData => {
    const copyData = data;
    const didx = copyData.findIndex(e => e.transaction.id === newData.id);
    if (didx !== -1) {
      copyData[didx].transaction = newData;
      setState({ data: copyData });
    }
  };
  // console.log('w', slots)
  // console.log('dim', daysInMonth(date), firstDay(date), currentDate)
  const jumpToDate = (newDate, mode) => {
    search.mode = mode;
    search.date = moment(newDate).format('YYYY-MM-DD');
    setState({ date: new Date(newDate), mode: mode });
    history.push(`?${qs.stringify(search)}`);
  };

  const showCompanySelect = role === 'seller' && user.companies.length > 0;

  return (
    <div
      className="calendar-container mb-5 shadow"
      style={{ margin: '0 -.75rem' }}
    >
      <div className="bg-secondary bg-gradient p-2 p-md-3 d-flex justify-content-between align-items-center">
        <h6 className="mb-0 text-light">
          <span className="fa fa-calendar me-2"></span>
          {t('schedule_text')}
        </h6>
        <div className="company-select d-flex align-items-center justify-content-end">
          {showCompanySelect && (
            <select
              className="form-select text-end fs-10 form-select-sm border-0"
              defaultValue={selectedCompany}
              onChange={e => {
                if (e.target.value !== '0') {
                  search.company = e.target.value;
                  setState({ selectedCompany: e.target.value });
                  history.push(`?${qs.stringify(search)}`);
                } else {
                  search.company = null;
                  setState({ selectedCompany: null });
                  history.push(`?${qs.stringify(search, { skipNull: true })}`);
                }
              }}
            >
              {!selectedCompany ? (
                <option value="0" selected>
                  {t('my_calendar')}
                </option>
              ) : (
                <option value="0">{t('my_calendar')}</option>
              )}
              {user.companies.map(ucom => (
                <option key={ucom.company_id} value={ucom.company_id}>
                  {ucom.company.name}
                </option>
              ))}
            </select>
          )}
          {mode === 'today' && role !== 'buyer' && (
            <>
              {individual ? (
                <button
                  className="p-1 py-0 btn rounded btn-primary btn-sm ms-2 text-light"
                  onClick={() => setState({ individual: false })}
                >
                  <strong>1</strong>
                </button>
              ) : (
                <button
                  className="p-1 py-0 btn rounded btn-light btn-sm ms-2"
                  onClick={() => setState({ individual: true })}
                >
                  <strong>1</strong>
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" p-0">
        <div className="calendar-widget">
          <div
            className={`calendar-toolbar bg-light shadow-sm border-bottom ${
              isWidget ? '' : 'sticky-top'
            }`}
          >
            <div className="d-flex justify-content-center align-items-center p-2">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-light border bg-white"
                  onClick={prevWeek}
                >
                  <span className="fa fa-chevron-left"></span>
                </button>
                <button
                  type="button"
                  className="btn btn-light bg-white border text-secondary"
                  onClick={() => setState({ calendarOpen: !calendarOpen })}
                >
                  <span className="fa fa-calendar"></span>
                </button>
                <button
                  type="button"
                  className="btn btn-light border bg-white"
                  onClick={nextWeek}
                >
                  <span className="fa fa-chevron-right"></span>
                </button>
              </div>
              <div
                className="current-date text-center px-2"
                style={{ flex: '1' }}
              >
                {mode === 'today' && (
                  <h6
                    className="mb-0"
                    onClick={() => setState({ calendarOpen: !calendarOpen })}
                  >
                    {moment(date).format('ddd MMM DD, YYYY')}{' '}
                    <span className="fa fa-angle-down ms-2"></span>
                  </h6>
                )}
                {mode === 'weekly' && (
                  <h6
                    className="mb-0"
                    onClick={() => setState({ calendarOpen: !calendarOpen })}
                  >
                    {moment(startWeek).format('MMM DD')} -{' '}
                    {moment(endWeek).format('DD, YYYY')}{' '}
                    <span className="fa fa-angle-down ms-2"></span>
                  </h6>
                )}
                {mode === 'monthly' && (
                  <h6
                    className="mb-0"
                    onClick={() => setState({ calendarOpen: !calendarOpen })}
                  >
                    {moment(date).format('MMMM YYYY')}{' '}
                    <span className="fa fa-angle-down ms-2"></span>
                  </h6>
                )}
              </div>
              <div className={`toolbar-menu ${openMenu ? 'is-open' : ''}`}>
                <div className="dropdown">
                  <button
                    className="btn btn-light bg-white border"
                    onClick={() => setState({ openMenu: !openMenu })}
                  >
                    <span
                      className={`fa fa-chevron-${openMenu ? 'up' : 'down'}`}
                    ></span>
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{
                      display: `${openMenu ? 'block' : 'none'}`,
                      left: 'auto',
                      right: '0',
                    }}
                  >
                    <li>
                      <button
                        className={`dropdown-item ${
                          mode === 'today' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setState({
                            mode: 'today',
                            date: new Date(),
                            openMenu: false,
                          });
                          search.mode = 'today';
                          search.date = moment(new Date()).format('YYYY-MM-DD');
                          history.push(`?${qs.stringify(search)}`);
                        }}
                      >
                        {t('calendar_today')}
                      </button>
                    </li>
                    <li>
                      <button
                        className={`dropdown-item ${
                          mode === 'weekly' ? 'active' : ''
                        }`}
                        onClick={() => {
                          const oldDate = date;
                          setState({
                            mode: 'weekly',
                            date: new Date(moment(oldDate).startOf('isoWeek')),
                            startWeek: moment(oldDate).startOf('isoWeek'),
                            endWeek: moment(oldDate).endOf('isoWeek'),
                            openMenu: false,
                            individual: false,
                          });
                          search.mode = 'weekly';
                          search.date = moment(oldDate)
                            .startOf('isoWeek')
                            .format('YYYY-MM-DD');
                          history.push(`?${qs.stringify(search)}`);
                        }}
                      >
                        {t('calendar_weekly')}
                      </button>
                    </li>
                    <li>
                      <button
                        className={`dropdown-item ${
                          mode === 'monthly' ? 'active' : ''
                        }`}
                        onClick={() => {
                          const oldDate = date;
                          setState({
                            mode: 'monthly',
                            date: new Date(),
                            startWeek: moment().startOf('isoWeek'),
                            endWeek: moment().endOf('isoWeek'),
                            openMenu: false,
                            individual: false,
                          });
                          search.mode = 'monthly';
                          search.date = moment(oldDate)
                            .startOf('month')
                            .format('YYYY-MM-DD');
                          history.push(`?${qs.stringify(search)}`);
                        }}
                      >
                        {t('calendar_monthly')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {calendarOpen && (
              <div className={`calendar-object pt-1 text-center `}>
                {(mode === 'today' || mode === 'weekly') && (
                  <DatePicker
                    selected={date}
                    locale={lang}
                    onChange={newDate => {
                      const startDate = new Date(
                        moment(newDate).startOf('isoWeek'),
                      );
                      const endDate = new Date(
                        moment(newDate).endOf('isoWeek'),
                      );
                      setState({
                        date: newDate,
                        startWeek: startDate,
                        endWeek: endDate,
                        calendarOpen: false,
                      });
                      search.date = moment(newDate).format('YYYY-MM-DD');
                      history.push(`?${qs.stringify(search)}`);
                    }}
                    highlightDates={
                      mode === 'weekly'
                        ? [
                            startWeek,
                            addDays(1),
                            addDays(2),
                            addDays(3),
                            addDays(4),
                            addDays(5),
                            addDays(6),
                          ]
                        : []
                    }
                    filterDate={isWeekday}
                    inline
                  />
                )}
                {mode === 'monthly' && (
                  <DatePicker
                    selected={date}
                    locale={lang}
                    onChange={newDate => {
                      const startDate = new Date(
                        moment(newDate).startOf('isoWeek'),
                      );
                      const endDate = new Date(
                        moment(newDate).endOf('isoWeek'),
                      );
                      setState({
                        date: new Date(moment(newDate).startOf('month')),
                        startWeek: startDate,
                        endWeek: endDate,
                        calendarOpen: false,
                      });
                      search.date = moment(newDate)
                        .startOf('month')
                        .format('YYYY-MM-DD');
                      history.push(`?${qs.stringify(search)}`);
                    }}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    // highlightDates={mode === 'weekly' ? [startWeek, addDays(1), addDays(2), addDays(3), addDays(4)] : []}
                    // filterDate={isWeekday}
                    inline
                  />
                )}
                {/* <Datetime input={false} id="datepicker" initialValue={date} closeOnSelect={true} dateFormat="MMM DD, YYYY" timeFormat={false} onChange={(e) => {
                  const startDate = e.startOf('isoWeek');
                  const endDate = e.endOf('isoWeek');
                  setState({ date: e, startWeek: startDate, endWeek: endDate, calendarOpen: false })
                }} /> */}
              </div>
            )}
            {mode === 'weekly' && (
              <div className="table-container">
                <table
                  className="table table-bordered border-bottom-0 table-sm m-0"
                  style={{ background: '#f3f3ff' }}
                >
                  <thead
                    className="text-center"
                    style={{ background: '#f3f3ff' }}
                  >
                    <tr className="border-0">
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                      >
                        W{moment(date).isoWeek()}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[0]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[0])), 'today')
                        }
                      >
                        {moment(weekSlots[0]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[1]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[1])), 'today')
                        }
                      >
                        {moment(weekSlots[1]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[2])), 'today')
                        }
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[2]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                      >
                        {moment(weekSlots[2]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[3]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[3])), 'today')
                        }
                      >
                        {moment(weekSlots[3]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[4]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[4])), 'today')
                        }
                      >
                        {moment(weekSlots[4]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[5]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[5])), 'today')
                        }
                      >
                        {moment(weekSlots[5]).format('MMM DD')}
                      </th>
                      <th
                        scope="col"
                        style={{ width: '12.5%' }}
                        className="fs-12 fw-normal border border-bottom-0 p-0 py-2"
                        // onClick={() => {
                        //   const newDate = new Date(
                        //     moment(weekSlots[6]).format('MM/DD/YYYY'),
                        //   );
                        //   setState({ mode: 'today', date: newDate });
                        // }}
                        onClick={() =>
                          jumpToDate(new Date(moment(weekSlots[6])), 'today')
                        }
                      >
                        {moment(weekSlots[6]).format('MMM DD')}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              // <div className="week-header border-top border-left border-right bg-gradient shadow-sm" style={{ background: '#f3f9f6' }}>
              //   <div className="grid-view">
              //     <div className="c-rows">
              //       <div className="c-row d-flex">
              //         <div className="c-head left-col">W{moment(date).isoWeek()}</div>
              //         <div className="c-head border-start calendar-date today">{moment(startWeek).format('MMM DD')}</div>
              //         <div className="c-head border-start calendar-date">{moment(addDays(1)).format('MMM DD')}</div>
              //         <div className="c-head border-start calendar-date">{moment(addDays(2)).format('MMM DD')}</div>
              //         <div className="c-head border-start calendar-date">{moment(addDays(3)).format('MMM DD')}</div>
              //         <div className="c-head border-start calendar-date">{moment(addDays(4)).format('MMM DD')}</div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            )}
          </div>
          {isRefetch ? (
            <div className="text-center p-5 vh-100">
              <Loader size="24" color="warning" />
            </div>
          ) : (
            <div className="calendar-body">
              {individual ? (
                <>
                  {mode === 'today' && (
                    <div className="today-view">
                      <div className="laundry-view"></div>
                      <div className="table-container">
                        <table className="table table-bordered table-sm bg-white">
                          <thead>
                            <tr className="border-bottom-0">
                              <th
                                scope="col"
                                style={{ width: '15%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '85%', height: '0' }}
                                className="p-0"
                              />
                            </tr>
                          </thead>
                          <tbody className="">
                            {slots.map((time, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={i === 0 ? 'border-top-0' : ''}
                                >
                                  {i % 2 === 0 && (
                                    <th
                                      valign="middle"
                                      className={`border fs-12 p-1 py-2 text-end bg-light fw-normal font-ssp ${
                                        i === 0 ? 'border-top-0' : ''
                                      }`}
                                      rowSpan={2}
                                    >
                                      {time.format('h A')}
                                    </th>
                                  )}
                                  <td className="p-0 td-today-view">
                                    <div className="c-cell">
                                      <div className="cell"></div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="extended-view">
                        <div className="extended-grid">
                          {data.map((cal, i) => {
                            return (
                              <HorseItem
                                selectedCompany={selectedCompany}
                                callbackSlot={updateOrderSlot}
                                data={cal.transaction}
                                timeslot={moment(
                                  cal.transaction.transaction_request[0]
                                    .datetime,
                                ).format('HH:mm')}
                                callback={updateItem}
                                newData={cal}
                                orderIndex={i}
                                key={i}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {mode === 'today' && (
                    <div className="today-view">
                      <div className="table-container">
                        <table className="table table-bordered table-sm bg-white">
                          <thead>
                            <tr className="border-bottom-0 border-top-0">
                              <th
                                scope="col"
                                style={{ width: '15%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '85%', height: '0' }}
                                className="p-0"
                              />
                            </tr>
                          </thead>
                          <tbody className="">
                            {slots.map((time, i) => {
                              const hasOrder = data.length > 0;
                              return (
                                <tr
                                  key={i}
                                  className={i === 0 ? 'border-top-0' : ''}
                                >
                                  {i % 2 === 0 && (
                                    <th
                                      valign="middle"
                                      className={`border fs-12 p-1 py-2 text-end bg-light fw-normal font-ssp ${
                                        i === 0 ? 'border-top-0' : ''
                                      }`}
                                      rowSpan={2}
                                    >
                                      {time.format('h A')}
                                    </th>
                                  )}
                                  <td className="p-0 td-today-view">
                                    {/* <div className="c-cell">
                                  <div className="cell"></div>
                                  <div className="cell"></div>
                                </div> */}

                                    {hasOrder ? (
                                      <div className="new-order-boxes">
                                        {data.map((cal, iii) => {
                                          const o = cal.transaction;
                                          const orderTime = moment(
                                            o.transaction_request[0].datetime,
                                          ).format('HH:mm');
                                          if (
                                            o.transaction_request[0].status ===
                                              1 &&
                                            o.type === 2
                                          ) {
                                            return null;
                                          }
                                          if (
                                            orderTime === time.format('HH:mm')
                                          ) {
                                            return (
                                              <Item
                                                selectedCompany={
                                                  selectedCompany
                                                }
                                                callbackSlot={updateOrderSlot}
                                                key={iii}
                                                data={o}
                                                timeslot={moment(
                                                  o.transaction_request[0]
                                                    .datetime,
                                                ).format('HH:mm')}
                                                callback={updateItem}
                                                newData={cal}
                                              />
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}
                                      </div>
                                    ) : (
                                      <div className="new-order-boxes"></div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {mode === 'weekly' && (
                    <div className="week-view">
                      <div className="table-container table-responsive">
                        <table className="table table-bordered table-responsive table-sm bg-white">
                          <thead>
                            <tr className="border-bottom-0 border-top-0">
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                              <th
                                scope="col"
                                style={{ width: '12.5%', height: '0' }}
                                className="p-0"
                              />
                            </tr>
                          </thead>
                          {/* <thead className="text-center" style={{ background: '#f3f9f6' }}>
                    <th style={{ width: '64px' }} className="fs-12 fw-normal border p-0 py-2">W{moment(date).isoWeek()}</th>
                    <th className="fs-12 fw-normal border p-0 py-2">{moment(startWeek).format('MMM DD')}</th>
                    <th className="fs-12 fw-normal border p-0 py-2">{moment(addDays(1)).format('MMM DD')}</th>
                    <th className="fs-12 fw-normal border p-0 py-2">{moment(addDays(2)).format('MMM DD')}</th>
                    <th className="fs-12 fw-normal border p-0 py-2">{moment(addDays(3)).format('MMM DD')}</th>
                    <th className="fs-12 fw-nor
                    mal border p-0 py-2">{moment(addDays(4)).format('MMM DD')}</th>
                  </thead> */}
                          <tbody className="">
                            {slots.map((time, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={i === 0 ? 'border-top-0' : ''}
                                >
                                  {i % 2 === 0 && (
                                    <th
                                      valign="middle"
                                      className={`border fs-12 p-1 py-2 text-end bg-light fw-normal font-ssp ${
                                        i === 0 ? 'border-top-0' : ''
                                      }`}
                                      style={{ width: '64px' }}
                                      rowSpan="2"
                                    >
                                      {time.format('h A')}
                                    </th>
                                  )}
                                  {weekSlots.map((w, ii) => {
                                    const hasOrder = data.filter(
                                      d =>
                                        d.transaction.confirmation_date ===
                                        w.format('YYYY-MM-DD'),
                                    );
                                    return (
                                      <td key={ii} className="p-0">
                                        <div className="order-boxes order-boxes-vertical">
                                          {hasOrder.map((cal, iii) => {
                                            const o = cal.transaction;
                                            const orderTime = moment(
                                              o.transaction_request[0].datetime,
                                            ).format('HH:mm');
                                            if (
                                              o.transaction_request[0]
                                                .status === 1 &&
                                              o.type === 2
                                            ) {
                                              return null;
                                            }
                                            if (
                                              orderTime === time.format('HH:mm')
                                            ) {
                                              return (
                                                <div
                                                  className="order-box"
                                                  key={iii}
                                                >
                                                  <Item
                                                    selectedCompany={
                                                      selectedCompany
                                                    }
                                                    callbackSlot={
                                                      updateOrderSlot
                                                    }
                                                    data={o}
                                                    timeslot={moment(
                                                      o.transaction_request[0]
                                                        .datetime,
                                                    ).format('HH:mm')}
                                                    callback={updateItem}
                                                    mode="block"
                                                    newData={cal}
                                                  />
                                                </div>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="week-body bg-white">
                    <div className="grid-view position-relative">
                      <div className="c-rows">
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">7am</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">8am</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">9am</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">10am</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">11am</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">12pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">1pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">2pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">3pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">4pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">5pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                        <div className="c-row d-flex border-bottom">
                          <div className="c-cell left-col bg-light text-end small">6pm</div>
                          <div className="c-cell border-start">
                            <div className="c-cell-item"></div>
                            <div className="c-cell-item cell-border"></div>
                          </div>
                        </div>
                      </div>
                      <div className="c-cols">
                        {[...Array(5)].map((c, i) => {
                          const thisDate = moment(startWeek).add(i, 'days').format('YYYY-MM-DD')
                          const hasOrder = data.filter(d => d.confirmation_date === thisDate);
                          if (hasOrder.length === 0) {
                            return (
                              <div className="c-col border-start" key={i}></div>
                            )
                          }
                          return (
                            <div className="c-col border-start" key={i}>
                              {hasOrder.map((o, idx) => {
                                return (
                                  <Item data={o} />
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div> */}
                    </div>
                  )}
                  {mode === 'monthly' && (
                    <div className="monthly-view">
                      <div className="monthly-body bg-light">
                        <div className="grid-view position-relative">
                          <div className="montly-grid">
                            <table className="table table-bordered">
                              <thead
                                className="text-center"
                                style={{ background: '#f3f9f6' }}
                              >
                                <tr>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[0].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[1].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[2].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[3].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[4].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[5].format('ddd')}
                                  </th>
                                  <th
                                    style={{ width: '14.29%' }}
                                    className="border fw-normal fs-14 py-2"
                                  >
                                    {weekSlots[6].format('ddd')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {rows.map((row, i) => {
                                  let r = i;
                                  return (
                                    <tr className="month-row" key={i}>
                                      {cols.map((col, idx) => {
                                        let c = idx;
                                        const thisDate = moment(
                                          new Date(
                                            date.getFullYear(),
                                            date.getMonth(),
                                            currentDate,
                                          ),
                                        );
                                        const xyz = thisDate.isoWeekday();
                                        const first = firstDay(date);
                                        const hasOrder = data.filter(
                                          d =>
                                            d.transaction.confirmation_date ===
                                            thisDate.format('YYYY-MM-DD'),
                                        );
                                        if (r === 0 && c < first && first < 7) {
                                          return (
                                            <td
                                              className="month-col bg-light"
                                              key={idx}
                                            >
                                              <div className="col-date text-end"></div>
                                            </td>
                                          );
                                        } else if (
                                          currentDate > daysInMonth(date)
                                        ) {
                                          return null;
                                        } else if (xyz > 7) {
                                          currentDate++;
                                          return null;
                                        } else {
                                          const renderDate = currentDate;
                                          currentDate++;
                                          return (
                                            <td
                                              className="bg-white month-col p-1"
                                              key={idx}
                                            >
                                              <div
                                                className="col-date clickable-date text-end text-muted fw-bold opacity-50"
                                                onClick={() =>
                                                  jumpToDate(
                                                    thisDate.format(
                                                      'YYYY-MM-DD',
                                                    ),
                                                    'today',
                                                  )
                                                }
                                              >
                                                {renderDate}
                                              </div>
                                              <div
                                                className="order-box overflow-hidden text-break"
                                                style={{ minHeight: '40px' }}
                                              >
                                                {hasOrder.length > 0 &&
                                                  hasOrder.map((cal, ii) => {
                                                    const o = cal.transaction;
                                                    const orderTime = moment(
                                                      o.transaction_request[0]
                                                        .datetime,
                                                    ).format('HH:mm');
                                                    if (
                                                      o.transaction_request[0]
                                                        .status === 1 &&
                                                      o.type === 2
                                                    ) {
                                                      return null;
                                                    }
                                                    return (
                                                      <div
                                                        className="order-item"
                                                        key={o.id}
                                                      >
                                                        {o.transaction_items
                                                          .length === 1 ? (
                                                          <>
                                                            <Item
                                                              selectedCompany={
                                                                selectedCompany
                                                              }
                                                              callbackSlot={
                                                                updateOrderSlot
                                                              }
                                                              data={o}
                                                              single
                                                              timeslot={
                                                                orderTime
                                                              }
                                                              callback={
                                                                updateItem
                                                              }
                                                              mode="block"
                                                              newData={cal}
                                                            />
                                                          </>
                                                        ) : (
                                                          <>
                                                            <Item
                                                              selectedCompany={
                                                                selectedCompany
                                                              }
                                                              callbackSlot={
                                                                updateOrderSlot
                                                              }
                                                              data={o}
                                                              timeslot={
                                                                orderTime
                                                              }
                                                              callback={
                                                                updateItem
                                                              }
                                                              mode="block"
                                                              newData={cal}
                                                            />
                                                          </>
                                                        )}
                                                      </div>
                                                    );
                                                  })}
                                              </div>
                                            </td>
                                          );
                                        }
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="calendar-widget d-none">
          <div className="calendar-header d-flex align-items-center justify-content-between">
            <button className="btn">
              <span className="fa fa-chevron-left"></span>
            </button>
            <h6 className="mb-0 flex-1">March 2021</h6>
            <button className="btn">
              <span className="fa fa-chevron-right"></span>
            </button>
          </div>
          <div className="calendar-body border-top d-flex justify-content-between">
            <div className="calendar-time border-right">
              <div className="calendar-th">
                <h6 className="btn small text-uppercase mb-0">Time</h6>
              </div>
              <div className="time-slot text-center p-2 border-top">07 am</div>
              <div className="time-slot text-center p-2">08 am</div>
              <div className="time-slot text-center p-2">09 am</div>
              <div className="time-slot text-center p-2">10 am</div>
              <div className="time-slot text-center p-2">11 am</div>
              <div className="time-slot text-center p-2">12 pm</div>
              <div className="time-slot text-center p-2">13 pm</div>
              <div className="time-slot text-center p-2">14 pm</div>
              <div className="time-slot text-center p-2">15 pm</div>
              <div className="time-slot text-center p-2">16 pm</div>
              <div className="time-slot text-center p-2">17 pm</div>
              <div className="time-slot text-center p-2">18 pm</div>
            </div>
            <div className="calendar-order" style={{ flex: '1' }}>
              <div className="calendar-day-head d-flex justify-content-between border-bottom position-relative">
                <div className="calendar-th flex-fill p-2 border-start text-center">
                  <h6 className="small text-uppercase mb-0">22</h6>
                </div>
                <div className="calendar-th flex-fill p-2 border-start text-center">
                  <h6 className="small text-uppercase mb-0">23</h6>
                </div>
                <div className="calendar-th flex-fill p-2 border-start text-center">
                  <h6 className="small text-uppercase mb-0">24</h6>
                </div>
                <div className="calendar-th flex-fill p-2 border-start text-center">
                  <h6 className="small text-uppercase mb-0">25</h6>
                </div>
                <div className="calendar-th flex-fill p-2 border-start text-center">
                  <h6 className="small text-uppercase mb-0">26</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
