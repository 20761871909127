import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/global/BackButton';
import Loader from '../../components/global/Loader';

import { useSelector } from 'react-redux';

import { uploadImage, createCompany } from '../../libs/apis';

function CompanyCreate() {
  const { t } = useTranslation();
  const history = useHistory();
  const { role } = useSelector(state => state.auth);

  if (role !== 'seller') {
    history.push('/profile');
  }

  const initialState = {
    data: null,
    isLoading: false,
    isError: false,
    comLogo: '',
    comName: '',
    comEmail: '',
    comWebsite: '',
    comDescription: '',
    comBusinessId: '',
    comVatId: '',
    comBankAccount: '',
    isUploading: false,
    isUploadError: false,
    isSubmiting: false,
    isSubmitError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isLoading,
    comLogo,
    comName,
    comEmail,
    comWebsite,
    comDescription,
    comBusinessId,
    comVatId,
    comBankAccount,
    isUploading,
    isUploadError,
  } = state;

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ isUploading: true, isUploadError: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          const resized = res.data.secure_url.replace(
            'https://res.cloudinary.com/luokki/image/upload/',
            'https://res.cloudinary.com/luokki/image/upload/w_800,h_800,c_fill/',
          );
          setState({ comLogo: resized });
        }
      })
      .catch(err => {
        setState({ isUploadError: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ isUploading: false });
      });
  };

  const handleSubmit = async () => {
    setState({ isSubmiting: true, isSubmitError: false });
    const payload = {
      logo: comLogo,
      name: comName,
      description: comDescription,
      business_id: comBusinessId,
      vat_id: comVatId,
      email: comEmail,
      website: comWebsite,
      bank_account: comBankAccount,
    };
    await createCompany(payload)
      .then(res => {
        if (res.status === 201) {
          window.location.href = '/profile';
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isSubmitError: true });
      })
      .finally(() => setState({ isSubmiting: false }));
  };

  if (isLoading) return <Loader size="48" center />;
  const canSubmit =
    comLogo !== '' &&
    comName !== '' &&
    comBusinessId !== '' &&
    comVatId !== '' &&
    comBankAccount !== '' &&
    comEmail !== '';

  return (
    <div className="app-container">
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="py-3">
              <div className="form-group">
                {isUploadError && (
                  <div className="alert alert-danger text-center">
                    <p className="m-0">{t('failed_to_upload')}</p>
                  </div>
                )}
                {isUploading && <Loader size={24} />}
                <div className="image-uploader text-center">
                  {comLogo && (
                    <div className="mb-3 d-flex justify-content-center">
                      <img
                        src={comLogo}
                        alt="upload"
                        className="img-thumbnail"
                      />
                    </div>
                  )}

                  <label
                    htmlFor="img"
                    className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                  >
                    <span className="fa fa-image me-3"></span>
                    {comLogo ? (
                      <small>{t('change_photo_label')}</small>
                    ) : (
                      <small>{t('upload_photo_label')}</small>
                    )}
                    <span className="text-danger"> *</span>

                    <input
                      type="file"
                      id="img"
                      accept="image/*"
                      onChange={onDropImage}
                      className="fs-6 small"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        opacity: 0,
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_email')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="email"
                  id="comEmail"
                  value={comEmail}
                  onChange={e => setState({ comEmail: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_email')}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_website')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="comWebsite"
                  value={comWebsite}
                  onChange={e => setState({ comWebsite: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_website')}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_name')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="comName"
                  value={comName}
                  onChange={e => setState({ comName: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_name')}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_business_id')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="comBusinessId"
                  value={comBusinessId}
                  onChange={e => setState({ comBusinessId: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_business_id')}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_vat_id')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="comVatId"
                  value={comVatId}
                  onChange={e => setState({ comVatId: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_vat_id')}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_bank_account')} <span className="text-danger">*</span>{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="comBankAccount"
                  value={comBankAccount}
                  onChange={e => setState({ comBankAccount: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_bank_account')}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="font-cabin text-primary">
                  {t('com_description')} <span className="text-danger">*</span>{' '}
                </label>
                <textarea
                  className="form-control"
                  id="comDescription"
                  value={comDescription}
                  onChange={e => setState({ comDescription: e.target.value })}
                  disabled={isLoading}
                  required
                  placeholder={t('com_description')}
                  style={{ height: '100px' }}
                ></textarea>
              </div>
              <div className="submit-btn mb-5">
                {canSubmit ? (
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                  >
                    <span className="fa fa-save me-3"></span>
                    {t('save_button')}
                  </button>
                ) : (
                  <button className="btn btn-secondary w-100" disabled>
                    <span className="fa fa-save me-3"></span>
                    {t('save_button')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyCreate;
