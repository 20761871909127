import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import InternalHeader from '../../components/global/InternalHeader';
import Page from '../../components/global/Page';
import { postResetPassword } from '../../libs/apis';

function ResetPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search);
  const email = search.email || '';
  const token = search.token || '';

  const initialState = {
    newPassword: '',
    verifyPassword: '',
    loading: false,
    success: false,
    error: false,
    message: '',
    passwordAlert: false,
    samePassword: false,
    isEmpty: true,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    newPassword,
    verifyPassword,
    loading,
    success,
    error,
    message,
    passwordAlert,
    samePassword,
    isEmpty,
  } = state;

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ loading: true, error: false, message: '', success: false });
    if (newPassword.length > 7 && !samePassword && !passwordAlert) {
      const payload = {
        email: email,
        token: token,
        password: newPassword,
        password_confirmation: verifyPassword,
      };
      await postResetPassword(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({
              success: true,
              password: '',
              newPassword: '',
              verifyPassword: '',
            });
            setTimeout(() => {
              history.push('/login');
            }, 2000);
          } else {
            setState({
              error: true,
              message: resp.data.error.message,
              success: false,
            });
          }
        })
        .catch(err => console.log('err', err))
        .finally(() => setState({ loading: false }));
    }
  };
  useEffect(() => {
    if (newPassword === '' || verifyPassword === '') {
      setState({ isEmpty: true });
    } else {
      setState({ isEmpty: false });
    }
  }, [newPassword, verifyPassword]);

  return (
    <div className="app-container">
      <InternalHeader title={t('reset_password_heading')} go="/login" />
      <div className="main-content pb-5">
        <Page>
          <div className="content py-3 text-break">
            {error && (
              <div className="alert text-center alert-danger" role="alert">
                {message}
              </div>
            )}
            {success && (
              <div className="alert text-center alert-success" role="alert">
                {t('change_password_success_text')}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-floating mb-3">
                <input
                  className={`form-control ${samePassword ? 'is-invalid' : ''}`}
                  type="password"
                  minLength="8"
                  maxLength="25"
                  id="newPassword"
                  value={newPassword}
                  onChange={e => {
                    setState({ newPassword: e.target.value });
                    if (
                      e.target.value !== verifyPassword &&
                      verifyPassword !== ''
                    ) {
                      setState({ passwordAlert: true });
                    } else {
                      setState({ passwordAlert: false });
                    }
                  }}
                  disabled={loading}
                  required
                  placeholder={t('reset_password_text')}
                />
                <label htmlFor="newPassword">{t('reset_password_text')}</label>
                {samePassword && (
                  <div className="invalid-feedback">
                    {t('same_password_alert')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  className={`form-control ${
                    passwordAlert ? 'is-invalid' : ''
                  }`}
                  type="password"
                  id="verifyPassword"
                  minLength="8"
                  maxLength="25"
                  required
                  value={verifyPassword}
                  onChange={e => {
                    setState({ verifyPassword: e.target.value });
                    if (e.target.value === newPassword) {
                      setState({ passwordAlert: false });
                    } else {
                      setState({ passwordAlert: true });
                    }
                  }}
                  disabled={loading}
                  placeholder={t('reset_password_confirm')}
                />
                <label htmlFor="verifyPassword">
                  {t('reset_password_confirm')}
                </label>
                {passwordAlert && (
                  <div className="invalid-feedback">
                    {t('verify_password_alert')}
                  </div>
                )}
              </div>
              {isEmpty || passwordAlert || samePassword ? (
                <button
                  type="button"
                  className="btn bg-gradient btn-lg btn-secondary w-100"
                  disabled
                >
                  {t('reset_password_button')}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn bg-gradient btn-lg btn-success w-100"
                >
                  {t('reset_password_button')}
                </button>
              )}
            </form>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default ResetPassword;
