import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import BackButton from '../../components/global/BackButton';
// import ProBadge from '../../components/global/ProBadge';
// import HorseIcon from '../../components/global/HorseIcon';
// import CalendarToday from '../../components/seller/CalendarToday';
import Loader from '../../components/global/Loader';
import { getSellerUsers, subscribeSeller } from '../../libs/apis';
import userImg from '../../assets/images/user.png';

export default function ProUpgrade() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);
  const initialState = {
    selectedSeller: -1,
    isOpen: false,
    isLoading: false,
    isError: false,
    sellers: [],
    search: '',
    upgraded: false,
    upgradeLoading: false,
    upgradeError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isOpen,
    sellers,
    isLoading,
    isError,
    search,
    selectedSeller,
    upgraded,
    upgradeLoading,
    upgradeError,
  } = state;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  const isSuperUser = user.super_user || false;

  if (!isSuperUser) {
    history.push('/seller');
  }

  useEffect(() => {
    const getSellerData = () => {
      setState({ isLoading: true, isError: false });
      const payload = {
        subscription: 0,
      };
      getSellerUsers(payload)
        .then(resp => {
          if (resp.data.response.length > 0) {
            setState({ sellers: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getSellerData();
  }, []);

  const filterSeller =
    search !== ''
      ? sellers.filter(seller => {
          let found = false;
          if (seller.name && seller.name.includes(search)) {
            found = true;
          }
          if (seller.username.includes(search)) {
            found = true;
          }
          if (seller.email.includes(search)) {
            found = true;
          }
          return found;
        })
      : sellers;

  const upgradeSeller =
    selectedSeller !== -1
      ? sellers.find(seller => seller.id === selectedSeller)
      : null;

  const upgrade = async sellerId => {
    setState({ upgradeLoading: true, upgradeError: false });
    const payload = {
      user_id: sellerId,
      expired_at: '2031-12-31 23:59:59',
    };
    await subscribeSeller(payload)
      .then(resp => {
        if (resp.status === 201) {
          setState({ upgraded: true });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ upgradeError: true });
      })
      .finally(() => {
        setState({ upgradeLoading: false });
      });
  };

  return (
    <div className="admin-container">
      <BackButton
        text={t('pro_upgrade_title')}
        classes="bg-light fs-5 text-dark"
      />

      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content pb-5">
                <div className="search-box mb-3">
                  <div className="d-flex align-items-center bg-white border px-3 py-1 rounded-3">
                    <span className="fa fa-search me-1 text-muted"></span>
                    <input
                      type="search"
                      className="form-control w-100 border-0 shadow-none"
                      placeholder={t('pro_search_seller_text')}
                      value={search}
                      onChange={e => {
                        setState({ search: e.target.value });
                      }}
                    />
                  </div>
                </div>

                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                )}
                {isError && (
                  <div className="alert alert-danger mb-3 text-center">
                    <p className="mb-0">{t('pro_general_error')}</p>
                  </div>
                )}
                {filterSeller.length > 0 && (
                  <div className="seller-list">
                    {filterSeller.map((seller, index) => {
                      const { photo, name, username, email, id } = seller;
                      return (
                        <div
                          className="seller-item py-3 border-bottom"
                          key={index}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="seller-avatar me-2">
                              <img
                                src={photo ? photo : userImg}
                                className="rounded-circle w-32 h-32"
                                alt=""
                              />
                            </div>
                            <div className="seller-info" style={{ flex: '1' }}>
                              <h6 className="fw-bold mb-0">
                                {name ? (
                                  name
                                ) : (
                                  <em className="fw-normal text-secondary">
                                    {username}
                                  </em>
                                )}
                              </h6>
                              <h6 className="mb-0 text-muted fs-12">{email}</h6>
                            </div>
                            <div className="btn-upgrade">
                              <button
                                className="btn btn-outline-primary shadow-sm fs-12 btn-sm border-2 "
                                onClick={() =>
                                  setState({
                                    selectedSeller: id,
                                    isOpen: true,
                                  })
                                }
                              >
                                {/* <span className="fa fa-chevron-up me-2 text-warning"></span> */}
                                {t('pro_upgrade')}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <Modal
                  isOpen={isOpen}
                  onRequestClose={() => setState({ isOpen: false })}
                  contentLabel="Modal"
                  className="reveal small p-3"
                  ariaHideApp={false}
                >
                  <div className="pt-4">
                    {/* <h6 className="mb-3 text-center text-success fw-bold">
                      <span className="fa fa-chevron-up me-2 text-warning"></span>
                      `Upgrade this` seller?
                    </h6> */}
                    {upgraded && (
                      <div className="alert alert-success p-2 mb-3 text-center">
                        <p className="mb-0">{t('pro_upgrade_success')}</p>
                      </div>
                    )}
                    {upgradeError && (
                      <div className="alert alert-danger p-2 mb-3 text-center">
                        <p className="mb-0">{t('pro_general_error')}</p>
                      </div>
                    )}
                    {upgradeSeller && (
                      <div>
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              upgradeSeller.photo
                                ? upgradeSeller.photo
                                : userImg
                            }
                            alt={
                              upgradeSeller.name
                                ? upgradeSeller.name
                                : upgradeSeller.username
                            }
                            className="w-32 h-32 me-2 rounded-circle"
                          />
                          <div className="seller-name">
                            <h6 className="fw-bold mb-0">
                              {upgradeSeller.name
                                ? upgradeSeller.name
                                : upgradeSeller.username}
                            </h6>
                            <h6 className="small mb-0 text-muted">
                              {upgradeSeller.email}
                            </h6>
                          </div>
                        </div>
                        <div className="seller-info py-3">
                          <table className="table mb-3 table-sm table-borderless table-white">
                            <tbody>
                              <tr>
                                <th className="w-25 fw-normal text-nowrap text-muted">
                                  {t('hl_business_id')}
                                </th>
                                <td>:</td>
                                <td className="w-100 fw-bold">
                                  {upgradeSeller.business_id
                                    ? upgradeSeller.business_id
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <th className="w-25 fw-normal  text-nowrap text-muted">
                                  {t('hl_phone')}
                                </th>
                                <td>:</td>
                                <td className="w-100 fw-bold">
                                  {upgradeSeller.phone}
                                </td>
                              </tr>
                              <tr>
                                <th className="w-25 fw-normal  text-nowrap text-muted">
                                  {t('hl_address')}
                                </th>
                                <td>:</td>
                                <td className="w-100 fw-bold">
                                  {upgradeSeller.address
                                    ? upgradeSeller.address
                                    : '-'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {/* <div className="alert alert-warning mb-3 p-2">
                      <p className="text-reset mb-0 text-center">
                        This seller will be permanently upgraded to{' '}
                        <span className="bg-primary p-1 rounded-2 text-light">
                          PRO Seller
                        </span>
                        . You can use{' '}
                        <Link to="/pro/downgrade" className="">
                          Downgrade page
                        </Link>{' '}
                        to manually downgrade the seller's status.
                      </p>
                    </div> */}
                    <div className="btn-container d-flex align-items-center justify-content-between">
                      <button
                        onClick={() => setState({ isOpen: false })}
                        className="btn btn-sm btn-outline-secondary w-50"
                      >
                        {t('cancel_button')}
                      </button>
                      {upgradeLoading ? (
                        <button
                          className="btn btn-sm btn-primary btn-disabled w-50 ms-3"
                          disabled
                        >
                          <span className="fa fa-refresh fa-spin me-2 text-warning"></span>
                          {t('pro_upgrade')}
                        </button>
                      ) : (
                        <button
                          onClick={() => upgrade(upgradeSeller.id)}
                          className="btn btn-sm btn-primary w-50 ms-3"
                        >
                          {/* <span className="fa fa-chevron-up me-2 text-warning"></span> */}
                          {t('pro_upgrade')}
                        </button>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isOpen: false })}
                  >
                    &times;
                  </button>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}
