import React, { useState, useEffect } from 'react';
import { Link, useParams, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
// import Moment from 'react-moment'
import moment from 'moment';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import Resizer from 'react-image-file-resizer';

import {
  getTransactionDetail,
  addTransactionBid,
  addTransactionAdditionalItemsBatch,
  getSellerInventory,
  sendInvoiceEmail,
  uploadImage,
  createInvoice,
  addTransactionIndividualInvoice,
  createIndividualInvoice,
  sendIndividualInvoiceEmail,
  updateTransactionStatus,
  addTransactionItemDetail,
} from '../../libs/apis';
import { numberFormat } from '../../libs/utils';
import Loader from '../../components/global/Loader';
import SubHeader from '../../components/global/SubHeader';
import ProBadge from '../../components/global/ProBadge';
import ProductBox from '../../components/global/ProductBox';
import HorseBox from '../../components/global/HorseBox';

function Quotation() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const { orderId } = useParams();
  const history = useHistory();
  const initialState = {
    data: null,
    isLoading: true,
    isError: false,
    isBidding: false,
    message: '',
    orderStatus: null,
    isOpen: false,
    action: 0,
    notes: '',
    date: new Date(),
    price: 0,
    quotedPrice: 0,
    isRefreshing: false,
    isDetailOpen: false,
    hideProduct: true,
    hideBuyer: true,
    hideHorse: true,
    qty1: 0,
    items: [],
    works: [],
    isLoadingItem: false,
    isErrorItem: false,
    itemQty: [],
    worksQty: [],
    isLoadingWorks: false,
    isErrorWorks: false,
    discountItem: 0,
    vatItem: 24,
    discountWork: 0,
    isSubmit: false,
    isOpenInvoice: false,
    openInvoiceId: 0,
    completeHorseInvoice: [],
    combinedInvoice: null,
    openCombinedInvoice: false,
    invoiceStep: 0,
    totalCombinedInvoice: 0,
    failedInvoice: false,
    summaryPhoto: '',
    isUploadError: false,
    isLoadingSummary: false,
    uploadGallery: false,
    errorGallery: false,
    summaryGallery: [],
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isOpen,
    isError,
    message,
    price,
    notes,
    quotedPrice,
    items,
    works,
    worksQty,
    itemQty,
    discountItem,
    discountWork,
    isSubmit,
    isOpenInvoice,
    openInvoiceId,
    completeHorseInvoice,
    combinedInvoice,
    openCombinedInvoice,
    invoiceStep,
    isBidding,
    totalCombinedInvoice,
    summaryPhoto,
    uploadGallery,
    summaryGallery,
  } = state;

  const processingText = [
    'billing_info_step_1',
    'billing_info_step_2',
    'billing_info_step_3',
  ];

  useEffect(() => {
    const getItems = async company => {
      const payload = {
        id: orderId,
        type: 0,
        company_id: company ? company.id : null,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let itemQty = [];
              resp.data.response.forEach(() => itemQty.push(0));
              setState({ items: resp.data.response, itemQty: itemQty });
            }
          } else {
            setState({
              isErrorItem: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorItem: true });
        })
        .finally(() => setState({ isLoadingItem: false }));
    };
    const getWorks = async company => {
      const payload = {
        id: orderId,
        type: 1,
        company_id: company ? company.id : null,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let worksQty = [];
              resp.data.response.forEach(() => worksQty.push(0));
              setState({ works: resp.data.response, worksQty: worksQty });
            }
          } else {
            setState({
              isErrorWorks: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorWorks: true });
        })
        .finally(() => setState({ isLoadingWorks: false }));
    };
    const getProduct = async () => {
      const payload = {
        id: orderId,
      };
      await getTransactionDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
              const lb = resp.data.response.transaction_request;
              let totalCombinedPrice = 0;
              setState({
                quotedPrice: lb[0].price,
                date: new Date(lb[0].datetime),
                // totalCombinedInvoice: lb[0].price,
              });
              if (resp.data.response.transaction_invoices.length > 0) {
                resp.data.response.transaction_invoices.forEach(e => {
                  totalCombinedPrice += e.price;
                });
                setState({
                  completeHorseInvoice: [
                    ...resp.data.response.transaction_invoices,
                  ],
                });
              }
              if (resp.data.response.transaction_additional_items.length > 0) {
                const addItemsData =
                  resp.data.response.transaction_additional_items;
                let combinedPrice = 0;
                addItemsData.forEach(e => {
                  const cprice = e.price;
                  const cqty = e.quantity;
                  const cvat = e.vat;
                  const cdiscount = e.discount;
                  const sub = cprice * cqty;
                  const subdisc = sub - (sub * cdiscount) / 100;
                  const total = subdisc + (subdisc * cvat) / 100;
                  combinedPrice += total;
                });
                const filteredItems =
                  resp.data.response.transaction_items.filter(
                    e => e.item.billing_active === false,
                  );
                totalCombinedPrice += combinedPrice;
                const newCombinedData = {
                  price: combinedPrice,
                  items: filteredItems,
                };
                setState({ combinedInvoice: newCombinedData });
              }
              setState({ totalCombinedInvoice: totalCombinedPrice });
              const company = resp.data.response.product.company;
              getItems(company);
              getWorks(company);
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProduct();
  }, [orderId]);
  if (!orderId) return <Redirect to="/404" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );
  const { transaction_request, transaction_items } = data;

  const subtractQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ worksQty: qtys });
    }
  };
  const addQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      qtys[i] += 1;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      qtys[i] += 1;
      setState({ worksQty: qtys });
    }
  };
  const changeQty = (i, val, type) => {
    if (type === 0) {
      const qtys = itemQty;
      qtys[i] = val;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      qtys[i] = val;
      setState({ worksQty: qtys });
    }
  };

  let estPrice = price;
  items.forEach((item, index) => {
    const eachItemPrice = item.price * itemQty[index];
    let discountItemPrice = 0;
    const vatItem = item.vat;
    if (discountItem > 0 && eachItemPrice > 0) {
      discountItemPrice = eachItemPrice * (discountItem / 100);
    }
    let vatItemPrice = 0;
    if (vatItem > 0 && eachItemPrice > 0) {
      vatItemPrice = (eachItemPrice - discountItemPrice) * (vatItem / 100);
    }
    estPrice += eachItemPrice - discountItemPrice + vatItemPrice;
  });
  works.forEach((work, index) => {
    // estPrice += work.price * worksQty[index];
    const eachWorkPrice = work.price * worksQty[index];
    let discountWorkPrice = 0;
    const vatWork = work.vat;
    if (discountWork > 0 && eachWorkPrice > 0) {
      discountWorkPrice = (eachWorkPrice * discountWork) / 100;
    }
    let vatWorkPrice = 0;
    if (vatWork > 0 && eachWorkPrice > 0) {
      vatWorkPrice = ((eachWorkPrice - discountWorkPrice) * vatWork) / 100;
    }
    estPrice += eachWorkPrice - discountWorkPrice + vatWorkPrice;
  });

  const handleSubmit = async () => {
    setState({ isSubmit: true, isSubmitError: false });
    let additionalItems = [];
    items.forEach((item, index) => {
      const price = item.price * itemQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: item.id,
          price: item.price,
          quantity: itemQty[index],
          discount: parseFloat(discountItem),
          vat: parseFloat(item.vat),
        };
        additionalItems.push(newItem);
      }
    });
    works.forEach((work, index) => {
      const price = work.price * worksQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: work.id,
          price: work.price,
          quantity: worksQty[index],
          discount: parseFloat(discountWork),
          vat: parseFloat(work.vat),
        };
        additionalItems.push(newItem);
      }
    });

    // console.log('items', items)
    const payload = {
      id: orderId,
      params: additionalItems,
    };
    await addTransactionAdditionalItemsBatch(payload).then(resp => {
      if (resp.status === 201) {
        processOrder(4);
      }
    });
  };
  // const createInvoicePdf = async () => {
  //   setState({ isSubmit: true, isSubmitError: false })
  //   const payload = {
  //     id: orderId,
  //   }
  //   await createInvoice(payload)
  //     .then(resp => {
  //       console.log('res', resp);
  //     })
  //     .finally(() => setState({ isSubmit: false }))
  // }

  const sendEmail = async () => {
    const payload = {
      id: orderId,
    };
    await sendInvoiceEmail(payload)
      .then(resp => {
        if (resp.status === 200) {
          history.push(`/seller/order/${orderId}?back=/seller/order`);
        }
      })
      .finally(() => setState({ isBidding: false, isSubmit: false }));
  };

  const generatePdf = async () => {
    const payload = {
      id: orderId,
    };
    setState({
      invoiceStep: 3,
    });
    await createInvoice(payload).then(resp => {
      if (resp.status === 200) {
        if (resp.data.response) {
          const file =
            resp.data.response.path && resp.data.response.path !== ''
              ? resp.data.response.path
              : null;
          if (file) {
            sendEmail();
          }
        }
      }
    });
  };

  const submitSummary = async () => {
    setState({ isLoadingSummary: true, isSubmit: true, isError: false });
    const payload = {
      id: orderId,
    };
    if (summaryPhoto !== '') {
      payload.summary_photo = summaryPhoto;
    }
    if (notes !== '') {
      payload.summary_text = notes;
    }
    await updateTransactionStatus(payload)
      .then(resp => {
        if (resp.status === 200) {
          submitGallery();
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setState({ isLoadingSummary: false });
      });
  };

  const processOrder = async stat => {
    setState({ isBidding: true });
    const lastBid = transaction_request[0];

    const payload = {
      id: orderId,
      price: Math.round(estPrice * 100) / 100,
      datetime: moment(lastBid.datetime).format('YYYY-MM-DD HH:mm:ss'),
      note: notes,
      status: stat,
    };

    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          generatePdf();
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setState({ isBidding: false }));
  };

  const hasHorseBilling =
    transaction_items.findIndex(e => e.item.billing_active === true) !== -1;

  const horseWithBilling = hasHorseBilling
    ? transaction_items.filter(e => e.item.billing_active === true)
    : [];
  const horseWithoutBilling = hasHorseBilling
    ? transaction_items.filter(e => e.item.billing_active === false)
    : [];

  const onlyHorseWithBilling =
    horseWithBilling.length > 0 && horseWithoutBilling.length === 0;

  const hasCombinedInvoice =
    data.transaction_additional_items.length > 0 || combinedInvoice;

  const setOpenInvoice = id => {
    setState({ isOpenInvoice: true, openInvoiceId: id });
  };

  const setOpenInvoiceDetail = id => {
    console.log('open detail', id);
  };

  const handleHorseInvoice = async () => {
    setState({ isSubmit: true, isSubmitError: false });
    let additionalItems = [];
    items.forEach((item, index) => {
      const price = item.price * itemQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: item.id,
          item_data: item,
          price: item.price,
          quantity: itemQty[index],
          discount: parseFloat(discountItem),
          vat: parseFloat(item.vat),
        };
        additionalItems.push(newItem);
      }
    });
    works.forEach((work, index) => {
      const price = work.price * worksQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: work.id,
          item_data: work,
          price: work.price,
          quantity: worksQty[index],
          discount: parseFloat(discountWork),
          vat: parseFloat(work.vat),
        };
        additionalItems.push(newItem);
      }
    });

    // console.log('items', items)
    const payload = {
      id: orderId,
      item_id: horseWithBilling[openInvoiceId].item.id,
      price: estPrice,
      invoice_data: additionalItems,
    };
    await addTransactionIndividualInvoice(payload)
      .then(resp => {
        if (resp.status === 201) {
          setState({ totalCombinedInvoice: totalCombinedInvoice + estPrice });
          // processOrder(4);
          let worksQty = [];
          works.forEach(() => worksQty.push(0));
          let itemQty = [];
          items.forEach(() => itemQty.push(0));
          setState({
            itemQty: itemQty,
            worksQty: worksQty,
            completeHorseInvoice: [...completeHorseInvoice, resp.data.response],
            isOpenInvoice: false,
            openInvoiceId: 0,
            discountItem: 0,
            discountWork: 0,
            estPrice: 0,
          });
        }
      })
      .finally(() => setState({ isSubmit: false }));
  };

  const handleCombinedInvoice = async () => {
    setState({ isSubmit: true, isSubmitError: false });
    let additionalItems = [];
    items.forEach((item, index) => {
      const price = item.price * itemQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: item.id,
          price: item.price,
          quantity: itemQty[index],
          discount: parseFloat(discountItem),
          vat: parseFloat(item.vat),
        };
        additionalItems.push(newItem);
      }
    });
    works.forEach((work, index) => {
      const price = work.price * worksQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: work.id,
          price: work.price,
          quantity: worksQty[index],
          discount: parseFloat(discountWork),
          vat: parseFloat(work.vat),
        };
        additionalItems.push(newItem);
      }
    });

    let combinedInvoiceHorses = [];
    horseWithoutBilling.forEach(e => {
      combinedInvoiceHorses.push(e.item);
    });
    const payload = {
      id: orderId,
      params: additionalItems,
      item_data: combinedInvoiceHorses,
    };
    await addTransactionAdditionalItemsBatch(payload).then(resp => {
      if (resp.status === 201) {
        setState({ totalCombinedInvoice: totalCombinedInvoice + estPrice });
        const combinedData = {
          price: estPrice,
          items: horseWithoutBilling,
        };
        let worksQty = [];
        works.forEach(() => worksQty.push(0));
        let itemQty = [];
        items.forEach(() => itemQty.push(0));
        setState({
          itemQty: itemQty,
          worksQty: worksQty,
          combinedInvoice: combinedData,
          estPrice: 0,
          discountItem: 0,
          discountWork: 0,
          openCombinedInvoice: false,
        });
      }
    });
  };

  const processFinalInvoice = async () => {
    setState({ isBidding: true, failedInvoice: false });

    const lastBid = transaction_request[0];

    const price = totalCombinedInvoice;
    const payload = {
      id: orderId,
      price: price,
      datetime: moment(lastBid.datetime).format('YYYY-MM-DD HH:mm:ss'),
      note: lastBid.notes,
      status: 4,
    };

    let horseInvoiceComplete = false;

    for (let y = 0; y < completeHorseInvoice.length; y++) {
      const horsePayload = {
        id: orderId,
        invoice_id: completeHorseInvoice[y].id,
      };
      setState({ invoiceStep: 1 });
      const res = await createIndividualInvoice(horsePayload);
      if (res.status === 200) {
        setState({ invoiceStep: 2 });
        const resEmail = await sendIndividualInvoiceEmail(horsePayload);
        if (resEmail.status === 200) {
          if (y === completeHorseInvoice.length - 1) {
            horseInvoiceComplete = true;
          }
        }
      }
    }

    if (horseInvoiceComplete) {
      await addTransactionBid(payload)
        .then(resp => {
          if (resp.status === 200) {
            generatePdf();
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    } else {
      setState({ failedInvoice: true });
    }
  };

  const isPro = data.product.seller.id === data.user.id;

  const onDropGallery = async e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ uploadGallery: true, errorGallery: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  setState({
                    summaryGallery: [...summaryGallery, res.data.secure_url],
                    summaryPhoto: '',
                  });
                }
              })
              .catch(err => {
                setState({ errorGallery: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ uploadGallery: false });
              });
          },
          'base64',
          1280,
          null,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const deleteGallery = item => {
    const newGallery = summaryGallery.filter(e => e !== item);
    setState({ summaryGallery: newGallery });
  };

  const itemId = transaction_items[0].id;

  const submitGallery = async () => {
    setState({ isLoadingSummary: true, isError: false });
    const payload = {
      id: orderId,
      itemId: itemId,
    };
    if (summaryGallery.length > 0) {
      payload.summary_gallery = summaryGallery;
    }
    if (notes !== '') {
      payload.summary_text = notes;
    }
    await addTransactionItemDetail(payload)
      .then(resp => {
        if (resp.status === 200) {
          // setState({ isDone: true, isCollapse: true });
          handleSubmit();
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setState({ isLoadingSummary: false });
      });
  };

  if (hasHorseBilling && !isPro) {
    return (
      <div className="app-container">
        <SubHeader
          title={t('create_order_summary')}
          go={`/seller/order/${orderId}`}
        />

        <div className="alert-warning p-2 px-3 position-sticky sticky-top shadow">
          <h6 className="mb-0 fs-12 text-center">
            {t('billing_info_transaction_alert')}
          </h6>
        </div>

        <div className="py-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h6 className="fs-14 text-primary text-center fw-bold ">
                  {t('billing_info_transaction_heading')}
                </h6>

                <div className="row g-2">
                  {horseWithBilling.map((h, index) => {
                    const { image, name, id } = h.item;
                    const hasInvoice =
                      completeHorseInvoice.findIndex(e => e.item.id === id) !==
                      -1;
                    return (
                      <div className="col-6" key={id}>
                        <div
                          className={`p-0 bg-light shadow border border-2 rounded overflow-hidden ${
                            hasInvoice
                              ? 'border-success'
                              : 'border-warning opacity-75'
                          }`}
                          onClick={() =>
                            hasInvoice
                              ? setOpenInvoiceDetail(index)
                              : setOpenInvoice(index)
                          }
                        >
                          <img
                            src={image}
                            alt={name}
                            className=" mb-0 d-block w-100"
                          />
                          <div className="p-2 position-relative">
                            <h6 className="mb-0 fs-12 d-flex justify-content-between align-items-center">
                              <span>{name} </span>
                            </h6>
                            {hasInvoice ? (
                              <h6 className="fs-10 text-muted fw-normal mb-0">
                                {t('billing_info_invoice_created')}{' '}
                              </h6>
                            ) : (
                              <h6 className="fs-10 text-muted fw-normal mb-0">
                                {t('billing_info_invoice_not_created')}{' '}
                              </h6>
                            )}

                            <div
                              className="fs-5 position-absolute"
                              style={{
                                right: '.5rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              {hasInvoice ? (
                                <span className="fa fa-check-circle text-primary"></span>
                              ) : (
                                <span className="fa fa-check-circle text-muted opacity-75"></span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {!onlyHorseWithBilling && (
                  <>
                    <h6 className="fs-14 mt-4 text-primary text-center fw-bold ">
                      {t('billing_info_combined_invoice_heading')}
                    </h6>

                    <div
                      className={`bg-light shadow border rounded overflow-hidden border-2 ${
                        hasCombinedInvoice ? 'border-success' : 'border-warning'
                      } p-2 position-relative`}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {horseWithoutBilling.map(h => {
                          const { image, name, id } = h.item;
                          return (
                            <div key={id} className="text-center me-2 mb-0">
                              <img
                                src={image}
                                className=" rounded mb-1"
                                style={{ width: '48px' }}
                                alt={name}
                              />
                              <h6 className="fs-12 mb-0 horse-name">{name}</h6>
                            </div>
                          );
                        })}
                      </div>
                      {hasCombinedInvoice ? (
                        <div
                          className="fs-5 position-absolute"
                          style={{
                            right: '.5rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <span className="fa fa-check-circle text-primary"></span>
                        </div>
                      ) : (
                        <div className="text-center mt-2">
                          <button
                            className="btn btn-primary btn-sm w-100"
                            onClick={() =>
                              setState({ openCombinedInvoice: true })
                            }
                          >
                            {t('billing_info_combined_invoice_button')}
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {(completeHorseInvoice.length > 0 || combinedInvoice) && (
                  <div>
                    <h6 className="fs-14 mt-4 text-primary fw-bold mb-3">
                      {t('billing_info_invoice_summary')}
                    </h6>

                    <h6 className="fs-12 text-muted mb-1">
                      {t('billing_info_individual_invoice_summary_heading')}
                    </h6>
                    {completeHorseInvoice.map((hi, index) => (
                      <div
                        className={`combined-invoice rounded bg-white p-2 border shadow-sm mb-2 position-relative`}
                        key={hi.id}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="item-data d-flex align-items-center">
                            <img
                              src={hi.item.image}
                              alt={hi.item.name}
                              style={{ width: '32px' }}
                              className="rounded-circle"
                            />
                            <h6 className="fs-10 mb-0 ms-1">
                              {hi.item.name}{' '}
                              <span className="d-block text-muted">
                                {t('billing_info_email')}:{' '}
                                <strong>{hi.item.billing_email}</strong>
                              </span>
                            </h6>
                          </div>
                          <h6 className="mb-0 fs-12">
                            {t('$')}{' '}
                            <strong className="text-primary">
                              <NumberFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType="text"
                                value={hi.price}
                              />
                            </strong>
                          </h6>
                        </div>
                      </div>
                    ))}

                    {!onlyHorseWithBilling && (
                      <>
                        <h6 className="fs-12 text-muted mb-1">
                          {t('billing_info_combined_invoice_summary_heading')}
                        </h6>
                        {combinedInvoice && (
                          <div className="combined-invoice rounded bg-white p-2 border shadow-sm mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="item-data d-flex align-items-center flex-wrap">
                                {combinedInvoice.items.map(h => (
                                  <div key={h.id} className="text-center me-1">
                                    <img
                                      src={h.item.image}
                                      alt={h.item.name}
                                      style={{ width: '32px' }}
                                      className="rounded-circle"
                                    />
                                    <h6 className="fs-12 mb-0">
                                      {h.item.name}
                                    </h6>
                                  </div>
                                ))}
                              </div>
                              <h6 className="mb-0 fs-12">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={combinedInvoice.price}
                                  />
                                </strong>
                              </h6>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-between pt-2 mt-2  border-top px-2">
                  <h6 className="mb-0 fs-14 fw-bold">
                    {t('billing_info_total')}
                  </h6>
                  <h6 className="mb-0 fs-6 fw-bold">
                    {t('$')}{' '}
                    <strong className="text-primary">
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                        value={totalCombinedInvoice}
                      />
                    </strong>
                  </h6>
                </div>
                <div className="main-cta mt-4">
                  {completeHorseInvoice.length === horseWithBilling.length &&
                  (onlyHorseWithBilling || combinedInvoice) ? (
                    <>
                      {isBidding ? (
                        <button
                          className="btn btn-secondary opacity-50 w-100"
                          disabled
                        >
                          {invoiceStep === 0 ? (
                            <>{t('billing_info_complete_order')}</>
                          ) : (
                            <>
                              <span className="fa fa-refresh fa-spin me-2 fs-12"></span>
                              {t(processingText[invoiceStep - 1])}
                            </>
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-100"
                          onClick={processFinalInvoice}
                        >
                          {t('billing_info_complete_order')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button className="btn btn-secondary w-100" disabled>
                      {t('billing_info_complete_order')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={openCombinedInvoice}
            contentLabel="Modal"
            className="reveal full p-2 py-3"
            ariaHideApp={false}
          >
            <h6 className="fs-14 text-primary">
              {t('billing_info_combined_invoice_modal_heading')}
            </h6>

            <div
              className="alert-warning p-2 px-3 position-sticky sticky-top shadow"
              style={{ margin: '0 -.5rem 1rem' }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0 small text-dark fs-14">
                  <em>{t('billing_info_invoice_modal_price')}:</em>
                </h6>
                <h6 className="mb-0 small text-success fs-6 ms-3 d-flex align-items-center">
                  <strong className="text-success">
                    <CountUp
                      decimals={2}
                      start={parseFloat(numberFormat(price))}
                      duration={0.5}
                      prefix={`${t('$')} `}
                      end={parseFloat(numberFormat(estPrice))}
                    />
                  </strong>
                </h6>
              </div>
            </div>
            <div className="p-2 border rounded shadow-sm mb-3">
              <div className="d-flex justify-content-center align-items-center">
                {horseWithoutBilling.map(h => {
                  const { image, name, id } = h.item;
                  return (
                    <div key={id} className="text-center me-2 mb-0">
                      <img
                        src={image}
                        className=" rounded-circle mb-1"
                        style={{ width: '32px' }}
                        alt={name}
                      />
                      <h6 className="fs-12 mb-0 horse-name">{name}</h6>
                    </div>
                  );
                })}
              </div>
            </div>

            {items.length > 0 && (
              <div className="invoice-generator">
                <div className="card shadow bg-white mb-3">
                  <div className="card-header bg-primary bg-gradient">
                    <h6 className="small mb-0 text-light">
                      <span className="fa fa-briefcase me-2"></span>
                      {t('item_label')}
                      {data.product.company && (
                        <span> ({data.product.company.name})</span>
                      )}
                    </h6>
                  </div>
                  <div className="card-body p-0">
                    {items.map((i, index) => {
                      const is_company_active = i.is_company_active;
                      if (!is_company_active) return null;
                      return (
                        <div
                          className={`spare-part p-2 ${
                            index > 0 ? 'border-top' : ''
                          }`}
                          key={index}
                        >
                          <div className="row gx-3 align-items-center">
                            <div className="col-4">
                              <h6 className=" fs-12 mb-1">
                                {i.name}
                                {i.unit && (
                                  <span className="text-muted fw-normal">
                                    <br />/ <em>{i.unit}</em>
                                  </span>
                                )}
                              </h6>
                              <h6 className="mb-0 fs-12 small">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={i.price}
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('exc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="mb-1 fs-12 small">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={
                                      i.vat > 0
                                        ? i.price + i.price * (i.vat / 100)
                                        : i.price
                                    }
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('inc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="text-muted fs-10 mb-0">
                                ({t('inventory_vat_label')} {i.vat}%)
                              </h6>
                            </div>
                            <div className="col-5">
                              <div className="text-center mb-0">
                                <div className="input-group input-group-sm flex-nowrap">
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => subtractQty(index, 0)}
                                  >
                                    <span className="fa fa-minus"></span>
                                  </button>
                                  <input
                                    type="number"
                                    id={`qty-${index}`}
                                    className="text-center form-control form-control-sm"
                                    min="0"
                                    placeholder="0"
                                    value={itemQty[index]}
                                    style={{ minWidth: '42px' }}
                                    onChange={e =>
                                      changeQty(index, e.target.value, 0)
                                    }
                                  />
                                  <button
                                    className="btn btn-sm btn-outline-success "
                                    onClick={() => addQty(index, 0)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <img
                                className="rounded shadow-sm"
                                src={i.image}
                                alt={i.name}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="card-footer p-2">
                    <div className="row g-2 ">
                      <div className="col-4">
                        {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                      </div>
                      <div className="col-8">
                        <div className="input-group input-group-sm align-items-center mb-0">
                          <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                            {t('discount_label')}
                          </span>
                          <input
                            type="number"
                            className="form-control text-center"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountItem}
                            onChange={e => {
                              if (e.target.value > 100) {
                                setState({ discountItem: 100 });
                              } else {
                                setState({ discountItem: e.target.value });
                              }
                            }}
                          />
                          <span className="input-group-text ">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {works.length > 0 && (
              <div className="invoice-generator">
                <div className="card shadow bg-white mb-3">
                  <div className="card-header bg-primary bg-gradient">
                    <h6 className="small mb-0 text-light">
                      <span className="fa fa-wrench me-2"></span>
                      {t('work_label')}
                      {data.product.company && (
                        <span> ({data.product.company.name})</span>
                      )}
                    </h6>
                  </div>
                  <div className="card-body p-0">
                    {works.map((i, index) => {
                      const is_company_active = i.is_company_active;
                      if (!is_company_active) return null;

                      return (
                        <div
                          className={`spare-part p-2 ${
                            index > 0 ? 'border-top' : ''
                          }`}
                          key={index}
                        >
                          <div className="row gx-3 align-items-center">
                            <div className="col-4">
                              <h6 className=" fs-12 mb-1">
                                {i.name}
                                {i.unit && (
                                  <span className="text-muted fw-normal">
                                    <br />/ <em>{i.unit}</em>
                                  </span>
                                )}
                              </h6>
                              <h6 className="mb-0 fs-12">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={i.price}
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('exc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="mb-1 fs-12">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={
                                      i.vat > 0
                                        ? i.price + i.price * (i.vat / 100)
                                        : i.price
                                    }
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('inc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="text-muted fs-10 mb-0">
                                ({t('inventory_vat_label')} {i.vat}%)
                              </h6>
                            </div>
                            <div className="col-5">
                              <div className="text-center mb-0">
                                {/* <label htmlFor="a" className="fs-12 mb-1 fw-bold">{t('qty')}:</label> */}

                                <div className="input-group flex-nowrap">
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => subtractQty(index, 1)}
                                  >
                                    <span className="fa fa-minus"></span>
                                  </button>
                                  <input
                                    type="number"
                                    id={`work-qty-${index}`}
                                    className="text-center form-control form-control-sm"
                                    min="0"
                                    placeholder="0"
                                    value={worksQty[index]}
                                    style={{ minWidth: '42px' }}
                                    onChange={e =>
                                      changeQty(index, e.target.value, 1)
                                    }
                                  />
                                  <button
                                    className="btn btn-outline-success btn-sm"
                                    onClick={() => addQty(index, 1)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <img
                                className="rounded shadow-sm"
                                src={i.image}
                                alt={i.name}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="card-footer p-2">
                    <div className="row g-2 ">
                      <div className="col-4">
                        {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                      </div>
                      <div className="col-8">
                        <div className="input-group input-group-sm align-items-center mb-0">
                          <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                            {t('discount_label')}
                          </span>
                          <input
                            type="number"
                            className="form-control text-center"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountWork}
                            onChange={e => {
                              if (e.target.value > 100) {
                                setState({ discountWork: 100 });
                              } else {
                                setState({ discountWork: e.target.value });
                              }
                            }}
                          />
                          <span className="input-group-text ">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="invoice-summary">
              <div className="card bg-white mb-3 shadow">
                <div className="card-header bg-secondary bg-gradient">
                  <h6 className="mb-0 text-light">{t('invoice_summary')}</h6>
                </div>
                <div className="card-body p-0">
                  <div className="table-container">
                    <table className="table mb-0 px-3 fs-12">
                      <thead className="table-light">
                        <tr>
                          {/* <th className="text-center">{t('invoice_no')}</th> */}
                          <th className="w-25">{t('invoice_items')}</th>
                          <th className="text-center lh-1">
                            {t('unit_price')}
                            <br />
                            <span className="fs-10 text-nowrap text-muted">
                              (0% {t('vat_label')})
                            </span>
                          </th>
                          <th className="text-center">{t('qty')}</th>
                          <th className="text-end lh-1">
                            {t('invoice_price')}
                            <br />
                            <span className="fs-10 text-nowrap text-muted">
                              (0% {t('vat_label')})
                            </span>
                          </th>
                          <th className="text-end lh-1">
                            {t('invoice_subtotal')}
                            <br />
                            <span className="fs-10 text-muted">
                              ({t('invoice_subtotal_info')})
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemQty.length > 0 &&
                          itemQty.map((ai, idx) => {
                            const item = items[idx];
                            if (ai === 0) return null;

                            const subtotal = item.price * ai;
                            const totalDiscount =
                              subtotal - subtotal * (discountItem / 100);
                            const total =
                              totalDiscount + totalDiscount * (item.vat / 100);

                            return (
                              <tr key={idx}>
                                <td className="lh-1 fw-bold">
                                  {item.name}
                                  <span className=" fs-10">
                                    &nbsp; ({t('vat_label')}: {item.vat}%)
                                  </span>
                                  {item.unit && (
                                    <span className="text-muted fs-12 fw-normal opacity-50">
                                      <br />/ <em>{item.unit}</em>
                                    </span>
                                  )}
                                </td>
                                <td className="text-center fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={item.price}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-center">{ai}</td>
                                <td className="text-end fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={subtotal}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end text-primary fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={total}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        {worksQty.length > 0 &&
                          worksQty.map((ai, idx) => {
                            const item = works[idx];
                            if (ai === 0) return null;

                            const subtotal = item.price * ai;
                            const totalDiscount =
                              subtotal - subtotal * (discountWork / 100);
                            const total =
                              totalDiscount + totalDiscount * (item.vat / 100);

                            return (
                              <tr key={idx}>
                                <td className="lh-1 fw-bold">
                                  {item.name}
                                  <span className=" fs-10">
                                    &nbsp; ({t('vat_label')}: {item.vat}%)
                                  </span>
                                  {item.unit && (
                                    <span className="text-muted fw-normal opacity-50">
                                      <br />
                                      <em>{item.unit}</em>
                                    </span>
                                  )}
                                </td>
                                <td className="text-center fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={item.price}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-center">{ai}</td>
                                <td className="text-end fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={subtotal}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end text-primary fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={total}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot className="">
                        <tr>
                          <th colSpan="4" className="text-end">
                            <h6 className="small mb-0 fw-bold">
                              {t('invoice_total')}
                            </h6>
                          </th>
                          <th className="text-end ">
                            <h6 className="mb-0 fw-bold text-success text-nowrap">
                              <CountUp
                                decimals={2}
                                start={parseFloat(numberFormat(price))}
                                duration={0.5}
                                prefix={`${t('$')} `}
                                end={parseFloat(numberFormat(estPrice))}
                              />
                            </h6>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="card-footer border-0 py-3">
                  <div className="btn-container">
                    {estPrice > 0 ? (
                      <button
                        className="btn w-100 btn-primary btn-sm py-2"
                        onClick={handleCombinedInvoice}
                      >
                        {t('billing_info_create_invoice_button')}
                      </button>
                    ) : (
                      <button
                        className="btn w-100 btn-secondary btn-sm py-2"
                        disabled
                      >
                        {t('billing_info_create_invoice_button')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-close close-reveal"
              onClick={() => setState({ openCombinedInvoice: false })}
            >
              &times;
            </button>
          </Modal>

          <Modal
            isOpen={isOpenInvoice}
            contentLabel="Modal"
            className="reveal full p-2 py-3"
            ariaHideApp={false}
          >
            <h6 className="fs-14 text-primary">
              {t('billing_info_invoice_modal_heading')}
            </h6>

            <div
              className="alert-warning p-2 px-3 position-sticky sticky-top shadow"
              style={{ margin: '0 -.5rem 1rem' }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0 small text-dark fs-14">
                  <em>{t('billing_info_invoice_modal_price')}:</em>
                </h6>
                <h6 className="mb-0 small text-success fs-6 ms-3 d-flex align-items-center">
                  <strong className="text-success">
                    <CountUp
                      decimals={2}
                      start={parseFloat(numberFormat(price))}
                      duration={0.5}
                      prefix={`${t('$')} `}
                      end={parseFloat(numberFormat(estPrice))}
                    />
                  </strong>
                </h6>
              </div>
            </div>
            <div className="p-2 border rounded shadow-sm mb-3">
              <div className="row g-2">
                <div className="col-3">
                  <img
                    src={horseWithBilling[openInvoiceId].item.image}
                    className="rounded"
                    alt={horseWithBilling[openInvoiceId].item.image}
                  />
                </div>
                <div className="col-9">
                  <h6 className="fs-14 fw-bold mb-1">
                    {horseWithBilling[openInvoiceId].item.name}
                  </h6>
                  <div className="alert alert-warning p-2 mb-0">
                    <p className="mb-0 fs-12">
                      {t('billing_info_email')}:{' '}
                      {horseWithBilling[openInvoiceId].item.billing_email}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {items.length > 0 && (
              <div className="invoice-generator">
                <div className="card shadow bg-white mb-3">
                  <div className="card-header bg-primary bg-gradient">
                    <h6 className="small mb-0 text-light">
                      <span className="fa fa-briefcase me-2"></span>
                      {t('item_label')}
                      {data.product.company && (
                        <span> ({data.product.company.name})</span>
                      )}
                    </h6>
                  </div>
                  <div className="card-body p-0">
                    {items.map((i, index) => {
                      const is_company_active = i.is_company_active;
                      if (!is_company_active) return null;
                      return (
                        <div
                          className={`spare-part p-2 ${
                            index > 0 ? 'border-top' : ''
                          }`}
                          key={index}
                        >
                          <div className="row gx-3 align-items-center">
                            <div className="col-4">
                              <h6 className=" fs-12 mb-1">
                                {i.name}
                                {i.unit && (
                                  <span className="text-muted fw-normal">
                                    <br />/ <em>{i.unit}</em>
                                  </span>
                                )}
                              </h6>
                              <h6 className="mb-0 fs-12 small">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={i.price}
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('exc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="mb-1 fs-12 small">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={
                                      i.vat > 0
                                        ? i.price + i.price * (i.vat / 100)
                                        : i.price
                                    }
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('inc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="text-muted fs-10 mb-0">
                                ({t('inventory_vat_label')} {i.vat}%)
                              </h6>
                            </div>
                            <div className="col-5">
                              <div className="text-center mb-0">
                                {/* <label htmlFor="a" className="fs-12 mb-1 fw-bold">{t('qty')}:</label> */}

                                <div className="input-group input-group-sm flex-nowrap">
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => subtractQty(index, 0)}
                                  >
                                    <span className="fa fa-minus"></span>
                                  </button>
                                  <input
                                    type="number"
                                    id={`qty-${index}`}
                                    className="text-center form-control form-control-sm"
                                    min="0"
                                    placeholder="0"
                                    value={itemQty[index]}
                                    style={{ minWidth: '42px' }}
                                    onChange={e =>
                                      changeQty(index, e.target.value, 0)
                                    }
                                  />
                                  <button
                                    className="btn btn-sm btn-outline-success "
                                    onClick={() => addQty(index, 0)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <img
                                className="rounded shadow-sm"
                                src={i.image}
                                alt={i.name}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="card-footer p-2">
                    <div className="row g-2 ">
                      <div className="col-4">
                        {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                      </div>
                      <div className="col-8">
                        <div className="input-group input-group-sm align-items-center mb-0">
                          <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                            {t('discount_label')}
                          </span>
                          <input
                            type="number"
                            className="form-control text-center"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountItem}
                            onChange={e => {
                              if (e.target.value > 100) {
                                setState({ discountItem: 100 });
                              } else {
                                setState({ discountItem: e.target.value });
                              }
                            }}
                          />
                          <span className="input-group-text ">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {works.length > 0 && (
              <div className="invoice-generator">
                <div className="card shadow bg-white mb-3">
                  <div className="card-header bg-primary bg-gradient">
                    <h6 className="small mb-0 text-light">
                      <span className="fa fa-wrench me-2"></span>
                      {t('work_label')}
                      {data.product.company && (
                        <span> ({data.product.company.name})</span>
                      )}
                    </h6>
                  </div>
                  <div className="card-body p-0">
                    {works.map((i, index) => {
                      const is_company_active = i.is_company_active;
                      if (!is_company_active) return null;

                      return (
                        <div
                          className={`spare-part p-2 ${
                            index > 0 ? 'border-top' : ''
                          }`}
                          key={index}
                        >
                          <div className="row gx-3 align-items-center">
                            <div className="col-4">
                              <h6 className=" fs-12 mb-1">
                                {i.name}
                                {i.unit && (
                                  <span className="text-muted fw-normal">
                                    <br />/ <em>{i.unit}</em>
                                  </span>
                                )}
                              </h6>
                              <h6 className="mb-0 fs-12">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={i.price}
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('exc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="mb-1 fs-12">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={
                                      i.vat > 0
                                        ? i.price + i.price * (i.vat / 100)
                                        : i.price
                                    }
                                  />
                                </strong>{' '}
                                <span className="text-muted">
                                  ({t('inc_vat_label')})
                                </span>
                              </h6>
                              <h6 className="text-muted fs-10 mb-0">
                                ({t('inventory_vat_label')} {i.vat}%)
                              </h6>
                            </div>
                            <div className="col-5">
                              <div className="text-center mb-0">
                                {/* <label htmlFor="a" className="fs-12 mb-1 fw-bold">{t('qty')}:</label> */}

                                <div className="input-group flex-nowrap">
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => subtractQty(index, 1)}
                                  >
                                    <span className="fa fa-minus"></span>
                                  </button>
                                  <input
                                    type="number"
                                    id={`work-qty-${index}`}
                                    className="text-center form-control form-control-sm"
                                    min="0"
                                    placeholder="0"
                                    value={worksQty[index]}
                                    style={{ minWidth: '42px' }}
                                    onChange={e =>
                                      changeQty(index, e.target.value, 1)
                                    }
                                  />
                                  <button
                                    className="btn btn-outline-success btn-sm"
                                    onClick={() => addQty(index, 1)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <img
                                className="rounded shadow-sm"
                                src={i.image}
                                alt={i.name}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="card-footer p-2">
                    <div className="row g-2 ">
                      <div className="col-4">
                        {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                      </div>
                      <div className="col-8">
                        <div className="input-group input-group-sm align-items-center mb-0">
                          <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                            {t('discount_label')}
                          </span>
                          <input
                            type="number"
                            className="form-control text-center"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountWork}
                            onChange={e => {
                              if (e.target.value > 100) {
                                setState({ discountWork: 100 });
                              } else {
                                setState({ discountWork: e.target.value });
                              }
                            }}
                          />
                          <span className="input-group-text ">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="invoice-summary">
              <div className="card bg-white mb-3 shadow">
                <div className="card-header bg-secondary bg-gradient">
                  <h6 className="mb-0 text-light">{t('invoice_summary')}</h6>
                </div>
                <div className="card-body p-0">
                  <div className="table-container">
                    <table className="table mb-0 px-3 fs-12">
                      <thead className="table-light">
                        <tr>
                          {/* <th className="text-center">{t('invoice_no')}</th> */}
                          <th className="w-25">{t('invoice_items')}</th>
                          <th className="text-center lh-1">
                            {t('unit_price')}
                            <br />
                            <span className="fs-10 text-nowrap text-muted">
                              (0% {t('vat_label')})
                            </span>
                          </th>
                          <th className="text-center">{t('qty')}</th>
                          <th className="text-end lh-1">
                            {t('invoice_price')}
                            <br />
                            <span className="fs-10 text-nowrap text-muted">
                              (0% {t('vat_label')})
                            </span>
                          </th>
                          <th className="text-end lh-1">
                            {t('invoice_subtotal')}
                            <br />
                            <span className="fs-10 text-muted">
                              ({t('invoice_subtotal_info')})
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr className="fw-bold fs-6">
                              <td className="text-center">1</td>
                              <td>{data.product.name}</td>
                              <td className="text-center ">1</td>
                              <td className="text-end text-primary">{transaction_request[0].price}</td>
                            </tr> */}
                        {itemQty.length > 0 &&
                          itemQty.map((ai, idx) => {
                            const item = items[idx];
                            if (ai === 0) return null;

                            const subtotal = item.price * ai;
                            const totalDiscount =
                              subtotal - subtotal * (discountItem / 100);
                            const total =
                              totalDiscount + totalDiscount * (item.vat / 100);

                            return (
                              <tr key={idx}>
                                <td className="lh-1 fw-bold">
                                  {item.name}
                                  <span className=" fs-10">
                                    &nbsp; ({t('vat_label')}: {item.vat}%)
                                  </span>
                                  {item.unit && (
                                    <span className="text-muted fs-12 fw-normal opacity-50">
                                      <br />/ <em>{item.unit}</em>
                                    </span>
                                  )}
                                </td>
                                <td className="text-center fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={item.price}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-center">{ai}</td>
                                <td className="text-end fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={subtotal}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end text-primary fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={total}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        {worksQty.length > 0 &&
                          worksQty.map((ai, idx) => {
                            const item = works[idx];
                            if (ai === 0) return null;

                            const subtotal = item.price * ai;
                            const totalDiscount =
                              subtotal - subtotal * (discountWork / 100);
                            const total =
                              totalDiscount + totalDiscount * (item.vat / 100);

                            return (
                              <tr key={idx}>
                                <td className="lh-1 fw-bold">
                                  {item.name}
                                  <span className=" fs-10">
                                    &nbsp; ({t('vat_label')}: {item.vat}%)
                                  </span>
                                  {item.unit && (
                                    <span className="text-muted fw-normal opacity-50">
                                      <br />
                                      <em>{item.unit}</em>
                                    </span>
                                  )}
                                </td>
                                <td className="text-center fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={item.price}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-center">{ai}</td>
                                <td className="text-end fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={subtotal}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end text-primary fw-bold">
                                  {t('$')}
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    value={total}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot className="">
                        <tr>
                          <th colSpan="4" className="text-end">
                            <h6 className="small mb-0 fw-bold">
                              {t('invoice_total')}
                            </h6>
                          </th>
                          <th className="text-end ">
                            <h6 className="mb-0 fw-bold text-success text-nowrap">
                              <CountUp
                                decimals={2}
                                start={parseFloat(numberFormat(price))}
                                duration={0.5}
                                prefix={`${t('$')} `}
                                end={parseFloat(numberFormat(estPrice))}
                              />
                            </h6>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="card-footer border-0 py-3">
                  <div className="btn-container">
                    {estPrice > 0 ? (
                      <button
                        className="btn w-100 btn-primary btn-sm py-2"
                        onClick={handleHorseInvoice}
                      >
                        {t('billing_info_create_invoice_button')}
                      </button>
                    ) : (
                      <button
                        className="btn w-100 btn-secondary btn-sm py-2"
                        disabled
                      >
                        {t('billing_info_create_invoice_button')}
                      </button>
                    )}
                    {/* <button className="btn w-100 btn-sm py-2" onClick={createInvoicePdf}>{t('create_invoice')}</button> */}
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-close close-reveal"
              onClick={() => setState({ isOpenInvoice: false })}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container pb-0">
      <SubHeader
        title={t('create_order_summary')}
        go={`/seller/order/${orderId}`}
      />

      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              {isPro && (
                <div className="position-relative p-3 rounded-3 mb-3 overflow-hidden d-flex align-items-center bg-gold-100">
                  <ProBadge classes="pro-badge--invert me-2 mb-1" size={16} />
                  <h6 className="mb-0 fw-bold fs-14">
                    {t('this_is_pro_order')}
                  </h6>
                </div>
              )}

              <div className="invoice-container">
                <h6 className="fw-bold mb-4">
                  {t('order_text')} #{orderId}
                </h6>

                <h6 className="fs-14 fw-bold mb-2">{t('send_invoice_to')}</h6>
                {hasHorseBilling ? (
                  <h6 className="mb-4 text-muted fw-bold">
                    {horseWithBilling[0].item.billing_email}
                  </h6>
                ) : (
                  <h6 className="mb-4 text-muted fw-bold">{data.user.email}</h6>
                )}

                <div className="order-summary mb-4">
                  <h6 className="fw-bold mb-3">{t('product_label')}</h6>
                  <ProductBox product={data.product} price={quotedPrice} />
                </div>

                <div className="mb-4">
                  <h6 className="fw-bold mb-3">{t('horse_label')}</h6>
                  {transaction_items.map(horse => (
                    <div key={horse.id} className="mb-1">
                      <HorseBox horse={horse.item} />
                    </div>
                  ))}
                </div>

                <div className="summary-photo">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="fs-6 fw-bold mb-0">
                      {t('service_documentation_heading')}
                    </h3>
                    <label
                      className="btn fw-bold text-primary btn-sm position-relative"
                      htmlFor="img"
                      style={{ textTransform: 'none' }}
                    >
                      <input
                        type="file"
                        id="img"
                        accept="image/*"
                        value={summaryPhoto}
                        onChange={onDropGallery}
                        className="fs-6 small w-auto"
                        style={{
                          maxWidth: '60px',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          bottom: '0',
                          opacity: 0,
                        }}
                      />
                      <span>{t('add_button')}</span>
                    </label>
                  </div>

                  {summaryGallery.length > 0 ? (
                    <div className="mb-4 position-relative">
                      <div className="row g-2">
                        {summaryGallery.map((gallery, index) => (
                          <div className="position-relative col-6" key={index}>
                            <img
                              src={gallery}
                              alt="summary"
                              className="w-100"
                            />
                            <button
                              className="position-absolute end-0 top-0 btn p-0 me-n1 mt-n1 w-32 h-32 lh-32 rounded-circle border-1 bg-white border-danger text-danger fs-5"
                              onClick={() => deleteGallery(gallery)}
                            >
                              <span className="fa fa-times"></span>
                            </button>
                          </div>
                        ))}
                        {uploadGallery && (
                          <div className="col-6 text-center">
                            <Loader size="16" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <h6 className="fs-14 text-muted mb-4">
                      {t('no_summary_photo')}
                    </h6>
                  )}
                </div>

                <div className="summary-text">
                  <label htmlFor="notes">{t('notes')}</label>
                  <textarea
                    name="notes"
                    id="notes"
                    rows="3"
                    className="form-control mb-5"
                    placeholder={t('write_a_note_label')}
                    value={notes}
                    onChange={e => setState({ notes: e.target.value })}
                  ></textarea>
                </div>

                {items.length > 0 && (
                  <div className="invoice-generator mb-4">
                    <div className="">
                      <h6 className="fw-bold mb-2">
                        {t('item_label')}
                        {data.product.company && (
                          <span className="text-muted">
                            {' '}
                            ({data.product.company.name})
                          </span>
                        )}
                      </h6>

                      <div className="item-container">
                        {items.map((i, index) => {
                          const is_company_active = i.is_company_active;
                          if (!is_company_active) return null;
                          return (
                            <div
                              className={`d-flex justify-content-between py-3 ${
                                index > 0 ? 'border-top' : ''
                              }`}
                              key={index}
                            >
                              <div className="spare-part-content d-flex flex-1">
                                <img
                                  className="rounded-3 w-40 h-40 me-3"
                                  src={i.image}
                                  alt={i.name}
                                />
                                <div className="flex-1">
                                  <h6 className=" fw-bold mb-1">
                                    {i.name}
                                    {i.unit && (
                                      <span className="text-muted fs-12">
                                        <br />/ {i.unit}
                                      </span>
                                    )}
                                  </h6>
                                  <h6 className="fw-bold fs-12 mb-0 text-muted">
                                    {t('$')}{' '}
                                    <strong className="">
                                      <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        value={i.price}
                                      />
                                    </strong>{' '}
                                    <span className="fs-10">
                                      ({t('exc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="fw-bold fs-12 mb-1">
                                    {t('$')}{' '}
                                    <strong className="">
                                      <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        value={
                                          i.vat > 0
                                            ? i.price + i.price * (i.vat / 100)
                                            : i.price
                                        }
                                      />
                                    </strong>{' '}
                                    <span className="fs-10">
                                      ({t('inc_vat_label')})
                                    </span>
                                  </h6>

                                  <h6 className="text-info fw-bold fs-12 mb-0">
                                    ({t('inventory_vat_label')} {i.vat}%)
                                  </h6>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <div className="input-group d-flex align-items-center justify-content-end flex-nowrap">
                                  {itemQty[index] === 0 ? (
                                    <button
                                      className="btn btn-outline-secondary btn-disabled rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                      disabled
                                    >
                                      <span className="fa fa-minus"></span>
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                      onClick={() => subtractQty(index, 0)}
                                    >
                                      <span className="fa fa-minus"></span>
                                    </button>
                                  )}
                                  <h6 className="mx-2 w-32 text-center fw-bold mb-0">
                                    {itemQty[index]}
                                  </h6>

                                  <button
                                    className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                    onClick={() => addQty(index, 0)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="py-3 border-top">
                        <div className="d-flex align-items-center justify-content-end">
                          <h6 className="mb-0 fw-bold">
                            {t('item_discount_label')}
                          </h6>
                          <input
                            type="number"
                            className="form-control text-center mx-2 w-60"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountItem}
                            onChange={e => {
                              if (e.target.valueAsNumber > 100) {
                                setState({ discountItem: 100 });
                              } else {
                                setState({
                                  discountItem: e.target.valueAsNumber,
                                });
                              }
                            }}
                          />
                          <h6 className="fw-bold mb-0 ">%</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {works.length > 0 && (
                  <div className="invoice-generator mb-4">
                    <div className="">
                      <h6 className="fw-bold mb-2">
                        {t('work_label')}
                        {data.product.company && (
                          <span className="text-muted">
                            {' '}
                            ({data.product.company.name})
                          </span>
                        )}
                      </h6>

                      <div className="item-container">
                        {works.map((i, index) => {
                          const is_company_active = i.is_company_active;
                          if (!is_company_active) return null;
                          return (
                            <div
                              className={`d-flex justify-content-between py-3 ${
                                index > 0 ? 'border-top' : ''
                              }`}
                              key={index}
                            >
                              <div className="spare-part-content d-flex flex-1">
                                <img
                                  className="rounded-3 w-40 h-40 me-3"
                                  src={i.image}
                                  alt={i.name}
                                />
                                <div className="flex-1">
                                  <h6 className=" fw-bold mb-1">
                                    {i.name}
                                    {i.unit && (
                                      <span className="text-muted fs-12">
                                        <br />/ {i.unit}
                                      </span>
                                    )}
                                  </h6>
                                  <h6 className="fw-bold fs-12 mb-0 text-muted">
                                    {t('$')}{' '}
                                    <strong className="">
                                      <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        value={i.price}
                                      />
                                    </strong>{' '}
                                    <span className="fs-10">
                                      ({t('exc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="fw-bold fs-12 mb-1">
                                    {t('$')}{' '}
                                    <strong className="">
                                      <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        value={
                                          i.vat > 0
                                            ? i.price + i.price * (i.vat / 100)
                                            : i.price
                                        }
                                      />
                                    </strong>{' '}
                                    <span className="fs-10">
                                      ({t('inc_vat_label')})
                                    </span>
                                  </h6>

                                  <h6 className="text-info fw-bold fs-12 mb-0">
                                    ({t('inventory_vat_label')} {i.vat}%)
                                  </h6>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <div className="input-group d-flex align-items-center justify-content-end flex-nowrap">
                                  {worksQty[index] === 0 ? (
                                    <button
                                      className="btn btn-outline-secondary btn-disabled rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                      disabled
                                    >
                                      <span className="fa fa-minus"></span>
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                      onClick={() => subtractQty(index, 1)}
                                    >
                                      <span className="fa fa-minus"></span>
                                    </button>
                                  )}
                                  <h6 className="mx-2 w-32 text-center fw-bold mb-0">
                                    {worksQty[index]}
                                  </h6>

                                  <button
                                    className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                    onClick={() => addQty(index, 1)}
                                  >
                                    <span className="fa fa-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="py-3 border-top">
                        <div className="d-flex align-items-center justify-content-end">
                          <h6 className="mb-0 fw-bold">
                            {t('work_discount_label')}
                          </h6>
                          <input
                            type="number"
                            className="form-control text-center mx-2 w-60"
                            placeholder="0"
                            min="0"
                            max="100"
                            pattern="\d*"
                            value={discountWork}
                            onChange={e => {
                              if (e.target.valueAsNumber > 100) {
                                setState({ discountWork: 100 });
                              } else {
                                setState({
                                  discountWork: e.target.valueAsNumber,
                                });
                              }
                            }}
                          />
                          <h6 className="fw-bold mb-0 ">%</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="invoice-summary">
                  <div className="mb-4">
                    <h6 className="fw-bold mb-3">{t('invoice_summary')}</h6>

                    <div className="">
                      <div className="table-container">
                        <table className="table mb-0 px-3 fs-12 table-bordered table-striped">
                          <tbody>
                            <tr>
                              {/* <th className="text-center">{t('invoice_no')}</th> */}
                              <th className="w-25">{t('invoice_items')}</th>
                              <th className="text-center lh-1">
                                {t('unit_price')}
                                <br />
                                <span className="fs-10 text-nowrap text-muted">
                                  (0% {t('vat_label')})
                                </span>
                              </th>
                              <th className="text-center">{t('qty')}</th>
                              <th className="text-end lh-1">
                                {t('invoice_price')}
                                <br />
                                <span className="fs-10 text-nowrap text-muted">
                                  (0% {t('vat_label')})
                                </span>
                              </th>
                              <th className="text-end lh-1">
                                {t('invoice_subtotal')}
                                <br />
                                <span className="fs-10 text-muted">
                                  ({t('invoice_subtotal_info')})
                                </span>
                              </th>
                            </tr>
                            {itemQty.length > 0 &&
                              itemQty.map((ai, idx) => {
                                const item = items[idx];
                                if (ai === 0) return null;

                                const subtotal = item.price * ai;
                                const totalDiscount =
                                  subtotal - subtotal * (discountItem / 100);
                                const total =
                                  totalDiscount +
                                  totalDiscount * (item.vat / 100);

                                return (
                                  <tr key={idx}>
                                    <td className="lh-1 fw-bold">
                                      {item.name}
                                      <span className=" fs-10">
                                        &nbsp; ({t('vat_label')}: {item.vat}%)
                                      </span>
                                      {item.unit && (
                                        <span className="text-muted fs-12 fw-normal opacity-50">
                                          <br />/ <em>{item.unit}</em>
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={item.price}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                    <td className="text-center">{ai}</td>
                                    <td className="text-end fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={subtotal}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                    <td className="text-end text-primary fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={total}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            {worksQty.length > 0 &&
                              worksQty.map((ai, idx) => {
                                const item = works[idx];
                                if (ai === 0) return null;

                                const subtotal = item.price * ai;
                                const totalDiscount =
                                  subtotal - subtotal * (discountWork / 100);
                                const total =
                                  totalDiscount +
                                  totalDiscount * (item.vat / 100);

                                return (
                                  <tr key={idx}>
                                    <td className="lh-1 fw-bold">
                                      {item.name}
                                      <span className=" fs-10">
                                        &nbsp; ({t('vat_label')}: {item.vat}%)
                                      </span>
                                      {item.unit && (
                                        <span className="text-muted fw-normal opacity-50">
                                          <br />
                                          <em>{item.unit}</em>
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={item.price}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                    <td className="text-center">{ai}</td>
                                    <td className="text-end fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={subtotal}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                    <td className="text-end text-primary fw-bold">
                                      {t('$')}
                                      <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={total}
                                        fixedDecimalScale={true}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot className="">
                            <tr>
                              <th colSpan="4" className="text-end">
                                <h6 className="small mb-0 fw-bold">
                                  {t('invoice_total')}
                                </h6>
                              </th>
                              <th className="text-end ">
                                <h6 className="mb-0 fw-bold text-success text-nowrap">
                                  <CountUp
                                    decimals={2}
                                    start={parseFloat(numberFormat(price))}
                                    duration={0.5}
                                    prefix={`${t('$')} `}
                                    end={parseFloat(numberFormat(estPrice))}
                                  />
                                </h6>
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="p-3 border-top sticky-bottom bottom-0"
        style={{
          background: 'rgba(255,255,255,.85)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <div className="d-flex justify-content-between mb-3 align-items-center">
          <h6 className="fw-bold mb-0">{t('final_price')}:</h6>
          <h6 className="mb-0 fw-bold text-primary ms-3 d-flex align-items-center">
            <strong className="">
              <CountUp
                decimals={2}
                start={parseFloat(numberFormat(price))}
                duration={0.5}
                prefix={`${t('$')} `}
                end={parseFloat(numberFormat(estPrice))}
              />
            </strong>
          </h6>
        </div>
        {estPrice > 0 ? (
          <button
            className="btn w-100 btn-primary"
            onClick={() => setState({ isOpen: true })}
          >
            {t('create_summary_invoice')}
          </button>
        ) : (
          <button className="btn w-100 btn-secondary" disabled>
            {t('create_summary_invoice')}
          </button>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => (isSubmit ? null : setState({ isOpen: false }))}
        contentLabel="Modal"
        className="reveal small p-3"
        ariaHideApp={false}
      >
        <h6 className="fw-bold mb-3">{t('send_final_invoice')}</h6>
        <p className="text-muted">{t('invoice_warning_text')}</p>
        <div className="alert alert-secondary border-0 mb-3">
          <p className="mb-0">{t('invoice_alert')}</p>
        </div>

        <div className="btn-container d-flex justify-content-center">
          <button
            className="btn w-50 btn-outline-secondary"
            onClick={() => (isSubmit ? null : setState({ isOpen: false }))}
          >
            {t('cancel_button')}
          </button>
          {isSubmit ? (
            <button className="btn w-50 btn-primary ms-3 btn-disabled" disabled>
              {t('send_invoice_button')}
            </button>
          ) : (
            <button
              className="btn w-50 btn-primary ms-3"
              onClick={submitSummary}
            >
              {t('send_invoice_button')}
            </button>
          )}
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => (isSubmit ? null : setState({ isOpen: false }))}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}

export default Quotation;
