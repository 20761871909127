import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Page from '../../components/global/Page';
import { useSelector } from 'react-redux';

function Home() {
  const { t } = useTranslation();
  const { user, role } = useSelector(state => state.auth);
  const { username, name } = user || {};

  return (
    <div className="app-container">
      <Helmet>
        <title>{t('home_page')} | Luokki</title>
      </Helmet>

      <div className="main-content py-5 px-3 text-center ">
        <Page>
          <h4>{t('welcome_heading')}</h4>
          {!user ? (
            <div>
              <p>{t('welcome_text')}</p>
              <div className="row mb-3">
                {/* <div className="col col-sm-6">
                  <Link
                    to="/register-seller"
                    className="btn btn-lg bg-gradient btn-primary lh-sm w-100"
                  >
                    <span className="text">
                      <small>{t('be_a')}</small>
                      <br />
                      <strong>{t('seller')}</strong>
                    </span>
                  </Link>
                </div> */}
                <div className="col col-sm-12">
                  <Link
                    to="/register-buyer"
                    className="btn btn-outline-primary bg-gradient btn-lg lh-sm w-100"
                  >
                    <span className="text">
                      <strong>{t('sign_up_button')}</strong>
                    </span>
                  </Link>
                </div>
              </div>
              <p className="mt-4 mb-3">{t('welcome_login_heading')}</p>
              <p>
                <Link
                  to="/login"
                  className="btn btn-lg shadow btn-primary bg-gradient text-uppercase w-100"
                >
                  {t('login_button')}{' '}
                  <span className="fa fa-sign-in ms-2"></span>
                </Link>
              </p>
              {/* <p>{t('welcome_explore_heading')}</p>
              <p><Link to="/explore" className="btn shadow btn-secondary bg-gradient text-uppercase w-100">{t('explore_button')} <span className="fa fa-search ms-2"></span></Link></p> */}
            </div>
          ) : (
            <div className="mt-5">
              {user.photo && (
                <div className="text-center">
                  <p>
                    <img
                      src={user.photo}
                      alt={name && name !== '' ? name : username}
                      className="img-thumbnail rounded-circle"
                      width="80"
                    />
                  </p>
                </div>
              )}
              <p>
                {t('welcome_hi')} {name && name !== '' ? name : username},{' '}
                <br />
                {t('welcome_logged_in_text')}
              </p>
              {role === 'seller' ? (
                <p>
                  <Link
                    to="/seller"
                    className="btn btn-lg shadow bg-gradient btn-primary w-100"
                  >
                    {t('go_to_dashboard')}{' '}
                    <span className="fa fa-dashboard ms-2"></span>
                  </Link>
                </p>
              ) : (
                <p>
                  <Link
                    to="/explore"
                    className="btn btn-lg shadow bg-gradient btn-primary text-uppercase w-100"
                  >
                    {t('explore_button')}{' '}
                    <span className="fa fa-search ms-2"></span>
                  </Link>
                </p>
              )}
            </div>
          )}
        </Page>
      </div>
      {/* <div className="onboarding py-5 px-3 text-center  bg-light bg-gradient">
        <Page>
          <h5>New to Luokki?</h5>
          <p>Follow this guidance to get you started</p>
          <a href="#get-started" className="btn shadow-sm bg-gradient btn-warning w-100">Get Started</a>
        </Page>
      </div> */}
      {/* <MainMenu /> */}
    </div>
  );
}

export default Home;
