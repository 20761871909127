import { useTranslation } from 'react-i18next';

export default function LoadMoreButton({
  isLoadMore = false,
  loadMore = false,
  text = null,
}) {
  const { t } = useTranslation();
  return (
    <div className="loadmore text-center p-3 mt-3">
      <button
        tabIndex="0"
        className={`btn btn-load-more btn-sm bg-gradient ${
          isLoadMore ? 'is-loading btn-secondary disabled' : 'btn-success'
        }`}
        onClick={isLoadMore ? null : loadMore}
      >
        <span className="loadmore-icon">
          <span className="ci ci-spin ci-loader" />
        </span>
        {text ? (
          <span className="text">{text}</span>
        ) : (
          <span className="text">{t('load_more')}</span>
        )}
      </button>
    </div>
  );
}
