import React, { useState, useEffect, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// import DateTimePicker from 'react-datetime-picker'
import Moment from 'react-moment';
import moment from 'moment';
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';
import { addNotif } from '../../redux/slices/appSlice';
import NumberFormat from 'react-number-format';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import fi from 'date-fns/locale/fi';

import {
  getTransactionCalendar,
  getTransactionDetail,
  addTransactionBid,
  updateTransactionStatus,
  createInvoice,
  sendInvoiceEmail,
  updateCalendar,
} from '../../libs/apis';
import { numberFormat } from '../../libs/utils';
import Loader from './Loader';
import WidgetSummary from './WidgetSummary';
import HorseSummary from './HorseSummary';
import ProBadge from './ProBadge';
import ProductBox from './ProductBox';
import HorseBox from './HorseBox';
import WidgetUser from './WidgetUser';

registerLocale('fi', fi);

export default function WidgetOrder({ orderId }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? (i18n.language === 'en-US' ? 'en' : i18n.language) : 'en';
  moment.locale(lang);
  const { user, role } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const isSeller = role === 'seller' || false;
  const today = new Date();
  const newDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    7,
    0,
    0,
  );

  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));

  // const types = [
  //   {
  //     typeName: 'type_normal',
  //     typeColor: 'secondary',
  //     orderName: 'order_normal',
  //   },
  //   {
  //     typeName: 'type_opendate',
  //     typeColor: 'secondary',
  //     orderName: 'order_opendate',
  //   },
  //   {
  //     typeName: 'type_continuous',
  //     typeColor: 'warning',
  //     orderName: 'order_continuous',
  //   },
  //   {
  //     typeName: 'type_emergency',
  //     typeColor: 'danger',
  //     orderName: 'order_emergency',
  //   },
  // ];

  const initialState = {
    data: null,
    intervalError: false,
    isLoading: true,
    isError: false,
    message: '',
    orderStatus: null,
    isOpen: false,
    action: 0,
    notes: '',
    date: newDate,
    // time: moment(newDate).format('HH:mm'),
    // newDatetime: moment(newDate).format('YYYY-MM-DD HH:mm'),
    price: 0,
    isRefreshing: false,
    isDetailOpen: false,
    isMapOpen: false,
    hideProduct: true,
    hideBuyer: true,
    hideHorse: true,
    isBidding: false,
    isConfirm: false,
    isCancel: false,
    isUpdate: false,
    invoiceFile: null,
    isEmailSuccess: false,
    isOpenDiscontinue: false,
    isOpenInterval: false,
    isActive: true,
    currentInterval: 7,
    numInterval: 1,
    cycle: 7,
    calendarData: null,
    isChangeOrder: false,
    isRecurringOpen: false,
    cancelReason: 'cancel_reason_need_to_change',
    cancelReasonText: '',
    isConfirmPayment: false,
    isComplete: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isBidding,
    isError,
    message,
    notes,
    price,
    isRefreshing,
    isMapOpen,
    date,
    isConfirm,
    isCancel,
    cancelReason,
    cancelReasonText,
    invoiceFile,
    isEmailSuccess,
    isOpenDiscontinue,
    isOpenInterval,
    isActive,
    isUpdate,
    currentInterval,
    numInterval,
    cycle,
    calendarData,
    isChangeOrder,
    isConfirmPayment,
    isComplete,
  } = state;

  const paymentStatus = 5;
  const completeStatus = 6;

  useEffect(() => {
    const getInvoice = async () => {
      const payload = {
        id: orderId,
      };
      await createInvoice(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ invoiceFile: resp.data.response.path || null });
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getCalendarDetail = async () => {
      const payload = {
        transaction_id: orderId,
      };
      await getTransactionCalendar(payload).then(resp => {
        if (resp.status === 200) {
          setState({ calendarData: resp.data.response });
        }
      });
    };
    const getProduct = async () => {
      const payload = {
        id: orderId,
      };
      await getTransactionDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
              const lb = resp.data.response.transaction_request[0];
              setState({
                price: lb.price,
                date: new Date(moment(lb.datetime).format()),
                isActive: resp.data.response.is_active,
                currentInterval: resp.data.response.interval,
                numInterval: resp.data.response.interval / 7,
              });
              if (lb.status > 3) {
                getInvoice();
              }
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProduct();
    getCalendarDetail();
  }, [orderId]);

  if (!user) return null;
  if (!orderId) return <Redirect to="/404" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );

  const refreshStatus = async () => {
    setState({ isRefreshing: true });
    const payload = {
      id: orderId,
    };
    await getTransactionDetail(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ data: resp.data.response });
            const lb = resp.data.response.transaction_request;
            setState({
              price: lb[0].price,
              date: new Date(moment(lb[0].datetime).format()),
            });
          }
        } else {
          setState({
            isError: true,
            message: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => setState({ isRefreshing: false }));
  };

  const {
    product,
    transaction_request,
    transaction_items,
    type,
    confirmation_date,
    prev_order,
    next_order,
    interval,
    summary_photo,
    summary_text,
  } = data;
  const { company = null } = product;

  const bids = transaction_request;

  const lastBid = bids[0];
  const lastStatus = lastBid.status;

  const confirmed = lastStatus === 3;
  const invoice = lastStatus === 4;
  const paymentConfirm = lastStatus === 5;
  const completed = lastStatus === 6;
  const cancelled = lastStatus === 7;

  const todayTime = today.getTime();
  const deliver = new Date(
    moment(lastBid.datetime).format('MM/DD/YYYY'),
  ).getTime();
  const delivered = todayTime >= deliver;

  const hasMonthlyBilling =
    data.customer && data.customer.is_monthly_billing_active;

  const stats = [
    {
      status: prev_order ? 'new_continuous_order_status' : 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-success',
      background: 'bg-primary',
      border: 'border-primary',
      alert: 'alert-primary',
    },
    {
      status: hasMonthlyBilling
        ? 'monthly_billing_status'
        : 'invoice_sent_status',
      color: 'text-primary',
      background: 'bg-primary',
      border: 'border-primary',
      alert: 'alert-primary',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-primary',
      background: 'bg-primary',
      border: 'border-primary',
      alert: 'alert-primary',
    },
    {
      status: 'completed_status',
      color: 'text-primary',
      background: 'bg-primary',
      border: 'border-primary',
      alert: 'alert-primary',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  const stat = lastStatus - 1;

  const processOrder = async stat => {
    setState({ isBidding: true });
    let orderNotes = notes;
    if (stat === 7) {
      if (cancelReason === 'cancel_reason_other' && cancelReasonText !== '') {
        orderNotes = cancelReasonText;
      } else {
        orderNotes = cancelReason;
      }
    }
    const payload = {
      id: orderId,
      price: price,
      datetime: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      note: orderNotes,
      status: stat,
    };
    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({
            data: resp.data.response,
            isOpen: false,
            notes: '',
            date: new Date(
              moment(
                resp.data.response.transaction_request[0].datetime,
              ).format(),
            ),
          });
          submitCalendar(resp.data.response);
          dispatch(addNotif({ hasNotification: true }));
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const submitCalendar = trData => {
    if (calendarData) {
      const payload = {
        transaction_id: orderId,
        calendar_id: calendarData.id,
        type: calendarData.type,
        end_datetime: moment(trData.transaction_request[0].datetime)
          .add(trData.transaction_items.length * 60, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'),
        start_datetime: trData.transaction_request[0].datetime,
      };
      updateCalendar(payload)
        .then(resp => {
          if (resp.status === 200) {
            window.location.reload();
            // console.log('sukses cal', resp.data.response)
          }
        })
        .finally(() => {
          setState({ isBidding: false, isChangeOrder: false });
        });
    }
  };

  const updateTransaction = async active => {
    setState({ isUpdate: true, isUpdateError: false });
    const payload = {
      id: orderId,
      is_active: active,
    };
    await updateTransactionStatus(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ isActive: resp.data.response.is_active });
        }
      })
      .catch(err => console.log('err', err))
      .finally(() => setState({ isUpdate: false, isOpenDiscontinue: false }));
  };

  const updateInterval = async () => {
    setState({ isUpdate: true, isUpdateError: false });
    const payload = {
      id: orderId,
      interval: currentInterval,
    };
    await updateTransactionStatus(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ currentInterval: resp.data.response.interval });
        }
      })
      .catch(err => console.log('err', err))
      .finally(() => setState({ isUpdate: false, isOpenInterval: false }));
  };

  const generatePdf = () => {
    const payload = {
      id: orderId,
    };
    createInvoice(payload).then(resp => {
      if (resp.status === 200) {
        if (resp.data.response) {
          const file =
            resp.data.response.path && resp.data.response.path !== ''
              ? resp.data.response.path
              : null;
          if (file) {
            window.open(file);
          }
        }
      }
    });
  };

  const sendEmail = async () => {
    setState({ isEmailSuccess: false });
    const payload = {
      id: orderId,
    };
    await sendInvoiceEmail(payload).then(resp => {
      if (resp.status === 200) {
        setState({ isEmailSuccess: true });
      }
    });
  };

  const intervalText =
    currentInterval && currentInterval % 30 === 0
      ? 'interval_month'
      : 'interval_week';
  const intervalCount =
    currentInterval && currentInterval % 30 === 0
      ? currentInterval / 30
      : currentInterval / 7;

  let horseHasSummary = false;
  transaction_items.forEach(h => {
    if (
      h.summary_gallery.length > 0 ||
      (h.summary_text && h.summary_text !== '')
    ) {
      horseHasSummary = true;
    }
    return;
  });

  const { transaction_invoices = [] } = data;
  const hasIndividualBilling = transaction_invoices.length > 0;
  let individualPrice = 0;

  let horseWithBilling = [];
  if (hasIndividualBilling) {
    transaction_invoices.forEach(e => {
      individualPrice += e.price;
      horseWithBilling.push(e.item);
    });
  }
  const hasHorseBilling =
    transaction_items.findIndex(e => e.item.billing_active === true) !== -1;
  const horseWithoutBilling = transaction_items.filter(
    e => horseWithBilling.findIndex(h => h.id === e.item.id) === -1,
  );

  const isPro = product.seller.id === data.user.id;
  const weekRange = [1, 2, 3, 4, 5, 6, 7, 8];

  const isProInvoice =
    isPro &&
    data.transaction_request[data.transaction_request.length - 1].status === 4;

  return (
    <div className="order-detail">
      {/* {type === 4 && (
        <div
          className="bg-danger p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )}
      {type === 3 && (
        <div
          className="bg-warning p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )}
      {type === 2 && lastStatus === 1 && (
        <div
          className="bg-secondary p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )} */}

      <div className="content py-3">
        {lastBid.status !== 7 && (
          <div className="multisteps-form">
            <div className="row">
              <div className="col-12 ms-auto me-auto mb-3">
                <div className="multisteps-form__progress">
                  <button
                    className={`multisteps-form__progress-btn active fs-10 ${
                      lastBid.status > 0 ? 'js-active' : ''
                    }`}
                    type="button"
                    title={t('request_label')}
                  >
                    {t('request_label')}
                  </button>
                  <button
                    className={`multisteps-form__progress-btn active fs-10 ${
                      lastBid.status > 1 ? 'js-active' : ''
                    }`}
                    type="button"
                    title={t('quotation_label')}
                  >
                    {t('quotation_label')}
                  </button>
                  <button
                    className={`multisteps-form__progress-btn active fs-10 ${
                      lastBid.status > 2 ? 'js-active' : ''
                    }`}
                    type="button"
                    title={t('confirmation_label')}
                  >
                    {t('confirmation_label')}
                  </button>
                  {lastBid.status === 7 ? (
                    <button
                      className={`multisteps-form__progress-btn active fs-10 js-active`}
                      type="button"
                      title={t('cancelled_label')}
                    >
                      {t('cancelled_label')}
                    </button>
                  ) : (
                    <button
                      className={`multisteps-form__progress-btn active fs-10 ${
                        lastBid.status === 6 ? 'js-active' : ''
                      }`}
                      type="button"
                      title={t('completed_label')}
                    >
                      {t('completed_label')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {isPro && (
          <div className="position-relative p-3 rounded-3 mb-3 overflow-hidden d-flex align-items-center bg-gold-100">
            <ProBadge classes="pro-badge--invert me-2 mb-1" size={16} />
            {isProInvoice ? (
              <h6 className="mb-0 fw-bold fs-14">{t('this_is_pro_invoice')}</h6>
            ) : (
              <h6 className="mb-0 fw-bold fs-14">{t('this_is_pro_order')}</h6>
            )}
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between mb-3">
          <h6 className="mb-0 fw-bold">{t('status_text')}</h6>
          {!cancelled && (
            <span className="status-icon text-primary ms-2">
              {isRefreshing ? (
                <span className="fa fa-refresh fa-spin opacity-50"></span>
              ) : (
                <span
                  className="fa fa-refresh"
                  onClick={() => refreshStatus()}
                ></span>
              )}
            </span>
          )}
        </div>
        <div
          className={`position-relative p-3 ps-4 rounded-3 border-2 border mb-4 overflow-hidden`}
        >
          <div className={`order-ribbon ${stats[stat].background}`}>&nbsp;</div>
          <div className="d-flex align-items-center">
            {lastStatus === 6 ? (
              <h6 className="fs-14 fw-bold mb-0">
                <strong className="text-success">
                  {t('order_completed_status')}
                </strong>{' '}
              </h6>
            ) : (
              <h6 className="mb-0 fs-14 fw-bold">
                <strong>
                  {t(stats[stat].status)}
                  &nbsp;-&nbsp;
                  {moment(transaction_request.datetime).format(
                    'MMM DD, YYYY - HH:mm',
                  )}
                </strong>
              </h6>
            )}
          </div>
        </div>
        {hasMonthlyBilling && (
          <div className="position-relative mt-n3 p-3 rounded-3 mb-3 overflow-hidden d-flex align-items-center alert-info">
            <h6 className="mb-0 fw-normal text-reset fs-12">
              <span className="fa fa-info-circle me-1"></span>
              {t('monthly_billing_info')}
            </h6>
          </div>
        )}

        <div className="mb-4">
          <h6 className="fs-16 fw-bold mb-2">{t('send_invoice_to')}</h6>
          {hasHorseBilling ? (
            <h6 className="mb-4 text-muted fw-bold">
              {horseWithBilling.map((hwb, index) => {
                if (index === 0) return <span>{hwb.billing_email}</span>;
                return <span>, {hwb.billing_email}</span>;
              })}
            </h6>
          ) : (
            <h6 className="mb-4 text-muted fw-bold">
              {data.customer && data.customer.email ? (
                <span>{data.customer.email}</span>
              ) : (
                <span>{data.user.email}</span>
              )}
            </h6>
          )}
        </div>

        {data?.customer && (
          <>
            <h6 className="fw-bold mb-3">{t('customer_label')}</h6>

            <div className="customer-container mb-4">
              {data?.customer && (
                <div className="selected-product border border-2 rounded-1 p-3 mb-0">
                  <h6 className="fw-bold">{data?.customer?.name}</h6>
                  <h6 className="text-muted mb-0 fs-14">
                    {data?.customer.email}
                  </h6>
                </div>
              )}
            </div>
          </>
        )}

        <h6 className="fw-bold mb-3">{t('product_label')}</h6>

        <ProductBox product={product} classes="mb-4" />

        {transaction_items.length > 0 && (
          <>
            <h6 className="fw-bold mb-3">{t('horse_label')}</h6>

            <div className="mb-4">
              {transaction_items.map((horse, index) => (
                <HorseBox
                  horse={horse.item}
                  key={index}
                  classes="mb-3"
                  showAddress
                />
              ))}
            </div>
          </>
        )}

        {!isPro && (
          <div className="buyer-card mb-4">
            <h6 className="fw-bold mb-3">
              {t(isSeller ? 'buyer_information' : 'seller_information')}
            </h6>
            {isSeller ? (
              <WidgetUser data={data.user} />
            ) : (
              <WidgetUser data={product.seller} company={company} />
            )}
          </div>
        )}

        <div className="delivery-card mb-4">
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="mb-0 fw-bold">{t('delivery_address_label')}</h6>
              <button
                className="btn text-primary fs-14 fw-bold"
                onClick={() => setState({ isMapOpen: true })}
              >
                {t('view_in_map_button')}
              </button>
            </div>
            {data.address && data.address !== '' ? (
              <h6
                className=" fw-bold text-muted fs-14 mb-5"
                dangerouslySetInnerHTML={{
                  __html: data.address.replace(/\n/g, '<br/>'),
                }}
              />
            ) : (
              <h6 className=" fw-bold text-muted fs-14 mb-5">
                {data.user.address}, {data.user.city}
              </h6>
            )}
            <Modal
              isOpen={isMapOpen}
              onRequestClose={() => setState({ isMapOpen: false })}
              contentLabel="Modal"
              className="reveal small p-3"
              ariaHideApp={false}
            >
              <h6 className="fw-bold mb-3">{t('delivery_address_label')}</h6>
              <div className="border rounded p-2 mb-3">
                {data.address && data.address !== '' ? (
                  <h6
                    className="mb-0 fs-14 fw-bold text-muted"
                    dangerouslySetInnerHTML={{
                      __html: data.address.replace(/\n/g, '<br/>'),
                    }}
                  />
                ) : (
                  <h6 className="mb-0 fs-14 fw-bold text-muted">
                    {data.user.address}, {data.user.city}
                  </h6>
                )}
              </div>
              <div className="map-container border mb-3">
                <div style={{ height: '240px' }} className="">
                  {data.latitude && data.longitude ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                      }}
                      center={[data.latitude, data.longitude]}
                      zoom={15}
                      draggable={true}
                    >
                      <Marker
                        photo={data.user.photo}
                        lat={data.latitude}
                        lng={data.longitude}
                      />
                    </GoogleMapReact>
                  ) : (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                      }}
                      center={[user.latitude, user.longitude]}
                      zoom={15}
                      draggable={true}
                    >
                      <Marker
                        photo={user.photo}
                        lat={user.latitude}
                        lng={user.longitude}
                      />
                    </GoogleMapReact>
                  )}
                </div>
              </div>
              <button
                onClick={() => setState({ isMapOpen: false })}
                className="btn btn-sm btn-secondary w-100"
              >
                Close
              </button>
              <button
                className="btn btn-close close-reveal"
                onClick={() => setState({ isMapOpen: false })}
              >
                &times;
              </button>
            </Modal>
          </>
        </div>

        {interval && interval > 0 && type === 3 && lastStatus !== 7 && (
          <div className={`mb-5`}>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold mb-3">{t('continuous_order')}</h6>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isRecurring"
                  id="isRecurring"
                  checked={isActive}
                  onChange={e => {
                    setState({ isOpenDiscontinue: true });
                    // setState({ isRecurring: e.target.checked })
                  }}
                />
              </div>
            </div>
            <div className="">
              <div className="continuous-info">
                {isActive ? (
                  <div className="">
                    <h6 className="fs-14 mb-2 text-muted">
                      <span className="fa fa-refresh me-2"></span>
                      {t('every_text')} <u>{intervalCount}</u> {t(intervalText)}
                      {` `}
                      <span
                        className={`text-primary text-decoration-underline fw-bold fs-12 ms-1`}
                        onClick={() => setState({ isOpenInterval: true })}
                      >
                        <span>({t('change_interval_button')})</span>
                      </span>
                    </h6>

                    {next_order ? (
                      <div className="d-flex align-items-center mt-3">
                        {prev_order && (
                          <Link
                            to={
                              isSeller
                                ? `/seller/order/${prev_order}`
                                : `/order/${prev_order}`
                            }
                            className="btn w-50 btn-outline-primary btn-sm me-3"
                          >
                            <span className="fa fa-chevron-left me-1"></span>
                            {t('view_prev_order_button')}
                          </Link>
                        )}
                        <Link
                          to={
                            isSeller
                              ? `/seller/order/${next_order}`
                              : `/order/${next_order}`
                          }
                          className="btn w-50 btn-outline-primary btn-sm"
                        >
                          {t('view_next_order_button')}{' '}
                          <span className="fa fa-chevron-right ms-1"></span>
                        </Link>
                      </div>
                    ) : (
                      <div className="">
                        <h6 className="fs-14 text-muted mb-0">
                          <span className="fa fa-calendar-o me-2"></span>
                          {isActive ? (
                            <span>
                              {moment(confirmation_date)
                                .add(currentInterval, 'days')
                                .format('MMM DD, YYYY')}
                            </span>
                          ) : (
                            <span>{t('discontinue_status')}</span>
                          )}
                        </h6>
                      </div>
                    )}

                    {user.username !== data.user.username && (
                      <div className="d-none">
                        {lastStatus < 6 ? (
                          <button
                            className="btn btn-sm btn-secondary btn-disabled opacity-50 border rounded-3 bg-gradient"
                            disabled
                          >
                            <span className="text">
                              {t('initiate_next_continuous')}
                            </span>
                            <span className="fa fa-chevron-right ms-2"></span>
                          </button>
                        ) : (
                          <button className="btn btn-sm btn-success rounded-3 bg-gradient">
                            <span className="text">
                              {t('initiate_next_continuous')}
                            </span>
                            <span className="fa fa-chevron-right ms-2"></span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <h6 className="text-muted fs-14">
                    <span>{t('discontinue_status')}</span>
                  </h6>
                )}

                {/* {isActive ? (
                  <div className="btn-container d-flex">
                    <button
                      className={`btn w-100 mt-3 btn-sm btn-outline-primary`}
                      onClick={() => setState({ isOpenInterval: true })}
                    >
                      <span>{t('change_interval_button')}</span>
                    </button>
                    <button
                      className={`btn w-100 mt-3 ms-3 btn-sm btn-secondary`}
                      onClick={() => setState({ isOpenDiscontinue: true })}
                    >
                      <span>{t('discontinue_button')}</span>
                    </button>
                  </div>
                ) : (
                  <button
                    className={`btn w-100 mt-3 btn-sm ${
                      isActive ? 'btn-secondary' : 'btn-outline-primary'
                    }`}
                    onClick={() => setState({ isOpenDiscontinue: true })}
                  >
                    <span>{t('continue_recurring_button')}</span>
                  </button>
                )} */}
                <Modal
                  isOpen={isOpenInterval}
                  onRequestClose={() => setState({ isOpenInterval: false })}
                  contentLabel="Modal"
                  className="reveal small p-3"
                  ariaHideApp={false}
                >
                  <div className="modal-container pt-3">
                    <h6 className="fw-bold mb-3">{t('select_interval')}</h6>
                    <p className="mb-3">{t('change_interval_heading')}</p>
                    <div className="mb-3">
                      <select
                        id="interval"
                        name="interval"
                        value={numInterval}
                        className="form-select mb-4"
                        onChange={e =>
                          setState({
                            numInterval: e.target.value,
                            currentInterval: e.target.value * cycle,
                          })
                        }
                      >
                        {weekRange.map(week => (
                          <option value={week} key={week}>
                            {t('every_text')} {week} {t('week_label')}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="btn-container d-flex align-items-center">
                      <button
                        onClick={() => setState({ isOpenInterval: false })}
                        className="btn btn-sm btn-outline-secondary w-50"
                      >
                        {t('cancel_button')}
                      </button>
                      {isUpdate ? (
                        <button
                          className="btn btn-sm btn-secondary w-50 ms-3"
                          disabled
                        >
                          {t('save_button')}
                        </button>
                      ) : (
                        <button
                          onClick={updateInterval}
                          className="btn btn-sm btn-primary w-50 ms-3"
                        >
                          {t('save_button')}
                        </button>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isOpenInterval: false })}
                  >
                    &times;
                  </button>
                </Modal>
                <Modal
                  isOpen={isOpenDiscontinue}
                  onRequestClose={() => setState({ isOpenDiscontinue: false })}
                  contentLabel="Modal"
                  className="reveal small p-3"
                  ariaHideApp={false}
                >
                  {isActive ? (
                    <div className="modal-container">
                      <h6 className="fw-bold mb-3">
                        {t('discontinue_recurring_heading')}
                      </h6>
                      <p>{t('discontinue_heading')}</p>
                      <div className="btn-container d-flex align-items-center">
                        <button
                          onClick={() => setState({ isOpenDiscontinue: false })}
                          className="btn btn-sm btn-secondary w-50"
                        >
                          {t('no_close_button')}
                        </button>
                        <button
                          onClick={() => updateTransaction(false)}
                          className="btn btn-sm btn-outline-secondary w-50 ms-3"
                        >
                          {t('yes_discontinue_button')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-container">
                      <h6 className="fw-bold mb-3">
                        {t('continue_recurring_heading')}
                      </h6>
                      <p>{t('continue_heading')}</p>
                      <div className="btn-container d-flex align-items-center">
                        <button
                          onClick={() => setState({ isOpenDiscontinue: false })}
                          className="btn btn-sm btn-outline-secondary w-50"
                        >
                          {t('no_close_button')}
                        </button>
                        <button
                          onClick={() => updateTransaction(true)}
                          className="btn btn-sm btn-primary w-50 ms-3"
                        >
                          {t('yes_continue_button')}
                        </button>
                      </div>
                    </div>
                  )}
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isOpenDiscontinue: false })}
                  >
                    &times;
                  </button>
                </Modal>
              </div>
            </div>
          </div>
        )}

        <div className="quotation-status pb-5">
          {/* <div className="product-card mb-3 bg-white p-2 shadow-sm border rounded">
            <div className="row g-2 align-items-center">
              <div className="col col-auto">
                <img
                  src={image}
                  className="img-thumbnail rounded-circle"
                  width="50"
                  alt="waiting vendor"
                  onClick={() => setState({ isDetailOpen: true })}
                />
              </div>
              <div className="col auto">
                <h6
                  className="fw-bold mb-1"
                  onClick={() => setState({ isDetailOpen: true })}
                >
                  {name}{' '}
                  <span className="fa fa-info-circle ms-1 opacity-50 text-muted"></span>
                </h6>
                {/* <h6 className="small mb-1 fw-normal"><span className="fa fa-tags text-primary opacity-50 me-2"></span>{product.category.name}</h6>
                <h6 className="fs-6 mb-0">
                  <small className="fs-12 opacity-50">{t('price')}:</small>{' '}
                  <strong>
                    <span className="text-success">{t('$')}</span>{' '}
                    {lastBid.price === 0 ? (
                      <span className="text-muted">
                        {numberFormat(product.price_min)} -{' '}
                        {numberFormat(product.price_max)}
                      </span>
                    ) : (
                      <span>{numberFormat(lastBid.price)}</span>
                    )}
                  </strong>
                </h6>
              </div>
            </div>
          </div> */}

          {/* <div className="horse-card mb-3 shadow-sm p-2 bg-light bg-gradient border rounded">
            {transaction_items.length > 0 && (
              <div className="horse-list d-flex flex-wrap align-items-center">
                {transaction_items.map(horse => (
                  <WidgetHorse data={horse} key={horse.id} />
                ))}
              </div>
            )}
          </div> */}

          {delivered && lastStatus > 3 && lastStatus !== 7 && (
            <>
              {invoiceFile && invoiceFile !== '' && (
                <div className=" mb-5 d-none">
                  <h6 className="mb-3 fw-bold">
                    {t('billing_info_invoice_summary')}
                  </h6>
                  <div className="">
                    <div className="table-container">
                      <table className="table table-striped border fs-12 mb-0">
                        <tbody className="">
                          {transaction_invoices.length > 0 &&
                            transaction_invoices.map(inv => {
                              const { price, item, pdf } = inv;
                              return (
                                <tr key={inv.id}>
                                  <td>
                                    <span className="fs-14 fw-bold">
                                      {item.name}
                                    </span>
                                  </td>
                                  <td className="text-end text-nowrap fw-bold text-dark">
                                    {t('$')}{' '}
                                    <strong className="">
                                      <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        value={price}
                                      />
                                    </strong>
                                  </td>
                                  <td className="text-end">
                                    <a
                                      href={pdf}
                                      className="text-danger"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="fa fa-file-pdf-o"></span>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          {horseWithoutBilling.length > 0 && (
                            <tr>
                              {horseWithoutBilling.length > 1 ? (
                                <td className="border-bottom-0" valign="middle">
                                  {t('billing_info_combined_invoice_for')}:
                                  <br />
                                  {horseWithoutBilling.map(e => (
                                    <span className="p-2 py-1 rounded-pill fs-10 me-1 text-light bg-primary">
                                      {e.item.name}
                                    </span>
                                  ))}
                                </td>
                              ) : (
                                <td className="border-bottom-0" valign="middle">
                                  <span className="p-2 py-1 rounded-pill text-light bg-primary">
                                    {horseWithoutBilling[0].item.name}
                                  </span>
                                </td>
                              )}
                              <td className="text-end border-bottom-0">
                                {t('$')}{' '}
                                <strong className="text-primary">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                    value={price - individualPrice}
                                  />
                                </strong>
                              </td>
                              <td className="text-end border-bottom-0">
                                <a
                                  href={invoiceFile}
                                  className="text-danger"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span className="fa fa-file-pdf-o"></span>
                                </a>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {bids.length > 0 && (
            <div className="mb-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="mb-0 fw-bold">{t('order_status_history')}</h6>
                {lastStatus <= 3 && (
                  <button
                    className="btn text-primary fs-14 fw-bold"
                    onClick={() => setState({ isChangeOrder: true })}
                  >
                    {t('change_order_button')}
                  </button>
                )}
              </div>
              <Modal
                isOpen={isChangeOrder}
                onRequestClose={() =>
                  isBidding ? null : setState({ isChangeOrder: false })
                }
                contentLabel="Modal"
                className="reveal small p-3"
                ariaHideApp={false}
              >
                <div className="modal-container">
                  <h6 className="mb-3 fw-bold">{t('change_order_heading')}</h6>

                  <label
                    htmlFor="nego_price"
                    className="fw-bold fs-6 text-dark mb-2"
                    style={{ textTransform: 'none' }}
                  >
                    {t('price_range')}{' '}
                    <span className="text-muted fs-12">
                      {t('$')} {numberFormat(product.price_min)} -{' '}
                      {numberFormat(product.price_max)}
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control mb-3"
                    id="requestedPrice"
                    name="requestedPrice"
                    pattern="\d*"
                    min="0"
                    placeholder={lastBid.price}
                    value={price}
                    onChange={e => setState({ price: e.target.value })}
                    required
                    disabled={isBidding}
                  />

                  <label
                    className="fw-bold text-dark fs-6"
                    style={{ textTransform: 'none' }}
                    htmlFor="datetime"
                  >
                    {t('service_date')}
                  </label>
                  <div className="mb-3">
                    <DatePicker
                      showTimeSelect
                      selected={date}
                      locale={lang}
                      onChange={date => setState({ date: date })}
                      customInput={<CustomInputCalendar />}
                      dateFormat="MMM dd, yyyy - HH:mm"
                      className="w-100"
                      disabled={isBidding}
                      filterDate={date => {
                        let valid = isSeller
                          ? moment(date).isSameOrAfter(moment())
                          : moment(date).isAfter(moment());
                        // if (moment(date).isoWeekday() >= 6) { valid = false }
                        return valid;
                      }}
                      excludeTimes={[
                        new Date().setHours(1, 0),
                        new Date().setHours(1, 30),
                        new Date().setHours(2, 0),
                        new Date().setHours(2, 30),
                        new Date().setHours(3, 0),
                        new Date().setHours(3, 30),
                        new Date().setHours(4, 0),
                        new Date().setHours(4, 30),
                        new Date().setHours(5, 0),
                        new Date().setHours(5, 30),
                        new Date().setHours(6, 0),
                        new Date().setHours(6, 30),
                        new Date().setHours(21, 30),
                        new Date().setHours(22, 0),
                        new Date().setHours(22, 30),
                        new Date().setHours(23, 0),
                        new Date().setHours(23, 30),
                        new Date().setHours(24, 0),
                        new Date().setHours(24, 30),
                      ]}
                    />
                  </div>

                  <div className="my-notes mb-3">
                    <textarea
                      className="form-control "
                      name="notes"
                      id="additionalInfo"
                      rows="3"
                      placeholder={t('write_a_note_label')}
                      value={notes}
                      onChange={e => setState({ notes: e.target.value })}
                      style={{ height: '120px' }}
                      disabled={isBidding}
                    ></textarea>
                  </div>

                  <div className="btn-container d-flex align-items-center">
                    <button
                      onClick={() =>
                        isBidding ? null : setState({ isChangeOrder: false })
                      }
                      className="btn btn-sm btn-outline-secondary w-50"
                    >
                      {t('cancel_button')}
                    </button>
                    {isBidding ? (
                      <button
                        disabled
                        className="btn btn-sm btn-primary btn-disabled w-50 ms-3"
                      >
                        <span className="fa fa-circle-o-notch fa-spin me-2"></span>
                        {t('save_and_confirm_button')}
                      </button>
                    ) : (
                      <button
                        onClick={() => processOrder(3)}
                        className="btn btn-sm btn-primary w-50 ms-3"
                      >
                        {t('save_and_confirm_button')}
                      </button>
                    )}
                  </div>
                </div>

                {!isBidding && (
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isChangeOrder: false })}
                  >
                    &times;
                  </button>
                )}
              </Modal>
              {/* <h6 className="mb-3 fw-bold">{t('order_status_history')}</h6> */}
              <div className="mb-4">
                {bids.map((trans, index) => {
                  const { datetime, note, price } = trans;
                  const prevIndex = bids.length > 1 ? bids.length - 1 : 0;

                  if (trans.status === 6) return null;
                  return (
                    <div
                      key={trans.id}
                      className={`position-relative order-item border border-2 rounded-3 my-3 overflow-hidden`}
                    >
                      <div
                        className={`order-item--header p-2 px-3 position-relative ${
                          trans.status === 7 ? '' : 'border-bottom '
                        }`}
                      >
                        <div
                          className={`position-absolute ${t(
                            stats[trans.status - 1].background,
                          )} start-0 top-0`}
                          style={{ width: '8px', height: '100%' }}
                        >
                          &nbsp;
                        </div>
                        <h6 className="fw-bold fs-14 mb-0">
                          {t(stats[trans.status - 1].status)} -{' '}
                          <Moment utc fromNow local locale={lang}>
                            {trans.created_at}
                          </Moment>
                        </h6>
                      </div>

                      {trans.status !== 7 && (
                        <div className="order-item--content p-2 px-3">
                          <p className="text-muted mb-0 fs-12">
                            <span className="fa fa-clock-o me-2"></span>
                            {index < bids.length - 1 ? (
                              <span>
                                {bids[prevIndex].datetime !== datetime ? (
                                  <>
                                    <Moment
                                      format="ddd, MMM DD - HH:mm"
                                      locale={lang}
                                      className="small fw-normal text-muted opacity-50 text-decoration-linough"
                                    >
                                      {bids[prevIndex].datetime}
                                    </Moment>
                                    <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                    <Moment
                                      format="ddd, MMM DD - HH:mm"
                                      className="text-dark "
                                      locale={lang}
                                    >
                                      {datetime}
                                    </Moment>
                                  </>
                                ) : (
                                  <Moment
                                    format="ddd, MMM DD - HH:mm"
                                    locale={lang}
                                  >
                                    {datetime}
                                  </Moment>
                                )}
                              </span>
                            ) : (
                              <Moment
                                format="ddd, MMM DD - HH:mm"
                                locale={lang}
                              >
                                {datetime}
                              </Moment>
                            )}
                          </p>
                          {note && note !== '' && (
                            <p className="text-muted mt-2 mb-0 fs-12">
                              <span className="fa fa-commenting-o me-2"></span>
                              <em>{note}</em>
                            </p>
                          )}
                        </div>
                      )}
                      {trans.status !== 7 && (
                        <div className="order-item--footer p-2 px-3 border-top">
                          <h6 className="fw-bold mb-0 fs-14">
                            {index < bids.length - 1 ? (
                              <span>
                                {bids[prevIndex].price !== price ? (
                                  <span>
                                    {trans.status >= 4 ? (
                                      <span>
                                        <span>{t('final_price')}</span>
                                        <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                        <strong>
                                          <span className="text-dark">
                                            {t('$')}
                                            {` `}
                                            {price === 0 ? (
                                              <span className="text-muted">
                                                {numberFormat(
                                                  product.price_min,
                                                )}{' '}
                                                -{' '}
                                                {numberFormat(
                                                  product.price_max,
                                                )}
                                              </span>
                                            ) : (
                                              <span>{numberFormat(price)}</span>
                                            )}
                                          </span>
                                        </strong>
                                      </span>
                                    ) : (
                                      <span>
                                        <span className="me-1">{t('$')}</span>
                                        <span className="">
                                          {bids[prevIndex].price}
                                        </span>
                                        <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                        <span className="">{price}</span>
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {price === 0 ? (
                                      <span className="">
                                        <span className="me-1">{t('$')}</span>
                                        {numberFormat(product.price_min)} -{' '}
                                        {numberFormat(product.price_max)}
                                      </span>
                                    ) : (
                                      <span>
                                        <span className="me-1">{t('$')}</span>
                                        {numberFormat(price)}
                                      </span>
                                    )}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span>
                                {price === 0 ? (
                                  <span className="">
                                    <span className="me-1">{t('$')}</span>
                                    {numberFormat(product.price_min)} -{' '}
                                    {numberFormat(product.price_max)}
                                  </span>
                                ) : (
                                  <span>
                                    <span className="me-1">{t('$')}</span>
                                    {numberFormat(price)}
                                  </span>
                                )}
                              </span>
                            )}
                          </h6>
                        </div>
                      )}

                      {/* {prev_order && lastStatus === 1 && (
                        <div className="alert mb-2 p-1 text-center alert-warning bg-white border-2">
                          {isSeller ? (
                            <p className="fst-italic small fs-12 lh-sm mb-0">
                              <strong>
                                <span className="fa fa-warning me-1"></span>
                                {t('recurring_warning_heading_seller')}
                              </strong>
                              <br />
                              <small>
                                {t('recurring_warning_text')}{' '}
                                <Link
                                  to={`/seller/order/${prev_order}`}
                                  className="text-reset fw-bold"
                                >
                                  {t('order_text')} #{prev_order}
                                </Link>
                              </small>
                            </p>
                          ) : (
                            <p className="fst-italic small lh-sm mb-0">
                              <strong>
                                <span className="fa fa-warning me-1"></span>
                                {t('recurring_warning_heading_buyer')}
                              </strong>
                              <br />
                              <small>
                                {t('recurring_warning_text')}{' '}
                                <Link
                                  to={`/order/${prev_order}`}
                                  className="text-reset fw-bold"
                                >
                                  {t('order_text')} #{prev_order}
                                </Link>
                              </small>
                            </p>
                          )}
                        </div>
                      )} */}
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <h6 className="small mb-1">
                          <span
                            className={`${trans.status < 3 ? 'text-warning' : 'text-success'
                              }`}
                          >
                            {t(stats[trans.status - 1].status)}
                          </span>
                          <span className="ms-2 font-ssp fs-12 text-muted">
                            <Moment utc fromNow local locale={lang}>
                              {trans.created_at}
                            </Moment>
                          </span>
                        </h6>
                        {isBuyer ? (
                          <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-warning bg-gradient">
                            {trans.user.name}
                          </h6>
                        ) : (
                          <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-secondary text-light bg-gradient">
                            {trans.user.name}
                          </h6>
                        )}
                      </div>
                      {data.type === 2 && trans.status === 1 ? (
                        <h6 className="small mb-1 fw-normal text-dark p-1 alert-warning border rounded px-2">
                          <em>{t('open_date_text')}</em>
                        </h6>
                      ) : (
                        <h6 className={`small mb-1`}>
                          <span className="text-dark text-uppercase small w-24 fs-6 me-1 text-center opacity-75">
                            <span className="fa fa-clock-o"></span>
                          </span>
                        </h6>
                      )}

                      <h6 className="small mb-1">
                        <span className="text-dark text-uppercase text-center small me-1 w-24 fs-6 opacity-75">
                          {t('$')}
                        </span>
                        {index < bids.length - 1 ? (
                          <strong>
                            {bids[prevIndex].price !== price ? (
                              <span>
                                <span className="text-muted opacity-50 fw-normal small">
                                  {bids[prevIndex].price}
                                </span>
                                <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                <span className="text-dark">{price}</span>
                              </span>
                            ) : (
                              <span>
                                {price === 0 ? (
                                  <span className="text-muted">
                                    {numberFormat(product.price_min)} -{' '}
                                    {numberFormat(product.price_max)}
                                  </span>
                                ) : (
                                  <span>{numberFormat(price)}</span>
                                )}
                              </span>
                            )}
                          </strong>
                        ) : (
                          <strong>
                            {price === 0 ? (
                              <span className="text-muted">
                                {numberFormat(product.price_min)} -{' '}
                                {numberFormat(product.price_max)}
                              </span>
                            ) : (
                              <span>{numberFormat(price)}</span>
                            )}
                          </strong>
                        )}
                      </h6>
                      {note && note !== '' && (
                        <div
                          className="py-1 bg-gradient bg-light px-2 border-top"
                          style={{
                            margin: '0 -.5rem -.5rem',
                            borderRadius: '0 0 4px 4px',
                          }}
                        >
                          <p className="small mb-0 text-dark">
                            <span className="me-1 text-center w-24 fs-12 text-muted opacity-50">
                              <span className="fa fa-commenting-o"></span>
                            </span>
                            <em>{note}</em>
                          </p>
                        </div>
                      )} */}
                    </div>
                  );
                })}

                {/* {lastStatus < 4 && (
                  <div className="d-flex justify-content-between">
                    {(lastBid.user.username === user.username &&
                      lastBid.status < 3) ||
                      lastBid.status === 3 ||
                      (prev_order && lastStatus === 1) ? (
                      <button
                        className="btn w-50 btn-sm btn-disabled btn-secondary shadow-sm me-2"
                        disabled
                      >
                        {t('confirm_button')}
                      </button>
                    ) : (
                      <>
                        {isSeller && type === 2 && lastBid.status === 1 ? (
                          <button
                            className="btn w-50 btn-sm btn-disabled btn-secondary shadow-sm me-2"
                            disabled
                          >
                            {t('confirm_button')}
                          </button>
                        ) : (
                          <button
                            className="btn w-50 btn-sm shadow-sm me-2  btn-success"
                            onClick={() =>
                              setState({ isOpen: true, action: 1 })
                            }
                          >
                            {t('confirm_button')}
                          </button>
                        )}
                      </>
                    )}
                    <button
                      className="btn w-50 btn-sm me-2 shadow-sm  btn-warning"
                      onClick={() => setState({ isOpen: true, action: 2 })}
                    >
                      {t('change_button')}
                    </button>
                    <button
                      className="btn w-50 btn-sm shadow-sm  btn-danger"
                      onClick={() => setState({ isOpen: true, action: 3 })}
                    >
                      {t('cancel_button')}
                    </button>
                  </div>
                )} */}
              </div>
              {calendarData && (
                <Link
                  className="btn w-100 btn-outline-primary rounded-3 mb-3"
                  to={`/${
                    role === 'seller' ? 'seller/' : ''
                  }schedule?mode=today&date=${moment(
                    calendarData.start_datetime,
                  ).format('YYYY-MM-DD')}`}
                >
                  <span className="fa fa-calendar me-2"></span>
                  {t('view_calendar')}
                </Link>
              )}

              {invoice && !isSeller && (
                <div
                  className={`order-item position-relative border rounded my-2 p-3 `}
                >
                  <button
                    className="mb-0 btn btn-primary w-100"
                    onClick={() => setState({ isConfirmPayment: true })}
                  >
                    {t('confirm_payment')}
                  </button>
                  <Modal
                    isOpen={isConfirmPayment}
                    onRequestClose={() => setState({ isConfirmPayment: false })}
                    contentLabel="Modal"
                    className="reveal small p-3"
                    ariaHideApp={false}
                  >
                    <div className="modal-s">
                      <h6 className="text-primary mb-3">
                        {t('please_confirm_heading')}
                      </h6>
                      <p>{t('please_confirm_text')}</p>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-outline-secondary btn-sm w-50"
                          onClick={() => setState({ isConfirmPayment: false })}
                        >
                          {t('cancel_button')}
                        </button>
                        <button
                          className="btn btn-primary ms-2 btn-sm w-75"
                          onClick={() => processOrder(paymentStatus)}
                        >
                          {t('confirm_payment')}
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn btn-close close-reveal"
                      onClick={() => setState({ isConfirmPayment: false })}
                    >
                      &times;
                    </button>
                  </Modal>
                </div>
              )}

              {lastStatus < 3 && (
                <>
                  {isBidding ? (
                    <button
                      className="btn btn-primary btn-disabled mb-0 w-100"
                      disabled
                    >
                      <span className="fa fa-circle-o-notch fa-spin me-2"></span>
                      {t('confirm_order_button')}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary mb-0 w-100"
                      onClick={() => processOrder(3)}
                    >
                      {t('confirm_order_button')}
                    </button>
                  )}
                </>
              )}

              {delivered && lastStatus > 3 && lastStatus !== 7 && (
                <div className="invoice-tools">
                  {horseWithoutBilling.length > 0 || (isPro && isProInvoice) ? (
                    <div className="">
                      {invoiceFile && invoiceFile !== '' ? (
                        <a
                          className="btn w-100 btn-outline-primary mb-3"
                          href={invoiceFile}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('download_invoice')}
                        </a>
                      ) : (
                        <button
                          className="btn w-100 btn-outline-primary mb-3"
                          onClick={generatePdf}
                        >
                          {t('download_invoice')}
                        </button>
                      )}

                      {isSeller && (
                        <button
                          className="btn w-100 btn-outline-primary mb-3"
                          onClick={sendEmail}
                        >
                          {t('resend_invoice')}
                        </button>
                      )}
                    </div>
                  ) : (
                    <>
                      {transaction_invoices.length > 0 &&
                        transaction_invoices.map(inv => {
                          const { pdf } = inv;
                          return (
                            <a
                              key={pdf}
                              href={pdf}
                              className="btn w-100 btn-outline-primary mb-3"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t('download_invoice')}
                            </a>
                          );
                        })}
                    </>
                  )}
                  <Modal
                    isOpen={isEmailSuccess}
                    onRequestClose={() => setState({ isEmailSuccess: false })}
                    contentLabel="Modal"
                    className="reveal small p-3"
                    ariaHideApp={false}
                  >
                    <div className="">
                      <h6 className="fw-bold mb-3">{t('resend_invoice')}</h6>
                      <h6 className="mb-3 fs-14 text-muted">
                        {t('resend_invoice_text')}
                      </h6>
                      <button
                        onClick={() => setState({ isEmailSuccess: false })}
                        className="btn btn-sm btn-outline-primary w-100"
                      >
                        {t('close_button')}
                      </button>
                    </div>
                    <button
                      className="btn btn-close close-reveal"
                      onClick={() => setState({ isEmailSuccess: false })}
                    >
                      &times;
                    </button>
                  </Modal>
                </div>
              )}

              {confirmed && (
                <div className={`order-item position-relative mb-0`}>
                  {!delivered ? (
                    <h6 className="mb-0">
                      <span className="fa fa-clock-o me-2"></span>
                      {t('waiting_for_delivery')}..
                    </h6>
                  ) : (
                    <>
                      {isSeller ? (
                        <>
                          <WidgetSummary data={data} />
                        </>
                      ) : (
                        <h6 className="mb-0 fw-bold text-center">
                          {t('waiting_seller_send_invoice')}
                        </h6>
                      )}
                    </>
                  )}
                </div>
              )}

              {!cancelled && lastStatus <= 3 && (
                <button
                  className="btn btn-outline-danger w-100 mt-3"
                  onClick={() => setState({ isCancel: true })}
                >
                  <span className="fa fa-trash me-2"></span>
                  {t('cancel_order_button')}
                </button>
              )}
            </div>
          )}

          <Modal
            isOpen={isConfirm}
            onRequestClose={() => setState({ isConfirm: false })}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <div className="modal-s">
              <h6 className="fw-bold mb-3">{t('confirm_order_heading')}</h6>
              <p>{t('confirm_order_text')}</p>
              {isBidding ? (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-outline-secondary btn-disabled btn-sm w-50"
                    disabled
                  >
                    {t('cancel_button')}
                  </button>
                  <button
                    className="btn btn-primary btn-disabled ms-3 btn-sm w-50"
                    disabled
                  >
                    {t('confirm_order_button')}
                  </button>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-outline-secondary btn-sm w-50"
                    onClick={() => setState({ isConfirm: false })}
                  >
                    {t('cancel_button')}
                  </button>
                  <button
                    className="btn btn-primary ms-3 btn-sm w-50"
                    onClick={() => processOrder(3)}
                  >
                    {t('confirm_order_button')}
                  </button>
                </div>
              )}
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => setState({ isConfirm: false })}
            >
              &times;
            </button>
          </Modal>

          <Modal
            isOpen={isCancel}
            onRequestClose={() =>
              isBidding ? null : setState({ isCancel: false })
            }
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <div className="modal-s">
              <h6 className="fw-bold mb-3">{t('cancel_order_heading')}</h6>
              <p>{t('cancel_order_text')}</p>
              <h6 className="fw-bold fs-14 mb-2">
                {t('reason_to_cancel_order')}
              </h6>
              <select
                name="reasonCancel"
                id="reasonCancel"
                className={`form-select mb-${
                  cancelReason === 'cancel_reason_other' ? '3' : '4'
                }`}
                defaultValue={cancelReason}
                onChange={e => {
                  setState({ cancelReason: e.target.value });
                }}
                disabled={isBidding}
              >
                <option value="cancel_reason_need_to_change">
                  {t('cancel_reason_need_to_change')}
                </option>
                <option value="cancel_reason_other">
                  {t('cancel_reason_other')}
                </option>
              </select>

              {cancelReason === 'cancel_reason_other' && (
                <textarea
                  className="form-control mb-4"
                  value={cancelReasonText}
                  onChange={e => setState({ cancelReasonText: e.target.value })}
                  rows="3"
                  placeholder={t('the_reason_is')}
                  disabled={isBidding}
                ></textarea>
              )}

              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-secondary btn-sm w-50"
                  onClick={() =>
                    isBidding ? null : setState({ isCancel: false })
                  }
                >
                  {t('keep_it_button')}
                </button>
                {isBidding ? (
                  <button
                    className="btn btn-danger ms-3 btn-sm w-50 btn-disabled"
                    disabled
                  >
                    <span className="fa fa-circle-o-notch fa-spin me-2"></span>
                    {t('cancel_order_button')}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger ms-3 btn-sm w-50"
                    onClick={() => processOrder(7)}
                  >
                    {t('cancel_order_button')}
                  </button>
                )}
              </div>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => (isBidding ? null : setState({ isCancel: false }))}
            >
              &times;
            </button>
          </Modal>

          {((summary_photo && summary_photo !== '') ||
            (summary_text && summary_text !== '')) && (
            <div className="summary-card">
              <div className="card mb-3">
                <div className="card-header p-2 bg-secondary text-light">
                  <h6 className="mb-0 text-light">{t('order_summary')}</h6>
                </div>
                <div className="body p-2">
                  {summary_text && summary_text !== '' && (
                    <div className="alert alert-warning p-2 mb-2">
                      <p className="mb-0 text-center">{summary_text}</p>
                    </div>
                  )}
                  {summary_photo && summary_photo !== '' && (
                    <div className="mb-1">
                      <img
                        src={summary_photo}
                        alt=""
                        className="img-thumbnail"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {horseHasSummary && (
            <div className="summary-card">
              <div className="card">
                <div className="card-header p-2 bg-secondary text-light">
                  <h6 className="mb-0 text-light">
                    {t('horse_order_summary')}
                  </h6>
                </div>
                <div className="body p-3">
                  {transaction_items.map((horse, index) => (
                    <HorseSummary
                      horse={horse}
                      key={horse.id}
                      index={index + 1}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isComplete}
        onRequestClose={() => setState({ isComplete: false })}
        contentLabel="Modal"
        className="reveal small p-3"
        ariaHideApp={false}
      >
        <div className="modal-s">
          <h6 className="fw-bold mb-3">{t('order_completed_heading')}</h6>
          <p>{t('order_completed_text')}</p>
          <div className="">
            <button
              className="btn btn-primary ms-2 btn-sm w-100"
              onClick={() => setState({ isComplete: false })}
            >
              {t('continue_to_this_page')}
            </button>
            {isPro && (
              <Link
                className="btn btn-secondary btn-sm w-100"
                to="/pro/create-order"
              >
                {t('create_another_pro_order')}
              </Link>
            )}
          </div>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isComplete: false })}
        >
          &times;
        </button>
      </Modal>

      {(invoice || paymentConfirm) &&
        !hasMonthlyBilling &&
        isSeller &&
        !completed && (
          <div
            className={`order-item p-2 border-bottom border-top position-fixed start-0 end-0`}
            style={{
              bottom: '48px',
              zIndex: '109',
              background: 'rgba(255,255,255,.85)',
              backdropFilter: 'blur(5px)',
            }}
          >
            {isBidding ? (
              <button
                className="mb-0 btn btn-primary w-100 btn-disabled`"
                disabled
              >
                {t('complete_order')}
              </button>
            ) : (
              <button
                className="mb-0 btn btn-primary w-100"
                onClick={() => processOrder(completeStatus)}
              >
                {t('complete_order')}
              </button>
            )}
          </div>
        )}
    </div>
  );
}

const Marker = ({ photo }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <img
      src={photo}
      className="rounded-circle"
      width="20"
      height="20"
      alt="marker"
    />
  </div>
);
