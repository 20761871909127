import { useTranslation } from 'react-i18next';

export default function HorseBox({
  horse,
  classes = '',
  showAddress = false,
  popup = false,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`selected-horse border border-2 rounded-1 p-3 mb-0 ${classes}`}
    >
      <div className="d-flex align-items-center">
        {horse.image && (
          <img
            src={horse.image}
            alt={horse.name}
            width="48"
            className="rounded me-3"
          />
        )}
        <div className="product-detail">
          <h4 className="fw-bold fs-6 mb-1">
            {horse.name}
            {horse.billing_active ? (
              <span className=" d-inline-block p-1 rounded-pill border border-2 text-primary ms-2 fs-8 fw-bold">
                {t('billing_info_active')}
              </span>
            ) : (
              <span className="border border-2 d-inline-block p-1 rounded-pill ms-2 fs-8 fw-bold">
                {t('billing_info_inactive')}
              </span>
            )}
          </h4>
          {horse.stable && (
            <h5 className="fs-10 mb-0 text-muted">{horse.stable.name}</h5>
          )}
          {horse.billing_active && horse.billing_email && (
            <h6 className="fs-10 text-muted mt-1 mb-0">
              {t('billing_info_email')}: {horse.billing_email}
            </h6>
          )}
          {showAddress && horse.billing_active && horse.billing_address && (
            <h6 className="fs-10 text-muted mt-1 mb-0">
              <span className="fa fa-map-marker me-1"></span>{' '}
              {horse.billing_address}
            </h6>
          )}
        </div>
      </div>
    </div>
  );
}
