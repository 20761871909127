import React from 'react';
import { Link } from 'react-router-dom';
import HorseImg from '../../assets/images/horse1.jpg';
import defaultProduct from '../../assets/images/default-product.jpg';
import UserImg from '../../assets/images/user.png';
function OrderDetailList() {
  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <Link to="/seller" className=" text-light text-decoration-none">
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content mt-1">
                <div className="request">
                  <ul className="d-flex justify-content-around ">
                    <li>Request</li>
                    <li>Quotation</li>
                    <li>Confirmation</li>
                    <li>Completed</li>
                  </ul>
                  <p className="bg-primary p-2 text-white fw-bold">
                    This is a Pro Order (You Create this order)
                  </p>
                </div>
                <div className="status mt-1">
                  <h3 className="fs-6 fw-bold">Status</h3>
                  <div className="status-confirmed border border-3 ps-2 pe-2 pt-2 pb-0">
                    <p className="fw-bold">
                      Confirmed - Thu. Jan 13.2022 (18:15)
                    </p>
                  </div>
                </div>
                <div className="product mt-3 mb-2">
                  <h3 className="fs-6 fw-bold">Product</h3>
                  <div className="product-contet">
                    <div className="row border border-3 pt-3 pb-0 pe-4 ps-4">
                      <div className="product-image col-2">
                        <img
                          src={defaultProduct}
                          alt="Horse"
                          style={{ width: '120px' }}
                        />
                      </div>
                      <div className="product-explaind col-10">
                        <h6 className="fs-6 fw-bold">
                          Brushing 101{' '}
                          <span className="fa fa-info-circle text-muted ms-1"></span>
                        </h6>
                        <p>
                          <span className="fa fa-tags me-1 text-primary"></span>
                          Farriery{' '}
                        </p>
                        <p style={{ marginTop: '-10px' }}>
                          <span>€</span>
                          150-300
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="seller-info mt-3 mb-2">
                  <h3 className="fs-6 fw-bold">Seller Information</h3>
                  <div className="seller-info-contet">
                    <div className="row pt-3 pb-0 pe-4 ps-4">
                      <div className="seller-image col-2">
                        <img
                          src={UserImg}
                          alt="Horse"
                          style={{ width: '120px', borderRadius: '100%' }}
                        />
                      </div>
                      <div className="seller-explain col-10">
                        <h6 className="fs-6 fw-bold">
                          Van Seller{' '}
                          <span className="fa fa-info-circle text-muted ms-1"></span>
                        </h6>
                        <p>
                          <span className="fa fa-map-marker me-1 text-primary"></span>
                          Northland Ancol Residence, Pademangan, Jakarta,
                          Indonesia{' '}
                        </p>
                        <h6>
                          {' '}
                          <span className="fa fa-building me-1"></span> EH
                          Company
                        </h6>
                        <p>Business ID 126725369</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="horse-info mt-3 mb-2">
                  <h3 className="fs-6 fw-bold">Horse</h3>
                  <div className="horse-info-contet">
                    <div className="row border border-3 pt-3 pb-3 pe-4 ps-4">
                      <div className="horse-image col-2">
                        <img
                          src={HorseImg}
                          alt="Horse"
                          style={{ width: '120px', borderRadius: '100%' }}
                        />
                      </div>
                      <div className="horse-explain col-10">
                        <h6 className="fs-6 fw-bold">Gomm </h6>
                        <p>
                          <span className="fa fa-home me-1 text-primary"></span>
                          Van Buyer Stable
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buyer-info mt-3 mb-2">
                  <h3 className="fs-6 fw-bold">Buyer Information</h3>
                  <div className="buyer-info-contet">
                    <div className="row pt-3 pb-0 pe-4 ps-4">
                      <div className="nuyer-image col-2">
                        <img
                          src={UserImg}
                          alt="Horse"
                          style={{ width: '120px', borderRadius: '100%' }}
                        />
                      </div>
                      <div className="buyer-explain col-10">
                        <h6 className="fs-6 fw-bold">
                          Mikaila{' '}
                          <span className="fa fa-info-circle text-muted ms-1"></span>
                        </h6>
                        <p>
                          <span className="fa fa-map-marker me-1 text-primary"></span>
                          Jaipur City
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="delivery-info mt-4 mb-4">
                  <div className="delivery-content d-flex justify-content-between mb-2">
                    <Link className="fs-6 fw-bold text-decoration-none">
                      Delvery Addres
                    </Link>
                    <Link className="fs-6 fw-bold text-decoration-none">
                      View in Map
                    </Link>
                  </div>
                  <p>
                    Northland Ancol Residence, Pademangan, Jakarta, Indonesia
                  </p>
                </div>
                <div className="order-status mt-4 mb-2">
                  <div className="order-status-container d-flex justify-content-between">
                    <Link className="fs-6 fw-bold text-decoration-none">
                      Order Status History
                    </Link>
                    <Link className="fs-6 fw-bold text-decoration-none">
                      Change Order
                    </Link>
                  </div>
                  <div className="order-status-content mt-3 mb-4">
                    <div className="order-status-confirmed border border-3 ps-2 pe-2 pt-2 pb-0">
                      <p> Confirmed - Thu, Jan 13, 2022 (18:15)</p>
                    </div>
                    <div className="order-status-date border border-3 ps-2 pe-2 pt-2 pb-0">
                      <p>
                        {' '}
                        <span className="fa fa-clock-o me-1"></span> Fri, Jan 14
                        12:30
                      </p>
                      <p className="ms-3">
                        {' '}
                        <span className="fa fa-comment"></span> This order is...
                      </p>
                      <p>
                        {' '}
                        <span>€</span>
                        150-300
                      </p>
                    </div>
                  </div>
                  <div className="order-status-content mt-3 mb-4">
                    <div className="order-status-new border border-3 ps-2 pe-2 pt-2 pb-0">
                      <p> New Order - Thu, Jan 13, 2022 (18:15)</p>
                    </div>
                    <div className="order-status-date border border-3 ps-2 pe-2 pt-2 pb-0">
                      <p>
                        {' '}
                        <span className="fa fa-clock-o me-1"></span> Fri, Jan 14
                        12:30
                      </p>
                      <p className="ms-3">
                        {' '}
                        <span className="fa fa-comment"></span> This order is...
                      </p>
                      <p>
                        {' '}
                        <span>€</span>
                        150-300
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" btn w-100 btn-primary text-center">
                  Create Summary Invoice
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default OrderDetailList;
