import React, { useState } from 'react';
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import userImg from '../../assets/images/user.png';

export default function WidgetSeller({ data, size = 'medium', owner = false }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { photo, address, phone, biodata, city, longitude, latitude, email } =
    data;
  const name = data.name ? data.name : data.username;
  const imgSize = size === 'small' ? 40 : 60;

  return (
    <div className="seller-widget-info">
      <div className="d-flex align-items-center position-relative">
        {photo ? (
          <div
            className={`me-${size === 'small' ? 2 : 3}`}
            style={{ flex: `0 0 ${imgSize}px` }}
          >
            <img
              src={photo}
              alt={name}
              className="img-thumbnail rounded-circle shadow-sm"
              style={{ width: `${imgSize}px` }}
            />
          </div>
        ) : (
          <div
            className={`me-${size === 'small' ? 2 : 3}`}
            style={{ flex: `0 0 ${imgSize}px` }}
          >
            <img
              src={userImg}
              alt={name}
              className="img-thumbnail rounded-circle shadow-sm"
              style={{ width: `${imgSize}px` }}
            />
          </div>
        )}
        <div className="seller-info" style={{ flex: 1 }}>
          <div className="d-flex justify-content-between align-items-start">
            <h6 onClick={() => setOpen(true)} className="stretched-link">
              {name} <span className="fa fa-info-circle text-muted ms-1"></span>
            </h6>
            {owner && (
              <h6 className="m-0 bg-warning fs-12 rounded-3 p-1 ">
                {t('com_owner')}
              </h6>
            )}
          </div>
          {address && (
            <h6 className="text-muted fw-light font-ssp small m-0">
              <span className="fa text-danger fa-map-marker me-0"></span>{' '}
              {address}
            </h6>
          )}
          {city && (
            <h6 className="text-muted fw-light font-ssp small m-0">{city}</h6>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="seller-complete-info">
          <h5 className="text-success small text-uppercase mb-3">
            {t('seller_information_heading')}
          </h5>
          {photo && (
            <img src={photo} alt={name} className="rounded shadow-sm mb-3" />
          )}
          <div className="info-box d-flex py-2">
            <small className="label">{t('name_label')}</small>
            <small>:</small>
            <h6>{name}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('email_label')}</small>
            <small>:</small>
            <h6>{email}</h6>
          </div>
          <div className="info-box d-flex border-top py-2">
            <small className="label">{t('phone_label')}</small>
            <small>:</small>
            <h6>{phone}</h6>
          </div>
          {biodata && biodata !== '' && (
            <div className="info-box d-flex border-top py-2">
              <small className="label">{t('bio_label')}</small>
              <small>:</small>
              <h6>{biodata}</h6>
            </div>
          )}
          {address && address !== '' && (
            <div className="info-box d-flex border-top py-2">
              <small className="label">{t('address_label')}</small>
              <small>:</small>
              <h6>
                <span className="fa fa-map-marker text-danger me-2"></span>
                {address}
              </h6>
            </div>
          )}
          {city && city !== '' && (
            <div className="info-box d-flex border-top py-2">
              <small className="label">{t('city_label')}</small>
              <small>:</small>
              <h6>{city}</h6>
            </div>
          )}

          {longitude && latitude && (
            <div style={{ height: '300px' }} className="pt-3 mb-3 border-top">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                }}
                center={[latitude, longitude]}
                zoom={15}
                draggable={true}
              >
                <Marker photo={photo} lat={latitude} lng={longitude} />
              </GoogleMapReact>
            </div>
          )}
          <button
            onClick={() => setOpen(false)}
            className="btn btn-sm btn-secondary w-100"
          >
            {t('close_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setOpen(false)}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}

const Marker = ({ photo }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <img
      src={photo}
      className="rounded-circle"
      width="20"
      height="20"
      alt="marker"
    />
  </div>
);
