import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
// import { getSellerProducts } from '../../libs/apis';
// import Product from '../../components/seller/Product';
// import OrderSummary from '../../components/seller/OrderSummary';
import Calendar from '../../components/calendar/Calendar';

function SellerIndex() {
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <div className="main-container pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content">
                <Calendar />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerIndex;
