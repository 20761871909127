import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import fi from 'date-fns/locale/fi';
import { addNotif } from '../../redux/slices/appSlice';

import {
  addTransactionItemDetail,
  updateCalendar,
  addTransactionBid,
} from '../../libs/apis';

registerLocale('fi', fi);

export default function Slot({
  orderId = 0,
  horse,
  timeslot = null,
  single = false,
  isMine = false,
  calData,
  callbackSlot,
  isHorse = false,
}) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const today = new Date();
  const initialTime = timeslot ? timeslot : '07:00';
  const initialHour = initialTime.split(':')[0];
  const initialMinute = initialTime.split(':')[1];
  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-clock-o me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));
  const horseTime = horse.time
    ? horse.time.split(':')
    : [initialHour, initialMinute];
  const newHorseTime = moment(today)
    .set({ hour: parseInt(horseTime[0]), minute: parseInt(horseTime[1]) })
    .format('YYYY-MM-DD HH:mm');
  const initialState = {
    isOpen: false,
    date: new Date(newHorseTime),
    time: horse.time ? horse.time : initialTime,
    edit: false,
    hasTime: horse.time,
    isLoading: false,
    isError: false,
    note: horse.note ? horse.note : '',
    isBidding: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen, time, edit, hasTime, note, date, isBidding } = state;
  const { item, id } = horse;
  const { name, image } = item;
  const setTime = async () => {
    setState({ isLoading: true, isBidding: true, isError: false });
    const payload = {
      id: orderId,
      itemId: id,
      time: time,
    };
    if (note && note !== '') {
      payload.note = note;
    }
    if (single) {
      payload.confirmation = time;
    }
    await addTransactionItemDetail(payload)
      .then(res => {
        if (res.status === 200) {
          const newItem = res.data.response.transaction_items.find(
            h => h.id === id,
          );
          let maxTime = newItem.time;
          let minTime = newItem.time;
          res.data.response.transaction_items.forEach(ho => {
            if (ho.time) {
              const val =
                parseInt(ho.time.split(':')[0]) * 30 +
                parseInt(ho.time.split(':')[1]);
              const minVal =
                parseInt(minTime.split(':')[0]) * 30 +
                parseInt(minTime.split(':')[1]);
              const maxVal =
                parseInt(maxTime.split(':')[0]) * 30 +
                parseInt(maxTime.split(':')[1]);
              // console.log('vv', moment())
              if (val < minVal) {
                minTime = ho.time;
              }
              if (val > maxVal) {
                maxTime = ho.time;
              }
            }
          });
          const start_dt = moment(calData.start_datetime)
            .set({
              hour: parseInt(minTime.split(':')[0]),
              minute: parseInt(minTime.split(':')[1]),
            })
            .format('YYYY-MM-DD HH:mm:ss');
          const end_dt = moment(calData.end_datetime)
            .set({
              hour: parseInt(maxTime.split(':')[0]) + 1,
              minute: parseInt(maxTime.split(':')[1]),
            })
            .format('YYYY-MM-DD HH:mm:ss');
          updateCalendars(start_dt, end_dt);

          setState({
            note: newItem.note,
            time: newItem.time,
            hasTime: newItem.time ? true : false,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ isLoading: false, edit: false });
      });
  };
  const updateCalendars = async (start, end) => {
    const payload = {
      calendar_id: calData.id,
      transaction_id: calData.transaction.id,
      start_datetime: start,
      end_datetime: end,
      type: 0,
    };
    await updateCalendar(payload).then(resp => {
      if (resp.status === 200) {
        // updateOtherCalendars(start, end);
        processOrder(start);
        // console.log('resp', resp.data.response);
      }
    });
  };

  // const updateOtherCalendars = async (start, end) => {
  //   const payload = {
  //     calendar_id: calData.id,
  //     transaction_id: calData.transaction.id,
  //     start_datetime: start,
  //     end_datetime: end,
  //     type: 0,
  //   };
  //   await updateCalendar(payload).then(resp => {
  //     if (resp.status === 200) {
  //       updateOtherCalendars(start, end);
  //       // processOrder(start);
  //       // console.log('resp', resp.data.response);
  //     }
  //   });
  // };

  const { role } = useSelector(state => state.auth);
  const isSeller = role === 'seller';
  // const link = isSeller ? 'seller/order' : 'order';
  // const color = status >= 4 ? 'secondary' : 'secondary';
  let excludeTimes = isHorse
    ? []
    : [
        new Date().setHours(1, 0),
        new Date().setHours(1, 30),
        new Date().setHours(2, 0),
        new Date().setHours(2, 30),
        new Date().setHours(3, 0),
        new Date().setHours(3, 30),
        new Date().setHours(4, 0),
        new Date().setHours(4, 30),
        new Date().setHours(5, 0),
        new Date().setHours(5, 30),
        new Date().setHours(6, 0),
        new Date().setHours(6, 30),
        new Date().setHours(21, 30),
        new Date().setHours(22, 0),
        new Date().setHours(22, 30),
        new Date().setHours(23, 0),
        new Date().setHours(23, 30),
        new Date().setHours(24, 0),
        new Date().setHours(24, 30),
      ];
  const processOrder = async datetime => {
    const tr = calData.transaction;
    const payload = {
      id: orderId,
      price: tr.transaction_request[0].price,
      datetime: datetime,
      status: tr.transaction_request[0].status,
    };
    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          callbackSlot(resp.data.response);
          dispatch(addNotif({ hasNotification: true }));
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setState({ isBidding: false }));
  };
  // let hourDiff = (initialHour - 7) * 2;
  // if (initialMinute > 0) {
  //   hourDiff += 1;
  // }
  // let defaultHour = 6;
  // for (let i = 1; i <= hourDiff; i++) {
  // const min = i % 2 === 1 ? 0 : 30;
  // const hr = i % 2 === 1 ? 1 : 0;
  // defaultHour += hr;
  // const newTime = new Date().setHours(defaultHour, min);
  // excludeTimes.push(newTime);
  // }
  return (
    <div
      className={`horse-item  rounded-3 border-2 mb-2 shadow-sm bg-white shadow-sm border`}
    >
      <div
        className="horse-name font-ssp p-1 px-2 fw-light text-dark clearfix"
        onClick={() => setState({ isOpen: !isOpen })}
      >
        <div className="horse-detail d-flex align-items-center">
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <div className="horse-img me-2">
              <img
                src={image}
                alt=""
                className="rounded-circle img-thumbnail"
              />
            </div>
            <h6 className=" lh-1 mb-0 me-2 ">{name}</h6>
          </div>
          {hasTime ? (
            <h6 className="mb-0 fs-14 font-ssp text-muted">{time}</h6>
          ) : (
            <>
              {isSeller && isMine && (
                <h6 className="mb-0 fs-12 font-ssp text-danger">
                  <em>{t('set_time')}</em>
                </h6>
              )}
            </>
          )}
        </div>
      </div>
      {isOpen && isMine && (
        <div className="horse-detail p-2">
          {/* <h6 className="mb-2 small "><span className="fa fa-info-circle me-1"></span>Schedule detail for:</h6> */}
          <div className=" d-flex align-items-center justify-content-start">
            {/* <img src={image} alt="" className="img-thumbnail rounded-circle me-2" width="48" /> */}
            <div style={{ flex: 1 }}>
              {/* <h6 className="mb-1">{name}</h6> */}
              {note && note !== '' && (
                <p className="mb-0 mt-1 small lh-1">
                  &ldquo;<em>{note}</em> &rdquo;
                </p>
              )}
              {!isSeller && !hasTime && (
                <p className="mb-0 mt-1 small lh-1 p-2 alert-warning rounded">
                  <em>Waiting seller to set time for this horse</em>
                </p>
              )}
            </div>
            {/* <Link to={`/${link}/${orderId}`} className="ms-2 btn fs-12 btn-sm btn-secondary">View Order #{orderId}</Link> */}
            {/* <button onClick={() => setState({ edit: true })} className="btn btn-warning btn-sm w-50"><span className="fa fa-edit me-2"></span>Change Time</button> */}
          </div>

          {isSeller && isMine && (
            <>
              {hasTime && !edit ? (
                <div className="mt-3 d-flex align-items-center justify-content-center">
                  {!isBidding ? (
                    <button
                      onClick={() => setState({ edit: true })}
                      className="btn btn-warning btn-sm fs-12 w-100"
                    >
                      <span className="fa fa-edit me-2"></span>
                      {t('change_time')}
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary btn-sm fs-12 w-100"
                      disabled
                    >
                      <span className="me-2">
                        <span className="fa fa-refresh fa-spin"></span>
                      </span>
                      {t('change_time')}
                    </button>
                  )}
                  {/* <button className="btn btn-sm ms-2 w-50 btn-secondary" onClick={() => setState({ isOpen: false })}>Close</button> */}
                </div>
              ) : (
                <div className="mt-3 card border-2 rounded-3 shadow-sm mb-3 bg-white mb-1">
                  <div className="card-header  rounded-0">
                    <h6 className="text-start fw-bold text-primary fs-6 m-0">
                      <span className="fa fa-clock-o me-2"></span>
                      {t('set_time_for_this_horse')}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className=" mb-3">
                      <DatePicker
                        showTimeSelectOnly
                        showTimeSelect
                        selected={date}
                        locale={lang}
                        onChange={newDate => {
                          const t = moment(newDate).format('HH:mm');
                          setState({ date: newDate, time: t });
                        }}
                        customInput={<CustomInputCalendar />}
                        dateFormat="HH:mm"
                        className="w-100"
                        excludeTimes={excludeTimes}
                      />
                      {/* <Datetime id="timepicker" className="picker-right w-50 rdt-input-group" inputProps={{ readOnly: true, className: 'text-center form-control datetime-time' }} locale={lang} initialValue={time} timeFormat="HH:mm" timeConstraints={{ hours: { min: 7, max: 17, }, minutes: { step: 30 }, seconds: { step: 60 } }} onChange={(e) => {
                      const t = e.format('HH:mm');
                      setState({ time: t })
                    }} dateFormat={false} /> */}
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        value={note}
                        onChange={e => setState({ note: e.target.value })}
                        rows="3"
                        id="addNotes"
                        placeholder={t('notes_label')}
                        style={{ height: '80px' }}
                      ></textarea>
                      <label
                        htmlFor="addNotes"
                        className="text-success font-cabin small"
                      >
                        {t('notes_label')}
                      </label>
                    </div>
                    {isBidding ? (
                      <button
                        className="btn btn-secondary btn-disabled w-100"
                        disabled
                      >
                        <span className="fa fa-check me-2"></span>
                        {t('set_time')}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-100"
                        onClick={setTime}
                      >
                        <span className="fa fa-check me-2"></span>
                        {t('set_time')}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
