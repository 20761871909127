import { createSlice } from '@reduxjs/toolkit';
import { setUserToken, deleteUserToken } from '../../libs/utils';

const initialState = {
  user: null,
  role: null,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.role = action.payload.user.authorization.param;
      state.token = action.payload.token;
      setUserToken(action.payload.token);
    },
    logout: state => {
      state.user = null;
      state.role = null;
      state.token = null;
      deleteUserToken();
    },
    update: (state, action) => {
      state.user = action.payload.user;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, update } = authSlice.actions;

export default authSlice.reducer;
