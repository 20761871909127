import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import {
  getCategories,
  addItem,
  uploadImage,
  getStables,
} from '../../libs/apis';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';
import { useSelector } from 'react-redux';
// import Modal from 'react-modal';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';
registerLocale('fi', fi);
registerLocale('id', id);

function AddItemPage() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const { user, role } = useSelector(state => state.auth);
  moment.locale(lang);
  const history = useHistory();
  const initialState = {
    categories: [],
    selectedCategory: 0,
    name: '',
    image: '',
    isLoading: false,
    isError: false,
    message: '',
    isUploading: false,
    isUploadError: false,
    birthdate: moment(new Date('2010-01-01')).format('YYYY-MM-DD'),
    datepickerDate: new Date('2010-01-01'),
    withers: '',
    job: '',
    breed: '',
    description: '',
    gallery: [],
    inputGallery: '',
    stableData: [],
    selectedStable: null,
    billing_active: false,
    billing_email: '',
    billing_data: [],
    isBillingActive: false,
    billing_name: '',
    billing_company_id: '',
    billing_address: '',
    billing_phone: '',
    warningEmail: false,
    isOpen: false,
    isNewStable: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    selectedCategory,
    categories,
    name,
    image,
    isLoading,
    isError,
    message,
    isUploading,
    isUploadError,
    birthdate,
    withers,
    job,
    breed,
    description,
    gallery,
    datepickerDate,
    inputGallery,
    stableData,
    selectedStable,
    billing_email,
    isBillingActive,
    billing_name,
    billing_company_id,
    billing_address,
    billing_phone,
    warningEmail,
    // isOpen,
    // isNewStable,
  } = state;

  const onDropImage = async e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ isUploading: true, isUploadError: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  const newUrl = res.data.secure_url.replace(
                    '/image/upload/',
                    '/image/upload/c_crop,h_1280,w_1280/',
                  );
                  setState({ image: newUrl });
                }
              })
              .catch(err => {
                setState({ isUploadError: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ isUploading: false });
              });
          },
          'base64',
          1280,
          1280,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isLoading: true, isError: false, message: '' });
    const payload = {
      name,
      category_id: selectedCategory,
      image,
      birthdate: moment(birthdate).isValid()
        ? moment(birthdate).format('YYYY-MM-DD')
        : birthdate,
      withers,
      job,
      breed,
      description,
      gallery,
      stable_id: selectedStable !== -1 ? selectedStable : null,
    };
    await addItem(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const item = resp.data.response || null;
          if (item && item.id) {
            history.push(`/horse/${item.id}`);
          }
        } else {
          setState({ isError: true, message: resp.data.error.message });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, message: err.message });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  };

  useEffect(() => {
    const getCategoryData = async () => {
      await getCategories(1).then(resp => {
        if (resp.status === 200) {
          setState({ categories: resp.data.response });
          if (resp.data.response.length > 0) {
            setState({ selectedCategory: resp.data.response[0].id });
          }
        }
      });
    };
    const getStableData = async () => {
      await getStables().then(resp => {
        if (resp.status === 200) {
          setState({ stableData: resp.data.response });
          // if (resp.data.response.length > 0) {
          //   setState({ selectedStable: resp.data.response[0].id });
          // }
        }
      });
    };
    getCategoryData();
    getStableData();
  }, []);

  const onDropGallery = async e => {
    const file = e.target.files;
    setState({ uploadGallery: true, errorGallery: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({
            gallery: [...gallery, res.data.secure_url],
            inputGallery: '',
          });
        }
      })
      .catch(err => {
        setState({ errorGallery: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadGallery: false });
      });
  };

  const deleteGallery = item => {
    const newGallery = gallery.filter(e => e !== item);
    setState({ gallery: newGallery });
  };

  const CustomInputCalendar = forwardRef(({ onClick }, ref) => (
    <button
      className="btn fs-5 text-muted me-1 p-0 border-0"
      ref={ref}
      onClick={onClick}
      style={{ height: '38px', width: '38px' }}
    >
      <span className="fa fa-calendar"></span>
    </button>
  ));

  if (role === 'seller' && !user.subscription) {
    return (
      <div className="app-container" style={{ height: 'calc(100vh - 108px)' }}>
        <div
          className="container mb-5 d-flex flex-direction-col justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 108px)' }}
        >
          <div className="text-center p-5">
            <h6 className="fw-bold fs-5 mb-3">{t('pro_restriction_title')}</h6>
            <p className="text-muted">{t('pro_restriction_text')}</p>
            <a
              className="btn btn-sm btn-outline-primary"
              target="_blank"
              href="mailto:laskutus@horsesfirst.com"
              rel="noreferrer noopener"
            >
              {t('contact_horsesfirst')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <SubHeader title={t('add_new_horse')} go="/manage" isClose>
        {image === '' || name === '' ? (
          <button type="button" className="btn text-primary fw-bold" disabled>
            {t('save_button')}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btn text-primary fw-bold"
          >
            {t('save_button')}
          </button>
        )}
      </SubHeader>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {message}
                    </div>
                  )}
                  <div>
                    <div className="form-floating mb-3 d-none">
                      <select
                        className="form-select "
                        name="category"
                        onChange={e =>
                          setState({ selectedCategory: e.target.value })
                        }
                      >
                        <option value="0" disabled>
                          {t('category_select')}
                        </option>
                        {categories.length > 0 &&
                          categories.map((cat, index) => (
                            <option key={cat.id + index} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="category">{t('category_select')}</label>
                    </div>

                    <div className="form-group">
                      {isUploadError && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {/* <Uploader /> */}
                      {isUploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        <label
                          htmlFor="img"
                          className="position-relative d-inline-block w-auto mx-auto align-items-center btn text-center justify-content-center fw-bold overflow-hidden"
                        >
                          {image ? (
                            <div className="mb-2 d-flex justify-content-center">
                              <img
                                src={image}
                                alt="upload"
                                height="160"
                                width="160"
                                className="rounded-circle"
                              />
                            </div>
                          ) : (
                            <div className="mb-1 d-flex justify-content-center">
                              <div
                                style={{ width: '160px', height: '160px' }}
                                className="rounded-circle d-flex justify-content-center align-items-center flex-direction-column bg-light display-1"
                              >
                                <span className="fa fa-image text-white"></span>
                              </div>
                            </div>
                          )}

                          {image ? (
                            <small>
                              {t('change_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          ) : (
                            <small>
                              {t('add_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          )}

                          <input
                            type="file"
                            id="img"
                            accept="image/*"
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="name">
                        {t('horse_name_label')}{' '}
                        <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('horse_name_label')}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="stable">{t('stable')}</label>
                      {/* <button
                          className="btn-outline-secondary btn border-1 w-100"
                          style={{
                            borderRadius: '4px',
                          }}
                          onClick={() => setState({ isOpen: true })}
                        >
                          {t('select_stable')}
                        </button> */}
                      <select
                        className="form-select "
                        name="stable"
                        onChange={e =>
                          setState({ selectedStable: e.target.value })
                        }
                        defaultValue={selectedStable}
                      >
                        <option value={null}>
                          {t('stable_not_specified')}
                        </option>
                        {stableData.map((stabl, index) => (
                          <option key={stabl.id + index} value={stabl.id}>
                            {stabl.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <label htmlFor="birthdate">
                      {t('horse_date_of_birth')}
                    </label>
                    <div className="d-flex mb-3 align-items-stretch position-relative">
                      <input
                        type="text"
                        className="form-control w-100 flex-1"
                        value={birthdate}
                        onChange={e => setState({ birthdate: e.target.value })}
                        name="birthdate"
                      />
                      <div className="ms-3 position-absolute top-0 end-0">
                        <DatePicker
                          selected={datepickerDate}
                          locale={lang}
                          showYearDropdown
                          popperPlacement="bottom-end"
                          dropdownMode="select"
                          onChange={newDate => {
                            setState({
                              datepickerDate: newDate,
                              birthdate: moment(newDate).format('YYYY-MM-DD'),
                            });
                          }}
                          customInput={<CustomInputCalendar />}
                        />
                      </div>
                    </div>
                    <label htmlFor="withers">{t('horse_withers')}</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="withers"
                        value={withers}
                        onChange={e => setState({ withers: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="job">{t('horse_job')}</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="job"
                        value={job}
                        onChange={e => setState({ job: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="breed">{t('horse_breed')}</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="breed"
                        value={breed}
                        onChange={e => setState({ breed: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="breed">{t('horse_description')}</label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={e =>
                          setState({ description: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        style={{ height: '160px' }}
                      />
                    </div>

                    <div className="gallery mb-3 p-3 bg-white bg-gradient border rounded d-none">
                      <h6 className="font-cabin text-primary small">
                        {t('horse_gallery')}
                      </h6>
                      <div className="gallery-uploader mb-3">
                        <div className="add-gallery">
                          <label
                            htmlFor="add-gallery"
                            className="btn w-100 bg-light border rounded-3 shadow-sm bg-gradient position-relative"
                          >
                            <span className="i`con">
                              <span className="fa fa-plus"></span>
                            </span>
                            <span className="text">{t('add_gallery')}</span>
                            <input
                              type="file"
                              id="add-gallery"
                              accept="image/*"
                              name="add-gallery"
                              onChange={onDropGallery}
                              value={inputGallery}
                            />
                          </label>
                        </div>
                      </div>
                      {gallery.length > 0 && (
                        <div className="gallery-container mb-3 d-none row g-2">
                          {gallery.map((g, i) => (
                            <div className="col-6" key={g + i}>
                              <div className="gallery-item border rounded shadow-sm">
                                <img
                                  src={g}
                                  alt={g + i}
                                  className="rounded-top"
                                />
                                <div className="p-2 bg-light">
                                  <button
                                    className="btn btn-danger btn-sm w-100 m-0"
                                    onClick={() => deleteGallery(g)}
                                  >
                                    <span className="fa fa-trash me-1"></span>
                                    <small>{t('delete_label')}</small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="billing-info-section mb-3 mt-4">
                      <div className="mb-3 d-flex justify-content-between align-items-center">
                        <h6 className="fw-bold">{t('billing_info_label')}</h6>
                        <div className="form-check form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="enable_billing"
                            checked={isBillingActive}
                            onChange={e => {
                              setState({ isBillingActive: e.target.checked });
                              if (e.target.checked && billing_email === '') {
                                setState({ warningEmail: true });
                              } else {
                                setState({ warningEmail: false });
                              }
                            }}
                          />
                          {/* <label
                              className="form-check-label fs-14 fw-bold"
                              for="enable_billing"
                            >
                              {t('billing_info_enable_label')}
                            </label> */}
                        </div>
                      </div>
                      <div className="billing-info-container">
                        {isBillingActive && (
                          <>
                            <div className="form-group mb-2 mt-3">
                              <label className="fs-14" htmlFor="billing_email">
                                {t('billing_info_email')}
                                <span className="text-danger">*</span>
                              </label>

                              {isBillingActive ? (
                                <input
                                  type="email"
                                  className={`form-control  ${
                                    warningEmail ? 'is-invalid' : ''
                                  }`}
                                  required
                                  id="billing_email"
                                  name="billing_email"
                                  value={billing_email}
                                  onChange={e => {
                                    setState({ billing_email: e.target.value });
                                    if (e.target.value !== '') {
                                      setState({ warningEmail: false });
                                    } else {
                                      setState({ warningEmail: true });
                                    }
                                  }}
                                />
                              ) : (
                                <input
                                  type="email"
                                  className="form-control "
                                  value={billing_email}
                                  id="billing_email"
                                  name="billing_email"
                                  onChange={e =>
                                    setState({ billing_email: e.target.value })
                                  }
                                />
                              )}
                              {warningEmail && (
                                <div className=" fs-12 invalid-feedback">
                                  {t('billing_info_email_invalid')}
                                </div>
                              )}
                            </div>

                            <div className="billing-data">
                              <div className="form-group mb-2">
                                <label className="fs-14" htmlFor="billing_name">
                                  {t('billing_info_name')}
                                </label>

                                <input
                                  type="text"
                                  className="form-control "
                                  id="billing_name"
                                  name="billing_name"
                                  value={billing_name}
                                  onChange={e => {
                                    setState({
                                      billing_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="billing-data">
                              <div className="form-group mb-2">
                                <label
                                  className="fs-14"
                                  htmlFor="billing_company_id"
                                >
                                  {t('billing_info_company_id')}
                                </label>

                                <input
                                  type="text"
                                  className="form-control "
                                  id="billing_company_id"
                                  name="billing_company_id"
                                  value={billing_company_id}
                                  onChange={e => {
                                    setState({
                                      billing_company_id: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="billing-data">
                              <div className="form-group mb-2">
                                <label
                                  className="fs-14"
                                  htmlFor="billing_address"
                                >
                                  {t('billing_info_address')}
                                </label>

                                <input
                                  type="text"
                                  className="form-control "
                                  id="billing_address"
                                  name="billing_address"
                                  value={billing_address}
                                  onChange={e => {
                                    setState({
                                      billing_address: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="billing-data">
                              <div className="form-group mb-3">
                                <label
                                  className="fs-14"
                                  htmlFor="billing_phone"
                                >
                                  {t('billing_info_phone')}
                                </label>

                                <input
                                  type="text"
                                  className="form-control "
                                  id="billing_phone"
                                  name="billing_phone"
                                  value={billing_phone}
                                  onChange={e => {
                                    setState({
                                      billing_phone: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {/* <div className="form-group mb-2">
                        <div className="row g-2">
                          <div className="col-4">
                            <label className="fs-14" htmlFor="billing_email">
                              Billing Name:
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              type="text"
                              className="form-control "
                              value={billing_name}
                              onChange={e =>
                                setState({ billing_name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItemPage;

// function valid(current) {
//   return current.isAfter(moment());
// }
