import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

export default function SubHeader({
  go = null,
  title = null,
  children = null,
  prevent = false,
  isClose = false,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const initialState = { isOpen: false };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen } = state;

  const handleNavigation = () => {
    if (go) {
      history.push(go);
    } else {
      history.goBack();
    }
  };
  return (
    <div className="sub-header sticky-top bg-white">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="admin-header-content d-flex justify-content-between align-items-center">
              {prevent ? (
                <button
                  className="btn fs-4 mb-0 me-2 p-3 shadow-none ms-n3"
                  onClick={() => setState({ isOpen: true })}
                >
                  {isClose ? (
                    <span className="fa fa-times"></span>
                  ) : (
                    <span className="fa fa-chevron-left"></span>
                  )}
                </button>
              ) : (
                <button
                  className="btn fs-4 mb-0 me-2 p-3 shadow-none ms-n3"
                  onClick={handleNavigation}
                >
                  {isClose ? (
                    <span className="fa fa-times"></span>
                  ) : (
                    <span className="fa fa-chevron-left"></span>
                  )}
                </button>
              )}
              <h6 className="fw-bold m-0 flex-1">{title}</h6>
              {children}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setState({ isOpen: false });
        }}
        contentLabel="Modal"
        className="reveal p-3 small"
        ariaHideApp={false}
      >
        <h6 className="fw-bold">{t('discard_changes_label')}</h6>
        <p className="mb-4">{t('discard_changes_text')}</p>
        <div className="btn-container d-flex">
          <button
            className="btn btn-outline-secondary w-50"
            onClick={() => setState({ isOpen: false })}
          >
            {t('cancel_button')}
          </button>
          <button
            className="btn btn-secondary w-50 ms-3"
            onClick={handleNavigation}
          >
            {t('discard_button')}
          </button>
        </div>
      </Modal>
      <Helmet>
        <title>{title} | Luokki</title>
      </Helmet>
    </div>
  );
}
