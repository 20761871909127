import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WidgetOrder from '../../components/global/WidgetOrder';
import SubHeader from '../../components/global/SubHeader';

function Quotation() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const history = useHistory();
  const { search = '' } = history.location;
  const parsedSearch = search.length > 0 ? search.split('?back=')[1] : null;

  return (
    <div className="app-container">
      <SubHeader
        title={`${t('order_text')} #${orderId}`}
        go={parsedSearch ? parsedSearch : `/seller/order`}
      />
      <div className="container">
        <div className="row">
          <div className="col">
            <WidgetOrder orderId={orderId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotation;
