import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function WidgetHorse({ data }) {
  const { t } = useTranslation();
  const initialState = {
    isOpen: false,
    summaryPhoto: '',
    summaryText: '',
    isSubmit: false,
    isLoading: false,
    isError: false,
    step: 1,
    isUploading: false,
    isUploadError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen } = state;

  const { id } = data;

  // const onDropImage = async e => {
  //   const file = e.target.files;
  //   setState({ isUploading: true, isUploadError: false });

  //   const payload = {
  //     file: file[0],
  //   };
  //   await uploadImage(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         const resized = res.data.secure_url.replace(
  //           'https://res.cloudinary.com/luokki/image/upload/',
  //           'https://res.cloudinary.com/luokki/image/upload/w_800,h_800,c_fill/',
  //         );
  //         setState({ summaryPhoto: resized });
  //       }
  //     })
  //     .catch(err => {
  //       setState({ isUploadError: true });
  //       console.log('err', err);
  //     })
  //     .finally(() => {
  //       setState({ isUploading: false });
  //     });
  // };

  return (
    <div className="widget-summary">
      <div className="position-relative">
        <button
          className="btn btn-primary w-100"
          onClick={() => setState({ isOpen: true })}
        >
          <span className="fa fa-calendar-o me-2"></span>{' '}
          {t('create_summary_invoice')}
        </button>
        {/* <h6
          onClick={() => setState({isOpen: true})}
          className="shadow-sm small d-flex align-items-center p-1 rounded-pill bg-primary px-1 pe-2 text-nowrap mb-0 text-light text-center"
        >
          {id}
        </h6> */}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal small p-3"
        ariaHideApp={false}
      >
        <div className="summary-container">
          <h6 className="fw-bold mb-4">{t('create_order_summary')}</h6>
          <p className="text-muted mb-4">{t('order_summary_description')}</p>
          {/* <div className="progress mb-3">
            <div
              className="progress-bar progress-bar-striped bg-secondary"
              role="progressbar"
              style={{ width: step === 1 ? '50%' : '99%' }}
              aria-valuenow={step === 1 ? '50' : '99'}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {step === 1 ? '50%' : '99%'}
            </div>
          </div> */}

          {/* {step === 1 ? (
            <div className="form-container"> */}
          {/* <div className="form-group">
                {isUploadError && (
                  <div className="alert alert-danger text-center">
                    <p className="m-0">{t('failed_to_upload')}</p>
                  </div>
                )}
                {isUploading && <Loader size={24} />}
                <div className="image-uploader text-center">
                  {summaryPhoto && (
                    <div className="mb-3 d-flex justify-content-center">
                      <img
                        src={summaryPhoto}
                        alt="upload"
                        className="img-thumbnail"
                      />
                    </div>
                  )}

                  <label
                    htmlFor="img"
                    className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                  >
                    <span className="fa fa-image me-3"></span>
                    {summaryPhoto ? (
                      <small>{t('change_photo_label')}</small>
                    ) : (
                      <small>{t('upload_photo_label')}</small>
                    )}

                    <input
                      type="file"
                      id="img"
                      onChange={onDropImage}
                      className="fs-6 small"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        opacity: 0,
                      }}
                    />
                  </label>
                </div>
              </div> */}

          {/* <label htmlFor="summaryText" className="font-cabin text-primary">
                {t('summary_description')}
              </label>
              <div className="form-group mb-3">
                <textarea
                  className="form-control"
                  id="summaryText"
                  value={summaryText}
                  onChange={e => setState({ summaryText: e.target.value })}
                  disabled={isLoading}
                  style={{ height: '100px' }}
                />
              </div>
              <div className="btn-container d-flex">
                <button
                  onClick={submitSummary}
                  className="btn btn-sm me-3 btn-primary w-50 mb-3"
                >
                  {t('save_button')}
                </button>
                <button
                  onClick={() => setState({ step: 2 })}
                  className="btn btn-sm btn-outline-secondary w-50 mb-3"
                >
                  {t('skip_button')}
                  <span className="fa fa-chevron-right ms-3"></span>
                </button>
              </div>
            </div>
          ) : (
            <div className="form-container">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="small text-primary mb-0">
                  {t('summary_for_horses')}
                </h6>
                <Link
                  className="btn py-0 btn-sm btn-light border text-muted"
                  to={`/seller/order/${id}/invoice`}
                >
                  {t('skip_button')}
                  <span className="fa fa-chevron-right ms-1"></span>
                </Link>
              </div>
              {transaction_items.map((horse, index) => {
                return (
                  <WidgetSummaryDetail
                    orderId={id}
                    horse={horse}
                    key={horse.id}
                    index={index + 1}
                  />
                );
              })}
              <Link
                to={`/seller/order/${id}/invoice`}
                className="btn btn-primary w-100"
              >
                <span className="fa fa-send me-2"></span>
                {t('create_final_invoice')}
              </Link>
            </div>
          )} */}
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-outline-secondary w-50"
              onClick={() => setState({ isOpen: false })}
            >
              {t('cancel_button')}
            </button>
            <Link
              to={`/seller/order/${id}/invoice`}
              className="btn btn-primary w-50 ms-3"
            >
              {t('yes_i_have_button')}
            </Link>
          </div>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
