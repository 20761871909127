import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { deactivateCompanyMember } from '../../libs/apis';

export default function WidgetDeleteSeller({ companyId, members = [] }) {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const initialState = {
    data: [],
    isOpen: false,
    isLoading: true,
    isError: false,
    isSubmit: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen } = state;

  const handleDelete = async userId => {
    const payload = {
      id: companyId,
      user_id: userId,
    };
    deactivateCompanyMember(payload)
      .then(res => {
        if (res.status === 200) {
          window.location.replace(`/company/${companyId}`);
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {});
  };

  return (
    <div className="invite-seller-widget">
      <div className="">
        <button
          className="btn btn-outline-danger w-100 btn-sm"
          onClick={() => setState({ isOpen: true })}
        >
          {t('com_delete_seller')}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="modal-container">
          <h6 className="text-danger fw-bold mb-2">{t('com_delete_seller')}</h6>
          <p className="mb-3">{t('com_delete_info')}</p>
          <div
            className="seller-list"
            style={{ maxHeight: '50vh', overflowY: 'scroll' }}
          >
            {members.map((member, index) => {
              if (member.user.id === user.id) return null;
              return (
                <div className={`seller-block card mb-2`} key={index}>
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        {member.user.photo && (
                          <img
                            src={member.user.photo}
                            alt=""
                            className="img-thumbnail me-1 rounded-circle "
                            style={{ width: '48px' }}
                          />
                        )}
                        <div className="address-detail">
                          <h6 className="small mb-1">{member.user.name}</h6>
                          <h6 className="small mb-1 fs-10">
                            {member.user.email}
                          </h6>
                          <h6 className="small mb-1 fs-10">
                            {member.user.phone}
                          </h6>
                          <p className="mb-0 me-2 fs-10 lh-1">
                            <span className="fa fa-map-marker text-danger me-1"></span>
                            {member.user.address}
                          </p>
                        </div>
                      </div>

                      <button
                        className="btn btn-sm btn-danger p-1 px-2"
                        onClick={() => handleDelete(member.user.id)}
                      >
                        {t('com_delete_seller')}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => setState({ isOpen: false })}
            className="btn mt-3 btn-sm btn-secondary w-100"
          >
            {t('close_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
