import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Page from '../../components/global/Page';
import Loader from '../../components/global/Loader';
import NotifItem from '../../components/global/NotifItem';
import LoadMore from '../../components/global/LoadMore';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../libs/apis';
import { setStorage } from '../../libs/utils';
import { readNotif } from '../../redux/slices/appSlice';

function Notifications() {
  const { t } = useTranslation();
  const { user, role } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const initialState = {
    data: [],
    isLoading: true,
    isError: false,
    current: 1,
    last: 99,
    hasNext: false,
    isLoadMore: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, current, hasNext, isLoadMore } = state;

  const isSeller = role === 'seller';

  useEffect(() => {
    const getNotificationData = () => {
      setState({ isLoading: true, isError: false });
      getNotifications()
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
            const page = resp.data.metadata.pagination.page || null;
            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            }
            const lastNotif =
              resp.data.response.length > 0
                ? resp.data.response[0].created_at
                : null;
            if (lastNotif) {
              setStorage('last_notif_time', lastNotif);
              dispatch(readNotif());
            }
          } else {
            setState({ isError: true });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getNotificationData();
  }, [dispatch]);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
    };
    await getNotifications(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ data: [...data, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };

  if (!user) return <Redirect to="/login" />;
  if (isLoading) return <Loader center />;

  return (
    <div className="app-container">
      <div className="main-content py-3">
        <Page>
          {data.length > 0 ? (
            <div className="notification-container">
              {data.map((notif, idx) => {
                return (
                  <NotifItem
                    notif={notif}
                    isSeller={isSeller}
                    key={notif.id + idx}
                  />
                );
              })}
              {hasNext && (
                <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />
              )}
            </div>
          ) : (
            <div className="content text-center py-5">
              <h3 className="mb-5">{t('notification_placeholder_text')}</h3>
              <Link to="/">
                <button className="btn mb-5 w-100 btn-primary">
                  {t('go_to_homepage_button')}
                </button>
              </Link>
            </div>
          )}
        </Page>
      </div>
    </div>
  );
}

export default Notifications;
