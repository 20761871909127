import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import { addStable, uploadImage, getGeoLocation } from '../../libs/apis';
import Resizer from 'react-image-file-resizer';
import { useSelector } from 'react-redux';
import WidgetShowMap from '../../components/global/WidgetShowMap';
import WidgetSetMap from '../../components/global/WidgetSetMap';

function AddStablePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirect = query.get('redirect');
  const go = redirect ? redirect : '/manage';
  const initialState = {
    name: '',
    logo: '',
    isLoading: false,
    isError: false,
    isSubmiting: false,
    isSubmitError: false,
    submitMessage: '',
    message: '',
    isUploading: false,
    isUploadError: false,
    phone: '',
    description: '',
    address: '',
    latitude: '',
    longitude: '',
    business_id: '',
    vat_id: '',
    lat: '',
    lng: '',
    zoom: 15,
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
    hasInput: false,
    isOpen: false,
    hasLocation: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    hasInput,
    name,
    logo,
    isLoading,
    isError,
    message,
    isUploading,
    isUploadError,
    description,
    address,
    phone,
    business_id,
    vat_id,
    lat,
    lng,
    hasLocation,
    geolocationStatus,
    isOpen,
  } = state;

  const onDropImage = e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ isUploading: true, isUploadError: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  const newUrl = res.data.secure_url.replace(
                    '/image/upload/',
                    '/image/upload/c_crop,h_1280,w_1280/',
                  );
                  setState({ logo: newUrl, hasInput: true });
                }
              })
              .catch(err => {
                setState({ isUploadError: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ isUploading: false });
              });
          },
          'base64',
          1280,
          1280,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isSubmiting: true, isSubmitError: false, submitMessage: '' });
    const payload = {
      name,
      logo,
      description,
      phone,
      business_id,
      vat_id,
      address,
      latitude: lat,
      longitude: lng,
    };
    await addStable(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const stable = resp.data.response || null;
          if (stable && stable.id) {
            if (redirect) {
              history.push(redirect);
            } else {
              history.push(`/stable/${stable.id}`);
            }
          }
        } else {
          setState({
            isSubmitError: true,
            submitMessage: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, submitMessage: err.message });
      })
      .finally(() => {
        setState({ isSubmiting: false });
      });
  };

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    if (user.latitude === '' && user.longitude === '') {
      navigator.geolocation.getCurrentPosition(
        position => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          console.log('getCurrentPosition Success ' + lat + lng); // logs position correctly
          getInitialGeoLoc(lat, lng);
          setState({
            lat: lat,
            lng: lng,
            geolocationStatus: true,
            center: [lat, lng],
          });
        },
        error => {
          console.error(JSON.stringify(error));
        },
        { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
      );
    } else {
      getInitialGeoLoc(user.latitude, user.longitude);
      setState({
        lat: user.latitude,
        lng: user.longitude,
        geolocationStatus: true,
        center: [user.latitude, user.longitude],
      });
    }
  }, [user]);

  const setLocation = loc => {
    setState({
      lat: loc.lat,
      lng: loc.lng,
      geoAddress: loc.geoAddress,
      hasLocation: true,
      isOpen: false,
    });
  };

  const cancel = () => {
    setState({
      isOpen: false,
    });
  };

  if (role === 'seller' && !user.subscription) {
    return (
      <div className="app-container" style={{ height: 'calc(100vh - 108px)' }}>
        <div
          className="container mb-5 d-flex flex-direction-col justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 108px)' }}
        >
          <div className="text-center p-5">
            <h6 className="fw-bold fs-5 mb-3">{t('pro_restriction_title')}</h6>
            <p className="text-muted">{t('pro_restriction_text')}</p>
            <a
              className="btn btn-sm btn-outline-primary"
              target="_blank"
              href="mailto:laskutus@horsesfirst.com"
              rel="noreferrer noopener"
            >
              {t('contact_horsesfirst')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <SubHeader
        go={go}
        title={t('add_new_stable')}
        prevent={hasInput}
        isClose={!redirect}
      >
        {logo === '' || name === '' ? (
          <button type="button" className="btn text-primary fw-bold" disabled>
            {t('save_button')}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btn text-primary fw-bold"
          >
            {t('save_button')}
          </button>
        )}
      </SubHeader>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {message}
                    </div>
                  )}

                  <div>
                    <div className="form-group">
                      {isUploadError && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {isUploading && <Loader size={24} />}

                      <div className="image-uploader mb-3 text-center">
                        <label
                          htmlFor="img"
                          className="position-relative d-inline-block w-auto mx-auto align-items-center btn text-center justify-content-center fw-bold overflow-hidden"
                        >
                          {logo ? (
                            <div className="mb-2 d-flex justify-content-center">
                              <img
                                src={logo}
                                alt="upload"
                                height="160"
                                width="160"
                                className="rounded-circle"
                              />
                            </div>
                          ) : (
                            <div className="mb-1 d-flex justify-content-center">
                              <div
                                style={{ width: '160px', height: '160px' }}
                                className="rounded-circle d-flex justify-content-center align-items-center flex-direction-column bg-light fs-1"
                              >
                                <span className="fa fa-image"></span>
                              </div>
                            </div>
                          )}

                          {logo ? (
                            <small>
                              {t('change_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          ) : (
                            <small>
                              {t('add_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          )}

                          <input
                            type="file"
                            id="img"
                            accept="image/*"
                            multiple={false}
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="name">
                        {t('stable_name_label')}{' '}
                        <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e =>
                          setState({ name: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('stable_name_label')}
                      />
                    </div>

                    <label htmlFor="phone">{t('phone_label')}</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={e =>
                          setState({ phone: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('phone_label')}
                      />
                    </div>
                    {/* <label htmlFor="business_id" >
                      {t('business_id')}
                    </label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="business_id"
                        value={business_id}
                        onChange={e => setState({ business_id: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="vat_id" >
                      {t('vat_id')}
                    </label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="vat_id"
                        value={vat_id}
                        onChange={e => setState({ vat_id: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div> */}
                    <label htmlFor="description">
                      {t('description_heading')}
                    </label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={e =>
                          setState({
                            description: e.target.value,
                            hasInput: true,
                          })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('description_heading')}
                        style={{ height: '80px' }}
                      />
                    </div>
                    <label htmlFor="address">{t('address_label')}</label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="address"
                        value={address}
                        onChange={e =>
                          setState({ address: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('address_label')}
                        style={{ height: '80px' }}
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="geolocation">
                        {t('geo_location_label')}
                      </label>
                      <button
                        className="btn btn-link text-decoration-none btn-sm fw-bold"
                        onClick={() => setState({ isOpen: true })}
                      >
                        {t('open_map')}
                      </button>
                    </div>
                    {!hasLocation ? (
                      <>
                        <h6 className="fs-14 mb-3 text-muted opacity-50">
                          {t('no_geolocation')}
                        </h6>
                      </>
                    ) : (
                      <div className="google-map-container">
                        {geolocationStatus && (
                          <WidgetShowMap latitude={lat} longitude={lng} />
                        )}
                      </div>
                    )}
                    {geolocationStatus && (
                      <WidgetSetMap
                        isOpen={isOpen}
                        callback={setLocation}
                        latitude={lat}
                        longitude={lng}
                        cancel={cancel}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStablePage;
