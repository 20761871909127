import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasNotification: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addNotif: (state, action) => {
      state.hasNotification = action.payload.hasNotification;
    },
    readNotif: state => {
      state.hasNotification = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNotif, readNotif } = appSlice.actions;

export default appSlice.reducer;
