import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';

// import SwiperCore, { Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';

import { getItemDetail, updateItem, getStables } from '../../libs/apis';

// SwiperCore.use([Navigation]);

export default function EditItemPage() {
  const { t } = useTranslation();
  const { horseId } = useParams();
  const history = useHistory();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    image: null,
    isLoadingStable: false,
    isErrorStable: false,
    messageStable: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
    isEditStable: false,
    stableData: [],
    selectedStable: -1,
    isSubmit: false,
    isSubmitError: false,
    isUploadError: false,
    isUploading: false,
    isOpen: false,
    hasInput: false,
    horseName: '',
    horseDateOfBirth: '',
    horseWithers: '',
    horseBreed: '',
    horseJob: '',
    horseDescription: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isError,
    // isLoadingStable,
    // isErrorStable,
    hasInput,
    horseName,
    horseDateOfBirth,
    horseWithers,
    horseBreed,
    horseJob,
    horseDescription,
    // isSubmitError
  } = state;

  useEffect(() => {
    const getItemData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: horseId,
      };
      await getItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({
              data: resp.data.response,
              image: resp.data.response.image,
            });
            setState({
              horseName: resp.data.response.name || '',
              horseDateOfBirth: resp.data.response.birthdate || '',
              horseWithers: resp.data.response.withers || '',
              horseBreed: resp.data.response.breed || '',
              horseJob: resp.data.response.job || '',
              horseDescription: resp.data.response.description || '',
            });
            if (resp.data.response.stable) {
              setState({ selectedStable: resp.data.response.stable.id });
            }
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, message: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getStableData = async () => {
      setState({
        isLoadingStable: true,
        isErrorStable: false,
        messageStable: '',
      });
      await getStables()
        .then(resp => {
          if (resp.status === 200) {
            setState({ stableData: resp.data.response });
          } else {
            setState({
              isErrorStable: true,
              messageStable: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          // setState({ isErrorStable: true, messageStable: err.error.message });
        })
        .finally(() => setState({ isLoadingStable: false }));
    };
    getItemData();
    getStableData();
  }, [horseId]);

  if (!horseId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const updateHorse = async () => {
    setState({ isSubmit: true, isSubmitError: false, submitMessage: '' });
    const payload = data;
    payload.stable_id = data.stable ? data.stable.id : null;
    payload.category_id = data.category.id;
    payload.name = horseName;
    payload.birthdate = horseDateOfBirth;
    payload.withers = horseWithers;
    payload.breed = horseBreed;
    payload.job = horseJob;
    payload.description = horseDescription;
    await updateItem(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ data: resp.data.response });
          setTimeout(() => {
            history.push(`/horse/${horseId}`);
          }, 1000);
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isSubmitError: true });
      })
      .finally(() => setState({ isSubmit: false }));
  };

  return (
    <div className="app-container">
      <SubHeader
        title={t('horse_detail_title')}
        go={`/horse/${horseId}`}
        prevent={hasInput}
      >
        {horseName !== '' ? (
          <button onClick={updateHorse} className="btn text-primary">
            {t('save_horse_button')}
          </button>
        ) : (
          <button type="button" className="btn text-secondary" disabled>
            {t('save_horse_button')}
          </button>
        )}
      </SubHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="main-content">
              <div className={`horse-info-container`}>
                <>
                  <div className="form-group mb-2">
                    <label className="fs-14" htmlFor="horse_name">
                      {t('horse_name_label')}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={horseName}
                      id="horse_name"
                      name="horse_name"
                      onChange={e => setState({ horseName: e.target.value })}
                      placeholder={t('horse_name_label')}
                    />
                  </div>

                  <div className="horse-data">
                    <div className="form-group mb-2">
                      <label className="fs-14" htmlFor="horse_date_of_birth">
                        {t('horse_date_of_birth')}
                      </label>

                      <input
                        type="text"
                        className="form-control "
                        id="horse_date_of_birth"
                        name="horse_date_of_birth"
                        value={horseDateOfBirth}
                        onChange={e => {
                          setState({
                            horseDateOfBirth: e.target.value,
                          });
                        }}
                        placeholder={t('horse_date_of_birth')}
                      />
                    </div>
                  </div>

                  <div className="billing-data">
                    <div className="form-group mb-2">
                      <label className="fs-14" htmlFor="horseWithers">
                        {t('horse_withers')}
                      </label>

                      <input
                        type="text"
                        className="form-control "
                        id="horseWithers"
                        name="horseWithers"
                        value={horseWithers}
                        onChange={e => {
                          setState({
                            horseWithers: e.target.value,
                          });
                        }}
                        placeholder={t('horse_withers')}
                      />
                    </div>
                  </div>

                  <div className="billing-data">
                    <div className="form-group mb-2">
                      <label className="fs-14" htmlFor="horseBreed">
                        {t('horse_breed')}
                      </label>

                      <input
                        type="text"
                        className="form-control "
                        id="horseBreed"
                        name="horseBreed"
                        value={horseBreed}
                        onChange={e => {
                          setState({
                            horseBreed: e.target.value,
                          });
                        }}
                        placeholder={t('horse_breed')}
                      />
                    </div>
                  </div>

                  <div className="billing-data">
                    <div className="form-group mb-3">
                      <label className="fs-14" htmlFor="horseJob">
                        {t('horse_job')}
                      </label>

                      <input
                        type="text"
                        className="form-control "
                        id="horseJob"
                        name="horseJob"
                        value={horseJob}
                        onChange={e => {
                          setState({
                            horseJob: e.target.value,
                          });
                        }}
                        placeholder={t('horse_job')}
                      />
                    </div>
                  </div>

                  <div className="billing-data">
                    <div className="form-group mb-3">
                      <label className="fs-14" htmlFor="horseDescription">
                        {t('horse_description')}
                      </label>

                      <textarea
                        className="form-control "
                        id="horseDescription"
                        name="horseDescription"
                        value={horseDescription}
                        onChange={e => {
                          setState({
                            horseDescription: e.target.value,
                          });
                        }}
                        rows={4}
                        placeholder={t('horse_description')}
                      ></textarea>
                    </div>
                  </div>
                </>

                {/* <div className="form-group mb-2">
                        <div className="row g-2">
                          <div className="col-4">
                            <label className="fs-14" htmlFor="billing_email">
                              Billing Name:
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              type="text"
                              className="form-control "
                              value={billing_name}
                              onChange={e =>
                                setState({ billing_name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
