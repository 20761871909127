import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTransaction } from '../../libs/apis';
import Moment from 'react-moment';
import 'moment/locale/fi';
import HorseIcon from './HorseIcon';

export default function TransactionHistory({ horseId = null }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const { user } = useSelector(state => state.auth);
  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { orders } = state;

  useEffect(() => {
    const getOrderData = async () => {
      let payload = {
        all: true,
      };
      if (horseId) {
        payload.itemId = horseId;
      } else {
        payload.statusFrom = 4;
      }
      await getTransaction(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, [horseId]);

  if (!user) return null;

  return (
    <div className="order-container">
      <div className="content py-0">
        {orders.length > 0 ? (
          <div className="order-list-container">
            {orders.map(order => {
              const {
                id,
                product,
                confirmation_date,
                created_at,
                transaction_items,
              } = order;

              return (
                <div
                  key={id}
                  className="border rounded mb-3 bg-white p-2 position-relative"
                >
                  <div className="d-flex justify-content-between mb-2">
                    <div className="item-header">
                      <h6 className="fw-bold fs-14 mb-1">
                        <Link
                          to={`/order/${id}`}
                          className="stretched-link text-decoration-none text-reset"
                        >
                          {product.name}
                        </Link>
                      </h6>
                      <div className="d-flex align-items-center">
                        {product.seller.photo && (
                          <img
                            src={product.seller.photo}
                            width="16"
                            className="me-1 rounded-circle"
                            alt={product.seller.name}
                          />
                        )}
                        <h6 className="mb-0 fs-10 text-muted">
                          {product.seller.name}
                        </h6>
                      </div>
                    </div>
                    <div className="item-meta">
                      <h6 className="fs-8 text-muted text-right">
                        <span className="fa fa-calendar-o me-2"></span>
                        <Moment locale={lang} format="MMM DD, Y">
                          {confirmation_date ? confirmation_date : created_at}
                        </Moment>
                      </h6>
                    </div>
                  </div>
                  {!horseId && (
                    <div className="pt-1 border-top d-flex align-items-center">
                      <h6 className="me-2 mb-0 fs-12">
                        <HorseIcon size={12} />
                      </h6>
                      <h6 className="fs-12 fw-bold mb-0">
                        {transaction_items.map((h, index) => {
                          if (index === transaction_items.length - 1)
                            return <span key={h.id}>{h.item.name}</span>;
                          return <span key={h.id}>{h.item.name}, </span>;
                        })}
                      </h6>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center p-3">
            <h6>{t('there_is_no_horse_order')}</h6>
          </div>
        )}
      </div>
    </div>
  );
}
