import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import Page from '../../components/global/Page';
import InternalHeader from '../../components/global/InternalHeader';
import { useSelector, useDispatch } from 'react-redux';
import { getToken, getProfile } from '../../libs/apis';
import { login } from '../../redux/slices/authSlice';

function LoginPage() {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search);
  const u = search.u || null;

  const dispatch = useDispatch();

  const initialState = {
    email: '',
    password: '',
    error: false,
    message: '',
    loading: false,
  };

  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };

  const { email, password, error, message, loading } = state;

  if (user) {
    history.push('/');
  }

  const handleProfile = async token => {
    setState({ loading: true, error: false, message: '' });
    await getProfile(token)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            const payload = {
              user: resp.data.response,
              token: token,
            };
            dispatch(login(payload));
          }
        } else {
          setState({ error: true, message: resp.data.error.message });
        }
        if (search.redirect) {
          //history.push(search.redirect)
        } else {
          //history.push('/profile');
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ error: true, message: 'Error', loading: false });
      })
      .finally(() => {
        setState({ loading: false });
      });
  };

  const handleLogin = async e => {
    e.preventDefault();
    setState({ loading: true, error: false, message: '' });
    const payload = {
      email,
      password,
    };
    await getToken(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response.token) {
            // setCredentials(resp.data.response);
            handleProfile(resp.data.response.token);
          }
        } else {
          setState({
            error: true,
            message: resp.data.error.message,
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ error: true, message: 'Error' });
      })
      .finally(() => {
        console.log('finish login');
      });
  };
  useEffect(() => {
    setState({ error: false, loading: false, message: '' });
  }, [history]);

  return (
    <div className="app-container">
      <InternalHeader go="/" title={t('login_page')} />
      <div className="alert alert-success p-2 shadow rounded-0 mb-0">
        <p className="m-0 lh-sm text-center">{t('register_info_text')}</p>
      </div>
      <div className="main-content py-3">
        <Page>
          {/* <h1 className="mb-3 text-center">Login</h1> */}
          {error ? (
            <div className="alert alert-danger fade show" role="alert">
              {message}
            </div>
          ) : null}
          <form onSubmit={handleLogin}>
            <div className="p-3">
              {u === 'seller' && (
                <h5 className=" mb-3 text-center">
                  {t('login_page_seller_heading')}
                </h5>
              )}
              {!u && (
                <h5 className=" mb-3 text-center">{t('login_page_heading')}</h5>
              )}

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  required
                  value={email}
                  onChange={e => setState({ email: e.target.value })}
                  disabled={loading}
                  placeholder={t('email_label')}
                />
                <label htmlFor="email">{t('email_label')}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  required
                  minLength="8"
                  value={password}
                  onChange={e => setState({ password: e.target.value })}
                  disabled={loading}
                  placeholder={t('password_label')}
                />
                <label htmlFor="password">{t('password_label')}</label>
              </div>

              <button
                className="btn mt-1 bg-gradient btn-lg btn-primary shadow w-100"
                type="submit"
                disabled={loading}
              >
                {t('login_button')}
              </button>
            </div>
          </form>

          <div className="p-3 text-center">
            <p>
              {t('forgot_password_info')}{' '}
              <Link
                to="/forgot-password"
                className="btn btn-secondary rounded-3 btn-sm p-1 py-0 text-decoration-none"
              >
                {t('forgot_password_button')}
              </Link>
            </p>
          </div>

          <div
            className="p-4 py-5 border-top  text-center"
            style={{ margin: '0 -0.75rem' }}
          >
            <h3>{t('or_register_heading')}</h3>
            <div className="row my-3">
              {/* <div className="col col-sm-6">
                <Link
                  to="/register-seller"
                  className="btn btn-lg bg-gradient shadow btn-primary lh-sm w-100"
                >
                  <span className="text">
                    <small>{t('login_button')}</small>
                    <br />
                    <strong>{t('seller')}</strong>
                  </span>
                </Link>
              </div> */}
              <div className="col col-sm-12">
                <Link
                  to="/register-buyer"
                  className="btn shadow bg-gradient btn-outline-primary btn-lg lh-sm w-100"
                >
                  <span className="text">{t('sign_up_button')}</span>
                </Link>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default LoginPage;
