import React from 'react';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

export default function HorseSummary({ horse, index }) {
  const { item, summary_gallery, summary_text } = horse;
  const { image, name } = item;
  return (
    <div className="p-2 border border-2 border-secondary shadow mb-3 rounded bg-light bg-gradient">
      <div className="d-flex mb-3 align-items-start justify-content-between">
        <h6 className="small mb-0">
          <span className="me-2 text-muted">#{index}</span>
          {image && (
            <img
              src={image}
              alt=""
              style={{ width: '32px', height: '32px' }}
              className="me-2 rounded"
            />
          )}
          {name}
        </h6>
        {summary_text && summary_text !== '' && (
          <div className="alert alert-warning m-0 p-2">
            <p className="text-center fs-14 mb-0">{summary_text}</p>
          </div>
        )}
      </div>

      {summary_gallery && summary_gallery.length > 0 && (
        <div className="horse-gallery sgallery-container mb-2">
          <div className="card shadow">
            {summary_gallery.length > 1 && (
              <div className="swiper-navigations d-flex align-items-center justify-content-between">
                <button className="swiper-nav swiper-prev btn btn-sm py-1 btn-light ">
                  <span className="fa fa-chevron-left"></span>
                </button>

                <button className="swiper-nav swiper-next btn btn-sm btn-light py-1">
                  <span className="fa fa-chevron-right"></span>
                </button>
              </div>
            )}
            <div className="card-body p-0">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoHeight={true}
                navigation={{
                  nextEl: '.swiper-next',
                  prevEl: '.swiper-prev',
                }}
              >
                {summary_gallery.map((g, index) => (
                  <SwiperSlide key={g + index}>
                    <img src={g} alt={g + index} className="w-100" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
