import React from 'react';

import Page from '../../components/global/Page';
import Calendar from '../../components/calendar/Calendar';

function SchedulePage() {
  return (
    <div className="app-container">
      <Page>
        <Calendar />
      </Page>
    </div>
  );
}

export default SchedulePage;
