import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';
import { updateStable, uploadImage, getStableDetail } from '../../libs/apis';
import Resizer from 'react-image-file-resizer';
import WidgetShowMap from '../../components/global/WidgetShowMap';
import WidgetSetMap from '../../components/global/WidgetSetMap';

function AddItemPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { stableId } = useParams();
  const initialState = {
    name: '',
    logo: '',
    isLoading: true,
    isError: false,
    isSubmiting: false,
    isSubmitError: false,
    submitMessage: '',
    message: '',
    isUploading: false,
    isUploadError: false,
    phone: '',
    description: '',
    address: '',
    latitude: '',
    longitude: '',
    business_id: '',
    vat_id: '',
    lat: '',
    lng: '',
    zoom: 15,
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
    hasLocation: false,
    isOpen: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    name,
    logo,
    isLoading,
    isError,
    message,
    isUploading,
    isUploadError,
    description,
    address,
    phone,
    business_id,
    vat_id,
    lat,
    lng,
    geolocationStatus,
    hasLocation,
    isOpen,
  } = state;

  const onDropImage = e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ isUploading: true, isUploadError: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  const newUrl = res.data.secure_url.replace(
                    '/image/upload/',
                    '/image/upload/c_crop,h_1280,w_1280/',
                  );
                  setState({ logo: newUrl });
                }
              })
              .catch(err => {
                setState({ isUploadError: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ isUploading: false });
              });
          },
          'base64',
          1280,
          null,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isSubmiting: true, isSubmitError: false, submitMessage: '' });
    const payload = {
      id: stableId,
      name,
      logo,
      description,
      phone,
      business_id,
      vat_id,
      address,
      latitude: lat,
      longitude: lng,
    };
    await updateStable(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const stable = resp.data.response || null;
          if (stable && stable.id) {
            history.push(`/stable/${stable.id}`);
          }
        } else {
          setState({
            isSubmitError: true,
            submitMessage: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, submitMessage: err.message });
      })
      .finally(() => {
        setState({ isSubmiting: false });
      });
  };

  useEffect(() => {
    const getStableData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: stableId,
      };
      await getStableDetail(payload)
        .then(resp => {
          let stable = null;
          if (resp.status === 200) {
            stable = resp.data.response;
            setState({
              ...stable,
            });
            if (stable.latitude && stable.longitude) {
              setState({
                lat: stable.latitude,
                lng: stable.latitude,
                hasLocation: true,
                geolocationStatus: true,
              });
            } else {
              if (stable.user.latitude && stable.user.longitude) {
                setState({
                  lat: stable.user.latitude,
                  lng: stable.user.longitude,
                  geolocationStatus: true,
                });
              }
            }
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, messsage: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getStableData();
  }, [stableId]);

  const setLocation = loc => {
    setState({
      lat: loc.lat,
      lng: loc.lng,
      geoAddress: loc.geoAddress,
      hasLocation: true,
      isOpen: false,
    });
  };

  const cancel = () => {
    setState({ isOpen: false });
  };

  return (
    <div className="app-container">
      <SubHeader go="/manage" title={t('edit_stable_heading')}>
        {logo === '' || name === '' ? (
          <button type="button" className="btn text-secondary" disabled>
            {t('save_horse_button')}
          </button>
        ) : (
          <button onClick={handleSubmit} className="btn text-primary">
            {t('save_horse_button')}
          </button>
        )}
      </SubHeader>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {message}
                    </div>
                  )}

                  <div>
                    <div className="form-group">
                      {isUploadError && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {isUploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        <label
                          htmlFor="img"
                          className="position-relative d-inline-block w-auto mx-auto align-items-center btn text-center justify-content-center fw-bold overflow-hidden"
                        >
                          {logo ? (
                            <div className="mb-2 d-flex justify-content-center">
                              <img
                                src={logo}
                                alt="upload"
                                height="160"
                                width="160"
                                className="rounded-circle"
                              />
                            </div>
                          ) : (
                            <div className="mb-1 d-flex justify-content-center">
                              <div
                                style={{ width: '160px', height: '160px' }}
                                className="rounded-circle d-flex justify-content-center align-items-center flex-direction-column bg-light fs-1"
                              >
                                <span className="fa fa-image"></span>
                              </div>
                            </div>
                          )}

                          {logo ? (
                            <small>
                              {t('change_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          ) : (
                            <small>
                              {t('add_photo')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          )}

                          <input
                            type="file"
                            id="img"
                            accept="image/*"
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="name">
                        {t('stable_name_label')}{' '}
                        <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('stable_name_label')}
                      />
                    </div>

                    <label htmlFor="phone">{t('phone_label')}</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={e => setState({ phone: e.target.value })}
                        disabled={isLoading}
                        placeholder={t('phone_label')}
                        required
                      />
                    </div>
                    <label htmlFor="description">
                      {t('description_heading')}
                    </label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={e =>
                          setState({ description: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('description_heading')}
                        style={{ height: '80px' }}
                      />
                    </div>
                    <label htmlFor="address">{t('address_label')}</label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="address"
                        value={address}
                        onChange={e => setState({ address: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('address_label')}
                        style={{ height: '80px' }}
                      />
                    </div>

                    <div className="mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="geolocation">
                          {t('geo_location_label')}
                        </label>
                        <button
                          className="btn btn-link text-decoration-none btn-sm fw-bold"
                          onClick={() => setState({ isOpen: true })}
                        >
                          {t('open_map')}
                        </button>
                      </div>
                      {!hasLocation ? (
                        <>
                          <h6 className="fs-14 mb-3 text-muted opacity-50">
                            {t('no_geolocation')}
                          </h6>
                        </>
                      ) : (
                        <div className="google-map-container">
                          {geolocationStatus && (
                            <WidgetShowMap latitude={lat} longitude={lng} />
                          )}
                        </div>
                      )}
                      {geolocationStatus && (
                        <WidgetSetMap
                          isOpen={isOpen}
                          callback={setLocation}
                          latitude={lat}
                          longitude={lng}
                          cancel={cancel}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItemPage;
