import React, { useState, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import AutocompleteCustomer from '../../components/global/AutocompleteCustomer';
import AutocompleteHorse from '../../components/global/AutocompleteHorse';
import AutocompleteProduct from '../../components/global/AutocompleteProduct';
import SubHeader from '../../components/global/SubHeader';
import CountUp from 'react-countup';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import {
  getGeoLocation,
  createTransaction,
  createCalendar,
  uploadImage,
  getSellerInventory,
  addTransactionAdditionalItemsBatch,
  createInvoice,
  sendInvoiceEmail,
  updateTransactionStatus,
  addTransactionItemDetail,
} from '../../libs/apis';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import fi from 'date-fns/locale/fi';
import Resizer from 'react-image-file-resizer';
import Loader from '../../components/global/Loader';
import NumberFormat from 'react-number-format';
import { numberFormat } from '../../libs/utils';
registerLocale('fi', fi);

function CreateProOrder() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const lang = i18n ? (i18n.language === 'en-US' ? 'en' : i18n.language) : 'en';
  moment.locale(lang);

  const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
  const roundUpTo30Minutes = roundUpTo(1000 * 60 * 30);
  const now = new Date();
  const msUp = roundUpTo30Minutes(now);

  const { user } = useSelector(state => state.auth);
  const initialState = {
    date: query.get('datetime')
      ? moment(query.get('datetime')).toDate()
      : new Date(msUp),
    isRecurring: false,
    sameAddress: true,
    isLoading: false,
    isError: false,
    interval: 1,
    cycle: 7,
    notes: '',
    selectedCustomer: null,
    selectedProduct: null,
    selectedHorse: null,
    lat: user ? (user.latitude ? parseFloat(user.latitude) : '') : '',
    lng: user ? (user.longitude ? parseFloat(user.longitude) : '') : '',
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
    zoom: 15,
    deliveryAddress: '',
    isOpen: false,
    isSubmit: false,
    isErrorSubmit: false,
    directInvoice: false,
    summaryPhoto: '',
    uploadGallery: false,
    summaryGallery: [],
    items: [],
    works: [],
    worksQty: 0,
    itemQty: 0,
    discountItem: 0,
    discountWork: 0,
    price: 0,
    defaultCustomerId: query.get('customer_id'),
    defaultProductId: query.get('product_id'),
    defaultHorseId: query.get('horse_id'),
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    lat,
    lng,
    geolocationStatus,
    geoAddress,
    deliveryAddress,
    draggable,
    zoom,
    center,
    date,
    isRecurring,
    sameAddress,
    interval,
    cycle,
    notes,
    selectedCustomer,
    selectedProduct,
    selectedHorse,
    isOpen,
    directInvoice,
    isSubmit,
    discountWork,
    discountItem,
    itemQty,
    worksQty,
    items,
    works,
    summaryGallery,
    uploadGallery,
    summaryPhoto,
    price,
    defaultCustomerId,
    defaultProductId,
    defaultHorseId,
  } = state;

  const selectCustomer = customer => {
    setState({ selectedCustomer: customer, defaultCustomerId: customer.id });
  };
  const resetCustomer = () => {
    setState({ selectedCustomer: null, defaultCustomerId: null });
  };
  const selectProduct = product => {
    setState({ selectedProduct: product, defaultProductId: product.id });
  };
  const resetProduct = () => {
    setState({ selectedProduct: null, defaultProductId: null });
  };
  const selectHorse = horse => {
    setState({ selectedHorse: horse, defaultHorseId: horse.id });
  };
  const resetHorse = () => {
    setState({ selectedHorse: null, defaultHorseId: null });
  };

  const onDropGallery = async e => {
    const file = e.target.files;
    if (file[0]) {
      try {
        setState({ uploadGallery: true, errorGallery: false });
        Resizer.imageFileResizer(
          file[0],
          1280,
          1280,
          'JPEG',
          80,
          0,
          uri => {
            const payload = {
              file: uri,
            };
            uploadImage(payload)
              .then(res => {
                if (res.status === 200) {
                  setState({
                    summaryGallery: [...summaryGallery, res.data.secure_url],
                    summaryPhoto: '',
                  });
                }
              })
              .catch(err => {
                setState({ errorGallery: true });
                console.log('err', err);
              })
              .finally(() => {
                setState({ uploadGallery: false });
              });
          },
          'base64',
          1280,
          null,
        );
      } catch (err) {
        console.log(err);
        setState({ isUploadError: true });
      }
    }
  };

  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
          // console.log('geo', geo)
        }
      }
    });
  };
  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };
  const onMapDragEnd = e => {
    setState({
      lat: e.center.lat(),
      lng: e.center.lng(),
    });
    getGeoLoc(lat, lng);
  };

  const deleteGallery = item => {
    const newGallery = summaryGallery.filter(e => e !== item);
    setState({ summaryGallery: newGallery });
  };

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    if (lat === '' && lng === '') {
      navigator.geolocation.getCurrentPosition(
        position => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
          getInitialGeoLoc(latitude, longitude);
          setState({
            lat: latitude,
            lng: longitude,
            geolocationStatus: true,
            center: [latitude, longitude],
          });
        },
        error => {
          console.error(JSON.stringify(error));
        },
        { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
      );
    } else {
      getInitialGeoLoc(lat, lng);
      setState({ geolocationStatus: true, center: [lat, lng] });
    }
  }, [lat, lng]);

  useEffect(() => {
    (async () => {
      // get items
      const payloadItems = {
        type: 0,
        company_id: selectedProduct?.company?.id ?? null,
      };
      await getSellerInventory(payloadItems)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let itemQty = [];
              resp.data.response.forEach(() => itemQty.push(0));
              setState({ items: resp.data.response, itemQty: itemQty });
            }
          } else {
            setState({
              isErrorItem: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorItem: true });
        })
        .finally(() => setState({ isLoadingItem: false }));

      // get works
      const payloadWorks = {
        type: 1,
        company_id: selectedProduct?.company?.id ?? null,
      };
      await getSellerInventory(payloadWorks)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let worksQty = [];
              resp.data.response.forEach(() => worksQty.push(0));
              setState({ works: resp.data.response, worksQty: worksQty });
            }
          } else {
            setState({
              isErrorWorks: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorWorks: true });
        })
        .finally(() => setState({ isLoadingWorks: false }));
    })();
  }, [selectedProduct]);

  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));

  const subtractQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ worksQty: qtys });
    }
  };

  const addQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      qtys[i] += 1;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      qtys[i] += 1;
      setState({ worksQty: qtys });
    }
  };

  let estPrice = price;
  items.forEach((item, index) => {
    const eachItemPrice = item.price * itemQty[index];
    let discountItemPrice = 0;
    const vatItem = item.vat;
    if (discountItem > 0 && eachItemPrice > 0) {
      discountItemPrice = eachItemPrice * (discountItem / 100);
    }
    let vatItemPrice = 0;
    if (vatItem > 0 && eachItemPrice > 0) {
      vatItemPrice = (eachItemPrice - discountItemPrice) * (vatItem / 100);
    }
    estPrice += eachItemPrice - discountItemPrice + vatItemPrice;
  });
  works.forEach((work, index) => {
    // estPrice += work.price * worksQty[index];
    const eachWorkPrice = work.price * worksQty[index];
    let discountWorkPrice = 0;
    const vatWork = work.vat;
    if (discountWork > 0 && eachWorkPrice > 0) {
      discountWorkPrice = (eachWorkPrice * discountWork) / 100;
    }
    let vatWorkPrice = 0;
    if (vatWork > 0 && eachWorkPrice > 0) {
      vatWorkPrice = ((eachWorkPrice - discountWorkPrice) * vatWork) / 100;
    }
    estPrice += eachWorkPrice - discountWorkPrice + vatWorkPrice;
  });

  const handleSubmit = async () => {
    const skip = directInvoice;
    setState({
      isSubmit: true,
      isErrorSubmit: false,
    });
    let type = 1;
    if (isRecurring) type = 3;
    const dayCount = interval * cycle;
    let newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    const payload = {
      customer_id: selectedCustomer ? selectedCustomer.id : null,
      product_id: selectedProduct.id,
      price: estPrice > 0 ? estPrice : 0,
      datetime: newDate,
      note: notes,
      items: selectedHorse ? [selectedHorse.id] : [],
      latitude: lat,
      longitude: lng,
      address: deliveryAddress,
      interval: dayCount,
      type,
      status: skip ? 4 : 3,
    };

    await createTransaction(payload)
      .then(resp => {
        if (resp.status === 200 || resp.status === 201) {
          const transData = resp.data.response;
          if (skip) {
            submitSummary(transData);
          } else {
            submitCalendar(transData);
          }
          // submitCalendar(transData, skip);
          // history.replace(`/order/${resp.data.response.id}`);
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isErrorSubmit: true });
      });

    // console.log('payload', payload, skip);
  };

  const submitGallery = async data => {
    if (selectedHorse) {
      setState({ isLoadingSummary: true, isError: false });
      const payload = {
        id: data.id,
        itemId: data.transaction_items[0].id,
      };
      if (summaryGallery.length > 0) {
        payload.summary_gallery = summaryGallery;
      }
      if (notes !== '') {
        payload.summary_text = notes;
      }

      await addTransactionItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            handleInvoice(data);
          }
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => {
          setState({ isLoadingSummary: false });
        });
    } else {
      handleInvoice(data);
    }
  };

  const submitSummary = async data => {
    setState({ isLoadingSummary: true, isSubmit: true, isError: false });
    const payload = {
      id: data.id,
    };
    if (summaryPhoto !== '') {
      payload.summary_photo = summaryPhoto;
    }
    if (notes !== '') {
      payload.summary_text = notes;
    }
    await updateTransactionStatus(payload)
      .then(resp => {
        if (resp.status === 200) {
          submitGallery(data);
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setState({ isLoadingSummary: false });
      });
  };

  const sendEmail = async data => {
    const payload = {
      id: data.id,
    };
    await sendInvoiceEmail(payload).finally(() => {
      setState({ isBidding: false, isSubmit: false });
      history.replace(`/seller/order/${data.id}`);
    });
  };

  const generatePdf = async data => {
    const payload = {
      id: data.id,
    };
    await createInvoice(payload).then(resp => {
      if (resp.status === 200) {
        if (resp.data.response) {
          const file =
            resp.data.response.path && resp.data.response.path !== ''
              ? resp.data.response.path
              : null;
          if (file) {
            sendEmail(data);
          } else {
            history.replace(`/seller/order/${data.id}`);
          }
        }
      }
    });
  };

  const handleInvoice = async data => {
    setState({ isSubmit: true, isSubmitError: false });
    let additionalItems = [];
    items.forEach((item, index) => {
      const price = item.price * itemQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: item.id,
          price: item.price,
          quantity: itemQty[index],
          discount: parseFloat(discountItem),
          vat: parseFloat(item.vat),
        };
        additionalItems.push(newItem);
      }
    });
    works.forEach((work, index) => {
      const price = work.price * worksQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: work.id,
          price: work.price,
          quantity: worksQty[index],
          discount: parseFloat(discountWork),
          vat: parseFloat(work.vat),
        };
        additionalItems.push(newItem);
      }
    });

    const payload = {
      id: data.id,
      params: additionalItems,
    };
    await addTransactionAdditionalItemsBatch(payload).then(resp => {
      if (resp.status === 201) {
        if (selectedCustomer && selectedCustomer.is_monthly_billing_active) {
          history.replace(`/seller/order/${data.id}`);
        } else {
          generatePdf(data);
        }
      }
    });
  };

  const submitCalendar = async data => {
    const payload = {
      user_id: data.product.seller.id,
      type: 0,
      transaction_id: data.id,
      start_datetime: data.transaction_request[0].datetime,
      end_datetime: moment(data.transaction_request[0].datetime)
        .add(data.transaction_items.length, 'hours')
        .format('YYYY-MM-DD HH:mm:ss'),
    };
    await createCalendar(payload)
      .then(resp => {
        if (resp.status === 200 || resp.status === 201) {
          history.replace(`/seller/order/${data.id}`);
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isErrorSubmit: true });
      })
      .finally(() => setState({ isLoadingSubmit: false }));
  };

  const productComponent = (
    <div className="product-container mb-5">
      <AutocompleteProduct
        productId={defaultProductId}
        callback={selectProduct}
        required={true}
      />
      {selectedProduct && (
        <div className="selected-product border border-2 rounded-1 p-3 mb-0">
          <div className="d-flex align-items-start">
            {selectedProduct.image && (
              <img
                src={selectedProduct.image}
                alt={selectedProduct.name}
                width="48"
                className="rounded me-3 align-self-start"
              />
            )}
            <div className="product-detail flex-1">
              <h4 className="fw-bold fs-6 mb-1">
                {selectedProduct.is_default && (
                  <span className="fa fa-star me-1 text-warning"></span>
                )}
                {selectedProduct.name}
              </h4>
              <h5 className="fs-10 mb-0 text-muted">
                <span className="fa fa-tag me-1"></span>
                {selectedProduct.category.name}
              </h5>
              {selectedProduct.company && (
                <h5 className="fs-10 mb-0 text-muted">
                  <span className="fa fa-building me-1"></span>
                  {selectedProduct.company.name}
                </h5>
              )}
              <h5 className="fs-6 mb-0 mt-1 fw-bold">
                {t('$')}
                {selectedProduct.price_min} - {selectedProduct.price_max}
              </h5>
            </div>
            <button
              className="align-self-center btn text-muted"
              onClick={resetProduct}
            >
              <span className="fa fa-trash"></span>
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const customerComponent = (
    <div className="customer-container mb-5">
      <AutocompleteCustomer
        customerId={defaultCustomerId}
        callback={selectCustomer}
        required={directInvoice}
      />
      {selectedCustomer && (
        <div className="selected-product border border-2 rounded-1 p-3 mb-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="flex-1">
              <h6 className="fw-bold">{selectedCustomer.name}</h6>
              <h6 className="text-muted mb-0 fs-14">
                {selectedCustomer.email}
              </h6>
              {selectedCustomer.is_monthly_billing_active && (
                <h6 className="fs-12 mt-1 text-muted fw-light mb-0">
                  {t('monthly_billing_info')}
                </h6>
              )}
            </div>
            <button className="btn text-muted" onClick={resetCustomer}>
              <span className="fa fa-trash"></span>
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const horseComponent = (
    <div className="horse-container mb-5">
      <AutocompleteHorse
        horseId={defaultHorseId}
        callback={selectHorse}
        required={!directInvoice}
      />
      {selectedHorse && (
        <div className="selected-product border border-2 rounded-1 p-3 mb-0">
          <div className="d-flex align-items-center">
            {selectedHorse.image && (
              <img
                src={selectedHorse.image}
                alt={selectedHorse.name}
                width="48"
                className="rounded me-3"
              />
            )}
            <div className="product-detail flex-1">
              <h6 className="fw-bold mb-1">
                {selectedHorse.name}
                {selectedHorse.billing_active ? (
                  <span className=" d-inline-block p-1 rounded-pill border border-2 text-primary ms-2 fs-8 fw-bold">
                    {t('billing_info_active')}
                  </span>
                ) : (
                  <span className="border border-2 d-inline-block p-1 rounded-pill ms-2 fs-8 fw-bold">
                    {t('billing_info_inactive')}
                  </span>
                )}
              </h6>
              {selectedHorse.stable && (
                <h5 className="fs-10 mb-0 text-muted">
                  {selectedHorse.stable.name}
                </h5>
              )}
            </div>
            <button className="btn text-muted" onClick={resetHorse}>
              <span className="fa fa-trash"></span>
            </button>
          </div>
        </div>
      )}
      {/* <div className="horse-menu d-flex justify-content-between">
      <h3 className="fs-6 fw-bold text-decoration-none">Horse</h3>
      <h3 className="fs-6 fw-bold text-decoration-none">Add</h3>
    </div>
    <p>No horse has been selected</p> */}
    </div>
  );

  const orderNotesComponent = (
    <div className="form-field mb-3">
      <h6 className="fw-bold mb-2">{t('additional_notes')}</h6>
      <textarea
        className="form-control mb-4"
        name="notes"
        id="notes"
        rows="5"
        value={notes}
        onChange={e =>
          setState({
            notes: e.target.value,
          })
        }
        placeholder={t('write_a_note_label')}
      ></textarea>
    </div>
  );

  // const invoiceNotesComponent = (
  //   <div className="form-field mb-3">
  //     <label>{t('invoice_notes')}</label>
  //     <textarea
  //       className="form-control mb-4"
  //       name="notes"
  //       id="notes"
  //       rows="5"
  //       value={notes}
  //       onChange={e =>
  //         setState({
  //           invoiceNotes: e.target.value,
  //         })
  //       }
  //       placeholder={t('write_a_note_label')}
  //     ></textarea>
  //   </div>
  // );

  const additionalComponent = (
    <div className="additonal-container mb-5">
      <h3 className="fs-6 fw-bold mb-2">{t('time_and_date_heading')}</h3>
      <div className="form-field">
        {/* <label className="mb-1">{t('service_date')}</label> */}
        <DatePicker
          showTimeSelect
          timeIntervals={30}
          selected={date}
          locale={lang}
          onChange={date => setState({ date: date })}
          customInput={<CustomInputCalendar />}
          dateFormat="MMM dd, yyyy - HH:mm"
          className="w-100"
        />
      </div>
    </div>
  );

  const recurringOrderComponent = (
    <div className="recuring-order-container mb-4">
      <div className="recuring-menu d-flex justify-content-between">
        <h3 className="fs-6 fw-bold">{t('recurring_order_heading')}</h3>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            name="isRecurring"
            id="isRecurring"
            value={isRecurring}
            onChange={e => setState({ isRecurring: e.target.checked })}
          />
        </div>
      </div>
      <div className={`recurring-interval ${isRecurring ? '' : 'd-none'}`}>
        {/* <label htmlFor="interval">{t('recurring_interval_label')}</label> */}
        <div className="d-flex align-items-center pt-2">
          <label
            htmlFor="interval"
            className="fs-14 w-50"
            style={{ textTransform: 'none' }}
          >
            {t('every_text')}
          </label>

          <input
            type="number"
            className="form-control mx-3 w-50"
            id="interval"
            name="interval"
            value={interval}
            disabled={!isRecurring}
            onChange={e => setState({ interval: e.target.valueAsNumber })}
          />
          <select
            id="cycle"
            name="cycle"
            value={cycle}
            className="form-select w-50"
            disabled={!isRecurring}
            onChange={e => setState({ cycle: e.target.valueAsNumber })}
          >
            <option value={7}>{t('weekly_text')}</option>
            <option value={30}>{t('monthly_text')}</option>
          </select>
        </div>
      </div>
    </div>
  );

  const deliveryAddressComponent = (
    <div className="delivery-address-container mb-5">
      <h3 className="fs-6 fw-bold mb-4">{t('delivery_address_label')}</h3>
      <div className="delivery-menu d-flex justify-content-between mb-3">
        <label
          htmlFor="sameAddress"
          className="fw-bold text-dark"
          style={{ textTransform: 'none' }}
        >
          {t('same_as_horse_address')}
        </label>
        <div className="form-check form-switch">
          <input
            id="sameAddress"
            name="sameAddress"
            className="form-check-input"
            type="checkbox"
            checked={sameAddress}
            onChange={e => setState({ sameAddress: e.target.checked })}
          />
        </div>
      </div>

      <div className="google-maps relative">
        {!sameAddress && (
          <div className="bg-white relative" style={{ marginTop: '-.5rem' }}>
            {geolocationStatus && (
              <div className="position-relative mb-4">
                <div
                  className=""
                  style={{
                    height: '50vh',
                    maxHeight: '400px',
                    width: '100%',
                  }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                    }}
                    center={center}
                    zoom={zoom}
                    draggable={draggable}
                    onChange={onChangeMap}
                    onDragEnd={onMapDragEnd}
                    options={{ gestureHandling: 'greedy' }}
                  ></GoogleMapReact>
                  <div className="marker-overlay">
                    <div className="pin">
                      <span className="fa fa-map-marker text-success"></span>
                    </div>
                  </div>
                </div>
                <div
                  className="geolocation-text bg-white p-2 position-absolute "
                  style={{
                    bottom: '1rem',
                    left: '1rem',
                    right: '1rem',
                  }}
                >
                  <label className="mb-1 fs-12">
                    {t('your_location_text')}
                  </label>
                  <p className="lh-1 mb-0 fs-12 text-dark fw-bold">
                    {geoAddress}
                  </p>
                </div>
              </div>
            )}
            <div className="form-field mb-4">
              <label htmlFor="deliveryAddress">{t('address_detail')}</label>
              <textarea
                className="form-control mb-0"
                name="notes"
                id="deliveryAddress"
                rows="2"
                placeholder={t('address_detail')}
                value={deliveryAddress}
                onChange={e =>
                  setState({
                    deliveryAddress: e.target.value,
                  })
                }
                style={{ height: '80px' }}
              ></textarea>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="admin-container">
      <SubHeader title={t('create_pro_order_heading')} isClose>
        {((directInvoice && selectedCustomer && selectedProduct) ||
          (!directInvoice && selectedHorse && selectedProduct)) &&
        !isSubmit ? (
          <button
            type="button"
            onClick={handleSubmit}
            className="btn text-primary fw-bold"
          >
            {t('next_button')}
          </button>
        ) : (
          <button
            type="button"
            className="btn text-secondary btn-disabled fw-bold"
            disabled
          >
            {t('next_button')}
          </button>
        )}
      </SubHeader>
      <div
        className={`main-container py-3 pb-5 ${isSubmit ? 'opacity-50' : ''}`}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content">
                <div className="bg-gold-100 rounded-1 p-3 mb-3">
                  <div className="form-check px-0 d-flex align-items-center justify-content-between">
                    <label
                      htmlFor="direct_invoice"
                      className="form-check-label fw-bold text-reset"
                      style={{ textTransform: 'none' }}
                    >
                      {t('create_direct_invoice')}
                    </label>
                    <div className="form-check form-switch m-0">
                      <input
                        name="direct_invoice"
                        id="direct_invoice"
                        className="form-check-input"
                        type="checkbox"
                        checked={directInvoice}
                        onChange={e => {
                          setState({ directInvoice: e.target.checked });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {customerComponent}
                {horseComponent}
                {productComponent}
                {!directInvoice && additionalComponent}
                {/* {directInvoice && invoiceNotesComponent} */}
                {!directInvoice && recurringOrderComponent}
                {!directInvoice && deliveryAddressComponent}
                {!directInvoice && orderNotesComponent}
                {directInvoice && (
                  <>
                    <div className="summary-photo">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="fs-6 fw-bold mb-0">
                          {t('service_documentation_heading')}
                        </h3>
                        <label
                          className="btn fw-bold text-primary btn-sm position-relative"
                          htmlFor="img"
                          style={{ textTransform: 'none' }}
                        >
                          <input
                            type="file"
                            id="img"
                            accept="image/*"
                            value={summaryPhoto}
                            onChange={onDropGallery}
                            className="fs-6 small w-auto"
                            style={{
                              maxWidth: '60px',
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                          <span>{t('add_button')}</span>
                        </label>
                      </div>

                      {summaryGallery.length > 0 ? (
                        <div className="mb-4 position-relative">
                          <div className="row g-2">
                            {summaryGallery.map((gallery, index) => (
                              <div
                                className="position-relative col-6"
                                key={index}
                              >
                                <img
                                  src={gallery}
                                  alt="summary"
                                  className="w-100"
                                />
                                <button
                                  className="position-absolute end-0 top-0 btn p-0 me-n1 mt-n1 w-32 h-32 lh-32 rounded-circle border-1 bg-white border-danger text-danger fs-5"
                                  onClick={() => deleteGallery(gallery)}
                                >
                                  <span className="fa fa-times"></span>
                                </button>
                              </div>
                            ))}
                            {uploadGallery && (
                              <div className="col-6 text-center">
                                <Loader size="16" />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <h6 className="fs-14 text-muted mb-4">
                          {t('no_summary_photo')}
                        </h6>
                      )}
                    </div>

                    <div className="summary-text">
                      <label htmlFor="notes">{t('notes')}</label>
                      <textarea
                        name="notes"
                        id="notes"
                        rows="3"
                        className="form-control mb-5"
                        placeholder={t('write_a_note_label')}
                        value={notes}
                        onChange={e => setState({ notes: e.target.value })}
                      ></textarea>
                    </div>

                    {items.length > 0 && (
                      <div className="invoice-generator mb-4">
                        <div className="">
                          <h6 className="fw-bold mb-2">{t('item_label')}</h6>

                          <div className="item-container">
                            {items.map((i, index) => {
                              const is_company_active = i.is_company_active;
                              if (!is_company_active) return null;
                              return (
                                <div
                                  className={`d-flex justify-content-between py-3 ${
                                    index > 0 ? 'border-top' : ''
                                  }`}
                                  key={index}
                                >
                                  <div className="spare-part-content d-flex flex-1">
                                    <img
                                      className="rounded-3 w-40 h-40 me-3"
                                      src={i.image}
                                      alt={i.name}
                                    />
                                    <div className="flex-1">
                                      <h6 className=" fw-bold mb-1">
                                        {i.name}
                                        {i.unit && (
                                          <span className="text-muted fs-12">
                                            <br />/ {i.unit}
                                          </span>
                                        )}
                                      </h6>
                                      <h6 className="fw-bold fs-12 mb-0 text-muted">
                                        {t('$')}{' '}
                                        <strong className="">
                                          <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            value={i.price}
                                          />
                                        </strong>{' '}
                                        <span className="fs-10">
                                          ({t('exc_vat_label')})
                                        </span>
                                      </h6>
                                      <h6 className="fw-bold fs-12 mb-1">
                                        {t('$')}{' '}
                                        <strong className="">
                                          <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            value={
                                              i.vat > 0
                                                ? i.price +
                                                  i.price * (i.vat / 100)
                                                : i.price
                                            }
                                          />
                                        </strong>{' '}
                                        <span className="fs-10">
                                          ({t('inc_vat_label')})
                                        </span>
                                      </h6>

                                      <h6 className="text-info fw-bold fs-12 mb-0">
                                        ({t('inventory_vat_label')} {i.vat}%)
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="align-self-center">
                                    <div className="input-group d-flex align-items-center justify-content-end flex-nowrap">
                                      {itemQty[index] === 0 ? (
                                        <button
                                          className="btn btn-outline-secondary btn-disabled rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                          disabled
                                        >
                                          <span className="fa fa-minus"></span>
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                          onClick={() => subtractQty(index, 0)}
                                        >
                                          <span className="fa fa-minus"></span>
                                        </button>
                                      )}
                                      <h6 className="mx-2 w-32 text-center fw-bold mb-0">
                                        {itemQty[index]}
                                      </h6>

                                      <button
                                        className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                        onClick={() => addQty(index, 0)}
                                      >
                                        <span className="fa fa-plus"></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="py-3 border-top">
                            <div className="d-flex align-items-center justify-content-end">
                              <h6 className="mb-0 fw-bold">
                                {t('item_discount_label')}
                              </h6>
                              <input
                                type="number"
                                className="form-control text-center mx-2 w-60"
                                placeholder="0"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={discountItem}
                                onChange={e => {
                                  if (e.target.valueAsNumber > 100) {
                                    setState({ discountItem: 100 });
                                  } else {
                                    setState({
                                      discountItem: e.target.valueAsNumber,
                                    });
                                  }
                                }}
                              />
                              <h6 className="fw-bold mb-0 ">%</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {works.length > 0 && (
                      <div className="invoice-generator mb-4">
                        <div className="">
                          <h6 className="fw-bold mb-2">{t('work_label')}</h6>

                          <div className="item-container">
                            {works.map((i, index) => {
                              const is_company_active = i.is_company_active;
                              if (!is_company_active) return null;
                              return (
                                <div
                                  className={`d-flex justify-content-between py-3 ${
                                    index > 0 ? 'border-top' : ''
                                  }`}
                                  key={index}
                                >
                                  <div className="spare-part-content d-flex flex-1">
                                    <img
                                      className="rounded-3 w-40 h-40 me-3"
                                      src={i.image}
                                      alt={i.name}
                                    />
                                    <div className="flex-1">
                                      <h6 className=" fw-bold mb-1">
                                        {i.name}
                                        {i.unit && (
                                          <span className="text-muted fs-12">
                                            <br />/ {i.unit}
                                          </span>
                                        )}
                                      </h6>
                                      <h6 className="fw-bold fs-12 mb-0 text-muted">
                                        {t('$')}{' '}
                                        <strong className="">
                                          <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            value={i.price}
                                          />
                                        </strong>{' '}
                                        <span className="fs-10">
                                          ({t('exc_vat_label')})
                                        </span>
                                      </h6>
                                      <h6 className="fw-bold fs-12 mb-1">
                                        {t('$')}{' '}
                                        <strong className="">
                                          <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            value={
                                              i.vat > 0
                                                ? i.price +
                                                  i.price * (i.vat / 100)
                                                : i.price
                                            }
                                          />
                                        </strong>{' '}
                                        <span className="fs-10">
                                          ({t('inc_vat_label')})
                                        </span>
                                      </h6>

                                      <h6 className="text-info fw-bold fs-12 mb-0">
                                        ({t('inventory_vat_label')} {i.vat}%)
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="align-self-center">
                                    <div className="input-group d-flex align-items-center justify-content-end flex-nowrap">
                                      {worksQty[index] === 0 ? (
                                        <button
                                          className="btn btn-outline-secondary btn-disabled rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                          disabled
                                        >
                                          <span className="fa fa-minus"></span>
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                          onClick={() => subtractQty(index, 1)}
                                        >
                                          <span className="fa fa-minus"></span>
                                        </button>
                                      )}
                                      <h6 className="mx-2 w-32 text-center fw-bold mb-0">
                                        {worksQty[index]}
                                      </h6>

                                      <button
                                        className="btn btn-outline-primary rounded-circle w-32 h-32 p-0 lh-32 border-1"
                                        onClick={() => addQty(index, 1)}
                                      >
                                        <span className="fa fa-plus"></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="py-3 border-top">
                            <div className="d-flex align-items-center justify-content-end">
                              <h6 className="mb-0 fw-bold">
                                {t('work_discount_label')}
                              </h6>
                              <input
                                type="number"
                                className="form-control text-center mx-2 w-60"
                                placeholder="0"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={discountWork}
                                onChange={e => {
                                  if (e.target.valueAsNumber > 100) {
                                    setState({ discountWork: 100 });
                                  } else {
                                    setState({
                                      discountWork: e.target.valueAsNumber,
                                    });
                                  }
                                }}
                              />
                              <h6 className="fw-bold mb-0 ">%</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="invoice-summary">
                      <div className="mb-4">
                        <h6 className="fw-bold mb-3">{t('invoice_summary')}</h6>

                        <div className="">
                          <div className="table-container">
                            <table className="table mb-0 px-3 fs-12 table-bordered table-striped">
                              <tbody>
                                <tr>
                                  {/* <th className="text-center">{t('invoice_no')}</th> */}
                                  <th className="w-25">{t('invoice_items')}</th>
                                  <th className="text-center lh-1">
                                    {t('unit_price')}
                                    <br />
                                    <span className="fs-10 text-nowrap text-muted">
                                      (0% {t('vat_label')})
                                    </span>
                                  </th>
                                  <th className="text-center">{t('qty')}</th>
                                  <th className="text-end lh-1">
                                    {t('invoice_price')}
                                    <br />
                                    <span className="fs-10 text-nowrap text-muted">
                                      (0% {t('vat_label')})
                                    </span>
                                  </th>
                                  <th className="text-end lh-1">
                                    {t('invoice_subtotal')}
                                    <br />
                                    <span className="fs-10 text-muted">
                                      ({t('invoice_subtotal_info')})
                                    </span>
                                  </th>
                                </tr>
                                {itemQty.length > 0 &&
                                  itemQty.map((ai, idx) => {
                                    const item = items[idx];
                                    if (ai === 0) return null;

                                    const subtotal = item.price * ai;
                                    const totalDiscount =
                                      subtotal -
                                      subtotal * (discountItem / 100);
                                    const total =
                                      totalDiscount +
                                      totalDiscount * (item.vat / 100);

                                    return (
                                      <tr key={idx}>
                                        <td className="lh-1 fw-bold">
                                          {item.name}
                                          <span className=" fs-10">
                                            &nbsp; ({t('vat_label')}: {item.vat}
                                            %)
                                          </span>
                                          {item.unit && (
                                            <span className="text-muted fs-12 fw-normal opacity-50">
                                              <br />/ <em>{item.unit}</em>
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={item.price}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                        <td className="text-center">{ai}</td>
                                        <td className="text-end fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={subtotal}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                        <td className="text-end text-primary fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={total}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {worksQty.length > 0 &&
                                  worksQty.map((ai, idx) => {
                                    const item = works[idx];
                                    if (ai === 0) return null;

                                    const subtotal = item.price * ai;
                                    const totalDiscount =
                                      subtotal -
                                      subtotal * (discountWork / 100);
                                    const total =
                                      totalDiscount +
                                      totalDiscount * (item.vat / 100);

                                    return (
                                      <tr key={idx}>
                                        <td className="lh-1 fw-bold">
                                          {item.name}
                                          <span className=" fs-10">
                                            &nbsp; ({t('vat_label')}: {item.vat}
                                            %)
                                          </span>
                                          {item.unit && (
                                            <span className="text-muted fw-normal opacity-50">
                                              <br />
                                              <em>{item.unit}</em>
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={item.price}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                        <td className="text-center">{ai}</td>
                                        <td className="text-end fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={subtotal}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                        <td className="text-end text-primary fw-bold">
                                          {t('$')}
                                          <NumberFormat
                                            decimalScale={2}
                                            displayType="text"
                                            value={total}
                                            fixedDecimalScale={true}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot className="">
                                <tr>
                                  <th colSpan="4" className="text-end">
                                    <h6 className="small mb-0 fw-bold">
                                      {t('invoice_total')}
                                    </h6>
                                  </th>
                                  <th className="text-end ">
                                    <h6 className="mb-0 fw-bold text-success text-nowrap">
                                      <CountUp
                                        decimals={2}
                                        start={parseFloat(numberFormat(price))}
                                        duration={0.5}
                                        prefix={`${t('$')} `}
                                        end={parseFloat(numberFormat(estPrice))}
                                      />
                                    </h6>
                                  </th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setState({ isOpen: false })}
        contentLabel="Modal"
        className="reveal p-3"
        ariaHideApp={false}
      >
        <div className="modal-container">
          <h3 className="fs-6 fw-bold mb-3">{t('place_order')}</h3>
          <p>{t('place_order_text')}</p>
          <button
            className="btn w-100 mb-3 btn-primary btn-sm"
            onClick={() => handleSubmit(true)}
          >
            {t('create_summary_invoice')}
          </button>
          <button
            className="btn w-100 btn-outline-primary btn-sm"
            onClick={() => handleSubmit(false)}
          >
            {t('skip_invoice_button')}
          </button>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setState({ isOpen: false })}
        >
          &times;
        </button>
      </Modal>
      {/* <MainMenu /> */}
      {directInvoice && (
        <div
          className="p-3 border-top border-bottom sticky-bottom"
          style={{
            background: 'rgba(255,255,255,.85)',
            backdropFilter: 'blur(5px)',
            bottom: 48,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="fw-bold mb-0">{t('final_price')}:</h6>
            <h6 className="mb-0 fw-bold text-primary ms-3 d-flex align-items-center">
              <strong className="">
                <CountUp
                  decimals={2}
                  start={parseFloat(numberFormat(price))}
                  duration={0.5}
                  prefix={`${t('$')} `}
                  end={parseFloat(numberFormat(estPrice))}
                />
              </strong>
            </h6>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateProOrder;
