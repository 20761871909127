import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import { addCustomer } from '../../libs/apis';
import { useSelector } from 'react-redux';

function CustomerCreatePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirect = query.get('redirect');
  const go = redirect ? redirect : '/manage';
  const initialState = {
    name: '',
    isLoading: false,
    isError: false,
    isSubmiting: false,
    isSubmitError: false,
    is_monthly_billing_active: false,
    submitMessage: '',
    message: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    postal_code: '',
    notes: '',
    company_name: '',
    business_id: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    hasInput,
    isLoading,
    isError,
    message,
    name,
    email,
    company_name,
    business_id,
    phone,
    address,
    city,
    postal_code,
    notes,
    is_monthly_billing_active,
  } = state;

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isSubmiting: true, isSubmitError: false, submitMessage: '' });
    const payload = {
      name,
      email,
      company_name,
      business_id,
      phone,
      address,
      city,
      postal_code,
      notes,
      is_monthly_billing_active,
    };
    await addCustomer(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const customer = resp.data.response || null;
          if (customer && customer.id) {
            if (redirect) {
              history.push(redirect);
            } else {
              history.push(`/customer/${customer.id}`);
            }
          }
        } else {
          setState({
            isSubmitError: true,
            submitMessage: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, submitMessage: err.message });
      })
      .finally(() => {
        setState({ isSubmiting: false });
      });
  };

  if (role === 'buyer') {
    history.replace('/profile');
  }

  if (role === 'seller' && !user.subscription) {
    return (
      <div className="app-container" style={{ height: 'calc(100vh - 108px)' }}>
        <div
          className="container mb-5 d-flex flex-direction-col justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 108px)' }}
        >
          <div className="text-center p-5">
            <h6 className="fw-bold fs-5 mb-3">{t('pro_restriction_title')}</h6>
            <p className="text-muted">{t('pro_restriction_text')}</p>
            <a
              className="btn btn-sm btn-outline-primary"
              target="_blank"
              href="mailto:laskutus@horsesfirst.com"
              rel="noreferrer noopener"
            >
              {t('contact_horsesfirst')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <SubHeader
        go={go}
        title={t('add_new_customer')}
        prevent={hasInput}
        isClose={!redirect}
      >
        {name === '' && email === '' ? (
          <button type="button" className="btn text-primary fw-bold" disabled>
            {t('save_button')}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btn text-primary fw-bold"
          >
            {t('save_button')}
          </button>
        )}
      </SubHeader>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {message}
                    </div>
                  )}

                  <div>
                    <div className="form-group mb-3">
                      <label htmlFor="name">
                        {t('customer_name_label')}
                        <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e =>
                          setState({ name: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_name')}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="email">{t('customer_email_label')}</label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="email"
                        id="email"
                        value={email}
                        onChange={e =>
                          setState({ email: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_email_label')}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="company_name">
                        {t('customer_company_name_label')}
                      </label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="company_name"
                        value={company_name}
                        onChange={e =>
                          setState({ company_name: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_company_name')}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="business_id">
                        {t('customer_business_id_label')}
                      </label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="business_id"
                        value={business_id}
                        onChange={e =>
                          setState({ business_id: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_business_id')}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="phone">{t('customer_phone_label')}</label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="number"
                        id="phone"
                        value={phone}
                        onChange={e => setState({ phone: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('customer_phone')}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="address">
                        {t('customer_address_label')}
                      </label>
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="address"
                        value={address}
                        onChange={e => setState({ address: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('customer_street')}
                      />
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="city"
                        value={city}
                        onChange={e => setState({ city: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('customer_town')}
                      />
                      <input
                        className="form-control mt-2 fw-bold"
                        type="text"
                        id="postal_code"
                        value={postal_code}
                        onChange={e =>
                          setState({ postal_code: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_zipcode')}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="notes">{t('customer_notes_label')}</label>
                      <textarea
                        className="form-control mt-2"
                        id="notes"
                        value={notes}
                        onChange={e =>
                          setState({ notes: e.target.value, hasInput: true })
                        }
                        disabled={isLoading}
                        required
                        placeholder={t('customer_notes')}
                        style={{ height: '80px' }}
                      />
                    </div>
                  </div>
                  <div className="ms-2 d-flex ">
                    <p className="me-auto fw-bold">
                      {t('customer_monthly_billing_label')}
                    </p>
                    <div className="form-check form-switch mb-3">
                      <input
                        style={{ height: '20px', width: '40px' }}
                        id=" is_monthly_billing_active"
                        name=" is_monthly_billing_active"
                        className="form-check-input"
                        type="checkbox"
                        onChange={e =>
                          setState({
                            is_monthly_billing_active: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                  {is_monthly_billing_active && (
                    <div
                      className="text px-4 py-2 text-center rounded"
                      style={{ backgroundColor: '#F2F2F2' }}
                    >
                      {t('customer_monthly_billing_text')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerCreatePage;
