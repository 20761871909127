import Browse from '../pages/portal/Browse';
import Error404 from '../pages/portal/Error404';
import BuyerOnly from '../pages/portal/BuyerOnly';
import Home from '../pages/portal/Home';
import Login from '../pages/portal/Login';
import ForgotPassword from '../pages/portal/ForgotPassword';
import ResetPassword from '../pages/portal/ResetPassword';
import Search from '../pages/portal/Search';
import RegisterBuyer from '../pages/portal/RegisterBuyer';
import RegisterSeller from '../pages/portal/RegisterSeller';

import CompanyIndex from '../pages/company/index';
import CompanyCreate from '../pages/company/Create';
import CompanyEdit from '../pages/company/Edit';
import CompanyProducts from '../pages/company/Products';
import CompanyInventory from '../pages/company/Inventory';

import EditProfile from '../pages/user/EditProfile';
import Item from '../pages/user/Item';
import Manage from '../pages/user/Manage';
import StableList from '../pages/user/StableList';
import StableDetail from '../pages/user/StableDetail';
import AddStable from '../pages/user/AddStable';
import EditStable from '../pages/user/EditStable';
import ItemList from '../pages/user/ItemList';
import AddItem from '../pages/user/AddItem';
import EditItem from '../pages/user/EditItem';
import EditItemStable from '../pages/user/EditItemStable';
import EditItemBilling from '../pages/user/EditItemBilling';
import Profile from '../pages/user/Profile';
import ChangePassword from '../pages/user/ChangePassword';

import OrderConfirm from '../pages/transactions/OrderConfirm';
import Product from '../pages/transactions/Product';
import Notification from '../pages/transactions/Notification';
import Schedule from '../pages/transactions/SchedulePage';
import Favorite from '../pages/transactions/Favorite';
import OrderPage from '../pages/transactions/OrderPage';
import Purchase from '../pages/transactions/Purchase';
import OrderDetail from '../pages/transactions/OrderDetail';

import SellerIndex from '../pages/seller/index';
import ProUpgrade from '../pages/seller/ProUpgrade';
import ProDowngrade from '../pages/seller/ProDowngrade';
import SellerAddProduct from '../pages/seller/AddProduct';
import SellerEditProduct from '../pages/seller/EditProduct';
import SellerProcessOrder from '../pages/seller/ProcessOrder';
import SellerSchedule from '../pages/seller/SellerSchedule';
import SellerOrder from '../pages/seller/SellerOrder';
import SellerProduct from '../pages/seller/SellerProduct';
import SellerProcessInvoice from '../pages/seller/ProcessInvoice';
import SellerInvoice from '../pages/seller/SellerInvoice';
import Bookkeeping from '../pages/seller/Bookkeeping';
import HorseList from '../pages/seller/HorseList';
import SellerInvoiceExport from '../pages/seller/SellerInvoiceExport';
import SellerInvoiceExportAll from '../pages/seller/SellerInvoiceExportAll';
import SellerInventory from '../pages/seller/Inventory';
import SellerAddInventory from '../pages/seller/AddInventory';
import SellerEditInventory from '../pages/seller/EditInventory';
import OrderDetailList from '../pages/pro/OrderDetailList';
import CreateProOrder from '../pages/pro/CreateProOrder';
import CustomerCreate from '../pages/customer/CustomerCreate';
import CustomerEdit from '../pages/customer/CustomerEdit';
import CustomerDetail from '../pages/customer/CustomerDetail';

const routes = [
  // public routes
  {
    path: '/explore',
    component: Browse,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/search',
    component: Search,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/register-seller',
    component: RegisterSeller,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/register-buyer',
    component: RegisterBuyer,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/for-buyer',
    component: BuyerOnly,
    isPrivate: false,
    showHeader: true,
  },

  {
    path: '/company/inventory',
    component: CompanyInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/company/products',
    component: CompanyProducts,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/company/create',
    component: CompanyCreate,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/company/:companyId/edit',
    component: CompanyEdit,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/company/:companyId',
    component: CompanyIndex,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/product/edit/:productId',
    component: SellerEditProduct,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/product/new',
    component: SellerAddProduct,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/order/:orderId/invoice',
    component: SellerProcessInvoice,
    isPrivate: true,
    showHeader: false,
    showMenu: false,
  },
  {
    path: '/seller/inventory/new',
    component: SellerAddInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/inventory/:inventoryId/edit',
    component: SellerEditInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/inventory',
    component: SellerInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/product',
    component: SellerProduct,
    isPrivate: true,
    showHeader: false,
    showMenu: false,
  },
  {
    path: '/seller/invoice/export/super',
    component: SellerInvoiceExportAll,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/invoice/export',
    component: SellerInvoiceExport,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/invoice',
    component: SellerInvoice,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/horse-list',
    component: HorseList,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/bookkeeping',
    component: Bookkeeping,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/schedule',
    component: SellerSchedule,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/order/:orderId',
    component: SellerProcessOrder,
    isPrivate: false,
    showHeader: false,
    showMenu: true,
  },
  {
    path: '/seller/order/',
    component: SellerOrder,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/seller',
    component: SellerIndex,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/pro/upgrade',
    component: ProUpgrade,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/pro/downgrade',
    component: ProDowngrade,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/pro/create-order',
    component: CreateProOrder,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/pro/order-detail-list',
    component: OrderDetailList,
    isPrivate: true,
    showHeader: false,
  },
  //user routes
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/edit-profile',
    component: EditProfile,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/notification',
    component: Notification,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/schedule',
    component: Schedule,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/wishlist',
    component: Favorite,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/stable/new',
    component: AddStable,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/stable/:stableId/edit',
    component: EditStable,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/stable/:stableId',
    component: StableDetail,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/stables',
    component: StableList,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/manage',
    component: Manage,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/horse/new',
    component: AddItem,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/horse/add',
    component: AddItem,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/horse/:horseId/billing',
    component: EditItemBilling,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/horse/:horseId/stable',
    component: EditItemStable,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/horse/:horseId/edit',
    component: EditItem,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/horse/:horseId',
    component: Item,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/item',
    component: ItemList,
    isPrivate: true,
    showHeader: true,
  },
  // customer routes
  {
    path: '/customer/:customerId/edit',
    component: CustomerEdit,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/customer/new',
    component: CustomerCreate,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/customer/:customerId',
    component: CustomerDetail,
    isPrivate: true,
    showHeader: false,
  },
  //transaction routes
  {
    path: '/product/:productId',
    component: Product,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/purchase/:productId',
    component: Purchase,
    isPrivate: true,
    showHeader: false,
    showMenu: false,
  },
  {
    path: '/order/:orderId/confirm',
    component: OrderConfirm,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/order/:orderId',
    component: OrderDetail,
    isPrivate: true,
    showHeader: false,
    showMenu: true,
  },
  {
    path: '/orders',
    component: OrderPage,
    isPrivate: true,
    showHeader: true,
  },

  //common routes
  {
    path: '/',
    component: Home,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/for-buyer',
    component: BuyerOnly,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/error',
    component: Error404,
    isPrivate: false,
    showHeader: true,
  },
];

export default routes;
