import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../global/Modal';
import { acceptCompanyInvitation } from '../../libs/apis';

export default function WidgetCompany({ data }) {
  const { t } = useTranslation();
  const initialState = {
    isOpen: false,
    isLoading: false,
    isError: false,
    isActive: data.is_active,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isOpen, isActive } = state;
  const { company, level } = data;
  const { id, name, logo, business_id } = company;

  const handleInvitation = async answer => {
    setState({ isLoading: true, isError: false });
    const payload = {
      id: data.id,
      answer: answer,
    };
    await acceptCompanyInvitation(payload)
      .then(res => {
        if (res.status === 201) {
          // console.log('res', res.data);
          window.location.replace('/profile');
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => setState({ isLoading: false }));
  };
  return (
    <div className="company-block mb-3">
      <div
        className={`card shadow-sm rounded-3 ${isActive ? '' : 'opacity-75'}`}
      >
        <div className="card-body p-2">
          <div className="d-flex justify-content-between align-items-start mb-2 flex-wrap">
            <div className="com-content d-flex">
              {logo && (
                <img
                  src={logo}
                  alt=""
                  className="rounded-circle align-self-start me-2"
                  width="48"
                  style={!isActive ? { filter: 'grayscale(1)' } : {}}
                />
              )}

              <div className="com-detail">
                <h6 className="fw-bold mb-0">{name}</h6>
                <h6 className="small mb-2 text-muted">
                  {t('com_business_id')}: <strong>{business_id}</strong>
                </h6>
                <Link
                  to={`/company/${id}`}
                  className="btn btn-outline-primary py-1 btn-sm fs-10"
                >
                  {t('com_view_detail')}
                </Link>
              </div>
            </div>
            {!isActive ? (
              <div className="role text-end">
                <h6 className="small d-inline-block p-1 fs-12 m-0 px-2 rounded bg-light border border-warning text-warning">
                  {t('com_invited')}
                </h6>
                <br />
                <button
                  className="btn btn-link text-end p-0 w-auto fs-14"
                  onClick={() => setState({ isOpen: true })}
                >
                  {t('com_accept_invitation')}
                </button>
                <Modal
                  isOpen={isOpen}
                  onClose={() => setState({ isOpen: false })}
                  center
                >
                  <h6 className="fw-bold mb-3 text-primary">
                    {t('com_accept_invitation')}
                  </h6>
                  <div className="btn-container d-flex">
                    <button
                      onClick={() => setState({ isOpen: false })}
                      className="btn btn-sm btn-outline-danger w-50 me-3"
                    >
                      {t('com_reject_button')}
                    </button>
                    <button
                      onClick={() => handleInvitation(true)}
                      className="btn btn-sm btn-primary w-50"
                    >
                      {t('com_accept_button')}
                    </button>
                  </div>
                </Modal>
              </div>
            ) : (
              <div className="role">
                {level === 2 ? (
                  <h6 className="small p-1 fs-10 m-0 px-2 rounded alert-success">
                    {t('com_owner')}
                  </h6>
                ) : (
                  <h6 className="small p-1 fs-10 m-0 px-2 rounded alert-secondary text-light">
                    {t('com_member')}
                  </h6>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
