import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

function CompanyIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const { role } = useSelector(state => state.auth);

  if (role !== 'seller') {
    history.push('/profile');
  }

  return (
    <div className="company-index">
      <h1>{t('company')}</h1>
      <Link to="/profile">Profile</Link>
    </div>
  );
}

export default CompanyIndex;
