import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/global/SubHeader';
import Loader from '../../components/global/Loader';

// import SwiperCore, { Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';

import { getItemDetail, updateItem, getStables } from '../../libs/apis';

// SwiperCore.use([Navigation]);

export default function EditItemStablePage() {
  const { t } = useTranslation();
  const { horseId } = useParams();
  const history = useHistory();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    image: null,
    isLoadingStable: false,
    isErrorStable: false,
    messageStable: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
    isEditStable: false,
    stableData: [],
    selectedStable: -1,
    isSubmit: false,
    isSubmitError: false,
    isUploadError: false,
    isUploading: false,
    isOpen: false,
    hasInput: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isError,
    // isLoadingStable,
    // isErrorStable,
    hasInput,
    selectedStable,
    stableData,
    // isSubmitError
  } = state;

  useEffect(() => {
    const getItemData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: horseId,
      };
      await getItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({
              data: resp.data.response,
              image: resp.data.response.image,
            });
            if (resp.data.response.stable) {
              setState({ selectedStable: resp.data.response.stable.id });
            }
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, message: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getStableData = async () => {
      setState({
        isLoadingStable: true,
        isErrorStable: false,
        messageStable: '',
      });
      await getStables()
        .then(resp => {
          if (resp.status === 200) {
            setState({ stableData: resp.data.response });
          } else {
            setState({
              isErrorStable: true,
              messageStable: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          // setState({ isErrorStable: true, messageStable: err.error.message });
        })
        .finally(() => setState({ isLoadingStable: false }));
    };
    getItemData();
    getStableData();
  }, [horseId]);

  if (!horseId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const updateHorse = async () => {
    setState({ isSubmit: true, isSubmitError: false, submitMessage: '' });
    const payload = data;
    payload.category_id = data.category.id;
    payload.stable_id = selectedStable;
    await updateItem(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ data: resp.data.response });
          setTimeout(() => {
            history.push(`/horse/${horseId}`);
          }, 1000);
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isSubmitError: true });
      })
      .finally(() => setState({ isSubmit: false }));
  };

  return (
    <div className="app-container">
      <SubHeader
        title={t('horse_detail')}
        go={`/horse/${horseId}`}
        prevent={hasInput}
      >
        {selectedStable === -1 ? (
          <button type="button" className="btn text-secondary" disabled>
            {t('save_horse_button')}
          </button>
        ) : (
          <button onClick={updateHorse} className="btn text-primary">
            {t('save_horse_button')}
          </button>
        )}
      </SubHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="main-content">
              <Link
                to={`/stable/new?redirect=/horse/${horseId}/stable`}
                className="btn btn-outline-primary w-100"
              >
                <span className="fa fa-plus me-2"></span>
                {t('add_new_stable')}
              </Link>

              {stableData.length > 0 && (
                <div className="stable-list py-3">
                  {stableData.map((stable, index) => {
                    const borderClass = index > 0 ? 'border-top' : '';
                    return (
                      <div
                        className={`stable-item mb-2 py-2 ${borderClass}`}
                        key={stable.id}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="stable"
                            id={`stable_${stable.id}`}
                            onChange={e => {
                              setState({ selectedStable: stable.id });
                            }}
                            checked={stable.id === selectedStable}
                          />
                          <label
                            className="form-check-label fw-bold text-capitalize text-dark fs-6"
                            htmlFor={`stable_${stable.id}`}
                          >
                            {stable.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
