import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { deleteInventory, updateInventory } from '../../libs/apis';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

export default function Inventory({ data, index = 0, onChange = () => {} }) {
  const { t } = useTranslation();
  const {
    id,
    name,
    image,
    price,
    type,
    unit,
    vat,
    company = null,
    is_company_active = true,
  } = data;
  const { user } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);
  const initialState = {
    isDeleted: false,
    productOwner: data.company
      ? data.is_company_active
        ? data.company.id
        : 0
      : 0,
    hasChanged: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isDeleted, productOwner, hasChanged } = state;

  const handleDelete = async () => {
    setState({ isDeleting: true });
    const payload = {
      id,
    };
    await deleteInventory(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ isDeleted: true });
        }
      })
      .finally(() => setState({ isDeleting: false }));
  };
  if (isDeleted) return null;

  const handleChange = () => {
    setState({ loading: true });
    const payload = {
      id,
      name,
      price,
      image,
      type,
      unit,
      vat,
      is_company_active: !isOwner ? data.is_company_active : productOwner !== 0,
    };
    updateInventory(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ hasChanged: false });
            onChange();
          }
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ loading: false, isOpen: false });
      });
  };

  const includingVat = vat > 0 ? price + price * (vat / 100) : price;

  const isCompany = !!company;
  const myCompany = user.company;
  const isOwner = isCompany && myCompany && myCompany.id === company.id;
  const isCompanyActive = is_company_active;

  const isNotCompanyActive = !isOwner && !isCompanyActive;

  if (isNotCompanyActive)
    return (
      <div
        className={`inventory-item opacity-75 ${
          index > 0 ? 'border-top' : ''
        } p-2 ${index % 2 === 0 ? '' : 'bg-light'}`}
      >
        <div className="row g-2 align-items-center">
          <div className="col-2">
            <img
              src={image.replace(
                'https://res.cloudinary.com/luokki/image/upload/',
                'https://res.cloudinary.com/luokki/image/upload/b_white,c_pad,w_300,h_300/',
              )}
              className="rounded shadow-sm border"
              alt={name}
            />
          </div>
          <div className="col-10">
            <div className="d-flex align-items-center justify-content-between">
              <div className="heading-container" style={{ flex: 1 }}>
                <h6 className="mb-2 small fw-bold">{name}</h6>
                <h6 className="small mb-0 font-ssp">
                  <span>
                    <span className="text-primary fw-bold">
                      {t('$')}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        value={price}
                      />
                    </span>{' '}
                    / {unit}
                  </span>{' '}
                  <span className="text-muted">
                    (
                    <Trans i18nKey="exc_price_label" vat={vat}>
                      Excluding {{ vat }}% VAT
                    </Trans>
                    )
                  </span>
                </h6>
                <h6 className="small mb-0 font-ssp">
                  <span>
                    <span className="text-primary fw-bold">
                      {t('$')}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        value={includingVat}
                      />
                    </span>{' '}
                    / {unit}
                  </span>{' '}
                  <span className="text-muted">
                    (
                    <Trans i18nKey="inc_price_label" vat={vat}>
                      Including {{ vat }}% VAT
                    </Trans>
                    )
                  </span>
                </h6>
                {/* {isCompany && (
                  <div
                    className="btn btn-sm d-flex align-items-center p-1 px-2 rounded-3 btn-outline-primary alert alert-primary mt-2 mb-0 fs-12"
                  >
                    <span className="fa fa-building me-2 text-primary"></span>
                    <span className="text-start" style={{ flex: 1 }}>
                      {company.name}
                    </span>
                    <span className="align-self-end">
                      <span className="fa fa-chevron-right"></span>
                    </span>
                  </div>
                )} */}
              </div>
            </div>

            <div className="alert alert-warning p-1 mt-2 mb-0">
              <small className="d-block">{t('com_disabled_inventory')}</small>
            </div>

            <Modal
              isOpen={open}
              onRequestClose={() => setOpen(false)}
              contentLabel="Modal"
              className="reveal small p-3"
              ariaHideApp={false}
            >
              <div className="modal-container">
                <h6 className="mb-4 text-center">
                  <span className="fa text-warning fa-warning me-1"></span>Are
                  you sure to remove this item?
                </h6>
                <div className="btn-container d-flex justify-content-center align-items-center">
                  <button className="btn w-50 btn-sm btn-secondary">
                    Cancel
                  </button>
                  <button
                    className="ms-2 w-50 btn btn-sm btn-outline-danger"
                    onClick={handleDelete}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <button
                className="btn btn-close close-reveal"
                onClick={() => setOpen(false)}
              >
                &times;
              </button>
            </Modal>
          </div>
        </div>
      </div>
    );

  if (isOwner)
    return (
      <div
        className={`inventory-item ${index > 0 ? 'border-top' : ''} p-2 ${
          index % 2 === 0 ? '' : 'bg-light'
        }`}
      >
        <div className="row g-2 align-items-center">
          <div className="col-2">
            <Link to={`/seller/inventory/${id}/edit`}>
              <img
                src={image.replace(
                  'https://res.cloudinary.com/luokki/image/upload/',
                  'https://res.cloudinary.com/luokki/image/upload/b_white,c_pad,w_300,h_300/',
                )}
                className="rounded shadow-sm border"
                alt={name}
              />
            </Link>
          </div>
          <div className="col-10">
            <div className="d-flex align-items-center justify-content-between">
              <div className="heading-container" style={{ flex: 1 }}>
                <h6 className="mb-2 small fw-bold">{name}</h6>
                <h6 className="small mb-0 font-ssp">
                  <span>
                    <span className="text-primary fw-bold">
                      {t('$')}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        value={price}
                      />
                    </span>{' '}
                    / {unit}
                  </span>{' '}
                  <span className="text-muted">
                    (
                    <Trans i18nKey="exc_price_label" vat={vat}>
                      Excluding {{ vat }}% VAT
                    </Trans>
                    )
                  </span>
                </h6>
                <h6 className="small mb-0 font-ssp">
                  <span>
                    <span className="text-primary fw-bold">
                      {t('$')}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        value={includingVat}
                      />
                    </span>{' '}
                    / {unit}
                  </span>{' '}
                  <span className="text-muted">
                    (
                    <Trans i18nKey="inc_price_label" vat={vat}>
                      Including {{ vat }}% VAT
                    </Trans>
                    )
                  </span>
                </h6>

                <>
                  <div className="mb-2 mt-2 d-flex align-items-center">
                    <span className="fa fa-building me-2 text-primary"></span>
                    <select
                      name="select"
                      id="select"
                      className="form-control form-select alert-primary small form-select-sm mb-0"
                      value={productOwner}
                      onChange={e =>
                        setState({
                          productOwner: parseInt(e.target.value),
                          hasChanged: true,
                        })
                      }
                    >
                      <option value={0}>{t('personal')}</option>
                      <option value={user.company.id}>
                        {user.company.name}
                      </option>
                    </select>
                  </div>
                  {hasChanged && (
                    <div className="change-confirm d-flex justify-content-end align-items-center mb-1">
                      <button
                        className="btn btn-sm btn-link text-muted text-decoration-none"
                        onClick={() => {
                          setState({
                            hasChanged: false,
                            productOwner: company ? company.id : 0,
                          });
                        }}
                      >
                        <span className="fa fa-times me-1"></span>
                        {t('cancel_button')}
                      </button>
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={handleChange}
                      >
                        <span className="fa fa-check me-1"></span>
                        {t('save_button')}
                      </button>
                    </div>
                  )}
                </>
              </div>
            </div>

            <Modal
              isOpen={open}
              onRequestClose={() => setOpen(false)}
              contentLabel="Modal"
              className="reveal small p-3"
              ariaHideApp={false}
            >
              <div className="modal-container">
                <h6 className="mb-4 text-center">
                  <span className="fa text-warning fa-warning me-1"></span>Are
                  you sure to remove this item?
                </h6>
                <div className="btn-container d-flex justify-content-center align-items-center">
                  <button className="btn w-50 btn-sm btn-secondary">
                    Cancel
                  </button>
                  <button
                    className="ms-2 w-50 btn btn-sm btn-outline-danger"
                    onClick={handleDelete}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <button
                className="btn btn-close close-reveal"
                onClick={() => setOpen(false)}
              >
                &times;
              </button>
            </Modal>
          </div>
        </div>
        <div className="button-group align-items-center d-flex pt-3 ">
          <Link
            to={`/seller/inventory/${id}/edit`}
            className="btn btn-secondary p-1 btn-sm lh-1 fs-12 font-ssp"
          >
            <span className="fa fa-edit me-1"></span>
            {t('edit_text')}
          </Link>
          <button
            className="ms-2 btn btn-danger p-1 btn-sm lh-1 fs-12 font-ssp"
            onClick={() => setOpen(true)}
          >
            <span className="fa fa-trash me-1"></span>
            {t('remove_text')}
          </button>
        </div>
      </div>
    );

  return (
    <div
      className={`inventory-item ${index > 0 ? 'border-top' : ''} p-2 ${
        index % 2 === 0 ? '' : 'bg-light'
      }`}
    >
      <div className="row g-2 align-items-center">
        <div className="col-2">
          <Link to={`/seller/inventory/${id}/edit`}>
            <img
              src={image.replace(
                'https://res.cloudinary.com/luokki/image/upload/',
                'https://res.cloudinary.com/luokki/image/upload/b_white,c_pad,w_300,h_300/',
              )}
              className="rounded shadow-sm border"
              alt={name}
            />
          </Link>
        </div>
        <div className="col-10">
          <div className="d-flex align-items-center justify-content-between">
            <div className="heading-container" style={{ flex: 1 }}>
              <h6 className="mb-2 small fw-bold">
                {name}
                {/* <span className="fs-10">
                  <br />({t('vat_label')}: {vat}%)
                </span> */}
                {/* {unit && (
                  <span className="text-muted fw-normal">
                    <br />
                    <em>{unit}</em>
                  </span>
                )} */}
              </h6>
              {/* <h6 className="small mb-0 text-primary fw-bold">
                {t('$')} {price}
              </h6> */}
              <h6 className="small mb-0 font-ssp">
                <span>
                  <span className="text-primary fw-bold">
                    {t('$')}
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      value={price}
                    />
                  </span>{' '}
                  / {unit}
                </span>{' '}
                <span className="text-muted">
                  (
                  <Trans i18nKey="exc_price_label" vat={vat}>
                    Excluding {{ vat }}% VAT
                  </Trans>
                  )
                </span>
              </h6>
              <h6 className="small mb-0 font-ssp">
                <span>
                  <span className="text-primary fw-bold">
                    {t('$')}
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      value={includingVat}
                    />
                  </span>{' '}
                  / {unit}
                </span>{' '}
                <span className="text-muted">
                  (
                  <Trans i18nKey="inc_price_label" vat={vat}>
                    Including {{ vat }}% VAT
                  </Trans>
                  )
                </span>
              </h6>
              {isCompany && (
                <div className="btn btn-sm d-flex align-items-center p-1 px-2 rounded-3 btn-outline-primary alert alert-primary mt-2 mb-0 fs-12 d-inline-flex">
                  <span className="fa fa-building me-2 text-primary"></span>
                  <span className="text-start" style={{ flex: 1 }}>
                    {company.name}
                  </span>
                </div>
              )}
            </div>
          </div>

          <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <div className="modal-container">
              <h6 className="mb-4 text-center">
                <span className="fa text-warning fa-warning me-1"></span>Are you
                sure to remove this item?
              </h6>
              <div className="btn-container d-flex justify-content-center align-items-center">
                <button className="btn w-50 btn-sm btn-secondary">
                  Cancel
                </button>
                <button
                  className="ms-2 w-50 btn btn-sm btn-outline-danger"
                  onClick={handleDelete}
                >
                  Remove
                </button>
              </div>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => setOpen(false)}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
      <div className="button-group align-items-center d-flex pt-3 ">
        <Link
          to={`/seller/inventory/${id}/edit`}
          className="btn btn-secondary p-1 btn-sm lh-1 fs-12 font-ssp"
        >
          <span className="fa fa-edit me-1"></span>
          {t('edit_text')}
        </Link>
        {((isCompany && isOwner) || !isCompany) && (
          <button
            className="ms-2 btn btn-danger p-1 btn-sm lh-1 fs-12 font-ssp"
            onClick={() => setOpen(true)}
          >
            <span className="fa fa-trash me-1"></span>
            {t('remove_text')}
          </button>
        )}
      </div>
    </div>
  );
}
