import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

import Page from '../../components/global/Page';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { getGeoLocation, getItems } from '../../libs/apis';

import userImg from '../../assets/images/user.png';
import Loader from '../../components/global/Loader';
import InternalHeader from '../../components/global/InternalHeader';
import ProBadge from '../../components/global/ProBadge';
import TransactionHistory from '../../components/global/TransactionHistory';

import CompanyBlock from '../../components/company/CompanyBlock';

function Profile() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, role } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    setLoading(true);
    dispatch(logout());
    setTimeout(() => {
      history.push('/login');
    }, 2000);
  };
  const initialState = {
    lat: '',
    lng: '',
    center: [],
    geolocationStatus: false,
    zoom: 16,
    geoAddress: '',
    items: [],
    isLoading: true,
    isError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    lat,
    lng,
    geolocationStatus,
    center,
    zoom,
    geoAddress,
    items,
    isLoading,
  } = state;
  const {
    address,
    biodata,
    email,
    email_verified_at,
    name,
    phone,
    website,
    photo,
    username,
    bank_account,
    company = null,
    companies = [],
  } = user;

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    navigator.geolocation.getCurrentPosition(
      position => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
        if (user.latitude && user.longitude) {
          const newLat = user.latitude;
          const newLng = user.longitude;
          getInitialGeoLoc(newLat, newLng);
          setState({
            lat: newLat,
            lng: newLng,
            geolocationStatus: true,
            center: [newLat, newLng],
          });
        } else if (!user.latitude || !user.longitude) {
          setState({ geolocationStatus: false });
        } else {
          getInitialGeoLoc(latitude, longitude);
          setState({
            lat: latitude,
            lng: longitude,
            geolocationStatus: true,
            center: [latitude, longitude],
          });
        }
      },
      error => {
        console.error(JSON.stringify(error));
      },
      { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
    );
    const getItemData = async () => {
      await getItems()
        .then(resp => {
          if (resp.status === 200) {
            setState({ items: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    if (role === 'buyer') {
      getItemData();
    }
  }, [role, user]);
  const isSeller = role === 'seller';
  const isPro = user.subscription ? user.subscription : false;
  const rv = process.env.REACT_APP_RELEASE_VERSION
    ? process.env.REACT_APP_RELEASE_VERSION
    : '1.0.0';

  return (
    <div className="app-container">
      <InternalHeader
        go="/"
        title={role === 'company' ? t('profile_page') : t('profile_page')}
      />
      {/* {(role === 'seller' && banner && banner !== '') && (
        <div className="mb-0 text-center">
          <img src={banner} alt={name} />
        </div>
      )} */}
      <div className="profile-container pt-3">
        <Page>
          <div
            className="content pt-3 text-break"
            style={role === 'company' ? { marginTop: '-67px' } : {}}
          >
            <div className="cards mb-1 mt-0">
              <div className="cover-image">
                {photo ? (
                  <img src={photo} alt={name} className="rounded-circle" />
                ) : (
                  <img
                    src={userImg}
                    alt="default avatar"
                    className=" rounded-circle"
                  />
                )}
              </div>
              {isPro && (
                <div
                  className="mb-2 text-center"
                  style={{ marginTop: '-1rem' }}
                >
                  <div className="bg-gold p-2 py-1 rounded-pill pe-3 d-inline-flex">
                    <h6 className="mb-0 text-gold  d-flex align-items-center fs-10 text-uppercase">
                      <ProBadge classes="me-1" />
                      <span className="text">{t('pro_status')}</span>
                    </h6>
                  </div>
                </div>
              )}

              {email_verified_at ? (
                <div className=" bg-transparent  text-center">
                  <p>Your profile is not complete, edit now</p>
                  <Link
                    to="/edit-profile"
                    className="btn btn-warning btn w-100 fw-bold"
                  >
                    Complete My Profile
                  </Link>
                </div>
              ) : (
                <div className=" bg-transparent mt-5">
                  {name ? (
                    <>
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {role === 'company'
                          ? t('company_name_label')
                          : t('name_label')}
                      </h6>
                      <h6 className="d-flex align-items-center">{name}</h6>
                    </>
                  ) : (
                    <>
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {role === 'company'
                          ? t('username_label')
                          : t('username_label')}
                      </h6>
                      <h6 className="">{username}</h6>
                    </>
                  )}
                  {/* {role === 'company' && (
                      <>
                        <h6 className="mb-0 small text-muted opacity-50 text-uppercase">Company ID</h6>
                        <h6 className="">{legal_id}</h6>
                        <hr />
                        {homepage_url && homepage_url !== '' && (
                          <>
                            <h6 className="mb-0 small text-muted opacity-50 text-uppercase">Homepage URL</h6>
                            <h6 className="">{homepage_url.replace('https://', '')}</h6>
                            <hr />
                          </>
                        )}
                      </>
                    )} */}
                  <hr />
                  <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                    {t('email_label')}
                  </h6>
                  <h6 className="">{email}</h6>

                  {isSeller && website && (
                    <>
                      <hr />
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {t('website_label')}
                      </h6>
                      <h6 className="">{website}</h6>
                    </>
                  )}
                  {isSeller && bank_account && (
                    <>
                      <hr />
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {t('bank_account_label')}
                      </h6>
                      <h6 className="">{bank_account}</h6>
                    </>
                  )}
                  {phone && (
                    <>
                      <hr />
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {t('phone_label')}
                      </h6>
                      <h6 className="">{phone}</h6>
                    </>
                  )}
                  {address && (
                    <>
                      <hr />
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {t('address_label')}
                      </h6>
                      <h6 className="">{address}</h6>
                    </>
                  )}
                  {/* <h6 className="mb-0 small text-muted opacity-50 text-uppercase">City</h6> */}
                  {/* <h6 className="">{city}</h6>
                    <hr />
                    </>
                    <h6 className="mb-0 small text-muted opacity-50 text-uppercase">Pin code</h6>
                    <h6 className="">{pincode}</h6> */}
                  {biodata && biodata !== '' && (
                    <>
                      <hr />
                      <h6 className="mb-0 small text-muted opacity-50 text-uppercase">
                        {t('bio_label')}
                      </h6>
                      <p>{biodata}</p>
                    </>
                  )}

                  {geolocationStatus && (
                    <div
                      className="rounded d-none mb-4 p-3 bg-gradient"
                      style={{ background: '#ddd' }}
                    >
                      <div
                        className="mb-3"
                        style={{ height: '50vh', width: '100%' }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                          }}
                          center={center}
                          zoom={zoom}
                          draggable={false}
                        >
                          <Marker lat={lat} lng={lng} text={geoAddress} />
                        </GoogleMapReact>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {role === 'seller' && (
                <div className="company-list p-0 mt-5">
                  <h6 className="mb-3 fw-bold">
                    {/* <span className="fa fa-building me-2"></span> */}
                    {t('com_my_company')}
                  </h6>
                  {!company && (
                    <Link
                      to="/company/create"
                      className="btn-primary w-100 btn mb-3"
                    >
                      {t('com_create_company_button')}
                    </Link>
                  )}
                  {companies.map((company, index) => (
                    <CompanyBlock key={index} data={company} />
                  ))}
                </div>
              )}
              <div className="change-language-container my-4 d-flex align-items-center">
                <h6 className="small mb-0 text-center text-nowrap me-2">
                  {t('change_language')}
                </h6>
                <select
                  className="form-select"
                  onChange={e => {
                    window.location.href = `/profile?lng=${e.target.value}`;
                  }}
                  defaultValue={lang}
                >
                  <option value="fi">{t('lang_finnish')}</option>
                  <option value="en">{t('lang_english')}</option>
                </select>
                {/* <div className="d-flex justify-content-between align-items-center">
                  <a
                    href="/profile?lng=fi"
                    className={`btn  btn-sm w-50 ${
                      lang === 'fi' ? 'btn-primary' : 'btn-light'
                    }`}
                  >
                    <span className="me-2">🇫🇮</span>
                    {t('lang_finnish')}
                  </a>
                  <a
                    href="/profile?lng=en"
                    className={`btn  btn-sm ms-3 w-50 ${
                      lang !== 'fi' ? 'btn-primary' : 'btn-light'
                    }`}
                  >
                    <span className="me-2">🇬🇧</span> {t('lang_english')}
                  </a>
                </div> */}
              </div>
              <div className="mb-5">
                {role === 'company' && (
                  <Link
                    to="/company"
                    className="btn bg-gradient btn-danger border-0 bg-danger w-100 mb-4 btn"
                  >
                    <span className="fa fa-dashboard me-3"></span>
                    {t('go_to_company_dashboard_button')}
                  </Link>
                )}

                <div className="row g-3">
                  <div className="col-6">
                    <Link
                      to="/edit-profile"
                      className="btn btn-outline-primary btn-sm w-100"
                    >
                      {t('edit_profile_button')}
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="/change-password"
                      className="btn btn-outline-primary btn-sm w-100"
                    >
                      {t('change_password_button')}
                    </Link>
                  </div>
                </div>
                <div className="logout-btn-container mt-3">
                  {loading ? (
                    <button className="btn btn-secondary w-100" disabled>
                      Logout..
                    </button>
                  ) : (
                    <button
                      onClick={handleLogout}
                      className="btn bg-gradient btn-secondary w-100"
                    >
                      {t('logout_button')}{' '}
                      <span className="ms-2 fa fa-sign-out"></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {role === 'buyer' && (
            <div className="cards my-3 d-none">
              <div className="card-header py-3">
                <h6 className="mb-0 small text-success text-uppercase">
                  {t('my_horse_label')}
                </h6>
              </div>
              <div className="card-body px-0">
                {items.length > 0 ? (
                  <>
                    <div className=" carousel-wrapper">
                      <div className="my-horse-container">
                        {items.map((horse, index) => (
                          <div className="my-horse-item" key={horse.id}>
                            <div className="card position-relative">
                              <Link
                                className="stretched-link"
                                to={`/horse/${horse.id}`}
                              >
                                <img
                                  src={horse.image}
                                  alt={horse.name}
                                  className="card-img-top"
                                />
                              </Link>
                              <div className="card-body py-2 border-top text-center">
                                <h6 className="m-0 card-text">{horse.name}</h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="px-3 mt-3">
                      <Link to="/horse/new" className="btn btn-warning w-100">
                        <span className="fa fa-plus me-2"></span>{' '}
                        {t('add_horse_button')}
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="px-3 text-center">
                    {isLoading && <Loader size="32" />}
                    <Link to="/horse/new" className="btn btn-warning w-100">
                      <span className="fa fa-plus me-2"></span>{' '}
                      {t('add_horse_button')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </Page>
      </div>
      {(role === 'buyer' || isPro) && (
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="mb-5">
                  <h6 className="mb-3 fw-bold">{t('service_history')}</h6>
                  <TransactionHistory />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-2 text-center">
        <p className="small mb-0">Version: {rv}</p>
      </div>

      {/* <MainMenu /> */}
    </div>
  );
}

export default Profile;

const Marker = ({ text = null }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <div className="icon">
      <span className="fa fa-map-marker text-success"></span>
    </div>
    {text && (
      <div className="text bg-white  p-2 rounded shadow">
        <p className="m-0">{text}</p>
      </div>
    )}
  </div>
);
