import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import WidgetStableList from '../../components/global/WidgetStableList';
import HorseList from '../../components/global/HorseList';
import CustomerList from '../../components/global/CustomerList';
import { useSelector } from 'react-redux';

function Manage() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramTab = query.get('tab') ? query.get('tab') : 'stable';
  const initialState = { tab: paramTab };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { tab } = state;
  const { user, role } = useSelector(state => state.auth);
  const isPro = role === 'seller' && user.subscription;
  if (role === 'seller' && !user.subscription) {
    return (
      <div className="app-container" style={{ height: 'calc(100vh - 108px)' }}>
        <div
          className="container mb-5 d-flex flex-direction-col justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 108px)' }}
        >
          <div className="text-center p-5">
            <h6 className="fw-bold fs-5 mb-3">{t('pro_restriction_title')}</h6>
            <p className="text-muted">{t('pro_restriction_text')}</p>
            <a
              className="btn btn-sm btn-outline-primary"
              target="_blank"
              href="mailto:laskutus@horsesfirst.com"
              rel="noreferrer noopener"
            >
              {t('contact_horsesfirst')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="my-3">
              <div
                className="app-filter-view d-flex align-items-center bg-white"
                style={{ margin: '0 -.75rem' }}
              >
                <button
                  className={`btn mb-0 fw-bold rounded-0 w-50 shadow-none ${
                    tab === 'stable'
                      ? 'active'
                      : 'inactive text-muted opacity-50'
                  }`}
                  onClick={() => setState({ tab: 'stable' })}
                >
                  {t('stable')}
                </button>
                <button
                  className={`btn mb-0 fw-bold rounded-0 w-50 shadow-none ${
                    tab === 'horse'
                      ? 'active'
                      : 'inactive text-muted opacity-50'
                  }`}
                  onClick={() => setState({ tab: 'horse' })}
                >
                  {t('my_horse_label')}
                </button>
                {role !== 'buyer' && isPro && (
                  <button
                    className={`btn mb-0 fw-bold rounded-0 w-50 shadow-none ${
                      tab === 'customer'
                        ? 'active'
                        : 'inactive text-muted opacity-50'
                    }`}
                    onClick={() => setState({ tab: 'customer' })}
                  >
                    {t('my_customer_label')}
                  </button>
                )}
              </div>

              <div className="tab-contents">
                <div
                  id="tab-stable"
                  className={`${tab !== 'stable' ? 'd-none' : ''}`}
                >
                  <WidgetStableList />
                </div>
                <div
                  id="tab-horse"
                  className={`${tab !== 'horse' ? 'd-none' : ''}`}
                >
                  <HorseList />
                </div>
                <div
                  id="tab-customer"
                  className={`${tab !== 'customer' ? 'd-none' : ''}`}
                >
                  <CustomerList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manage;
