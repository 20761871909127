import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import InvoiceSummary from '../../components/seller/InvoiceSummary';
import SubHeader from '../../components/global/SubHeader';

function SellerOrder() {
  const { t } = useTranslation();
  const history = useHistory();
  const { orderId } = useParams();
  const { user, role } = useSelector(state => state.auth);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <SubHeader
        title={t('create_order_summary_heading')}
        go={`/seller/order/${orderId}`}
      />

      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <InvoiceSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerOrder;
