import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import OrderSummary from '../../components/seller/OrderSummary';

function SellerOrder() {
  const history = useHistory();
  const { user, role } = useSelector(state => state.auth);

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <OrderSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerOrder;
