import React from 'react';

export default function Page({ fluid = false, children }) {
  return (
    <div className="container">
      <div className="row">
        <div className={`col ${fluid ? 'g-0' : ''}`}>{children}</div>
      </div>
    </div>
  );
}
