import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Product({ data }) {
  const { t } = useTranslation();
  const {
    image,
    id,
    name,
    seller,
    price_min,
    price_max,
    company = null,
    is_company_active = false,
  } = data;

  const isCompany = !!company;

  return (
    <div className="card mb-3 bg-white shadow-sm">
      <div className="card-body p-2 position-relative">
        <div className="d-flex  justify-content-between">
          {image && (
            <div
              className="p-image me-3"
              style={{ alignSelf: 'center', width: '60px' }}
            >
              <img
                src={image}
                alt={name}
                width="60"
                height="60"
                style={{ height: '60px', objectFit: 'cover' }}
              />
            </div>
          )}
          <div className="p-info" style={{ flex: '1' }}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold fs-6 mb-2">
                <Link to={`/product/${id}`} className="stretched-link">
                  {name}
                </Link>
              </h5>
              <h6 className="small m-0 fw-bold ms-2 text-nowrap">
                <small className="fw-normal text-success">{t('$')}</small>{' '}
                {price_min}-{price_max}
              </h6>
            </div>

            <div className="p-seller d-flex align-items-center mb-2">
              {seller.photo && (
                <img
                  src={seller.photo}
                  alt={seller.name}
                  className="me-2 rounded-circle"
                  width="20"
                  height="20"
                  style={{ height: '20px', objectFit: 'cover' }}
                />
              )}
              <div className="seller-data">
                <h6 className="small m-0">
                  {seller.name ? seller.name : seller.username}
                </h6>
                {/* {seller.address && (
                  <h6 className="small text-muted fs-12 m-0 mt-1 opacity-75"><span className="fa fa-map-marker text-danger me-1"></span>{seller.address}</h6>
                )} */}
              </div>
            </div>
            {isCompany && is_company_active && (
              <div className="company-info d-flex align-items-center">
                <div className="btn btn-sm d-flex align-items-center p-0 px-1 fs-12 rounded-3 btn-outline-primary alert alert-primary mb-1">
                  <span className="fa fa-building me-2 text-primary"></span>
                  <span className="text-start" style={{ flex: 1 }}>
                    {company.name}
                  </span>
                </div>
              </div>
            )}
            <h6 className="text-muted d-none mb-0 small fs-12 opacity-75">
              <span className="fa fa-star me-1 text-warning"></span>
              <em>
                {Math.floor(Math.random(10) * 100, 0)}{' '}
                {t('order_summary_prefix')} 100 {t('order_summary_suffix')}
              </em>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}
