import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTransaction, sendInvoiceEmail } from '../../libs/apis';
import LoadMore from '../global/LoadMore';
import Loader from '../global/Loader';
import { numberFormat } from '../../libs/utils';

export default function OrderSummary({ data }) {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
    current: 1,
    last: 99,
    hasNext: true,
    isLoadMore: false,
    isEmailSuccess: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { orders, isLoading, current, hasNext, isLoadMore, isEmailSuccess } =
    state;

  const sendEmail = async id => {
    setState({ isEmailSuccess: false });
    const payload = {
      id,
    };
    await sendInvoiceEmail(payload).then(resp => {
      if (resp.status === 200) {
        setState({ isEmailSuccess: true });
      }
    });
  };

  useEffect(() => {
    const getOrderData = async () => {
      const payload = {
        statusFrom: 4,
      };
      await getTransaction(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
            const page = resp.data.metadata.pagination.page || null;
            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, []);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
    };
    await getTransaction(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ orders: [...orders, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };

  if (!user) return null;

  return (
    <div className="order-container">
      <div className="content pb-3">
        <div className="d-flex align-items-center mb-3 justify-content-between">
          <h6 className="mb-0 text-dark text-uppercase fw-bold">
            {t('invoice_text')}
          </h6>
          <Link
            to="/seller/invoice/export"
            className="btn btn-sm  rounded-pill btn-primary"
          >
            <strong>{t('export_all_text')}</strong>
          </Link>
          {/* <select name="" id="" className="form-select form-select-sm w-auto">
            <option value="">All Order </option>
            <option value="">New Order </option>
            <option value="">Confirmed Order </option>
            <option value="">Cancelled Order </option>
            <option value="">Completed Order </option>
          </select> */}
        </div>

        {orders.length > 0 ? (
          <div className="order-list-container">
            {orders.map(order => {
              const {
                id,
                product,
                transaction_items,
                transaction_request,
                pdf,
              } = order;
              if (!pdf) return null;

              return (
                <div
                  key={id}
                  className={`card shadow-sm bg-white position-relative mb-3 border`}
                >
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="fw-bold text-dark mb-1">
                          {product.name} -{' '}
                          <span className="small text-success">
                            {t('$')}{' '}
                            {numberFormat(transaction_request[0].price)}
                          </span>
                        </h6>
                        <div className="mb-1 fs-12 d-flex flex-wrap align-items-center">
                          {transaction_items.map((h, i) => (
                            <h6
                              className="mb-1 me-1 shadow-sm small d-flex align-items-center rounded-pill p-1 bg-primary px-2 text-nowrap mb-0 text-light text-center fs-12 text-break"
                              key={h.id}
                            >
                              {/* {h.item.image && (
                    <img src={h.item.image} alt={name} className="me-1 rounded-circle shadow-sm" width="20" height="20" style={{ height: '20px', objectFit: 'cover' }} />
                  )} */}
                              {h.item.name}
                            </h6>
                          ))}
                        </div>
                        <Link
                          to={`/seller/order/${id}`}
                          className="btn btn-sm p-1 lh-1 bg-light bg-gradient border-secondary opacity-75 fs-12"
                        >
                          <span className="fa fa-search me-1"></span>
                          {t('view_order_button')} #{id}
                        </Link>
                      </div>
                      <div className="text-right d-flex justify-content-end align-items-center">
                        <a
                          className="btn btn-sm p-1 lh-1"
                          href={pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="fa fa-file-pdf-o d-block mb-1 text-danger"></span>
                          <span className="text d-block fs-12">
                            {t('download_text')}
                          </span>
                        </a>
                        <button
                          className="btn btn-sm ms-2 p-1 lh-1"
                          onClick={() => sendEmail(id)}
                        >
                          <span className="fa fa-send-o d-block mb-1 text-success"></span>
                          <span className="text d-block fs-12">
                            {t('send_text')}
                          </span>
                        </button>
                      </div>
                      <Modal
                        isOpen={isEmailSuccess}
                        onRequestClose={() =>
                          setState({ isEmailSuccess: false })
                        }
                        contentLabel="Modal"
                        className="reveal small p-3"
                        ariaHideApp={false}
                      >
                        <div className="pt-4">
                          <h6 className="mb-3 text-center text-success fw-bold">
                            Success sending invoice to buyer's email.
                          </h6>
                          <button
                            onClick={() => setState({ isEmailSuccess: false })}
                            className="btn btn-sm btn-secondary w-100"
                          >
                            Close
                          </button>
                        </div>
                        <button
                          className="btn btn-close close-reveal"
                          onClick={() => setState({ isEmailSuccess: false })}
                        >
                          &times;
                        </button>
                      </Modal>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center p-3">
            {isLoading ? (
              <Loader center size="24" />
            ) : (
              <h6>{t('there_is_no_order')}</h6>
            )}
          </div>
        )}
        {hasNext && <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />}
      </div>
    </div>
  );
}
