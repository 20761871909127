import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './config/routes.js';
import AppRoute from './components/AppRoute';
import ScrollToTop from './components/ScrollToTop';

import { getUserToken } from './libs/utils';
import { getProfile } from './libs/apis';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from './redux/slices/authSlice';

import { Helmet } from 'react-helmet';

function App() {
  const rv = process.env.REACT_APP_RELEASE_VERSION
    ? process.env.REACT_APP_RELEASE_VERSION
    : '1.0.0';

  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const initialState = {
    loading: true,
    error: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { loading } = state;

  const token = getUserToken() || null;
  useEffect(() => {
    const checkUser = async t => {
      await getProfile(token)
        .then(resp => {
          if (resp.status === 403) {
            setState({ error: true });
            dispatch(logout());
          } else {
            if (resp.data.response !== undefined) {
              const payload = {
                user: resp.data.response,
                token: token,
              };
              dispatch(login(payload));
            } else {
              setState({ error: true });
              dispatch(logout());
            }
          }
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => setState({ loading: false }));
    };
    if (token && !user) {
      checkUser(token);
    } else if (!token && !user) {
      setState({ loading: false });
      dispatch(logout());
    } else {
      setState({ loading: false });
    }
  }, [token, user, dispatch]);

  if (loading) {
    return null;
  }

  return (
    <Router>
      <Helmet>
        <meta name="release-version" content={rv} />
      </Helmet>
      <ScrollToTop />
      <Switch>
        {routes.map(route => (
          <AppRoute
            user={user}
            key={route.path}
            path={route.path}
            component={route.component}
            isPrivate={route.isPrivate}
            showHeader={route.showHeader}
            showMenu={route.showMenu}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default App;
